import React from 'react';
import useAllUserFilters from '../../admin_hooks/useAllUserFilters';

const IssueListFilter = ({params, changeOrder}) => {

    const {openFilter} = useAllUserFilters()

    return (
        <div className="issue_filters">

            <div className="filter_item">

                <div className="sort_filter_wrapper">

                    <div className="sort_name main_card">Field: <span>{params.field}</span></div>

                    <button className="icon_button" onClick={(e) => openFilter(e)}>
                        <div className="admin_icon_arrows_right"></div>
                    </button>

                    <div className="user_filter_list" onClick={(e) => changeOrder(e, 'field')}>
                        <p className='active'>date</p>
                        <p>issued</p>
                    </div>

                </div>
            </div>

            <div className="filter_item">

                <div className="sort_filter_wrapper">

                    <div className="sort_name main_card">Order: <span>{params.order}</span></div>

                    <button className="icon_button" onClick={(e) => openFilter(e)}>
                        <div className="admin_icon_arrows_right"></div>
                    </button>

                    <div className="user_filter_list" onClick={(e) => changeOrder(e, 'order')}>
                        <p className='active'>ASC</p>
                        <p>DESC</p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default IssueListFilter;