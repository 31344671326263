import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { NavLink } from 'react-router-dom';
import AdminNewsBtn from './AdminNewsBtn';
import Api from '../../../Api';
import Notice from '../../../Notice';
import AdminEditNewsBtn from './AdminEditNewsBtn';
import Store from '../../../Store';
import AdminNewsDelBtn from './AdminNewsDelBtn';


const NewsSplider = ({role}) => {

    let [newsList, setNewsList] = useState([])
    let [load, setLoad] = useState(false)
    const sliderRef = useRef(null)

    useEffect(() => {
        (async () => {
            let init = await Api.asyncGet('api/v1/news/all')
            if(init === 'error') {
                return Notice.Send({type: 'error', text: 'Ошибка сервера при получении новостей'})
            }
            if(init && init.items.length > 0) {
                setNewsList(init.items)
            }
            setLoad(prev => prev = true)
        })()
    }, [])

    function editNewsAdmin(data) {
        if(newsList?.length) {
            setNewsList(newsList.map(el => {
                if(el.id === data.id) {
                    return data
                }
                return el
            }))
        } else {
            setNewsList([data])
        }
    }

    Store.useListener('ADMIN_EDIT_NEWS_GLOBAL', editNewsAdmin)

    function addNewsAdmin(data) {
        setNewsList(prev => [data, ...prev])
    }

    Store.useListener('ADMIN_CREATE_NEWS_GLOBAL', addNewsAdmin)

    function adminDeleteNews(id) {
        setNewsList(prev => prev.filter(el => el.id != id))
    }

    Store.useListener('ADMIN_DELETE_NEWS_GLOBAL', adminDeleteNews)


    return (
        <div className="news_splider" id='splider'>
            <div className="grid_nav_item_header news">
                <div className="game_icon">
                    <div className="icon_arrow_left" onClick={() => sliderRef.current.splide.go('<')}></div>
                    <div className="admin_icon_news"></div>
                </div>
                <NavLink to='/' className='game_title'>News</NavLink>

                <div className="button_wrapper">
                    {role === 'ADMIN' && <AdminNewsBtn/>}
                    
                    <div className="icon_arrow_right" onClick={() => sliderRef.current.splide.go('>')}></div>
                    <div className="decor pink"></div>
                </div>
                
            </div>

            {load ? 
                <>
                    {newsList && newsList.length > 0 ? 
                        <Splide 
                            ref={sliderRef}
                            options={ {
                                rewind       : true,
                                gap          : '0px',
                                autoplay     : true,
                                pauseOnHover : true,
                                autoWidth    : true,
                                autoHeight   : true,
                                perPage      : 1,
                                padding      : '0px',
                                interval     : 10000,
                                speed        : 3000,
                                arrows       : false,
                            } } 
                            className='news_splider_content'
                            aria-label="News">
                                {newsList.map(el => (
                                    <SplideSlide key={el.id}>
                                        <NavLink><img src={el.image} alt="" /></NavLink>

                                        {role === 'ADMIN' &&
                                            <div className="admin_manage">
                                                <AdminEditNewsBtn data={el}/>
                                                <AdminNewsDelBtn id={el.id}/>
                                            </div>
                                        }

                                    </SplideSlide>
                                ))}
                        </Splide>
                        :
                        <div className="loading_news">Sorry, there are no news...</div>
                    }
                </>
                
            :
                <div className="loading_news">Loading...</div>
            }
                
        </div>
    );
};

export default NewsSplider;