import React, { useState } from 'react';
import Store from '../../../Store';
import Api from '../../../Api';
import Notice from '../../../Notice';

const TfaConfirm = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [authCode, setAuthCode] = useState('')
    const [error, setError] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)
    const [options, setOptions] = useState('')

    Store.useListener('CONFIRM_TFA_POPUP', (data) => {
        setOptions(data)
        // setPath(prev => prev = data.path)
        // setMarker(prev => prev = data.marker)
        setIsOpen(prev => prev = true)
    })

    function onInput(e) {
        setError(false)

        if(!e.target.value) {
            setError(true)
        }

        let value = e.target.value.replace(/[^0-9]/g,'')

        setAuthCode(value)
    }

    async function sendCode (e) {

        if(authCode === '') {
            setError(true)
            return
        }

        setLoadBtn(prev => prev = false)

        try {
            let res = await fetch(`${Api.url}/${options.path}`,{
                method: options.method,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-OTP-Code': authCode.toString(),
                    'X-Client-Id': options.marker === 'GIVE_MONEY' ? options.userId : '',
                },
                credentials: 'include',
            })

            res = await res.json()
            setLoadBtn(prev => prev = true)

            console.log(options)

            if(res.statusCode) {
                setError(true)
                Notice.Send({type: 'error', text: `${res?.exception?.message || 'Error. Try again later'}`})
                return
            }

            if(options.marker === 'GIVE_MONEY') {
                Store.setListener('GIVE_MONEY')
                closeQR()
                return
            } 
            if(options.marker === 'CHANGE_ROLE1') {
                Store.setListener('TFA_CHANGE_USER_ROLE1', res.role)
                closeQR()
                return
            }
            if(options.marker === 'CHANGE_ROLE2') {
                Store.setListener('CHANGE_ROLE_IN_USERS', res)
                Store.setListener('TFA_CHANGE_USER_ROLE', res.role)
                closeQR()
                return
            }

            return
        } catch (e) {
            console.log('here')
            Notice.Send({type: 'error', text: 'Error. Try again later'})
            return 
        }
    }

    function closeQR () {
        setAuthCode('')
        setOptions({})
        setError(false)
        setIsOpen(prev => prev = false)
    }

    if(!isOpen) return null

    return (
        <div className='tfa_confirm_wrapper' onMouseDown={closeQR}>
            <div className="tfa_confirm_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Cofirm 2fa</div>
                    <div className="decor pink" onMouseDown={closeQR}></div>
                </div>

                <div className="tfa_confirm_popup_content">

                    <p>This account is protected by Two-Factor Authentication. Please enter the code below to continue.</p>

                    <input
                        type="text"
                        inputMode='numeric' 
                        className={`main_input main_card ${error ? 'error' : ''}`} 
                        placeholder='Enter code...'
                        value={authCode}
                        onChange={(e) => onInput(e)}
                        onBlur={(e) => setError(false)}
                    />

                    <button className="common_button full green"
                        onMouseDown={() => sendCode()}
                    >
                        {loadBtn ? 'confirm' : 'Loading...'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TfaConfirm;