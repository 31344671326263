import React, { useRef, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import betImg from './../images/bet.svg'
import Bet from '../../../Bet';

const YourBet = ({bet, setBet, error, setError}) => {

    const changeBet = (value, callback) => {
        setError(false)
        Bet.setBets(value, callback)
    }

    const plusBet = (value, stat, callback) => {
        setError(false)
        Bet.plus(value, stat, callback)
    }

    const multiBet = (value, stat, callback) => {
        setError(false)
        Bet.multi(value, stat, callback)
    }

    const divideBet = (value, stat, callback) => {
        setError(false)
        Bet.divide(value, stat, callback)
    }

    const maxBet = (stat, callback) => {
        setError(false)
        Bet.maxBet(stat, callback)
    }

    const clearBet = (callback) => {
        setError(false)
        Bet.clear(callback)
    }

    return (
        <div className="your_bet">
            <div className={`label ${error ? 'error' : ''}`} htmlFor="">Your bet</div>

            <div className={`bet ${error ? 'error' : ''}`}  id='roulette_bet'>
                <div className="coin">
                    <img src={coin} alt="" />
                </div>
                
                <div className={`input_wrapper`}>
                    <input type="text" value={(bet)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(e) => changeBet(e.target.value, setBet)}  className='' placeholder='Enter your bet...'/>
                    <button className="common_button full flex black capitalize" onClick={() => clearBet(setBet)}>Clear</button>
                </div>
            </div>
            
            <div className="bet_plurals">
                <div className="plural_wrapper">
                    <button className="common_button full flex" onClick={() => plusBet(10, bet, setBet)}>+10</button>
                    <button className="common_button full flex" onClick={() => plusBet(1, bet, setBet)}>+1</button>
                    <button className="common_button full flex" onClick={() => plusBet(100, bet, setBet)}>+100</button>
                </div>
                <div className="plural_wrapper">
                    <button className="common_button full flex" onClick={() => plusBet(1000, bet, setBet)}>+1,000</button>
                    <button className="common_button full flex" onClick={() => divideBet(2, bet, setBet)}>1/2</button>
                    <button className="common_button full flex" onClick={() => multiBet(2, bet, setBet)}>x2</button>
                    <button className="common_button black capitalize" onClick={() => maxBet(bet, setBet)}>Max</button>
                </div>
            </div>

        </div>
    );
};

export default YourBet;