import React, { useRef } from 'react';

import avatar from './../../../images/avatar.svg'
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import caseImage from './../images/case.png'
import { NavLink } from 'react-router-dom';
import NotItems from '../../../components/NotItems';
import Avatar from '../../../components/avatar/Avatar';
import GameView from './GameView';
import GameHistoryItem from './GameHistoryItem';
import LoadingItems from '../../../components/LoadingItems';


const CaseBtlList = ({getType, gameHistory, load}) => {
    return (
        <div className="case_battles_list_wrapper">

            {load ? 
            gameHistory?.length ?
                <div className="case_battles_list">

                    {gameHistory.map(((el, i) => (
                        <GameHistoryItem getType={getType} el={el} key={i}/>   
                    )))}             

                </div>
            :<NotItems />
            :<LoadingItems />}

        </div>
    );
};

export default CaseBtlList;