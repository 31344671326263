import { useEffect, useState } from "react";
import Api from "../../../../../Api";
import Notice from "../../../../../Notice";

export default function useStats () {
    const [userStat, setUserStat] = useState(false)

    useEffect(() => {
        (async () => {
            const init = await Api.asyncGetCred('api/v1/clients/stats')
            if(!init || init === 'error') {
                return Notice.Send({type: 'error', text: 'Server error'})
            }
            console.log(init)
            setUserStat(init)
        })()
    }, [])

    return {userStat}
}