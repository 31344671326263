import React, { useState } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

import Header from './components/Header';
import Chat from './components/chat/Chat';
import Drops from './components/Drops';
import ScrollToTop from './utils/ScrollToTop';
import LoginPopUp from './components/login/LoginPopUp';
import MobilePanel from './components/mobile_panel/MobilePanel';
import MainContainer from './MainContainer';
import useSocketAuth from './hooks/useSocketAuth';
import Preloader from './components/preloader/Preloader';
import Notice from './Notice';
import ChatFlex from './components/chat/ChatFlex';
import DropsFlex from './components/DropsFlex';
import AddCases from './admin/AddCases';
import EditItem from './admin/EditItem';
import EditCategories from './admin/EditCategories';
import SkinPopUp from './components/skin_pop_up/SkinPopUp';
import AllUsers from './admin/AllUsers';
import useAuth from './hooks/useAuth';
import GiveMoneyPopUp from './admin/components/all_users/GiveMoneyPopUp';
import AddNews from './admin/AddNews';
import MutePopUp from './admin/MutePopUp';
import useMode from './hooks/useMode';
import CaseBattlesPreviewPasswordPopUp from './pages/case_battles/components/CaseBattlesPreviewPasswordPopUp';


const App = () => {

  const [mode, setMode] = useState('dark')
  //const [mode] = useMode()
  const { isAuth, loader, role } = useAuth()
  let [wager] = useSocketAuth(isAuth)

  return (

    <>
  
    {loader === true ? 

      <Preloader mode={mode}/> 
    
    :
      
      <div className={`main_page ${mode}`}>
        <Router>
          <ScrollToTop />
          <Notice.Init />
          <Header isAuth={isAuth} role={role} wager={wager}/>

          <Chat auth={isAuth} role={role}/>
          <div className="main_flex">

            <ChatFlex />

            <MainContainer isAuth={isAuth} role={role} mode={mode}/>

            <DropsFlex />

          </div>

          <Drops isAuth={isAuth}/>
          <MobilePanel isAuth={isAuth}/>

          {/* ----Админка---- */}

          {role === 'ADMIN' ?
            <>
              <AddCases/>
              <EditItem/>
              <EditCategories/>
              <AllUsers/>
              <AddNews/>
              <MutePopUp/>
            </>
            : <></>
          }

          {/* ----Админка---- */}

        </Router>

        {/*----Всплывающие окна----*/}

          <LoginPopUp />
          
          {/* -----Для игр----- */}

          <SkinPopUp />
          <CaseBattlesPreviewPasswordPopUp />

        {/*----Всплывающие окна----*/}

      </div>
    }
    </>
  );
};

export default App;

