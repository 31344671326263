import React from 'react';

const CaseTypeFlex = ({setCaseParams, caseParams}) => {

    function changeCaseType (e, value) {
        e.preventDefault()
        setCaseParams(prev => ({...prev, ['type']: value}))
    }

    return (
        <div className="case_type_flex">

            <button className={caseParams.type === 'paid' ? 'common_button full flex active' : 'common_button full flex'}  onClick={(e) => changeCaseType(e, 'paid')}>
                <div className="admin_icon_mark"></div>
                For money ept
            </button>

            <button className={caseParams.type === 'free' ? 'common_button full flex active' : 'common_button full flex'} onClick={(e) => changeCaseType(e, 'free')}>
                <div className="admin_icon_mark"></div>
                Free
            </button>

        </div>
    );
};

export default CaseTypeFlex;