import { useEffect, useState } from 'react';
import Api from '../../Api';

export default function useOpenCase(id) {

    const [caseDetail, setCaseDetail] = useState([])
    let [items, setItems] = useState(false)
    const [free, setFree] = useState(false)

    useEffect(() => {
        (async () => {
            let caseInit = await Api.asyncGet(`api/v1/case/${id}`)
            if(caseInit === 'error'){
                console.log('error')
                return
            }
            if(caseInit.type === 'free'){
                setFree(prev => prev = true)
            }
            setCaseDetail(caseInit)

            let result = []

            while (result.length < 60) {

                caseInit.fakeItems.forEach((e) => {

                        switch (e.fakeItem.type) {
                            case 'red':
                                result.push(e)
                                break;
        
                            case 'orange':
                                result.push(e)
                                break;
                            case 'purple':
                                for (let i = 0; i < 2; i++) {
                                    result.push(e)
                                }
                                break;
                            case 'blue':
                                for (let i = 0; i < 3; i++) {
                                    result.push(e)
                                }
                                break;
                            case 'green':
                                for (let i = 0; i < 4; i++) {
                                    result.push(e)
                                }
                                break;
                            default:
                                break;
                        }

                })

            }

            setItems(result)

        })()

    }, [id])

    return {items, caseDetail, free}
}