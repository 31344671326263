import React, { useCallback, useEffect, useRef, useState } from 'react';

import avatar from './../../images/avatar.svg'
import coin from './../../fonts/main_icon_fonts/coin.svg'
import info from './../../fonts/main_icon_fonts/info.svg'
import live from './../images/live.svg'

import ChatList from './components/ChatList';
import SendMess from './components/SendMess';
import Avatar from '../avatar/Avatar';
import LocationBlock from './components/LocationBlock';
import Store from '../../Store';
import useChat from '../../socket/useChat';
import LoadingItems from '../LoadingItems';
import NotItems from '../NotItems';
import pin from './images/pin.svg'
import ChatMutePopUp from './components/ChatMutePopUp';
import Notice from '../../Notice';


const Chat = (props) => {
    
    const {gameStatus, participant, joinRainPool, load, socket, chatMessages, online, pinMessage, sendMassage, pinMessageFunc, unPinMessageFunc, rainPool, banUser, deleteMess, rainTimer, changeRoom } = useChat(props.auth, props.role)

    const [isOpen, setIsOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [openPopUp, setOpenPopUp] = useState(false)

    Store.useListener('openChat', setMobileOpen)

    const btnWrapper = useRef(null)

    function openChatMenu() {
        setIsOpen(prev => !prev)
        Store.setListener('chatFlex', prev => !prev)
    }

    function updateDistance() {
        if (btnWrapper.current) {
            const rect = btnWrapper.current.getBoundingClientRect();
            const elementBottomPosition = rect.bottom + window.scrollY;
            const screenWidth = window.innerWidth;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );

            const distanceToBottom = pageHeight - elementBottomPosition;

            if (distanceToBottom <= 250) {
                if(screenWidth > 1700) {
                    btnWrapper.current.style.paddingBottom = `${220 - distanceToBottom}px`;
                } else {
                    btnWrapper.current.style.paddingBottom = `${250 - distanceToBottom}px`;
                }
            } else {
                btnWrapper.current.style.paddingBottom = `0px`;
            }
        }
    }

    useEffect(() => {

        window.addEventListener('scroll', updateDistance);

        return () => {
            window.removeEventListener('scroll', updateDistance);
        };

    }, [])

    useCallback(() => {
        return () => {
            window.removeEventListener('scroll', updateDistance);
        };
    }, [])

    return (
        <>
            <div className={`chat_menu ${isOpen ? 'close' : ''} ${mobileOpen ? 'open': ''}`} id='chat_menu'>
                <div className="chat_wrapper">

                    <div className="sticky_btn_wrapper right" ref={btnWrapper}>
                        <button className={`icon_button sticky_btn ${isOpen ? 'close' : ''}`} id='chat_btn' onClick={openChatMenu}>
                            <div className="icon_arrow_left"></div>
                        </button>
                    </div>

                    <div className="live_info_wrapper">
                        {gameStatus && props.auth ?
                            <div className="join_mess">
                                <button className={`common_button  flex full ${participant ? 'close pink' : 'green'}`} disabled={participant} onClick={joinRainPool}>
                                    {load ? 
                                        'Loading...'
                                    :
                                    <>
                                    {participant
                                    ?'You are joined!'
                                    :'Join rain pool'}
                                    </>
                                    }
                                </button>
                            </div>
                        :<></>}
                        <div className="live_info main_card">
                            <div className="status_block">
                                <h3 className='status'>LIVE <img src={live} alt="" /></h3>
                                <p><span onClick={() => Notice.Send({type: 'error', text: 'efdf'})}>RAIN POOL</span> <img src={info} alt="" /></p>
                            </div>
                            <div className="pool main_card">{(rainPool).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <img src={coin} alt="" /></div>
                            <div className="rain_pool_progress_bar">
                                <span className="rain_pool_progress" style={{transform: `translateX(-${rainTimer}%)`}}></span>
                            </div>
                        </div>
                    </div>
                    
                    <div className='track_wrapper'>

                        {chatMessages ? 
                            <>
                                {chatMessages.length ? 
                                    <ChatList  role={props.role} chatMessages={chatMessages} pinMessageFunc={pinMessageFunc} setOpenPopUp={setOpenPopUp} deleteMess={deleteMess}/>
                                :
                                    <NotItems />
                                }
                            </>
                        :
                            <LoadingItems />
                        }

                    </div>

                    {pinMessage &&
                        <div className="sticky_message main_card">
                            {props.role === 'ADMIN' || props.role === 'MODERATOR' ?
                                <button className="unpin_btn" onClick={unPinMessageFunc}></button>
                            :
                                <img src={pin} alt="" className="pin_icon" />
                            }
                            
                            <Avatar 
                                img={pinMessage.user.avatar}
                                lvl={pinMessage.user.client.playerAccount.level}
                                percent={(pinMessage.user.client.playerAccount.experience / pinMessage.user.client.playerAccount.experienceBorderTillLevel) * 100}
                                color={'rgb(233, 63, 63)'}
                                size={40}
                                rad={17}
                                dasharray={107}
                            />

                            <div className="message_content">
                                <div className="name_block">
                                    {pinMessage.user.role === 'MODERATOR' && <div className="role moderator">{pinMessage.user.role}</div>}
                                    <h4 className="name">{pinMessage.user.username}:</h4>
                                </div>
                                <div className="message">{pinMessage.message}</div>
                            </div>
                        </div>
                    }

                    <LocationBlock online={online} changeRoom={changeRoom}/>

                    {socket.current && <SendMess sendMassage={sendMassage}/>}
                </div>
            </div>
            {props.role === 'ADMIN' || props.role === 'MODERATOR' ? <ChatMutePopUp banUser={banUser} openPopUp={openPopUp} setOpenPopUp={setOpenPopUp}/> : <></>}
        </>
        
    );
};

export default Chat;