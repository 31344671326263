import React, { useRef } from 'react';

import LoadingItems from '../../../components/LoadingItems';
import useItemList from './../hooks/useItemsList'
import NotItems from '../../../components/NotItems';
import Item from './Item';
import SearchBlock from './SearchBlock';

const ItemsGrid = () => {

    const {load, itemList, params, changeParams, price, changePriceFilter, searching} = useItemList()

    var betList = useRef([])

    return (
        <>
            <SearchBlock {...{changeParams, params, price, changePriceFilter, searching}}/>

            <div className="items_grid" >
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="upgrade_icon_upgrade"></div>
                    </div>
                    <h4 className='game_title'>Site items</h4>
                    <div className="decor pink"></div>
                </div>
                <div className="grid_wrapper">
                    {load ? 
                        <>
                            {itemList?.length ? 
                                <div className="grid_element">
                                    {itemList.map((el, i) => (
                                        <Item key={el.id} el={el} betList={betList} index={i}/>
                                    ))}
                                </div>
                            :
                                <NotItems />
                            }
                        </>
                    :
                        <LoadingItems />
                    }
                </div>
            </div>
        </>
    );
};

export default ItemsGrid;