import { useCallback, useEffect, useRef, useState } from "react"
import Games from "../../../Games"
import Api from "../../../Api"


export default function useBattleRoulette(round, result, cases) {

    const [itemsOpenList, setItemsOpenList] = useState([])
    
    const play = useRef(null)
    const roullete = useRef(null)
    const winItem = useRef(null)


    const spinRoullete = () => {

        if(roullete.current) {
            const elHeight = Games.getHeight(roullete)
            const randomize = Math.floor(Math.random() * elHeight) - (elHeight / 2);

            roullete.current.style.transitionTimingFunction = `cubic-bezier(0.2, 0, 0, 1)`
            roullete.current.style.transitionDuration = '3s'
            roullete.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px - ${elHeight / 2}px + ${randomize}px))`

            play.current = setTimeout(() => {
                roullete.current.style.transitionDuration = '0.5s'
                roullete.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px - ${elHeight / 2}px))`
                winItem.current.style.transitionDuration = '0.5s'
                winItem.current.style.transform = 'scale(1.15)'
            }, 3500)
        }
    }

    const launchGame = async (init) => {
        setItemsOpenList((prev) => {
          const newList = Games.shuffle([...prev]);
          newList[newList.length - 10] = {
            fakeItem: {
              id: init?.fakeItem.id,
              name: init?.fakeItem.name,
              image: init?.fakeItem.image,
              type: init?.fakeItem.type,
              price: init?.fakeItem.price,
            },
          };
          return newList;
        });
    
        await Api.updateAccount();
        spinRoullete();
    };

    const resetRoulete = () => {
        if(roullete.current) {
            const elHeight = Games.getHeight(roullete)
            roullete.current.style.transitionTimingFunction = ''
            roullete.current.style.transitionDuration = ''
            roullete.current.style.transform = `translateY(calc(50% - ${(elHeight * 2) + (elHeight / 2)}px))`
        }
        if(winItem.current) {
            winItem.current.style.transitionDuration = ''
            winItem.current.style.transform = 'scale(1)'
        }
    }

    useEffect(() => {

        if(result && result.length > 0) {
            if(round > 1) {
                resetRoulete()
            }
            setItemsOpenList(structuredClone(cases[round]) || [])
            launchGame(result[round - 1])
        }

        return () => {
            clearTimeout(play.current)
        }

    }, [result])

    useEffect(() => {
        return () => {
            clearTimeout(play.current)
        }
    }, [])

   // console.log(`list ${round}`, itemsOpenList)

    return {itemsOpenList, winItem, roullete}

}