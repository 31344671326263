import React, { useEffect, useState } from 'react';
import Api from '../../../Api';
import Notice from '../../../Notice';
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import CrashLastListItem from './CrashLastListItem';
import ProvablyModal from './provably_fair/ProvablyModal';

const CrashLastList = ({colorUpdate, last12, setLast12, crashLast}) => {

    const [load, setLoad] = useState(false)

    useEffect(() => {

        (async () => {

            let res = await Api.asyncGet('api/v1/game-crash/last')

            if(res === 'error') {
                Notice.Send({type: 'error', text: 'Server error'})
                return
            }
            setLast12(res)
            setLoad(true)
        })()

    }, [])

    return (
        <div className="crash_last_list_wrapper">
            <label htmlFor="">Last:</label>
            
            <div className="crash_last_list" ref={crashLast}>
                {load ? 
                    <>
                        {last12?.length ? 
                            <>
                                {last12.map((el, i) => (
                                    <CrashLastListItem key={i} colorUpdate={colorUpdate} el={el} />
                                ))}
                            </>
                        :
                            <div className="no_items">Nothing here.</div>
                        }
                    </>
                :
                    <div className="load">Loading...</div>
                }

                <ProvablyModal />
            </div>
        </div>
    );
};

export default CrashLastList;