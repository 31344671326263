import { useEffect, useMemo, useState } from "react";
import Notice from "../../../../../Notice";
import Games from "../../../../../Games";

export default function useHistory(params, setParams, URL, gameType, setGameType) {
    const [load, setLoad] = useState(false)
    const [list, setList] = useState(false)

    useEffect(() => {
        (async () => {
            setLoad(prev => prev = false)
            let query
            if(gameType) {
                query = new URLSearchParams(params)
                if(gameType === 'all') {
                    const range = ['1vs1', '1vs1vs1', '1vs1vs1vs1', '2vs2']
                    range.map(el => {
                        query.append('gameType', el)
                    })
                } else {
                    query.append('gameType', gameType)
                }
            } else {
                query = new URLSearchParams(params).toString();
            }
            let res = await Games.gameHistory(`api/v1/${URL}?${query}`)

            setLoad(true)

            if(!res || res === 'error') {
                return Notice.Send({type: 'error', text: 'Server error'})
            }
            res.items.map(el => {
                el.time = el.time.split(' ')
            })
            setList(res)
        })()

    }, [params, gameType])

    const changeFilters = (e, name) => {
        setLoad(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el === e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        const value = e.target.textContent === 'payout' ? 'totalPayout' : e.target.textContent
        setParams(prev => ({...prev, [name]: value}))
    }

    const changeDate = (value) => {
        setLoad(false)
        const sort_list = document.getElementById('sort_list')
        const itemList = sort_list.querySelectorAll('p')
        itemList.forEach(el => {
            if(el.textContent === 'date') {
                el.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, 'field': 'date', 'order': value}))
    }

    const changeGameType = (e) => {
        setLoad(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el === e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setGameType(prev => prev = e.target.textContent)
    }

    const functions = useMemo(() => ({
        changeFilters, 
        changeDate, 
        changeGameType
    }),[])

    return {load, list, ...functions}
}