import React, { useEffect, useState } from 'react';
import ThatsMe from './dash_board/ThatsMe';
import CollectBlock from './dash_board/CollectBlock';

const Collect = () => {
    const [user, setUser] = useState(false)

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('client')))
    }, [])

    return (
        <div className="dashboard_grid_item collect">
            
            <ThatsMe user={user}/>

            <CollectBlock userId={user.id}/>

        </div>
    );
};

export default Collect;