import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import './../css/skin_slider.css'


const SkinHeadSplider = ({winSkins, head}) => {

    return (
        <Splide 
            ref={(splider) => (head.current = splider)}
            options={ {
                rewind       : true,
                gap: '20px',
                type: 'fade',
                autoplay     : false,
                autoWidth    : true,
                autoHeight   : true,
                perPage      : 1,
                perMove      : 1,
                drag: false,
                padding      : '30px',
                updateOnMove: true,
                arrows       : false,
                focus: 'center',
                pagination: false,
                classes: {
                    arrows: 'arrows_header',
                    arrow : 'splide__arrow your-class-arrow',
                    prev  : 'splide__arrow--prev your-class-prev',
                    next  : 'splide__arrow--next your-class-next',
                },
            } } 
            className='skin_head_splider'
            aria-label="Skin">
            <>
                {winSkins && winSkins.length > 0 && 
                    <>  
                        {winSkins.map((e, i) => (

                            <SplideSlide key={i}>
                                    <div className="title">
                                        <h2>{e.wonItem.name}</h2>
                                    </div>
                            </SplideSlide>

                        ))}
                    </>
                }
            </>
        </Splide>
    );
};

export default SkinHeadSplider;