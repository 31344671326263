import React, { useState } from 'react';
import Api from '../../../../../Api';
import Notice from '../../../../../Notice';

const Disable2faPopUp = ({setOpen, setTFAEnable}) => {

    const [authCode, setAuthCode] = useState('')
    const [error, setError] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)

    function onInput(e) {
        setError(false)

        if(!e.target.value) {
            setError(true)
        }

        let value = e.target.value.replace(/[^0-9]/g,'')

        setAuthCode(value)
    }

    async function sendCode (e) {
        e.preventDefault()

        if(authCode === '') {
            setError(true)
            return
        }

        setLoadBtn(prev => prev = false)

        await fetch(`${Api.url}/api/v1/auth/disable/2fa`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-OTP-Code': authCode.toString(),
            },
            credentials: 'include',
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            setLoadBtn(prev => prev = true)

            if(data.statusCode) {
                Notice.Send({type: 'error', text: `${data?.exception?.message ||' Error. Try again later'}`})
                return
            }

            setTFAEnable(prev => prev = false)
            Notice.Send({type: 'error', text: 'You turned off 2FA'})
            closePopup()
        })
        .catch(error => console.log(error))
        setAuthCode('')
    }

    function closePopup () {
        setOpen(prev => ({...prev, disable2faPopup: false}))
        document.querySelector('html').classList.remove('hidden')
    }

    return (
        <div className='disable_popup_wrapepr' onMouseDown={() => closePopup()}>
            <div className="disable_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Disable 2fa authorization</div>
                    <div className="decor pink" onMouseDown={() => closePopup()}></div>
                </div>

                <div className="disable_popup_content">
                    <form>
                        <input 
                            type="text"
                            inputMode='numeric' 
                            className={`main_input main_card ${error ? 'error' : ''}`} 
                            placeholder='Enter code...'
                            id='qr_code_input'
                            value={authCode}
                            onChange={(e) => onInput(e)}
                            onBlur={(e) => setError(false)}
                        />
                        <button className="common_button red full" onClick={(e) => sendCode(e)}>
                            {loadBtn ? 'DISABLE' : 'Loading...'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Disable2faPopUp;