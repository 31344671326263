import { useEffect, useRef, useState } from "react"
import Store from "../../../Store"
import Api from "../../../Api"
import Notice from "../../../Notice"

export default function useTakeCase () {
    const [store, setStore] = useState(false)
    const [newList, setNewList] = useState(false)
    const [categories, setCategories] = useState(false)
    const [caseSum, setCaseSum] = useState(0)
    const [categoryFilter, setCategoryFilter] = useState('all')
    const [search, setSearch] = useState('')
    const round = useRef(0) 
    const caseList = useRef(false)
    
    const params = {
        size: 1000,
        page: 1,
        order: 'ASC',
        field: 'id'
    }
    const price = ['0-10', '10-25', '25-50', '50-100', '100-250', '250-1000', '1000+']

    const [load, setLoad] = useState(false)

    Store.useListener('updatedList', ({rounds, list, sum}) => {
        setNewList(list)
        round.current = rounds
        setCaseSum(sum)
    })

    function serching(e) {
        if(categoryFilter !== 'all') {
            setStore(caseList.current.filter(el => (el.categories === categoryFilter && el.name.toLowerCase().includes(e.target.value))))
        } else {
            setStore(caseList.current.filter(el => el.name.toLowerCase().includes(e.target.value)))
        }
        setSearch(e.target.value)
    }

    useEffect(() => {
        (async () => {
            let query = new URLSearchParams(params)

            price.map(el => {
                query.append('priceRanges', el)
            })

            let cases = await Api.asyncGet(`api/v1/case/all?${query}`)
            let categoriesInit = await Api.asyncGet('api/v1/categories/all')
            
            setLoad(true)

            if(categoriesInit === 'error' || cases === 'error') {
                Notice.Send({type: 'error', text: 'Server error'})
                return
            }

            let list = cases.items.filter(el => el.type === 'paid')
            
            if(categoryFilter !== 'all') {
                list = list.filter(el => (el.categories === categoryFilter && el.name.toLowerCase().includes(search)))
            } 
        
            if(!store) {
                caseList.current = list
            }
            setCategories(categoriesInit)

            setStore(list?.length ? list : false)
            setLoad(true)
        })()

    }, [categoryFilter])

    function changeFilter (e) {

        if(categoryFilter === e.target.textContent) {
            return
        }
        setLoad(prev => prev = false)
        const allFilters = e.currentTarget.querySelectorAll('p')
        allFilters.forEach(el => {
            if(el === e.target) {
                el.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setCategoryFilter(e.target.textContent)
    }
    
    /* ---------------функции добавления/уменьшения колличества карточек--------------- */

    function plusCard(newCard, callback) {
        if(round.current === 100) {
            return Notice.Send({type: 'error', text: 'Max number of cases is 100!'})
        }
        let caseItem = newList.find(el => el.id === newCard.id)
        if(caseItem) {
            setNewList(newList.map(c => {
                if(c.id === newCard.id) {
                    setCaseSum(prev => +(prev + c.price).toFixed(2))
                    callback(prev => prev + 1)
                    return {...c, count: c.count + 1}
                }
                return c
            }))
        } else {
            newCard.count = 1
            setCaseSum(prev => +(prev + newCard.price).toFixed(2))
            callback(1)
            setNewList((prev) => [...prev, newCard])
        }
        round.current = round.current + 1
    }

    function minusCard(id, callback) {
        let clone = newList
        let caseItem = clone.find(el => el.id === id)

        if(caseItem) {
            if(caseItem.count === 1) {
                setNewList(clone.filter(el => el.id !== id))
                callback(0)
            } else {
                setNewList(newList.map(c => {
                    if(c.id === id) {
                        callback(prev => prev - 1)
                        return {...c, count: c.count - 1}
                    }
                    return c
                }))
            }
            setCaseSum(prev => +(prev - caseItem.price).toFixed(2))
            round.current = round.current - 1 
        } else {
            return
        }
        
    }

    function closeTakeCasePopup () {
        document.querySelector('.take_case_popup_wrapper').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')

        setCaseSum(0)
        round.current = 0
    }
    

    function selectCases() {

        let clone = newList

        clone.forEach((el, i) => {
            el.index = i + 1
        })

        const config = {
            list: clone, 
            sum: caseSum, 
            rounds: round.current
        }

        Store.setListener('newList', config)

        closeTakeCasePopup()
    }

    return {load, serching, search, store, categories, categoryFilter, params, selectCases, closeTakeCasePopup, newList, caseSum, setNewList, plusCard, minusCard, changeFilter}
}