import React, {useEffect} from 'react';
import './css/upgrade.css'
import BalanceGrid from './components/BalanceGrid'
import Title from '../../mainTitle';
import ItemsGrid from './components/ItemsGrid';
import LiveUpgrade from './components/LiveUpgrade';

const Upgrade = () => {
    useEffect(() => {
        Title.setListener('title', 'UPGRADE')
    }, [])

    return (
        <div className='upgrade_block container'>
            <LiveUpgrade />

            <BalanceGrid />

            <ItemsGrid />
        </div>
    );
};

export default Upgrade;