import React from 'react';

const ProfileVerification = () => {
    return (
        <div className="profile_verification">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_gear"></div>
                </div>
                <div className='game_title'>Profile Verification</div>
                <div className="decor pink"></div>
            </div>
            <form action="" className="body_content">
                <button className="common_button green full">VIEW ALL LEVELS</button>
            </form>
        </div>
    );
};

export default ProfileVerification;