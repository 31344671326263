import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import avatar from './../../../images/avatar.svg'
import Api from '../../../Api';
import Notice from '../../../Notice';
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import Avatar from '../../../components/avatar/Avatar';
import Price from '../../../components/price/Price';


const AllBet = ({allBets, setAllBets, sortByBet, colorUpdate}) => {

    const [load, setLoad] = useState(false)

    useEffect(() => {
        (async () => {

            let res = await Api.asyncGet(`api/v1/game-crash/bets?size=100&page=1`)
            if(res === 'error') {
                Notice.Send({type: 'error', text: 'Server error'})
                return
            }

            setAllBets(sortByBet(res.items))
            setLoad(true)
        })()
    }, [])

    return (
        <div className="all_bet_block">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="home_icon_crash"></div>
                </div>
                <h4 className='game_title'>All bet</h4>
                <div className="decor pink"></div>
            </div>
            <div className="all_bet_list_wrapper">
                {load ? 
                    <>
                        {allBets?.length ? 
                            <div className="all_bet_list">
                                {allBets.map((el, i) => (
                                    <div className={`all_bet_list_item ${(i + 1) % 2 === 0 ? 'second' : ''}`} key={i}>
                                        <Avatar 
                                            img={el.avatar}
                                            lvl={el.level}
                                            percent={(el.experience / el.experienceBorderTillLevel) * 100}
                                            color={'rgb(233, 63, 63)'}
                                            size={24}
                                            rad={11}
                                            dasharray={69}
                                            lvlFont={8}
                                            lvlPos={-3}
                                            lvlSize={14}
                                        />
                                        <h5 className="name">{el.username}</h5>
                                        <div className="coin_bet_block">
                                            <div className={`coin ${el.totalPayout >= 0 ? 'plus' : ''}`}>
                                                {el.totalPayout < 0 ? ((el.totalPayout).toFixed(2)).slice(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (el.totalPayout).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <img src={coin} alt="" />
                                            </div>
                                            <div className="coef" style={{background: colorUpdate(el.multiplier * 100)?.color, border: colorUpdate(el.multiplier * 100)?.border}}>
                                                x<Price price={el.multiplier}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        :
                            <NotItems />
                        }
                    </>
                :
                    <LoadingItems />
                }
            </div>
            
        </div>
    );
};

export default AllBet;