import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import Store from '../../../Store';
import Notice from '../../../Notice';
import Price from '../../../components/price/Price';

const Item = ({el, betList, index}) => {
    const {image, name, price, type} = el
    const [inList, setInList] = useState(false)

    const addNewItem = () => {
        
        if(inList) {
            setInList(prev => prev = false)
            betList.current = betList.current.filter(elem => elem.id !== el.id)
            Store.setListener('ADD_NEW_ITEM_UPGRADE', betList.current)
            return 
        }

        if(betList.current.length === 4) {
            Notice.Send({type: 'error', text: 'Sorry, you can`t chose more then 4 items'})
            return
        }

        setInList(prev => prev = true)

        betList.current.push(el)

        Store.setListener('ADD_NEW_ITEM_UPGRADE', betList.current)
    }

    useEffect(() => {
        betList.current.map(elem => {
            if(el.id === elem.id) {
                setInList(prev => prev = true)
            }
        })
    }, [])

    return (
        <div className={`drop_card site nocopy ${inList && 'active'} ${type}`} onMouseDown={() => addNewItem()}>
            <img src={image} className={`drop_image ${type}`} alt="" />
            <div className="drop_card_content">
                <h4 className="title"><span>{name}</span></h4>
                <p className={`price main_card ${type}`}><Price price={price}/> <img src={coin} alt="" /></p>
            </div>
        </div>
    );
};
export default Item;