import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import Api from "../../../Api";
import Title from "../../../mainTitle";
import Notice from "../../../Notice";
import { useNavigate } from "react-router-dom";
import Store from "../../../Store";


export default function useCaseBattle(id) {

    const [profileId, setProfileId] = useState(() => {
        const id = JSON.parse(localStorage.getItem('client')) || {}
        return id.id
    })
    const [gameDetails, setGameDetails] = useState({})
    const [formed, setFormed] = useState({})
    const [spectators, setSpectators] = useState(0)
    const [mainId, setMainId] = useState('')
    const [result, setResult] = useState(false)
    const [gameStatus, setGameStatus] = useState('')
    const [timer, setTimer] = useState(false)
    const [cases, setCases] = useState({})
    const [round, setRound] = useState(0)
    const [inventory, setInventory] = useState([])
    const [inGame, setInGame] = useState(false)
    const [roomsType, setRoomsType] = useState('')
    const [load, setLoad] = useState(false)
    const [password, setPassword] = useState(false)
    const navigate = useNavigate()

    const battleSocket = useRef(null)
    const timeout = useRef(null)
    const gameEnd = useRef(null)
    const gameInventory = useRef(null)
    const mainSocket = useRef(null)

    useEffect(()=> {
        
        if(id) {

            mainSocket.current = io(`${Api.urlWs}/game-case-battle`, {
                transports: ["websocket"],
                reconnection: true,
                reconnectionDelay: 2000,
                reconnectionAttempts: 5
            })

            battleSocket.current = io(`${Api.urlWs}/game-case-battle?battleId=${id}`, {
                transports: ["websocket"],
                reconnection: true,
                reconnectionDelay: 2000,
                reconnectionAttempts: 5
            })
            battleSocket.current.on('case-battle.roundStarted', (data) => {
                setGameStatus('play')
                setTimer(false)
            })
            battleSocket.current.on('case-battle.roundResults', (data) => {
                setRound(data?.round)
                setResult(data.resultResponse)
                gameInventory.current = setTimeout(() => {
                    setInventory(data.resultResponse)
                }, 5000)
            })
            battleSocket.current.on('case-battle.gameEnded', (data) => {
                gameEnd.current = setTimeout(async () => {
                    setResult(data)
                    setGameStatus('end')
                    setInventory(data)
                    setRound('end')
                    await Api.updateAccount()
                }, 2000)
            })
            battleSocket.current.on('case-battle.spectators', setSpectators)
            battleSocket.current.on('case-battle.gameDetails', setGameDetails)
            battleSocket.current.on('case-battle.formedResponse', (data) => {
                setFormed(data)
                setMainId(data?.id)
                shuffle(data?.cases)
                setRoomsType(data?.roomsType)
                setLoad(true)
    
                let playerInGame = data?.client?.find(player => player.clientId === profileId)
                if(playerInGame) {
                    setInGame(true)
                    if(data?.roomsType === 'PRIVATE') {
                        setPassword(data?.password)
                    } else {
                        setPassword(false)
                    }
                } else {
                    setInGame(false)
                    setPassword(false)
                }
            })
            battleSocket.current.on('case-battle.startTime', (data) => {
                if(Math.round(data)) {
                    setTimer(Math.round(data))
                }
                if(Math.round(data) == 1) {
                    timeout.current = setTimeout(() => {
                        setTimer(false)
                    }, 1000)
                }
            })
            battleSocket.current.on('case-battle.resultResponse', (data) => {
                if(data) {
                    setResult(data)
                    setInventory(data)
                }
                if(data?.gameType) {
                    setGameStatus('end')
                    setRound('end')
                }
            })
        }

        Title.setListener('title', 'CASE BATTLE')

        return () => {
            if(battleSocket.current) {
                battleSocket.current.off('case-battle.gameStarted')
                battleSocket.current.off('case-battle.roundStarted')
                battleSocket.current.off('case-battle.roundResults')
                battleSocket.current.off('case-battle.gameEnded')
                battleSocket.current.off('case-battle.spectators')
                battleSocket.current.off('case-battle.gameDetails')
                battleSocket.current.off('case-battle.formedResponse')
                battleSocket.current.off('case-battle.startTime')
                battleSocket.current.off('case-battle.resultResponse')
                battleSocket.current.disconnect()
            }
            if(mainSocket.current) {
                mainSocket.current.disconnect()
            }
            if(timeout.current) {
                clearTimeout(timeout.current)
            }
            if(gameInventory.current) {
                clearTimeout(gameInventory.current)
            }
            if(gameEnd.current) {
                clearTimeout(gameEnd.current)
            }
            //leaveClient()
        }

    }, [id])
    
    const joinGame = async (position) => {
        
        if(roomsType === 'PUBLIC') {

            const wagers = localStorage.getItem('balanceField')
            const query = {
                gameCaseBattleId: mainId,
                position: position,
                bet: gameDetails.totalCost,
                forWagers: wagers && wagers === 'wager' ? true : false
            }
            const params = new URLSearchParams(query).toString()

            let res = await Api.asyncGetCred(`api/v1/game-case-battle/join?${params}`)

            if (res === 'unAuth') {
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.querySelector('html').classList.add('hidden')
                return
            }

            if(res === 'no balane') {
                return Notice.Send({type: 'error', text: 'You do not have enough balance to place a bet!'})
            }

            if(res === 'error') {
                return
            }
        } 

        if (roomsType === 'PRIVATE') {

            const wagers = localStorage.getItem('balanceField')

            document.querySelector('html').classList.add('hidden')
            Store.setListener('openBattlePriate', true)
            Store.setListener('pathToJoin', 'join')
            Store.setListener('setOprionsBattle', {
                gameCaseBattleId: mainId,
                position: position,
                bet: gameDetails.totalCost,
                forWagers: wagers && wagers === 'wager' ? true : false,
                password: ''
            })
        }
        

        await Api.updateAccount()
    }

    const joinTeam = async (position) => {

        const wagers = localStorage.getItem('balanceField')

        if(roomsType === 'PUBLIC') {
            const query = {
                gameCaseBattleId: mainId,
                position: position,
                bet: gameDetails.totalCost,
                forWagers: wagers && wagers === 'wager' ? true : false,
                team: position < 3 ? 'teamOne' : 'teamTwo'
            }
            const params = new URLSearchParams(query).toString()

            let res = await Api.asyncGetCred(`api/v1/game-case-battle/team-join?${params}`)

            if (res === 'unAuth') {
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.querySelector('html').classList.add('hidden')
                return
            }

            if(res === 'no balane') {
                return Notice.Send({type: 'error', text: 'You do not have enough balance to place a bet!'})
            }

            if(res === 'error') {
                return
            }
        }
        
        if(roomsType === 'PRIVATE') {
            const wagers = localStorage.getItem('balanceField')

            document.querySelector('html').classList.add('hidden')
            Store.setListener('openBattlePriate', true)
            Store.setListener('pathToJoin', 'team-join')
            Store.setListener('setOprionsBattle', {
                gameCaseBattleId: mainId,
                position: position,
                bet: gameDetails.totalCost,
                forWagers: wagers && wagers === 'wager' ? true : false,
                team: position < 3 ? 'teamOne' : 'teamTwo',
                password: ''
            })
        }

        await Api.updateAccount()
    }

    const callBots = async (position) => {
        let res = await Api.asyncGetCred(`api/v1/game-case-battle/invite-bot?gameCaseBattleId=${mainId}&position=${position}`)

        if (res === 'error') {
            return
        }
    }

    const callTeamBots = async (position) => {
        const team = position < 3 ? 'teamOne' : 'teamTwo'
        let res = await Api.asyncGetCred(`api/v1/game-case-battle/invite-bot?gameCaseBattleId=${mainId}&position=${position}&team=${team}`)

        if (res === 'error') {
            return
        }
    }

    const leaveClient = async () => {
        let res = await Api.asyncGetCred(`api/v1/game-case-battle/leave-client?gameCaseBattleId=${mainId}`)

        if(res === 'unAuth') {
            Store.setListener('openAuthPopUp', prev => prev = true)
            document.querySelector('html').classList.add('hidden')
            return
        }

        if(res === 'error') {
            return 
        }

        return navigate('/case_battles')
    }

    const leaveBot = async () => {
        let res = await Api.asyncGetCred(`/api/v1/game-case-battle/leave-bot?gameCaseBattleId=${mainId}`)

        if(res === 'error') {
            return
        }
    }


    const shuffle = (list) => {
        let round = 1;
        list?.forEach((el) => {
            let result = []

            while (result.length < 60) {

                el.fakeItems.forEach((e) => {

                    switch (e.fakeItem.type) {
                        case 'red':
                            result.push(e)
                            break;
    
                        case 'orange':
                            result.push(e)
                            break;
                        case 'purple':
                            for (let i = 0; i < 2; i++) {
                                result.push(e)
                            }
                            break;
                        case 'blue':
                            for (let i = 0; i < 3; i++) {
                                result.push(e)
                            }
                            break;
                        case 'green':
                            for (let i = 0; i < 4; i++) {
                                result.push(e)
                            }
                            break;
                        default:
                            break;
                    }

                })

            }
            setCases(prev => ({...prev, [round++]: result}))
        })

    }
    
    return {gameDetails, spectators, formed, mainId, joinGame, callBots, leaveClient, result, leaveBot, timer, gameStatus, round, cases, inventory, inGame, joinTeam, callTeamBots, roomsType, load, password}
}