import React, { useCallback, useEffect, useState } from 'react';

const Filters = ({filter, setFilter, priceOrder, search, orederBy, setSearch}) => {

    const [isOpen, setIsOpen] = useState(false)
    const menuList = [
        {name: 'ALL PRICES'},
        {name: '0-10'},
        {name: '10-25'},
        {name: '25-50'},
        {name: '50-100'},
        {name: '100-250'},
        {name: '250-1000'},
        {name: '1000+'},
    ]
    const closeMenu = () => {
        setIsOpen(false)
    }

    const toggleMenu = (e) => {
        e.stopPropagation()
        setIsOpen(prev => !prev)
    }

    useEffect(() => {
        window.addEventListener('click', closeMenu)

        return () => window.removeEventListener('click', closeMenu)
    }, [])

    useCallback(() => {
        return () => window.removeEventListener('click', closeMenu)
    }, [])

    return (
        <div className="withdraw_filters">
            <div className="search main_card">
                <input type="text" placeholder='Search...' value={search} onChange={(e) => setSearch(e.target.value)}/>
                <div className="icon_search"></div>
            </div>

            <div className="price_range">
                <div className='value main_card'>
                    <h4>Price range</h4>
                    <p>{priceOrder}</p>
                </div>

                <button className="icon_button" onClick={(e) => toggleMenu(e)}>
                    <div className="icon_arrow_right"></div>
                </button>

                {isOpen && 
                    <div className="withdraw_filter_menu" >
                        {menuList.map((el) => (
                            <p className={`${el.name === priceOrder ? 'active' : ''}`} onClick={() => orederBy(el.name)}>{el.name}</p>
                        ))}
                    </div>
                }
            </div>

            <div className="rare_filter">
                <button className={`common_button full ${filter === 'all' ? 'active': ''}`} onClick={() => setFilter('all')}>All</button>
                <button className={`common_button full ${filter === 'red' ? 'active': ''}`} onClick={() => setFilter('red')}>Red</button>
                <button className={`common_button full ${filter === 'orange' ? 'active': ''}`} onClick={() => setFilter('orange')}>Orange</button>
                <button className={`common_button full ${filter === 'purple' ? 'active': ''}`} onClick={() => setFilter('purple')}>Purple</button>
                <button className={`common_button full ${filter === 'blue' ? 'active': ''}`} onClick={() => setFilter('blue')}>Blue</button>
                <button className={`common_button full ${filter === 'green' ? 'active': ''}`} onClick={() => setFilter('green')}>Green</button>
            </div>

        </div>
    );
};

export default Filters;