import React from 'react';
import pensil from './../images/pensil_home.svg'
import logo from './../images/logo.svg'
import heart from './../images/heart.svg'
import squiggle from './../images/squiggle.svg'
import check_one from './../images/check_one.svg'
import check_two from './../images/check_two.svg'
import way from './../images/way.svg'
import way_online from './../images/way2.svg'
import squig from './../images/squig.svg'
import first from './../images/first.svg'
import second from './../images/second.svg'
import third from './../images/third.svg'
import fourth from './../images/fourth.svg'
import people from './../images/people.svg'


const MobileHead = () => {
    return (
        <div className='mobile_home_head_block'>
            <img className='pensil' src={pensil} alt="" />
            <div className="welcome">
                <h2>Welcome to</h2>
                <div className="logo">
                    {/* <img className='spin_rust' src={logo} alt="" /> */}
                    <p className='LogoSpin'>Spin</p>
                    <p className='LogoRust'>Rust</p>
                    <img src={heart} alt="" className="heart" />
                    <img src={squiggle} alt="" className="squiggle" />
                    <img src={check_one} alt="" className="check_one" />
                    <img src={check_two} alt="" className="check_two" />
                </div>
            </div>
            <div className="online main_card">
                <img src={people} alt="" className="people" />
                <p>5.222 ruster’s online</p>
                <img src={way_online} alt="" className='way'/>
                <img src={squig} alt="" className="squig" />
            </div>
            <a href="" className="join_block">
                <button className='common_button green'>Join us</button>
            </a>
            <div className="scroll_to">
                <a href='#home_top' className='common_button pink'>Scroll ↓</a>
                <img src={way} alt="" className='way'/>
                <img src={first} alt="" className="first" />
                <img src={second} alt="" className="second" />
                <img src={third} alt="" className="third" />
                <img src={fourth} alt="" className="fourth" />
            </div>
        </div>
    );
};

export default MobileHead;