import React from 'react';
import Store from '../../Store';

const EditCatgoryBtn = ({categoryId}) => {

    function openEditPopup(e) {
        e.preventDefault()
        document.getElementById('adminEditCategories').classList.add('open')
        document.querySelector('html').classList.add('hidden')

        Store.setListener('categoryToEdit', categoryId)
    }

    return (
        <button className="edit_category icon_button" onClick={openEditPopup}>
            <div className="admin_icon_pencil"></div>
        </button>
    );
};

export default EditCatgoryBtn;