import { useCallback, useEffect } from "react"

export default function useFilters () {
    function closeFilters() {
        const allFilt = document.querySelectorAll('.filter_list')
        allFilt.forEach((el) => {
            if(el.classList.contains('open')){
                el.classList.remove('open')
            } else {
                return 
            }
        })
    }

    function openFilter(e) {
        e.stopPropagation()
        const allLists = document.querySelectorAll('.filter_list')
        
        const currentList = e.currentTarget.nextSibling

        allLists.forEach(el => {
            if(el !== currentList) {
                el.classList.remove('open')
            }
        })

        currentList.classList.toggle('open')
    }

    useEffect(() => {

        window.addEventListener('mousedown',() => closeFilters())
        
        return () => window.removeEventListener('click', () => closeFilters())
    }, [])


    useCallback(() => {
        window.removeEventListener('mousedown', () => closeFilters())
    })

    return {closeFilters, openFilter}
}