import React, { useState } from 'react';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import Avatar from '../../../components/avatar/Avatar';
import ProvablyModal from './provably_fair/ProvablyModal';
import Price from '../../../components/price/Price';
import Coin from '../../../components/coin/Coin';

const ListItem = ({el, index}) => {

    const [open, setOpen] = useState(false)

    const openModal = () => {
        setOpen(prev => prev = true)
        document.querySelector('html').classList.add('hidden')
    }

    const date = el.time.split(' ')

    return (
        <>
            <div className={`all_bet_list_item nocopy`} onMouseDown={() => openModal()} >
                <div className="user">
                    <Avatar
                        img={el.avatar}
                        lvl={80}
                        percent={25}
                        color={'rgb(233, 63, 63)'}
                        size={24}
                        rad={11}
                        dasharray={69}
                        lvlFont={8}
                        lvlPos={-3}
                        lvlSize={14}
                    />
                    <div className="name">{el.username}</div>
                </div>

                <button className="common_button" onMouseDown={() => openModal()}>Check</button>
                
                <div className="bet">
                    <Coin />
                    <Price price={el.bet}/>
                </div>
                <div className="time">
                    <span className='bet_time'>{date[1]}</span>
                </div>
                <div className={`multiplier_item`}>
                    <span className={`${el.totalPayout > 0 ? 'green': 'red'}`}>x{el.multiplier}</span>
                </div>
                <div className="bet payout">
                    <Coin />
                    <span className={`${el.totalPayout > 0 ? 'win' : 'lose'}`}>
                        {el.totalPayout > 0 && '+'}<Price price={el.totalPayout}/>
                    </span>
                </div>
            </div>

           {open && 
                <ProvablyModal 
                    setOpen={setOpen}
                    hash={el.hash}
                    client={el.client}
                    server={el.server}
                    nonce={el.nonce}
                />
            }
        </>
    );
};

export default ListItem;