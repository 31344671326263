import React, { useEffect, useState } from 'react';
import docs from './../images/docs.svg'
import Api from '../../../Api';


const ReferalCode = ({Copy}) => {
    let [userReferral, setUserReferral] = useState('')
    let [refError, setRefError] = useState(false)
    let [referalLink, setReferalLink] = useState(false)

    useEffect(() => {
        const refer = JSON.parse(localStorage.getItem('client')).referralCode
        if(refer) {
            return setReferalLink(refer)
        } else {
            return setReferalLink(false)
        }
    },[])

    function referalInput(value) {
        setUserReferral(value)
        setRefError(false)
        document.getElementById('referalInput').classList.remove('error')
    }

    function referalMessage() {
        document.getElementById('referal_message').classList.add('active')

        setTimeout(() => {
            document.getElementById('referal_message').classList.remove('active')
        }, 3000)
    }

    function successMesage() {
        const message = document.getElementById('referal_success_message')
        message.classList.add('active');

        setTimeout(() => {
            message.classList.remove('active');
        }, 2000)
    }

    function sendReferal (e) {
        e.preventDefault()

        if(userReferral.trim() === '') {
            document.getElementById('referalInput').classList.add('error')
            setRefError('Please enter your code')
        } else {
            fetch(`${Api.url}/api/v1/referrals/me/referral-code/?referralCode=${userReferral}`, {
                method: 'POST',
                headers: {},
                credentials: 'include',
            })
            .then(res => res.json())
            .then((data) => {
                if(data.statusCode === 409) {
                    referalMessage()
                } else {
                    setReferalLink(data.referralCode)

                    let  client = JSON.parse(localStorage.getItem('client'))
                    client = {...client, 'referralCode': data.referralCode}
                    localStorage.setItem('client', JSON.stringify(client))

                    successMesage()
                } 
            })
            .catch(error => console.error(error))
        }

        setUserReferral('')
    }
    
    return (
        <div className="referal_block">

            <div className="grid_nav_item_header ">
                <div className="game_icon">
                    <div className="promo_icon_referal"></div>
                </div>
                <h4 className='game_title'>Referal</h4>
                <div className="decor"></div>
            </div>


            <form className="referal_window" onSubmit={sendReferal}>

                <div id="referal_message" >You already have referral code!</div>
                
                <div className="referal_input main_card" id='referalInput'>

                    <input className='main_input' type="text" placeholder='Set your referral code*' value={userReferral} onInput={(e) => referalInput(e.target.value)}/>
                    <button className="common_button pink">Claim now</button>
                    
                </div>

                {refError && <div className="error_message">{refError}</div>}

                <div className="copy_block main_card">
                    <div className="icon" onClick={Copy}>
                        <img src={docs} alt="" />
                    </div>
                    <a id='link' href={referalLink ? referalLink : 'https://spinrust.com'}>{referalLink ? referalLink : 'https://spinrust.com'}</a>
                </div>

            </form>
            
            <div id="referal_success_message" className='popup_mess main_card'>You create a referal code!</div>
        </div>
    );
};

export default ReferalCode;