import React, { useEffect, useRef, useState } from 'react';
import ak from './../../../images/ak.svg'
import NotItems from '../../../components/NotItems';
import LoadingItems from '../../../components/LoadingItems';
import ItemsGrid from '../../upgrade/components/ItemsGrid';
import Store from '../../../Store';


const OpenOneCase = ({roullete, itemsOpenList }) => {

    let [reren, setReren] = useState(false)
    
    Store.useListener('caseItems', setReren)

    return (
        <div className="case_open_item_wrapper">
            <div className='case_open_item'>
                

                <div className="case_open_roulette_wrapper" id='open_one'>
                {itemsOpenList ? 
                    <>
                        {itemsOpenList.length > 0 ?
                            <div className="case_open_roulette" ref={roullete}>
                                {itemsOpenList.map((el, i) => (
                                    <div className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf1' : el.fakeItem.id} >
                                        <img 
                                            key={i} 
                                            
                                            src={el.fakeItem.image} 
                                            className={el.fakeItem.type}
                                            alt="" 
                                        />
                                    </div>
                                ))}
                            </div>
                        :
                            <NotItems />
                        }
                    </>
                :
                    <LoadingItems />
                }
                
                </div>
                
                <div className="triangle"></div>
                <div className="triangle top"></div>
            </div>

            
        </div>
        
    );
};

export default OpenOneCase;