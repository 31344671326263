import React, { useEffect, useState } from 'react';

import pinkCoin from './../../fonts/main_icon_fonts/pink_coin.svg';
import coin from './../../fonts/main_icon_fonts/coin.svg';
import Store from '../../Store';
import BalanceList from './BalanceList';
import Api from '../../Api';
import Price from '../price/Price';


const BalanceBtn = ({wager}) => {
    
    const [balance, setBalance] = useState(false)
    const [field, setField] = useState(() => {
        const local = localStorage.getItem('balanceField') || 'coin'
        return local
    })
    const [load, setLoad] = useState(false)

    Store.useListener('updateBalance', setBalance)

    function openBalance(e) {
        e.stopPropagation()
        document.querySelector('.balance_list').classList.toggle('open')
    }

    function switchBalance(field) {
        localStorage.setItem('balanceField', field)
        setField(field)
    }

    useEffect(() => {

        (async () => {
            const clientId = JSON.parse(localStorage.getItem('client')).id
            let res = await Api.asyncGet(`api/v1/billing-accounts?clientId=${clientId}`)

            if(res === 'error') {
                return
            }
            setBalance(res)
            setLoad(true)
        })()

    }, [])

    useEffect(() => {

    }, [wager])

    return (
        <>
            {load ? 
                <div className="balance_wrapper">
                
                {balance ? 
                    <>  
                        {field === 'wager' ? 
                            <button className='common_button flex full' onClick={openBalance}>
                                <Price price={balance.wagers}/>
                                <img src={pinkCoin} alt="" />
                            </button>
                        :<button className='common_button flex full' onClick={openBalance}>
                            <span><Price price={balance.balance}/></span>
                            <img src={coin} alt="" />
                        </button>}

                        <BalanceList field={field} balance={balance} func={switchBalance} wager={wager} />
                        
                    </>
                :
                    <button className="common_button full capitalize">No balance</button>
                }

                </div>
                
            :   
                <button className="common_button full capitalize">Loading...</button>
            }
        </>
        
    );
};

export default BalanceBtn;