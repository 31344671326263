import React from 'react';

const AdminNewsBtn = () => {

    function openAdminPopUp() {
        document.getElementById('adminNewsPopup').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className="admin_news_button" onClick={() => openAdminPopUp()}>
            <div className="admin_icon_edit_news"></div>
        </button>
    );
};

export default AdminNewsBtn;