import React, { useEffect } from 'react';
import './css/case_battles.css'

import Title from '../../mainTitle';

import LastBattles from './components/LastBattles';
import Filters from './components/Filters';
import CaseBtlList from './components/CaseBtlList';
import useMainCaseBattle from './hooks/useMainCaseBattle';


const CaseBattles = () => {

    const {gameHistory, lastBattles, getType, load, filterList, radio} = useMainCaseBattle()

    return (
        <div className='case_battles_block container'>

            <Filters radio={radio} filterList={filterList} gameHistory={gameHistory}/>

            <CaseBtlList getType={getType} load={load} gameHistory={gameHistory}/>
            
            <LastBattles getType={getType} lastBattles={lastBattles}/>

        </div>
    );
};

export default CaseBattles;