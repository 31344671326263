import React from 'react';
import './css/pagination.css';

import dable_arrow_left from './icons/dable_arrow_left.svg'
import arrow_left from './icons/arrow_left.svg'
import dable_arrow_right from './icons/dabl_arrow_right.svg'
import arrow_right from './icons/arrow_right.svg'

const CommonPagination = ({ maxPage, params, setParams }) => {


    function switchPage(value) {
        let pages = params?.page + value
        if(pages > maxPage) {
            return
        } else if (pages < 1) {
            return
        } else {
            setParams(prev => ({...prev, ['page']: pages}))
        }   
    }


    function switchMaxPage(value){
        if(+value === 0) {
            return
        }
        if((params?.page === 1 && value === 1) || (params?.page === maxPage && value === maxPage)) {
            return
        }
        setParams(prev => ({...prev, page: value}))
    }

    return (
        <nav className='common_pagination'>
            <button className={`${params.page === 1 ? 'disable' : ''}`} onClick={() => switchMaxPage(1)}>
                <img src={dable_arrow_left} alt="" />
            </button>

            <button className={`${params.page === 1 ? 'disable' : ''}`} onClick={() => switchPage(-1)}>
                <img src={arrow_left} alt="" />
            </button>

            <button className={`${params.page >= maxPage ? 'disable' : ''}`} onClick={() => switchPage(1)}>
                <img src={arrow_right} alt="" />
            </button>

            <button className={`${params.page >= maxPage ? 'disable' : ''}`} onClick={() => switchMaxPage(maxPage)}>
                <img src={dable_arrow_right} alt="" />
            </button>

            <div className="page_number">{params?.page || 1} - {maxPage || 0}</div>
        </nav>
    );
};

export default CommonPagination;