import React from 'react';
import Store from '../../Store';

const EditItemBtn = ({item}) => {

    function openEditPopup(e) {
        e.preventDefault()
        document.getElementById('adminEditItems').classList.add('open')
        document.querySelector('html').classList.add('hidden')

        Store.setListener('itemToEdit', item)
    }

    return (
        <button className="icon_button edit_btn" onClick={openEditPopup}>
            <div className="admin_icon_pencil"></div>
        </button>
    );
};

export default EditItemBtn;