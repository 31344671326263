import React, { useCallback, useEffect } from 'react';

const PasswordRequire = () => {

    function closeMenu(e) {
        e.stopPropagation()
        const requiere = document.querySelector('.password_require')
        if(requiere) {
            requiere.classList.remove('open')
        }
        
    }

    useEffect(() => {
        window.addEventListener('click', closeMenu)

        return () => window.removeEventListener('click', closeMenu)
      }, [])

      useCallback(() => {
        return () => window.removeEventListener('click', closeMenu)
      })

    return (
        <div className="password_require main_card" onClick={(e) => e.stopPropagation()}>
            <p>The password must contain at least:</p>
            <ul>
                <li>8 characters</li>
                <li>One upper case</li>
                <li>One lower case</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    );
};

export default PasswordRequire;