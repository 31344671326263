import React from 'react';
import useFilters from '../hooks/useFilters';

const Filters = ({params, changeFilters}) => {

    const {openFilter} = useFilters()

    return (
        <div className="replenishment_filters">

            <div className='replenisment_filter_item'>
                <h4>Order by:</h4>
                <button className="common_button flex full" onMouseDown={(e) => openFilter(e)}>
                    {params.order}
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" name='order' onMouseDown={(e) => changeFilters(e, 'order')}>
                    <p className='active'>ASC</p>
                    <p>DESC</p>
                </div>
            </div>

            <div className='replenisment_filter_item'>
                <h4>Source:</h4>
                <button className="common_button flex full" onMouseDown={(e) => openFilter(e)}>
                    {params.source}
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" onMouseDown={(e) => changeFilters(e, 'source')}>
                    <p className='active'>TRANSACTION</p>
                    <p>TRADE</p>
                    <p>ALL</p>
                </div>
            </div>

            <div className='replenisment_filter_item'>
                <h4>Type:</h4>
                <button className="common_button flex full" onMouseDown={(e) => openFilter(e)}>
                    {params.type}
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" onMouseDown={(e) => changeFilters(e, 'type')}>
                    <p className='active'>DEPOSIT</p>
                    <p>WITHDRAWAL</p>
                    <p>WAGER</p>
                    <p>ISSUED</p>
                    <p>ALL</p>
                </div>
            </div>

        </div>
    );
};

export default Filters;