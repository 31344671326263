import React from 'react';
import { NavLink } from 'react-router-dom';

import './css/footer.css'

import logo_center from './footer_fonts/logo_center.svg'
import logo_name from './footer_fonts/logo_name.svg'

const Footer = () => {
    return (
        <footer>
            <div className="footer_header">

                <div className="info">
                    <div className="logo_block">
                        <div className="logo">
                            <img src={logo_center} alt="" />
                        </div>

                        <img className='logo_name' src={logo_name} alt="" />
                    </div>

                    <p className="rights">©All Rights Reserver 2024</p>

                    <p className="adress">
                        <span>SpinRust.com is a brand name of APEGANG (CYPRUS) LIMITED, REG NO. HE 434551,</span>
                        <span>Having it's registered address at 1, Avlonos, Maria House, Nicosia, 1075 Cyprus</span></p>    
                </div>

                <div className="social">
                    <div className="social_item">
                        <a href="" className='facebook' ><div className="footer_icon_facebook"></div></a>
                        <a href="" ><div className="footer_icon_instagram"></div></a>
                        <a href="" ><div className="footer_icon_tiktok"></div></a>
                        <a href="" ><div className="footer_icon_x_com"></div></a>
                    </div>

                    <div className="social_item">
                        <a href="" ><div className="footer_icon_youtube"></div></a>
                        <a href="" ><div className="footer_icon_telegram"></div></a>
                        <a href="" ><div className="footer_icon_discord"></div></a>
                        <a href="" ><div className="footer_icon_twitch"></div></a>
                    </div>
                </div>

            </div>

            <div className="footer_bottom">
                <p className="age_limit">
                    <span>18+</span>

                    Gambling is only a voluntary decision.
                </p>

                <div className="social_mobile">
                    <a href="" className='facebook' ><div className="footer_icon_facebook"></div></a>
                    <a href="" ><div className="footer_icon_instagram"></div></a>
                    <a href="" ><div className="footer_icon_tiktok"></div></a>
                    <a href="" ><div className="footer_icon_x_com"></div></a>
                    <a href="" ><div className="footer_icon_youtube"></div></a>
                    <a href="" ><div className="footer_icon_telegram"></div></a>
                    <a href="" ><div className="footer_icon_discord"></div></a>
                    <a href="" ><div className="footer_icon_twitch"></div></a>
                </div>

                <nav>
                    <div className="nav_item">
                        <a href="">Contact</a>
                        <a href="">Blog</a>
                        <a href="">FAQ</a>
                    </div>

                    <div className="nav_item">
                        <a href="">Privacy Statement</a>
                        <a href="">Terms Of Service</a>
                    </div>

                    <div className="nav_item">
                        <a href="">Provably Fair</a>
                        <a href="">Cookie Policy</a>
                        <a href="">Aml Policy</a>
                    </div>
                </nav>
            </div>
        </footer>
    )
}

export default Footer;