import React from 'react';

const CallBots = ({callBots, position}) => {
    return (
        <div className='button_block'>
            <button className='common_button white full' onClick={() => callBots(position)}>Call bots</button>
        </div>
    );
};

export default CallBots;