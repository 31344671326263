import React from 'react';
import RefereesList from '../RefereesList';
import ReferesListFilter from './ReferesListFilter';
import useReferalList from '../../hooks/useReferalList';

const ReferesBlock = () => {

    const {load, refereesList, paramsUrl, setParamsUrl, changeFilter} = useReferalList()

    return (
        <div className="referees_list_block">
            <h3>Referees list</h3>

            <div className="grid_nav_item_header ">
                <div className="game_icon">
                    <div className="promo_icon_referal_list"></div>
                </div>
                <h4 className='game_title'>Referees list</h4>
                <div className="decor green" ></div>
            </div>

            <div className="referees_list_content">

                <ReferesListFilter changeFilter={changeFilter}/>

                <p>Active deposits are shown for last 14 days.</p>

                <div className="referees_list_wraper">

                    <div className="referees_list_header">
                        <h5 className='name'>NAME</h5>
                        <h5 className=''>DEPOSITED</h5>
                        <h5 className=''>COMMISSION</h5>
                        <h5 className='last_seen'>LAST SEEN</h5>
                        <h5 className=''>LAST DEPOSIT</h5>
                        <h5 className=''>STATUS</h5>
                    </div>

                    <RefereesList 
                        refereesList={refereesList} 
                        load={load} 
                        paramsUrl={paramsUrl} 
                        setParamsUrl={setParamsUrl}
                    />

                </div>
                

            </div>

        </div>
    );
};

export default ReferesBlock;