import React, { useEffect, useState } from 'react';
import CasesGridItem from './CasesGridItem';
import left_decor from './../images/category_decor_left.svg'
import right_decor from './../images/category_decor_right.svg'
import EditCatgoryBtn from '../../../admin/components/EditCatgoryBtn';

const CaseGrids = ({category, casesList, role}) => {

    let [caseCat, setCaseCat] = useState([])

    useEffect(() => {

        if (casesList) {
            let cases = casesList.filter((cases) => cases.categories === category.name)
            setCaseCat(cases)
        }

    }, [casesList])

    return (
        <>
            {caseCat && caseCat.length > 0 &&
                <div className="cases_grid">
                    <div className="info_wrapper">
                        <div className="info">
                            <img src={left_decor} alt="" className="left_decor" />
                            <img src={right_decor} alt="" className="right_decor" />
                            <img src={category.image} alt="" className="icon_cat" />
                            <p>{category.name}</p>
                        </div>
                        {role === 'ADMIN' && <EditCatgoryBtn categoryId={category.id}/>}
                    </div>
                    
                    {caseCat.map((el) => (
                        <CasesGridItem key={el.id} el={el} role={role}/>
                    ))}
                </div>
            }   
        </>
    );
};

export default CaseGrids;