import React, { useCallback, useEffect } from 'react';

const AddCasesCategoriFilter = ({setCaseParams, allCategories, caseParams}) => {
    

    function changeCategory(value, id) {
        setCaseParams(prev => ({...prev, ['category']: value, ['categoryId']: id}))
    }

    
    function openFilter(e) {
        e.preventDefault()
        e.stopPropagation()
        document.querySelector('.category_filter_list').classList.toggle('open')
    }


    function closeFilter() {
        document.querySelector('.category_filter_list').classList.remove('open')   
    }


    useEffect(() => {
        window.addEventListener('click', () => closeFilter())

        return () => window.removeEventListener('click', () => closeFilter())

    }, [])

    useCallback(() => {
        window.removeEventListener('click', () => closeFilter())
    })


    return (
        <div className="category_filter">
            <div className="category_name main_card">
                {allCategories && allCategories.length > 0 ? caseParams.category : 'no category'}
            </div>
            <button className="icon_button" onClick={(e) => openFilter(e)}>
                <div className="admin_icon_arrows_right"></div>
            </button>

            <div className="category_filter_list" >
                {allCategories && allCategories.length > 0 && 
                    allCategories.map((el, i) => (
                        <p key={i} className={caseParams.categoryId === el.id && 'active'} onClick={(e) => changeCategory(el.name, el.id)}>{el.name}</p>
                    ))
                }
            </div>
        </div>
    );
};

export default AddCasesCategoriFilter;