import React, {useEffect, useState} from 'react';

import './css/roulette.css'

import PlaceBetList from './components/PlaceBetList';
import RouletteWrapper from './components/RouletteWrapper';
import RouletteLast from './components/RouletteLast';
import YourBet from './components/YourBet';
import LastHundred from './components/LastHundred';
import Title from '../../mainTitle';
import Timer from './components/Timer';
import useRoulette from './useRoulette';


const Roulette = ({isAuth}) => {

    let rouletteObj = useRoulette(isAuth)

    useEffect(() => {
        Title.setListener('title', 'ROULETTE')
    }, [])
    
    return (
        <div className='rulette_block container'>
            
            <div className="roulette_main">
                <RouletteLast lastHundred={rouletteObj.lastHundred} refer={rouletteObj.lastList} last={rouletteObj.last} timefield={rouletteObj.timefield}/>

                <RouletteWrapper roullete={rouletteObj.roullete}/>

                <YourBet bet={rouletteObj.bet} setBet={rouletteObj.setBet} error={rouletteObj.inputError} setError={rouletteObj.setInputError}/>

                <div className="place_bet_grid">
                    <div className="place_bet_grid_item">
                        <PlaceBetList myUpdateBet={rouletteObj.myUpdateBet} myBet={rouletteObj.myBet} loader={rouletteObj.loader} sortByBet={rouletteObj.sortByBet} newList={rouletteObj.newBetList.red} block={rouletteObj.block} setBetList={rouletteObj.setNewBetList} updateList={rouletteObj.updateBetList.red} stats={rouletteObj.columnStats.red} type={'red'} func={rouletteObj.setBetsRoulette} coef={2} newBet={rouletteObj.newBet}/>
                        <PlaceBetList myUpdateBet={rouletteObj.myUpdateBet} myBet={rouletteObj.myBet} loader={rouletteObj.loader} sortByBet={rouletteObj.sortByBet} newList={rouletteObj.newBetList.black} block={rouletteObj.block} setBetList={rouletteObj.setNewBetList} updateList={rouletteObj.updateBetList.black} stats={rouletteObj.columnStats.black} type={'black'} func={rouletteObj.setBetsRoulette} coef={2} newBet={rouletteObj.newBet}/>
                    </div>
                    <div className="place_bet_grid_item">
                        <PlaceBetList myUpdateBet={rouletteObj.myUpdateBet} myBet={rouletteObj.myBet} loader={rouletteObj.loader} sortByBet={rouletteObj.sortByBet} newList={rouletteObj.newBetList.green} block={rouletteObj.block} setBetList={rouletteObj.setNewBetList} updateList={rouletteObj.updateBetList.green} stats={rouletteObj.columnStats.green} type={'green'} func={rouletteObj.setBetsRoulette} coef={14} newBet={rouletteObj.newBet}/>
                        <PlaceBetList myUpdateBet={rouletteObj.myUpdateBet} myBet={rouletteObj.myBet} loader={rouletteObj.loader} sortByBet={rouletteObj.sortByBet} newList={rouletteObj.newBetList.adjacent} block={rouletteObj.block} setBetList={rouletteObj.setNewBetList} updateList={rouletteObj.updateBetList.adjacent} stats={rouletteObj.columnStats.adjacent} type={'adjacent'} func={rouletteObj.setBetsRoulette} coef={7} newBet={rouletteObj.newBet}/>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Roulette;