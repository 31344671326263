import React, { useEffect, useState } from 'react';
import Api from '../../../Api';
import Avatar from '../../../components/avatar/Avatar';
import GiveMoneyBtn from './GiveMoneyBtn';
import Store from '../../../Store';
import Notice from '../../../Notice';
import { useNavigate } from 'react-router-dom';

const User = ({ el }) => {
    const [role, setRole] = useState(false)
    const [load, setload] = useState(true)
    const navigate = useNavigate()

    const PATH_TO_ASSIGN = `api/v1/moderation/assign-moderator`
    const PATH_TO_REMOVE = 'api/v1/moderation/remove-moderator'

    Store.useListener('TFA_CHANGE_USER_ROLE1', (data) => {
        setRole(prev => prev = data)
    })

    const assignModerator = async (path) => {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json;'
                },
                credentials: 'include'
            })

            res = await res.json()

            if(res.statusCode === 401 && res?.exception?.message === 'Unauthorized') {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json;'
                    },
                    credentials: 'include'
                })

                res = res.json()
            }

            if(res.statusCode === 401 && res?.exception?.message === 'No OTP code provided!') {
                return 'tfa_confirm'
            }

            if (res.statusCode !== 200) {
                return 'error'
            }

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    const changeRole = async (path, userId) => {
        setload(prev => prev = false)

        const data = await assignModerator(`${path}/${userId}`)

        setload(prev => prev = true)
        
        if(data === 'unAuth') {
            Notice.Send({type: 'error', text: 'The session has expired. You will be redirected to the main page'})
            setTimeout(() => {
                navigate('/')
                window.location.reload()
            }, 3000);
            return
        }

        if(data === 'tfa_confirm') {
            const options = {
                path: `${path}/${userId}`,
                marker: 'CHANGE_ROLE1',
                method: 'PATCH'
            }
            Store.setListener('CONFIRM_TFA_POPUP', options)
            return
        }

        if(data === 'error'){
            Notice.Send({type: 'error', text: 'Error. Try again later'})
            return
        }

        setRole(data.role)
        return
    }

    useEffect(() => {
        setRole(el.user.role)
    }, [el])


    return (
        <div className="user_list_item" key={el.id}>
            
            <div className="user_info">
                
                <div className="user">
                    <Avatar 
                        img={el.user.steamProfile.avatar}
                        lvl={el.playerAccount.level}
                        percent={el.experienceBorderTillLevel}
                        color={'rgb(233, 63, 63)'}
                        size={24}
                        rad={11}
                        dasharray={69}
                        lvlFont={8}
                        lvlPos={-3}
                        lvlSize={14}
                    />
                    
                    <div className="user_name">{el.user.username}</div>
                </div>
                
                
                <a href={el.user.steamProfile.profileUrl}>{el.user.steamProfile.profileUrl}</a>

                <div  className={`user_role ${role}`}>{role}</div>

            </div>

            <div className="admin_manage">
                <>
                    {role === 'ADMIN' && 
                        <button className="icon_button red">
                            <div className="admin_icon_crown"></div>
                        </button>
                    }
                    {role === 'PLAYER' &&
                        <button className="icon_button orange" onClick={() => changeRole(PATH_TO_ASSIGN, el.user.id)}>
                            {load ?
                                <div className="admin_icon_player"></div>
                            :
                                <svg width = {`20px`}height={'20px'}>
                                    <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                                </svg>
                            }
                        </button>
                    }
                    {role === 'MODERATOR' &&
                        <button className="icon_button blue" onClick={() => changeRole(PATH_TO_REMOVE, el.user.id)}>
                            {load ?
                                <div className="admin_icon_help"></div>
                            :
                            <svg width = {`20px`}height={'20px'}>
                                <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                            </svg>
                            }
                        </button>
                    }
                </>

                <GiveMoneyBtn userId={el.user.id}/>

                <div className="icon_button">
                    <div className="admin_icon_chat"></div>
                </div>
            </div>
            
            

        </div>
    );
};

export default User;