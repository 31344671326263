import Api from "../../../Api"

const UpgradeServices = {}

UpgradeServices.getPercent = ( price, cb, list) => {
    const wagers = localStorage.getItem('balanceField')
    const forWagers = wagers && wagers === 'wager' ? true : false

    var items = new URLSearchParams

    list.map(el => {
        items.append('fakeItemIds', el.id)
    })

    fetch(`${Api.url}/api/v1/game-upgrade/calculate?${items}&forWagers=${forWagers}&bet=${price}`, {
        credentials: 'include'
    })
    .then(res => {
        if(!res.ok) {
            return 'error'
        }
        return res.json()
    })
    .then(data => {
        if(data === 'error') {
            return
        }
        cb(data.winChance > 95 ? 95 : data.winChance)
        // console.log('calculated bet = ', price)
        // console.log('calculated percent = ', data.winChance)
    })
    .catch(error => console.log(error))
}

export default UpgradeServices;