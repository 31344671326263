import { useEffect, useState } from "react"
import Store from "../../../Store"
import Notice from "../../../Notice"
import Api from "../../../Api"
import { useNavigate } from "react-router-dom"
import Title from "../../../mainTitle"

export default function useCreateBattle () {
    const [load, setLoad] = useState(true)
    const [newList, setNewList] = useState([])
    const [rounds, setRounds] = useState(0)
    const [battleParams, setBattleParams] = useState({
        roomType: 'PUBLIC',
        gameType: '1vs1',
        bet: 0,
        passwordLength: 6,
    })
    const navigate = useNavigate()

    Store.useListener('newList', ({list, sum, rounds}) => {
        setRounds(rounds)
        setBattleParams(prev => ({...prev, bet: sum}))
        setNewList(list)
    })

    useEffect(() => {
        Title.setListener('title', 'CASE BATTLES')
    }, [])

    /* ---------------функции добавления/уменьшения колличества карточек--------------- */

    function plusCard(id) {
        if(rounds === 100) {
            return Notice.Send({type: 'error', text: 'Max number of cases is 100!'})
        }
        setNewList(newList.map(c => {
            if(c.id === id) {
                setBattleParams(prev => ({...prev, bet: +(prev.bet + c.price).toFixed(2)}))
                setRounds(prev => prev + 1)
                return {...c, count: c.count + 1}
            }
            return c
        }))
    }

    function minusCard(card) {
        if(card.count === 1) {
            let clone = newList
            setNewList(clone.filter(el => el.id !== card.id))
        } else {
            setNewList(newList.map(c => {
                if(c.id === card.id) {
                    return {...c, count: c.count - 1}
                }
                return c
            }))
        }
        setBattleParams(prev => ({...prev, bet: +(prev.bet - card.price).toFixed(2)}))
        setRounds(prev => prev - 1)
    }

    const sortCards = (a, b) => {
        if (a.index > b.index) {
            return 1
        } else {
            return -1
        }
    }

    /* --------------Обновление списка в попапе---------------- */
    function updateList () {
        const config = {
            rounds: rounds,
            list: newList,
            sum: battleParams.bet
        }
        Store.setListener('updatedList', config)
    }

    function setMode (e, mode) {
        setBattleParams(prev => ({...prev, roomType: mode}))
        let filters = document.querySelector('.filters')
        filters.querySelectorAll('.common_button').forEach(filter => {
            if(filter === e.currentTarget){
                filter.classList.add('active')
            } else{
                filter.classList.remove('active')
            }
        })
    }

    function setPlayers(e, type) {
        setBattleParams(prev => ({...prev, gameType: type}))
        let battleType = document.querySelector('.battle_type')
        battleType.querySelectorAll('.common_button').forEach(player => {
            if(player === e.currentTarget){
                player.classList.add('active')
            } else{
                player.classList.remove('active')
            }
        })
    }

    async function createBattle () {
        
        if(!newList?.length) {
            return Notice.Send({type: 'error', text: 'Chose case before play!'})
        }
        
        setLoad(prev => prev = false)
        
        const wagers = localStorage.getItem('balanceField')
        const forWagers = wagers && wagers === 'wager' ? true : false

        let query = new URLSearchParams(battleParams)

        newList.map(el => {
            if(el.count > 1) {
                for(let i = 0; i < el.count; i++) {
                    query.append('cases', el.id)
                }
            } else {
                query.append('cases', el.id)
            }
        })

        const init = await Api.asyncGetWithMessage(`api/v1/game-case-battle/create?${query}&forWagers=${forWagers}`)

        setLoad(prev => prev = true)
        
        if(init === 'unAuth') {
            Store.setListener('openAuthPopUp', prev => prev = true)
            document.querySelector('html').classList.add('hidden')
            return
        }
        
        if(init.statusCode === 400) {
            Notice.Send({type: 'error', text: init.exception.message})
            return
        }

        if(!init.statusCode) {
            if(init.roomsType === 'PRIVATE') {
                Store.setListener('openPreviewPassword', true)
                Store.setListener('previewPassword', init?.password)
            }
            navigate(`/case_battles/${battleParams.gameType}/${init.id}`)
            await Api.updateAccount()
        }
        
    }

    return {load, plusCard, minusCard, sortCards, updateList, battleParams, setNewList, newList, rounds, setMode, setPlayers, createBattle}
}