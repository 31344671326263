import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Store from '../../../Store';



const UserHeader = () => {
    const [list, setList] = useState([])

    let [num, setNum] = useState(0)
    Store.useNumListener('page', setNum)

    useEffect(() => {
        setList(document.querySelectorAll('.head_button'));
    }, [])


    function nextPage() {
        if(num < list.length - 1){
           num++;
           list[num].click()
        }
    }

    function prevPage() {
        if(num > 0){
            num--;
            list[num].click()
        }
    }

    return (
        <div className="user_header_wrapper">
            
            <nav className='user_header'>
                <div className="account_icon_chevron_left" onClick={prevPage}></div>
                <NavLink to='/user/account' className='common_button head_button'>Account</NavLink>
                <NavLink to='/user/stats' className='common_button head_button' id='stats'>Stats</NavLink>
                <NavLink to='/user/inventory' className='common_button head_button'>Inventory</NavLink>
                <NavLink to='/user/deposits' className='common_button head_button'>Deposits / Withdraws</NavLink>
                <NavLink to='/user/game_history/' className='common_button head_button'>Game History</NavLink>
                <button className='common_button red' onClick={Store.logout}>LOGOUT</button>
                <div className="account_icon_chevron_right" onClick={nextPage}></div>
            </nav>
            
        </div>
        
    );
};

export default UserHeader;