import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import open_lock from './../images/open_lock.svg'
import lock from './../images/lock.svg'



const Tier = (props) => {
    const {userTier, image, lvl, total, progres, referal, loan, commission} = props

    const [check, setCheck] = useState({
        lock: true,
        referal: false,
        commission: false,
        loan: false
    })

    function checkForProgres() {
        if(referal <= userTier.activeReferrals) {
            setCheck(prev => ({...prev, referal: true}))
        }
        if(commission <= userTier.commission) {
            setCheck(prev => ({...prev, commission: true}))
        }
        if(lvl <= userTier.level) {
            setCheck(prev => ({...prev, loan: true, lock: false}))
        }
    }   

    useEffect(() => {
        checkForProgres()
    }, [])


    return (
        <div className='referal_tier_popup_item'>

            <div className="referal_deposit">
                <img className={`image${lvl}`} src={image} alt="" />
                <div className="referal_deposit_content">
                    <div className="tier">
                        <h3 className="title">Tier {lvl}</h3>
                        <h5 className="pretitle">Referal deposits</h5>
                    </div>
                    <div className='deposit_progress'>
                        {check.lock ? <img className='open' src={lock}  alt=''/>: <img src={open_lock} alt='' />}
                        <div className="price">
                            <img src={coin} alt="" />
                            {total}
                        </div>
                    </div>
                </div>
            </div>

            <div className="referal_scale">
                <div className="scale" style={{width: `${progres}%`}}></div>
            </div>

            <div className="description">
                <p> 
                    {check.commission ?
                        <div className='ref_popup_check'></div>
                    :
                        <div className='ref_popup_cross'></div>
                    }
                    {commission}% Commissions on deposits
                </p>

                {referal != 0 &&
                    <p>
                        {check.referal ?
                            <div className='ref_popup_check'></div>
                        :
                            <div className='ref_popup_cross'></div>
                        }
                        {referal} Active referrals
                    </p>
                }
                
                {loan != 0 && 
                    <p>
                        {check.loan ?
                            <div className='ref_popup_check'></div>
                        :
                            <div className='ref_popup_cross'></div>
                        }
                        Loan ${loan}
                    </p>
                }
                
            </div>

        </div>
    );
};

export default Tier;