import React, { useEffect, useState } from 'react';
import RouletteLastItem from './RouletteLastItem';
import ProvablyModal from './provably_fair/ProvablyModal';

import helmet from './../images/helmet.png'
import helmet2 from './../images/helmet2.png'
import helmet3 from './../images/helmet3.png'
import header from './../images/header.png'
import truba from './../images/truba.png'


const RouletteLast = ({lastHundred, last, refer, timefield}) => {

    return (
        <div className="roulette_header">

            <div className="header_section">
                <div className="timer">
                    <div className="timer_wrapper">
                    <p>Start: {timefield}</p>
                    </div>
                </div>

                <div className="roulette_last">
                    <div className="roulette_last_content nocopy">
                        <h4>Last:</h4>
                        <div className="roulette_last_track" ref={refer}>
        
                            {last?.length && last.map((e, i) => (
                                <RouletteLastItem key={i} e={e}/>
                            ))}
                            
                        </div>
                    </div>
        
                    <ProvablyModal />
                </div>
            </div>

            <div className="last_hundred_list">

                <div className="last_hundred_list_item">
                    <div className="image green">
                        <img src={truba} alt="" />
                    </div>
                    <div className="count">{lastHundred.green}</div>
                </div>
                
                <div className="last_hundred_list_item red">
                    <div className="image red2">
                        <img src={helmet} alt="" />
                    </div>
                    <div className="count">{lastHundred.red}</div>
                </div>

                <div className="last_hundred_list_item">
                    <div className="image red1">
                        <img src={helmet2} alt="" />
                    </div>
                    <div className="count">{lastHundred.adjacent_red}</div>
                </div>

                <div className="last_hundred_list_item">
                    <div className="image black2">
                        <img src={helmet3} alt="" />
                    </div>
                    <div className="count">{lastHundred.adjacent_black}</div>
                </div>

                <div className="last_hundred_list_item">
                    <div className="image black1">
                        <img src={header} alt="" />
                    </div>
                    <div className="count">{lastHundred.black}</div>
                </div>
                
            </div>

        </div>
        );
};

export default RouletteLast;