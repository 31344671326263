import {useState} from "react";
import Api from "../../../../../Api";
import Notice from "../../../../../Notice";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../constants";
import Store from "../../../../../Store";
import { useNavigate } from "react-router-dom";

export const usePersonalInfo = (profile) => {

    const [load, setLoad] = useState(false)
    const [open2fa, setOpen2fa] = useState(false)
    const [body, setBody] = useState({})
    const navigate = useNavigate()

    const [input, setInput] = useState({
        email: '',
        username: '',
        password: '',
        repeatPassword: ''
    });

    const [error, setError] = useState({
        email: false,
        username: false,
        password: false,
        repeatPassword: false
    })

    const onInputChange = e => {
        const {name, value} = e;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
        document.querySelector('.password_require').classList.remove('open')
    }

    const validateInput = e => {
        let {name, value} = e;
        setError(prev => {
            const stateObj = {...prev, [name]: ""};

            switch (name) {

                case "password":
                    if (!PASSWORD_REGEX.test(value)) {
                        stateObj[name] = 'The password does not meet the security requirements'
                    } else {
                        stateObj[name] = null
                    }
                    break;

                case "repeatPassword":
                    if(value !== input.password) {
                        stateObj[name] = "Passwords don't match"
                    } else {
                        stateObj[name] = null
                    }
                    break;

                case "email": 
                    if (!EMAIL_REGEX.test(value)) {
                        stateObj[name] = "Please enter valid Email.";
                    } else {
                        stateObj[name] = null
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    const changeLogin = async (e) => {
        if(load) {
            return
        }
        e.preventDefault()
        let data = {}
        setLoad(true)
        if(!profile.user.email) {
            if(error.email || error.password || error.repeatPassword || error.username) {
                setLoad(false)
                return
            } else if (!input.email || !input.password || !input.repeatPassword || !input.username) {
                if(!input.email) {
                    setError(prev => ({...prev, email: "Please enter email"}))
                }
                if(!input.password) {
                    setError(prev => ({...prev, password: "Please enter password"}))
                }
                if(!input.repeatPassword) {
                    setError(prev => ({...prev, repeatPassword: "Please enter repeatPassword"}))
                }
                if(!input.username) {
                    setError(prev => ({...prev, username: "Please enter username"}))
                }
                setLoad(false)
                return
            } else {
                data = {
                    username: input.username,
                    email: input.email,
                    password: input.password
                }
            }
        } else {
            if(input.email) {
                if(!error.email) {
                    data.email = input.email
                } else {
                    setLoad(false)
                    return
                }
            }
            if(input.username) {
                data.username = input.username
            }
            if(input.password) {
                if(error.password) {
                    setLoad(false)
                    return
                } else {
                    if (input.repeatPassword) {
                        if(error.repeatPassword) {
                            setLoad(false)
                            return
                        } else {
                            data.password = input.password
                        }
                    } else {
                        setError(prev => ({...prev, repeatPassword: "Passwords don't match"}))
                        setLoad(false)
                        return
                    }
                }
            }
        }

        if(Object.keys(data).length === 0) {
            setLoad(false)
            return
        }

        try {
            setError({email: null, password: null, username: null})
            let res = await fetch(`${Api.url}/api/v1/auth/credentials`, {
                method: 'PUT',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                },
                credentials: 'include',
                body: JSON.stringify(data)
            })
            res = await res.json()

            if(res.statusCode && res.statusCode === 401 && res.exception.message === 'Unauthorized') {//нет токена
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.querySelector('html').classList.add('hidden')
                return navigate('/')
            }
            
            if(res.statusCode && res.statusCode !== 200) {
                if(res.exception.name === "ConflictException") {
                    setError(prev => ({...prev, [res.exception.message.split(' ')[1]]: res.exception.message}))
                }
                if(res.exception.message === 'No OTP code provided!') {
                    setOpen2fa(prev => prev = true)
                    setBody(data)
                }
                setLoad(false)
                return 
            }
            setLoad(false)
            window.location.reload()
            return 
        }
        catch(e) {
            console.log(e)
            return Notice.Send({type: 'error', text: 'Ошибка сервера'})
        }
    }

    function openRequire(e) {
        e.stopPropagation()
        document.querySelector('.password_require').classList.add('open')
    }

    return {onInputChange, changeLogin, open2fa, body, setOpen2fa, error, input, validateInput, openRequire, load}
}