import React from 'react';
import ak from './../../images/ak.svg'
import coin from './../../fonts/main_icon_fonts/coin.svg'
import NotItems from '../NotItems';
import Price from '../price/Price';

const DropsItems = ({refer, list}) => {
    return (
        <div className="drops_wrapper" ref={refer}>
            {list && list.length ? 
                    <>
                        {list.map((el, i) => (
                            <div className="drop_card_wrapper" key={i}>
                                <div className={`drop_card main_card ${el.type}`}>
                                    <img src={el.image} className={`drop_image ${el.type}`} alt="" />
                                    <div className="drop_card_content">
                                        <h4 className="title">{el.name}</h4>
                                        <p className={`price main_card ${el.type}`}><Price price={el.price}/> <img src={coin} alt="" /></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                :
                    <NotItems />
                }
            
        </div>
    );
};

export default DropsItems;