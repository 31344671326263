import { useEffect, useState } from "react"
import PromoServices from "../services/PromoServices"

export default function useInfo (user) {
    const [open, setOpen] = useState(false)
    let [client, setClient] = useState(false)
    let [tier, setTier] = useState(false)
    let [progres, setProgres] = useState(false)
    const [load, setLoad] = useState(false)

    const getTier = (id) =>  PromoServices.getReqCookie(`api/v1/referral-tiers/${id}`)
    const getProgres = () => PromoServices.getReqCookie(`api/v1/referrals/me/progress`)

    useEffect(() => {
        (async () => {
            if(user) {
                setClient(user) 
                await Promise.all([getTier(user.billingAccount.referralTier), getProgres()])
                .then(response => {

                    const [tierInit, progresInit] = response
                    
                    setProgres(progresInit)

                    if(tierInit === 'error') {
                        setTier(false)
                    } else {
                        setTier(tierInit)
                    }
                });
                setLoad(prev => prev = true)
            }
        })()

    }, [user])

    function openTierPopUp() {
        setOpen(true)
        document.querySelector('html').classList.add('hidden')
    }

    function closeTierPopUp() {
        setOpen(false)
        document.querySelector('html').classList.remove('hidden')
    }

    return {load, open, setOpen, client, tier, progres, openTierPopUp, closeTierPopUp}
}