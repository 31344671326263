import React from 'react';

import coin from './../../../../../../fonts/main_icon_fonts/coin.svg'

const HistoryList = ({list}) => {

    return (
        <div className="history_list dice">
            {list && list.map((e, i) => (
                <div className="history_list_item" key={i}>
                    <div className="id"><span>{e.betId}</span></div>

                    <div className="created">
                        {e.time[0]}
                        <p className="time">{e.time[1]}</p>
                    </div>

                    <div className="initial_bet">
                        <img src={coin} alt="" />
                        <span>{e.bet}</span>
                    </div>

                    <div className="plurals">
                        x{e.multiplier}
                    </div>

                    <div className={`payout ${+e.totalPayout < 0 ? 'red' : 'green'}`}>
                        <img src={coin} alt="" />
                        <span>{e.totalPayout}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HistoryList;