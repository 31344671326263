import Api from "./Api";

const Bet = {}

let timer = false

Bet.setBets = (e, callback) => {
    let value = e.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
    if(value > 1000) {
        callback((1000).toFixed(2))
    } else {
        let str = value.split('.')
        if(str[1] && str[1].length > 2) {
            return
        } else {
            callback(str.join('.'))
        }
    }
}

Bet.setCoeficient = (e, callback) => {
    let value = e.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
    if(value > 1000) {
        callback((1000).toFixed(2))
    }  else {
        let str = value.split('.')
        if(str[1] && str[1].length > 2) {
            return
        } else {
            callback(str.join('.'))
        }
    }
}

Bet.plus = (value, bet, callback) => {
    if(timer) {
        return
    }
    let val = bet * 100 || 0
    const sum = val + value * 100

    const timeout = () => {
        if (val >= 100000) {
            callback('1000.00')
            cancelAnimationFrame(timer)
            timer = false
        } else if (val >= sum) {
            cancelAnimationFrame(timer)
            timer = false
        } else {
            val += value * 10
            callback((val / 100).toFixed(2))
            timer = requestAnimationFrame(timeout)
        }
    }
    timer = requestAnimationFrame(timeout)
    return () => {
        if(timer) {
            cancelAnimationFrame(timer)
        }
    }
}

Bet.plusAnim = (value, bet, callback) => {
    //value = новое значение - старое
    let val = bet * 100 || 0
    const sum = val + value * 100

    const timeout = () => {
        if (val >= sum) {
            cancelAnimationFrame(timer)
            timer = false
        } else {
            val += value * 5
            callback((val / 100).toFixed(2))
            timer = requestAnimationFrame(timeout)
        }
    }
    timer = requestAnimationFrame(timeout)
    return () => {
        if(timer) {
            cancelAnimationFrame(timer)
        }
    }
}

Bet.multi = (value, bet, callback) => {
    if(timer) {
        return
    }
    let val = bet * 100 || 0
    const sum = val * value
    let interval = (sum - val) / 10
    const tiomeout = () => {
        if (val >= 100000) {
            callback('1000.00')
            cancelAnimationFrame(timer)
            timer = false
        } else if (val >= sum) {
            cancelAnimationFrame(timer)
            timer = false
        } else {
            val = interval + val
            callback((val / 100).toFixed(2))
            timer = requestAnimationFrame(tiomeout)
        }
    }
    timer = requestAnimationFrame(tiomeout)
    return () => {
        if(timer) {
            cancelAnimationFrame(timer)
        }
    }
}

Bet.divide = (value, bet, callback) => {
    if(timer) {
        return
    }
    let val = bet * 100 || 0
    const sum = val / value
    if (sum <= 1) {
        return callback('0.01')
    }
    let interval = (val - sum) / 10

    const timeout = () => {
         if (val <= sum) {
            cancelAnimationFrame(timer)
            timer = false
        } else {
            val = val - interval
            callback((val / 100).toFixed(2))
            timer = requestAnimationFrame(timeout)
        }
    }

    timer = requestAnimationFrame(timeout)
    return () => {
        if(timer) {
            cancelAnimationFrame(timer)
        }
    }
}

Bet.percent = (value, bet, callback, balance) => {
    if(timer) {
        return
    }
    let lastBet = bet * 10000 || 0
    let sum = balance * 100 * value
    if ((sum / 10000).toFixed(2) === lastBet) {
        return
    }
    if (sum > 10000000) {
        sum = 10000000
    }

    let interval = (sum - lastBet)  / 100

    if(interval >= 0) {
        timer = setInterval(() => {
            if (lastBet >= sum) {
                callback((sum/10000).toFixed(2))
                clearInterval(timer)
                timer = false
            } else {
                lastBet += interval
                callback((lastBet / 10000).toFixed(2))
            }
        }, 1)
    } else {
        timer = setInterval(() => {
            if (lastBet <= sum) {
                callback((sum/10000).toFixed(2))
                clearInterval(timer)
                timer = false
            } else {
                lastBet += interval
                callback((lastBet / 10000).toFixed(2))
            }
        }, 1)
    }
}

Bet.maxBet = async (val, callback) => {
    const bet = val || 0
    const localField = localStorage.getItem('balanceField')
    let res = await Api.asyncGetCred(`api/v1/auth/me/profile`)

    if (res === 'error' || res === 'unAuth') {
        return console.log(res)
    }
    
    if(localField) {
        if(localField === 'coin') {
            if(res.client.billingAccount.balance < bet) {
                return
            } else if(res.client.billingAccount.balance > 1000) {
                Bet.plus(1000 - bet, bet, callback)
            } else {
                Bet.plus(res.client.billingAccount.balance - bet, bet, callback)
            }
        } else if (localField === 'wager') {
            if(res.client.billingAccount.wagers < bet) {
                return
            } else if(res.client.billingAccount.wagers > 1000) {
                Bet.plus(1000 - bet, bet, callback)
            } else {
                Bet.plus(res.client.billingAccount.wagers - bet, bet, callback)
            }
        }
    } else {
        if(res.client.billingAccount?.balance > 1000) {
            Bet.plus(1000 - bet, bet, callback)
        } else {
            Bet.plus(res.client.billingAccount?.balance - bet, bet, callback)
        }
    }
}

Bet.clear = (callback) => {
    if(timer) {
        return
    }
    callback('')
}

export default Bet;