import React from 'react';
import './../../../../components/css/components.css'
import useProvably from '../../../../hooks/useProvably';

const ProvablyModal = (props) => {
    
    const {setOpen, hash, client, server, nonce} = props

    const params = {
        gameHash: hash,
        clientSeed: client,
        serverSeed: server,
        nonce: nonce
    }

    const LINK = 'api/v1/game-dice/public-bitcoin-hash'
    const VLINK = 'api/v1/game-dice/verify?'

    const {result, loadBtn, loadSeed, paramsUrl, salt, closeModal, verify, changeSeed} = useProvably({setOpen, params, LINK, VLINK})

    return (
        <div className='provably_modal_wrapper' onMouseDown={() => closeModal()}>
            <div className="provably_modal_window" onMouseDown={(e) => e.stopPropagation()}>

                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_scales"></div>
                    </div>   
                    <h4 className='game_title'>Provably fair</h4>
                    <div className="decor pink" onMouseDown={() => closeModal()}></div>
                </div>

                <div className="provably_modal_content">

                    <div className="item">
                        <h3>Game hash</h3>

                        <div className="info"> <span>{hash}</span> </div>
                    </div>

                    <div className="item">
                        <h3>Client Seed</h3>

                        <div className="info flex">
                            <span>{paramsUrl.clientSeed}</span>
                            <button className='icon_button green' onMouseDown={() => changeSeed()}>
                                {loadSeed ?
                                    <div className="case_btl_icon_arrows"></div>
                                :
                                    <svg width = {`20px`}height={'20px'}>
                                        <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>

                    <div className="item">
                        <h3>Hashed Server Seed</h3>

                        <div className="info"> <span>{server}</span> </div>
                    </div>

                    <div className="item">
                        <h3>Nonce</h3>

                        <div className="info"> <span>{nonce}</span> </div>
                    </div>

                    <div className="item">
                        
                        <h3>Salt</h3>
                        
                        <div className="info">
                            {salt ?
                                <span>{salt}</span>
                            :
                                'Loading...'
                            }
                        </div>

                    </div>

                    <div className="item">
                        <h3>Result</h3>

                        <div className="item_flex">
                            <div className="result main_card">
                                {result ? result : '????'}
                            </div>

                            <button className='common_button green full flex' onMouseDown={() => verify()}>
                                {loadBtn ? 'Verify' : 'Loading...'}
                            </button>
                        </div>

                    </div>

                </div>

            </div>            
        </div>
    );
};

export default ProvablyModal;