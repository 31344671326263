import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'

import logo from './../images/Logo.png'
//import logo from './images/dark_logo.svg'


import './css/components.css'
import GameList from './GameList';
import Title from '../mainTitle';
import Burger from './header/Burger';
import Store from '../Store';
import BalanceBtn from './header/BalanceBtn';
import ButtonBack from './header/ButtonBack';
import LoginBtn from './header/LoginBtn';
import AccountBtn from './header/AccountBtn';
import AdminAllUsersBtn from './header/AdminAllUsersBtn';
import Api from '../Api';
import DarkModeBtn from './dark_mode_btn/DarkModeBtn';


const Header = (props) => {

    const[title, setTitle] = useState('HOME')
    const [isOpen, setIsOpen] = useState(false)
    const [profile, setProfile] = useState()
    const [load, setLoad] = useState(false)

    Title.useListener('title', setTitle)

    useEffect(() => {

        if(props.isAuth) {
            (async () => {

                let res = await Api.asyncGetCred('api/v1/auth/me/profile')
                
                if (res === 'unAuth' || res === 'error') {
                    return 
                } else {
                    setProfile(res.client)
                    setLoad(true)
                }
    
            })()
        }
        
        window.addEventListener('click', closeGameList)
    
        return () => window.removeEventListener('click', closeGameList)

    }, [props.isAuth])

    useCallback(() => {
        return () => window.removeEventListener('click', closeGameList)
    }, [])

    const OpenGameList = (e) => {
        e.stopPropagation()
        setIsOpen(prev => !prev)
    }

    const closeGameList = (e) => {
        e.stopPropagation()
        setIsOpen(false)
    }

    return (
        <header>
            <nav className='navigation first'>

                {/*<ButtonBack />*/}

                {/* <h2 className='header_title'>{title}</h2> */}

                <NavLink to='/daily_race' className='common_button mob flex race'>
                    <div className='race_iconrace'></div>
                    Race
                </NavLink>

                <NavLink to='/promo' className="common_button" id='promo'>PROMO</NavLink>

                <div className="game_button_wrapper">
                    <a className="common_button" id='game' onClick={OpenGameList}>
                        GAMES
                        <div className="icon_arrow_right"></div>
                    </a>

                    <NavLink to='/rewards' className="icon_button" id='rewards'><div className="race_iconGift"></div></NavLink>

                    <GameList isOpen={isOpen} OpenGameList={OpenGameList}/>
                </div>

            </nav>

            <NavLink to='/'><img src={logo} className="main_logo"></img></NavLink>
            
            <nav className="navigation left">

                {/* <DarkModeBtn mode={mode}/> */}
                
                {props.role === 'ADMIN' && <AdminAllUsersBtn />}
                
                {/* <NavLink to='/help' className='icon_button mob'><div className="race_iconhelp"></div></NavLink> */}
                <NavLink to='/withdrow' className="common_button mob">Withdraw</NavLink>
                <NavLink to='/deposit' className="common_button mob green" id='deposit'>Deposit</NavLink>
                
                <div className="game_button_wrapper">
                    {props.isAuth && <BalanceBtn balance={profile?.billingAccount} load={load} wager={props.wager} />}
                    
                    {props.isAuth && <NavLink to='/user/inventory' className="icon_button mobile"><div className='icon_bag' ></div></NavLink>}

                    <Burger isAuth={props.isAuth} role={props.role}/>

                    {!props.isAuth && <LoginBtn />}
                </div>

                {props.isAuth && <AccountBtn />}
                
            </nav>
            
        </header>
    );


}

export default Header;