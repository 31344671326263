import React from 'react';
import useCaseStoreFilters from '../hooks/useCaseStoreFilters';

const Filters = ({params, changeParams, changePriceFilter, price}) => {

    let {openFilter} = useCaseStoreFilters()

    return (
        <div className="cases_filters_block">

            <div className="cases_filters_item ">
                <div className="cases_filters_item_description main_card">
                    <h6>Price range</h6>
                    <p id='casesFilterPriceRange'>{price.length > 1 ? price.map((el, i )=> (<span>{i > 0 && ','} {el}</span>)) : price[0]}</p>
                </div>

                <button className="icon_button" onMouseDown={(e) => openFilter(e)}>
                    <div className="icon_arrow_right"></div>
                </button>

                <div className="cases_filter_list" onMouseDown={(e) => changePriceFilter(e)}>
                    <p className='active'>all</p>
                    <p>0-10</p>
                    <p>10-25</p>
                    <p>25-50</p>
                    <p>50-100</p>
                    <p>100-250</p>
                    <p>250-1000</p>
                    <p>1000+</p>
                </div>
            </div>

            <div className="cases_filters_item ">
                <div className="cases_filters_item_description main_card">
                    <h6>Order by</h6>
                    <p>{params.order}</p>
                </div>

                <button className="common_button icon_button" onMouseDown={(e) => openFilter(e)}>
                    <div className="icon_arrow_right" ></div>
                </button>

                <div className="cases_filter_list" onMouseDown={(e) => changeParams(e, 'order')}>
                    <p className='active'>ASC</p>
                    <p>DESC</p>
                </div>
            </div>
            
            <div className="cases_filters_item ">
                <div className="cases_filters_item_description main_card">
                    <h6>Field</h6>
                    <p>{params.field}</p>
                </div>

                <button className="common_button icon_button" onMouseDown={(e) => openFilter(e)}>
                    <div className="icon_arrow_right" ></div>
                </button>

                <div className="cases_filter_list" onMouseDown={(e) => changeParams(e, 'field')}>
                    <p className='active'>name</p>
                    <p>price</p>
                </div>
            </div>
        </div>
    );
};

export default Filters;