import React, { useEffect, useRef, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import { io } from 'socket.io-client';
import Api from '../../../Api';
import Price from '../../../components/price/Price';

const LiveDrops = ({id, fast}) => {

    const [bestDrops, setBestDrops] = useState(false)
    const [liveDrops, setLiveDrops] = useState(false)

    const dropSocket = useRef(null)
    const live = useRef(null)
    const best = useRef(null)
    const liveRef = useRef()
    const bestRef = useRef()

    useEffect(() => {
        
        const interval = 7500

        dropSocket.current = io(`${Api.urlWs}/game-open-case?case_${id}`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        
        dropSocket.current.on('open-case.BestItems', (data) => {
            if(data instanceof Array) {
                setBestDrops(data)
            } else {
                best.current = setTimeout(() => {
                    // console.log(data)
                    // let allItems = bestRef.current.querySelectorAll('.drop_card_wrapper')

                    // if(allItems?.length) {
                    //     allItems[allItems.length - 1].style.opacity = '0'
                    // }
                    // setTimeout(() => {
                    //     const el = data.bestItems[0]
                    //     const newitem = document.createElement('div')
                    //     newitem.className = 'drop_card_wrapper anim'
                    //     newitem.innerHTML = `<div class="drop_card border nocopy hidden">
                    //                             <img src='${el.image}' class='drop_image ${el.type}' alt="" />
                    //                             <div class="drop_card_content">
                    //                             <h4 class="title">${el.name}</h4>
                    //                             <p class="price main_card">${el.price} <img src='${coin}' alt="" /></p>
                    //                             </div>
                    //                         </div>`
                    //     bestRef.current.insertBefore(newitem, bestRef.current.firstChild)

                    //     allItems = bestRef.current.querySelectorAll('.drop_card_wrapper')

                    //     setTimeout(() => {
                    //         if(allItems.length >= 2) {
                    //         }
                    //         allItems[allItems.length - 1].classList.add('hide')
                    //         newitem.classList.add('show')
                    //     }, 1)
                    //     setTimeout(() => {
                    //         if(allItems.length > 2) {
                    //             allItems[allItems.length - 1].remove()
                    //         }
                    //     }, 400)
                    // }, 500)

                    setBestDrops(data.bestItems)
                }, interval)
            }
        })
        dropSocket.current.on('open-case.liveDrops', (data) => {
            if(data instanceof Array) {
                console.log(data)
                setLiveDrops(data)
            } else {
                const el = data.liveDrops[0]
                live.current = setTimeout(() => {
                    const newitem = document.createElement('div')
                    newitem.className = `drop_card_wrapper anim ${el.type}`
                    newitem.innerHTML = `<div class="drop_card border nocopy hidden ${el.type}">
                                            <img src='${el.image}' class='drop_image ${el.type}' alt="" />
                                            <div class="drop_card_content">
                                            <h4 class="title">${el.name}</h4>
                                            <p class="price main_card ${el.type}">${(el.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <img src='${coin}' alt="" /></p>
                                            </div>
                                        </div>`
                    if(liveRef.current.firstChild) {
                        liveRef?.current?.insertBefore(newitem, liveRef.current.firstChild)
                    } else {
                        liveRef?.current?.prepend(newitem)
                    }
                    setTimeout(() => {
                        newitem.classList.add('show')
                    }, 1)

                    const allItems = liveRef.current.querySelectorAll('.drop_card_wrapper')
                    if(allItems.length > 4) {
                        allItems[allItems.length - 1].remove()
                    }
                }, interval)
            }
        })
        dropSocket.current.on('connect_error', (error) => console.log(error))
        dropSocket.current.emit('caseSelected', id)
        
        return () => {
            if (dropSocket.current) {
                dropSocket.current.off('open-case.BestItems');
                dropSocket.current.off('open-case.liveDrops');
                dropSocket.current.off('connect_error');
                dropSocket.current.disconnect();
    
                if(best.current) {
                    clearTimeout(best.current)
                }
                
                if(live.current) {
                    clearTimeout(live.current)
                }
            }
        }
        
    }, [id, fast])

    return (
        <div className='last_drop_block'>
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="home_icon_cases"></div>
                </div>   
                <h4 className='game_title'>Best drops / Live drops</h4>
                <div className="decor pink"></div>
            </div>
            <div className="last_drop_list_wrapper">

                <div className="last_drop_list" ref={bestRef}>
                    {bestDrops?.length && bestDrops.map((e, i) => (
                        <div className="drop_card_wrapper">
                            <div className={`drop_card border ${e.type}`} key={i}>
                                <img src={e.image} className={`drop_image ${e.type}`} alt="" />
                                <div className="drop_card_content">
                                    <h4 className="title">{e.name}</h4>
                                    <p className={`price main_card ${e.type}`}><Price price={e.price}/> <img src={coin} alt="" /></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="last_drop_list" ref={liveRef}>
                    {liveDrops?.length && liveDrops.map((e, i) => (
                        <div className="drop_card_wrapper">
                            <div className={`drop_card border ${e.type}`} key={i}>
                                <img src={e.image} className={`drop_image ${e.type}`} alt="" />
                                <div className="drop_card_content">
                                    <h4 className="title">{e.name}</h4>
                                    <p className={`price main_card ${e.type}`}><Price price={e.price}/> <img src={coin} alt="" /></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LiveDrops;