import React, { useEffect } from 'react';
import not_f from './images/pensil_404.png'

const NotFoundPage = () => {

    useEffect(() => {
        const body = document.getElementById('root')
    
        body.innerHTML = `<div class='not_found'>
                            <p>404</p>
                            <img src='${not_f}' alt="" />
                         </div>`;
    }, [])
    
    
    return (
        <div className='' onclick=''>
            Not found
        </div>
    );
};

export default NotFoundPage;