import React, { useCallback, useEffect, useState } from 'react';
import rectrangle from './../images/Rectangle.svg';
import hair from './../images/hair.svg';


const Pensil = ({game, refs}) => {

    const[position, isPosition] = useState({
        X: 0,
        Y: 0
    })
    
    const screenWidth = window.innerWidth; //Находим ширину экрана
    const screenHeight = window.innerHeight; //Находим высоту экрана

    function positionFunc(pos) {
        if (pos > 60) {
            return 60
        } else if (pos < -60) {
            return -60
        } else {
            return pos
        }
    }


    function eyeMove(event) {

        if(game) {
            return
        }

        const center = document.querySelector('.center_eyes').getBoundingClientRect(); //Узнаем координаты глаз по отношению к экрану
        let posX = ((event.x - center.x)*100)/screenWidth 
        let posY = ((event.y - center.y)*100)/screenHeight

        isPosition({
            X: positionFunc(posX), //Вычисляем положение глаза по Х
            Y: positionFunc(posY) //Вычисляем положение глаза по Y
        })

    }


    useEffect(() => {

        document.addEventListener('mousemove', eyeMove)

        return () => {
            document.removeEventListener('mousemove', eyeMove);
          };

    }, [position, game]) 


    useCallback(() => {
        document.removeEventListener('mousemove', eyeMove);
    })

    return (
        <div className={`pensil_box ${game == 'progres' ? 'progres' : ''} ${game == 'crash' ? 'crash_pensil' : ''}`} ref={refs.pensil}>
            <div className="header">
                <img className='rectangle' src={rectrangle} alt="" />
                <img className='hair' src={hair} alt="" />
                <div className="eyes_block">
                    <div className="center_eyes"></div>
                    <div className="eyes left">
                        <div className="eyeball" id='e-1' style={{'transform': `translate(${game === 'progres' || game === 'crash' ? -45 : -50 + position.X}%, ${game === 'progres' || game === 'crash' ? -90 : -50 + position.Y}%)`}}></div>
                    </div>
                    <div className="eyes">
                        <div className="eyeball" id='e-2' style={{'transform': `translate(${game === 'progres' || game === 'crash' ? -55 : -50 + position.X}%, ${game === 'progres' || game === 'crash' ? -90 : -50 + position.Y}%)`}}></div>
                    </div>
                </div>
                <div className="mouth"></div>
            </div>
            <div className="body">
                <div className="body_flex">
                    <div className="body_flex_item blue"></div>
                    <div className="body_flex_item center"></div>
                    <div className="body_flex_item green"></div>
                </div>
                <div className="layer_gery"></div>
                <div className="layer_gery"></div>
                <div className="eraser"></div>
            </div>
            <div className="rocket">
                <div className="rocket_item red" ref={refs.redRocket}></div>
                <div className="rocket_item yellow" ref={refs.yellowRocket}></div>
                <div className="rocket_item blue" ref={refs.blueRocket}></div>
            </div>
        </div>
    );
};

export default Pensil;