import Api from "../../Api"
import Notice from "../../Notice"
import Store from "../../Store"

const Administrator = {}

Administrator.getRes = (res) => {
    if(res.status === 401) {
        return 'unAuthorized'
    }
    if (res.status === 404) {
        return '404'
    }
    if (res.status === 409) {
        return 'exist'
    }
    if(res.status === 400) {
        return '400'
    }
    if (!res.ok) {
        return 'error'
    } 
    return res.json()
}


Administrator.onSuccess = (message) => {
    Notice.Send({type: 'success', text: message})
}


Administrator.resetErrorAddCases = (newList, setError) => {

    if(newList.length > 0) {
        newList.map(el => {
            document.getElementById(`${el.id}`).querySelector('.info_item').classList.remove('error')
        })
    }

    setError(prev => ({...prev, name: false}))

    document.getElementById('err_case_drop_chance').classList.remove('error')

    document.getElementById('err_case_exist').classList.remove('error')

}


Administrator.checkForErrorAddCases = (newList, setError, caseParams) => {
    let err = false

    if(newList.length < 2) {
        err = true
        setError(prev => ({...prev, list: true}))
    }
    if (newList.length > 15) {
        err = true
        setError(prev => ({...prev, list: true}))
        document.getElementById('err_max_num').classList.add('error')
    }
    if(caseParams.name === '') {
        err = true
        setError(prev => ({...prev, name: true}))
    }
    if(caseParams.image === '') {
        err = true
        setError(prev => ({...prev, image: true}))
    }
    if(newList.length > 0) {
        newList.map(el => {
            if(!Object.hasOwn(el, 'dropChance') || el.dropChance == '' || el.dropChance <= 0){
                setError(prev => ({...prev, dropChance: true}))
                document.getElementById(`${el.id}`).querySelector('.info_item').classList.add('error')
                err = true
                return
            } else{
                return
            }
        })
        
    }

    return err
}





export default Administrator