import React from 'react';

const BtnAdminAddNewCategory = () => {
    function openAdminPopup() {
        document.getElementById('adminEditCategories').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className="common_button green" onClick={openAdminPopup}>Add New Category</button>
    );
};

export default BtnAdminAddNewCategory;