import React, { useCallback, useEffect, useRef, useState } from 'react';

import './css/dice.css';

import YourBet from './components/YourBet';
import DiceGame from './components/DiceGame';
import Title from '../../mainTitle';
import Games from '../../Games';
import Store from '../../Store';
import Bet from '../../Bet';
import Api from '../../Api';
import Notice from '../../Notice';
import BetList from './components/BetList';


const Dice = ({isAuth}) => {

    const [roll, setRoll] = useState('under')
    const [range, setRange] = useState('50.00')
    const [coef, setCoef] = useState(0)
    const [bet, setBet] = useState()
    const [result, setResult] = useState(false)
    const [rollUnder, setRollUnder] = useState('50.00')
    const [iter, setIter] = useState('1')
    const [blocked, setBlocked] = useState(false)
    const [winnings, setWinnigs] = useState('1.92')
    const [error, setError] = useState({
        roll: false,
        win: false,
        chance: false,
        bet: false
    })

    let stopRef = useRef(false)

    function setBets(value) {
        document.querySelector('.amount_filed').classList.remove('error')
        setError(prev => ({...prev, bet: false}))
        let val = value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        
        if(!val || val < 0.1) {
            setError(prev => ({...prev, bet: true}))
            document.querySelector('.amount_filed').classList.add('error')
        }

        Bet.setBets(val, setBet)
    }

    function betDivivde(value) {
        Bet.divide(value, bet, setBet)
        document.querySelector('.amount_filed').classList.remove('error')
    }

    function dicePlay() {

        document.querySelector('.amount_filed').classList.remove('error')

        if(!isAuth) {
            Store.setListener('openAuthPopUp', prev => prev = true)
            document.body.style.overflow = 'hidden';
            return
        }

        if(blocked) {
            return
        }

        if(!bet || bet < 0.1) {
            setError(prev => ({...prev, bet: true}))
            document.querySelector('.amount_filed').classList.add('error')
            return
        }

        if(error.roll || error.chance || error.win) {
            return
        }
        
        (async () => {
            setBlocked(true)
            let rollUnderDec = Games.rollUnder(coef, parseFloat(rollUnder))
            let wagers = localStorage.getItem('balanceField')
            let query = {
                winChance: range,
                rollUnder: rollUnderDec,
                bet: bet,
                forWagers: wagers && wagers === 'wager' ? true : false
            }
            const params = new URLSearchParams(query).toString();

            let res = await Games.dicePlay(`api/v1/game-dice/play?${params}`)
            if (res === 'no balance') {
                Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
                setBlocked(false)
                return
            } 
            if (res === 'unAuthorization') {
                document.querySelector('html').classList.add('hidden')
                Store.setListener('openAuthPopUp', prev => prev = true)
                setBlocked(false)
                return
            }
            if (res === 'error') {
                setBlocked(false)
                return
            }

            Store.setListener('updateList', (prev) => {
                if(prev.length >= 10) {
                    prev = prev.slice(0, 9)
                }
                return [res, ...prev]
            })

            let queryUpd = {size: 10, page: 1, field: 'id', order: 'DESC'}
            const options = new URLSearchParams(queryUpd).toString();
            let total = await Games.gameHistory(`api/v1/game-dice/me/?${options}`)
            if(total !== 'error') {
                Store.setListener('updateTotal', total)
                Store.setListener('update_last', total)
            }

            await Api.updateAccount()
            setResult(res)
            setBlocked(false)
        })()

    }


    function diceMultiPlay() {

        document.querySelector('.amount_filed').classList.remove('error')

        if(!isAuth) {
            Store.setListener('openAuthPopUp', prev => prev = true)
            document.body.style.overflow = 'hidden';
            return
        }

        if(blocked) {
            return
        }
        if(!bet || bet < 0.01) {
            document.querySelector('.amount_filed').classList.add('error')
            return
        }
        if(!iter) {
            return
        }

        if(error.roll || error.chance || error.win) {
            return
        }
        setBlocked(true)
        let i = iter

        function multi () {
            
            if(stopRef.current) {
                stopRef.current = false
                setBlocked(false)
                return
            }
            (async () => {
                let rollUnderDec = Games.rollUnder(coef, parseFloat(rollUnder))
                let wagers = localStorage.getItem('balanceField')
                let query = {
                    winChance: range,
                    rollUnder: rollUnderDec,
                    bet: bet,
                    forWagers: wagers && wagers === 'wager' ? true : false
                }
                const params = new URLSearchParams(query).toString();

                let res = await Games.dicePlay(`api/v1/game-dice/play?${params}`)
                if (res === 'no balance') {
                    Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
                    setBlocked(false)
                    return
                } 
                if (res === 'unAuthorization') {
                    document.querySelector('html').classList.add('hidden')
                    Store.setListener('openAuthPopUp', prev => prev = true)
                    setBlocked(false)
                    return
                }
                if (res === 'error') {
                    setBlocked(false)
                    return
                }
                if(i < 1) {
                    return setBlocked(false)
                } else {
                    
                    Store.setListener('updateList', (prev) => {
                        if(prev.length >= 10) {
                            prev = prev.slice(0, 9)
                        }
                        return [res, ...prev]
                    })

                    let queryUpd = {size: 10, page: 1, field: 'id', order: 'DESC'}
                    const options = new URLSearchParams(queryUpd).toString();
                    let total = await Games.gameHistory(`api/v1/game-dice/me/?${options}`)
                    if(total !== 'error') {
                        Store.setListener('updateTotal', total)
                        Store.setListener('update_last', total)
                    }

                    await Api.updateAccount()
                    setResult(res)
                    setIter(prev => prev = prev <= 1 ? prev : prev - 1)
                    i--
                    setTimeout(() => {
                        multi()
                    }, 10)
                }
            })()
        }
        multi()
    }

    useEffect(() => {
        Title.setListener('title', 'DICE')

        return () => stopRef.current = true
    }, [])

    useCallback(() => {
        stopRef.current = true
    }, [])

    return (
        <div className='dice_block container'>

            <div className="dice">

                <YourBet winnings={winnings} blocked={blocked} play={dicePlay} bet={bet} setBet={setBet} setBets={setBets} betDivivde={betDivivde} diceMultiPlay={diceMultiPlay} iter={iter} setIter={setIter} stopRef={stopRef} error={error} setError={setError}/>

                <DiceGame winnings={winnings} setWinnigs={setWinnigs} result={result} range={range} setRange={setRange} roll={roll} setRoll={setRoll} setCoef={setCoef} rollUnder={rollUnder} setRollUnder={setRollUnder} error={error} setError={setError} block={blocked}/>

            </div>

            {isAuth && <BetList />}
            
        </div>
    );
};

export default Dice;