import React from 'react';
import Player from './components/Player';
import BattleHeader from './components/BattleHeader';
import './../css/case_battles.css';
import CaseRoulete from './components/case_roulete/CaseRoulete';
import useCaseBattle from '../hooks/useCaseBatle';
import { useParams } from 'react-router-dom';
import BattlePasswordPopUp from './components/BattlePasswordPopUp';


const Battle3v3 = () => {

    const {id} = useParams()
    const caseBattlesObj = useCaseBattle(id)
    const list = [1, 2, 3]

    return (
        <div className='case_battles_block container'>

            <BattleHeader password={caseBattlesObj.password} spectators={caseBattlesObj.spectators} gameDetails={caseBattlesObj.gameDetails}/>

            <CaseRoulete round={caseBattlesObj.round} cases={caseBattlesObj.formed?.cases}/>

            <div className="battle_grid">
                {caseBattlesObj.timer && <div className="timer">{caseBattlesObj.timer}</div>}
                {list.map((i) => (
                    <Player multi={'false'} load={caseBattlesObj.load} inGame={caseBattlesObj.inGame} cases={caseBattlesObj.cases} round={caseBattlesObj.round} gameStatus={caseBattlesObj.gameStatus} inventory={caseBattlesObj.inventory} result={caseBattlesObj.result} joinGame={caseBattlesObj.joinGame} callBots={caseBattlesObj.callBots} position={i} player={caseBattlesObj.formed.client?.find(el => el.position == i)} totalCost={caseBattlesObj.gameDetails?.totalCost} bot={caseBattlesObj.formed.bot?.find(el => el.position == i)} leaveClient={caseBattlesObj.leaveClient}/>
                ))}
            </div>

            {caseBattlesObj.roomsType === 'PRIVATE' && <BattlePasswordPopUp totalCost={caseBattlesObj.gameDetails?.totalCost}/>}
        </div>
    );
};

export default Battle3v3;