import React, { useCallback, useEffect, useRef, useState } from 'react';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import LoadingItems from '../../../components/LoadingItems';
import OpenFourCase from '../components/OpenFourCase';
import Games from '../../../Games';
import Store from '../../../Store';
import Api from '../../../Api';
import Notice from '../../../Notice';
import Price from '../../../components/price/Price';

const GameOpenFour = ({caseDetail, tab, isTab, items, fast, setFast}) => {

    let block = useRef(false)
    let [itemsOpenList1, setItemsOpenList1] = useState(false)
    let [itemsOpenList2, setItemsOpenList2] = useState(false)
    let [itemsOpenList3, setItemsOpenList3] = useState(false)
    let [itemsOpenList4, setItemsOpenList4] = useState(false)

    let roullete = useRef()
    let roulleteTwo = useRef()
    let roulleteThree = useRef()
    let roulleteFour = useRef()
    let play = useRef()
    let startPlay1 = useRef()
    let startPlay2 = useRef()
    let startPlay3 = useRef()
    let startPlay4 = useRef()
    let finishPlay = useRef()

     /* ---------Запуск игры----------- */
     function caseOpen() {

        if(!items) {
            return
        }

        if(block.current) {
            return
        }

        (async () =>{
            Store.setListener('winSkins', false)
            const elHeight = Games.getHeight(roullete)
            
            Games.defaultPosition(roullete, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf17"]')
            Games.defaultPosition(roulleteTwo, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf18"]')
            Games.defaultPosition(roulleteThree, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf19"]')
            Games.defaultPosition(roulleteFour, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf110"]')

            block.current = true
            document.querySelectorAll('.case_open_button').forEach((e) => {
                e.classList.add('close')
            })

            let wagers = localStorage.getItem('balanceField')
            let query = {
                openings: 4,
                bet: (caseDetail.price * 4).toFixed(2),
                caseId: caseDetail.id,
                forWagers: wagers && wagers === 'wager' ? true : false
            }

            let params = new URLSearchParams(query).toString()

            let init = await Games.dicePlay(`api/v1/game-open-case/play?${params}`)

            if (init === 'no balance') {
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
                return
            } 
            if (init === 'unAuthorization') {
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.body.style.overflow = 'hidden';
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }
            if (init === 'error') {
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }

            await Api.updateAccount()
            
            setItemsOpenList1(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList1.length - 10] = {
                    fakeItem: {
                        id: init[0].wonItem.id,
                        name: init[0].wonItem.name,
                        image: init[0].wonItem.image,
                        type: init[0].wonItem.type,
                        price: init[0].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList2(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList2.length - 10] = {
                    fakeItem: {
                        id: init[1].wonItem.id,
                        name: init[1].wonItem.name,
                        image: init[1].wonItem.image,
                        type: init[1].wonItem.type,
                        price: init[1].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList3(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList3.length - 10] = {
                    fakeItem: {
                        id: init[2].wonItem.id,
                        name: init[2].wonItem.name,
                        image: init[2].wonItem.image,
                        type: init[2].wonItem.type,
                        price: init[2].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList4(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList4.length - 10] = {
                    fakeItem: {
                        id: init[3].wonItem.id,
                        name: init[3].wonItem.name,
                        image: init[3].wonItem.image,
                        type: init[3].wonItem.type,
                        price: init[3].wonItem.price
                    }
                }
                return prev
            })

            Store.setListener('winSkins', init)
            Store.setListener('caseItems4', prev => !prev)

            play.current = setTimeout(() => {
                const elHeight = Games.getHeight(roullete)
                const randomize = Math.floor(Math.random() * elHeight) - (elHeight / 2);
                Games.verticalSpinRoullete(roullete, 7, fast, startPlay1, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteTwo, 8, fast, startPlay2, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteThree, 9, fast, startPlay3, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteFour, 10, fast, startPlay4, randomize, elHeight)

                if(fast) {
                    finishPlay.current = setTimeout(() => {
                        block.current = false
                        document.querySelectorAll('.case_open_button').forEach((e) => {
                            e.classList.remove('close')
                        })
                        document.getElementById('skin_dice').classList.add('open')
                        document.querySelector('html').classList.add('hidden')
                    }, 3500)
                } else {
                    
                    finishPlay.current = setTimeout(() => {
                        block.current = false
                        document.querySelectorAll('.case_open_button').forEach((e) => {
                            e.classList.remove('close')
                        })
                        document.getElementById('skin_dice').classList.add('open')
                        document.querySelector('html').classList.add('hidden')
                    }, 7500)

                }

            }, 500)
            
        })()
    }

    /*------------- Демо игра -------------*/
    function caseDemoOpen() {

        if(!items) {
            return
        }

        if(block.current) {
            return
        }

        (async () =>{
            Store.setListener('winSkins', false)
            const elHeight = Games.getHeight(roullete)

            Games.defaultPosition(roullete, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf17"]')
            Games.defaultPosition(roulleteTwo, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf18"]')
            Games.defaultPosition(roulleteThree, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf19"]')
            Games.defaultPosition(roulleteFour, elHeight, '[data-id="2320--dfdad=a=dsf=f=asd--asaf110"]')

            block.current = true
            document.querySelectorAll('.case_open_button').forEach((e) => {
                e.classList.add('close')
            })

            let query = {
                openings: 4,
                caseId: caseDetail.id,
            }

            let params = new URLSearchParams(query).toString()

            let init = await Games.dicePlay(`api/v1/game-open-case/demo-play?${params}`)

            if (init === 'no balance') {
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
                return
            } 
            if (init === 'unAuthorization') {
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.body.style.overflow = 'hidden';
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }
            if (init === 'error') {
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }

            await Api.updateAccount()
            
            setItemsOpenList1(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList1.length - 10] = {
                    fakeItem: {
                        id: init[0].wonItem.id,
                        name: init[0].wonItem.name,
                        image: init[0].wonItem.image,
                        type: init[0].wonItem.type,
                        price: init[0].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList2(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList2.length - 10] = {
                    fakeItem: {
                        id: init[1].wonItem.id,
                        name: init[1].wonItem.name,
                        image: init[1].wonItem.image,
                        type: init[1].wonItem.type,
                        price: init[1].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList3(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList3.length - 10] = {
                    fakeItem: {
                        id: init[2].wonItem.id,
                        name: init[2].wonItem.name,
                        image: init[2].wonItem.image,
                        type: init[2].wonItem.type,
                        price: init[2].wonItem.price
                    }
                }
                return prev
            })

            setItemsOpenList4(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList4.length - 10] = {
                    fakeItem: {
                        id: init[3].wonItem.id,
                        name: init[3].wonItem.name,
                        image: init[3].wonItem.image,
                        type: init[3].wonItem.type,
                        price: init[3].wonItem.price
                    }
                }
                return prev
            })

            Store.setListener('winSkins', init)
            Store.setListener('caseItems4', prev => !prev)

            play.current = setTimeout(() => {
                const elHeight = Games.getHeight(roullete)
                const randomize = Math.floor(Math.random() * elHeight) - (elHeight / 2);
                Games.verticalSpinRoullete(roullete, 7, fast, startPlay1, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteTwo, 8, fast, startPlay2, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteThree, 9, fast, startPlay3, randomize, elHeight)
                Games.verticalSpinRoullete(roulleteFour, 10, fast, startPlay4, randomize, elHeight)

                if(fast) {
                    finishPlay.current = setTimeout(() => {
                        block.current = false
                        document.querySelectorAll('.case_open_button').forEach((e) => {
                            e.classList.remove('close')
                        })
                        document.getElementById('skin_dice').classList.add('open')
                        document.querySelector('html').classList.add('hidden')
                    }, 3500)
                } else {
                    
                    finishPlay.current = setTimeout(() => {
                        block.current = false
                        document.querySelectorAll('.case_open_button').forEach((e) => {
                            e.classList.remove('close')
                        })
                        document.getElementById('skin_dice').classList.add('open')
                        document.querySelector('html').classList.add('hidden')
                    }, 7500)

                }

            }, 500)
            
        })()
    }


    useEffect(() => {

        setItemsOpenList1(Games.shuffle(structuredClone(items)))
        setItemsOpenList2(Games.shuffle(structuredClone(items)))
        setItemsOpenList3(Games.shuffle(structuredClone(items)))
        setItemsOpenList4(Games.shuffle(structuredClone(items)))

        return () => {
            clearTimeout(play.current)
            clearTimeout(startPlay1.current)
            clearTimeout(startPlay2.current)
            clearTimeout(startPlay3.current)
            clearTimeout(startPlay4.current)
            clearTimeout(finishPlay.current)
        }

    }, [items])

    useCallback(() => {
        return () => {
            clearTimeout(play.current)
            clearTimeout(startPlay1.current)
            clearTimeout(startPlay2.current)
            clearTimeout(startPlay3.current)
            clearTimeout(startPlay4.current)
            clearTimeout(finishPlay.current)
        }
    }, [])

    return (
        <>
            <div className="case_window_block">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="home_icon_cases"></div>
                    </div>   
                    <h4 className='game_title'>{caseDetail.categories} case</h4>
                    <div className="decor pink"></div>
                </div>

                <div className="case_window">
                    <img src={caseDetail.image} alt="" />
                    <div className="case_window_navigatoin_block">
                        <div className="navigation">
                            <h2 className="title">{caseDetail.name}</h2>
                            <div className="tabs_buttons_block">
                                <button className={`common_button icon_button ${tab === 'one' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'one', isTab)}>1</button>
                                <button className={`common_button icon_button ${tab === 'two' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'two', isTab)}>2</button>
                                <button className={`common_button icon_button ${tab === 'three' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'three', isTab)}>3</button>
                                <button className={`common_button icon_button ${tab === 'four' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'four', isTab)}>4</button>
                                <div className="check_block">
                                    <input type="checkbox" checked={fast} onChange={(e) => setFast(prev => !prev)}/>
                                    FAST OPENING
                                </div>
                            </div>
                            <div className="open_demo_block">
                                <button className="common_button green case_open_button" onClick={caseOpen}>
                                    OPEN 4 TIME
                                    <div className="coin_block">
                                        <img src={coin} alt="" />
                                        <Price price={caseDetail.price * 4}/>
                                    </div>
                                </button>
                                <button className="common_button white case_open_button" onClick={caseDemoOpen}>Demo spin</button>
                            </div>
                        </div>
                        <div className="provably">
                            <div className="rewards_icon_lock"></div>
                            PROVABLY FAIR
                        </div>
                    </div>
                </div>
            </div>

            <div className="case_open_block">
                {items && items.length > 0 ?
                    <OpenFourCase 
                        roullete={roullete} 
                        roulleteTwo={roulleteTwo}
                        roulleteThree={roulleteThree}
                        roulleteFour={roulleteFour}
                        itemsOpenList1={itemsOpenList1}
                        itemsOpenList2={itemsOpenList2}
                        itemsOpenList3={itemsOpenList3}
                        itemsOpenList4={itemsOpenList4}
                    />
                    :
                    <LoadingItems />
                }
            </div>
            
        </>
            
    );
};

export default GameOpenFour;