import React from 'react';

const ErrorWindow = ({text}) => {
    return (
        <div className="error_window">
            <p>{text}</p>
            <span className="arrow_down"></span>
        </div>
    );
};

export default ErrorWindow;