import React from 'react';
import Avatar from '../../../components/avatar/Avatar';
import LoadingItems from '../../../components/LoadingItems';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import useGiveMoney from '../../admin_hooks/useGiveMoney';
import NotItems from '../../../components/NotItems';
import IssueListFilter from './IssueListFilter';
import CommonPagination from '../../../components/pagination/CommonPagination';
import UserGiveMoney from './UserGiveMoney';

const GiveMoneyPopUp = () => {

    let {load, loadBtn, userLoad, error, setError, params, setParams, issueList, balance, user, onInput, closePopUp, giveMoney, changeOrder} = useGiveMoney()

    return (
        <div className='admin_popup_wrapper' id='adminGiveMoney' onMouseDown={() => closePopUp()}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_box"></div>
                        </div>
                        <div className='game_title'>Give money</div>
                        <div className="decor pink" onMouseDown={() => closePopUp()}></div>
                    </div>

                    <div className="admin_popup_content">
                        {userLoad ?
                            <>
                                {user ?
                                    <>
                                        <h3>For this shrih:</h3>
                                        
                                        <UserGiveMoney user={user}/>

                                        <h3>Give money epta</h3>

                                        <div className="give_money_wrapper">
                                            <div className={`give_money_input ${error ? 'error' : ''} main_card`}>
                                                <img src={coin} alt="" />
                                                <input 
                                                    type="text"
                                                    inputMode='numeric' 
                                                    placeholder='0'
                                                    value={balance}
                                                    onChange={(e) => onInput(e)}
                                                    onBlur={(e) => setError(false)}
                                                />
                                            </div>

                                            <button className="common_button green" onClick={() => giveMoney()}>
                                                {loadBtn ? 'GIVE' : 'Load..'}       
                                            </button>
                                        </div>


                                        <h3>Last gives money epta:</h3>

                                        <IssueListFilter params={params} changeOrder={changeOrder}/>

                                        <div className="last_gives_money">

                                            {load ?
                                                <>
                                                    {issueList && issueList?.items?.length ? 
                                                    
                                                        <>
                                                            {issueList.items.map(el => (
                                                                <div className="last_gives_money_item" >

                                                                    <div className="user">
                                                                    <img src={el.avatar} alt="" />
                                                                        
                                                                        <div className="user_name">{el.issuer}</div>
                                                                    </div>
                        
                                                                    <div className="gived_money">
                                                                        <img src={coin} alt="" />
                                                                        <span>{el.issued}</span>
                                                                    </div>
                        
                                                                    <div className="date">
                                                                        {el.date}
                                                                    </div>
                        
                                                                    <div className={`user_role ADMIN`}>
                                                                        ADMIN
                                                                    </div>
                        
                                                                </div>
                                                            ))}

                                                            <CommonPagination 
                                                                params={params} 
                                                                setParams={setParams}
                                                                maxPage={Math.ceil(issueList.total / params.size) || 1}
                                                            />
                                                        </>
                                                        
                                                    : 
                                                        <NotItems />
                                                    }
                                                </>
                                            :
                                                <LoadingItems />
                                            }

                                            
                                            
                                        </div>
                                        
                                    </>

                                    :

                                    <LoadingItems />
                                }
                            </>
                        :
                            <LoadingItems />     
                        }
                        
                        

                    </div>

                </div>
            </div>
        </div>
    );
};

export default GiveMoneyPopUp;