import React, { useEffect, useState } from 'react';
import Store from '../../../../Store';
import Notice from '../../../../Notice';
import Api from '../../../../Api';
import coin from './../../../../fonts/main_icon_fonts/coin.svg'

const BattlePasswordPopUp = ({totalCost}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [options, setOptions] = useState({})
    const [error, setError] = useState(false)
    const [path, setPath] = useState('')
    Store.useListener('openBattlePriate', setIsOpen)
    Store.useListener('setOprionsBattle', setOptions)
    Store.useListener('pathToJoin', setPath)

    useEffect(() => {

    }, [])

    const closePopUp = () => {
        setIsOpen(false)
        setOptions({})
        setError(false)
        setPath('')
        document.querySelector('html').classList.remove('hidden')
    }

    const validateInput = (value) => {
        if(!value) {
            setError('Please enter password')
            return 'error'
        }
        if(value.length < 6) {
            setError('Minimum of 6 characters')
            return 'error'
        }
        return 'ok'
    }

    const cahngeInput = (value) => {
        setError(false)
        setOptions(prev => ({...prev, password: value}))
        validateInput(value)
    }

    const joinGame = async () => {

        if(validateInput(options.password) === 'error') {
            return
        }

        const params = new URLSearchParams(options).toString()

        let res = await fetch(`${Api.url}/api/v1/game-case-battle/${path}?${params}`, {credentials: 'include'})

        res = await res.json()

        if(res.statusCode && res.statusCode === 401) {

            let ref = await Api.refresh()

            if (ref === 'unAuthorization') {
                localStorage.removeItem('client')
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.querySelector('html').classList.add('hidden')
                return 'unAuth'
            }

            if(ref !== 'ok') {
                return 'error'
            }

            res = await fetch(`${Api.url}/api/v1/game-roulette/place-a-bet?${params}`, {
                credentials: 'include'
            })
            
            res = await res.json()
        }

        if(res.statusCode && res.statusCode === 400) {
            if(res.exception.message === 'Incorrect password!') {
                setError(res.exception.message)
            } else {
                setError('You do not have enough balance to place a bet!')
            }
            return
        }

        if(!res.statusCode) {
            closePopUp()
        }
    }

    if(!isOpen) return null

    return (
        <div className={`battle_password_popup_wrapper ${isOpen ? 'open' : ''}`} onMouseDown={closePopUp}>
            <div className="battle_password_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="case_btl_icon_folder"></div>
                    </div>   
                    <h4 className='game_title'>Password</h4>
                    <div className="decor pink" onMouseDown={closePopUp} style={{cursor: 'pointer'}}></div>
                </div>
                <div className="battle_password">
                    <input type="password" placeholder='Enter password' className={`main_input ${error ? 'error' : ''}`} onChange={(e) => cahngeInput(e.target.value)} onBlur={(e) => validateInput(e.target.value)}/>
                    {error && <p className='error_mess'>{error}</p>}
                    <button className='common_button green flex' onMouseDown={joinGame}>
                        <p>Join</p>
                        <img src={coin} alt="" />
                        <p>{totalCost}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BattlePasswordPopUp;