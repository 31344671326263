import React from 'react';
import useFilters from '../../hooks/useFilters';

const Filters = ({params, changeFilters, changeDate}) => {

    const {openFilter} = useFilters()

    return (
        <div className="history_filters">

            {/* <button className="common_button full">PVP Game ID</button> */}
            
            <div className="filter_item">
                <button className="common_button full flex" onMouseDown={(e) => {
                    changeDate('ASC')
                }}>
                    Created at Min Date
                    <div className="account_icon_kalendar"></div>
                </button>
            </div>

            <div className="filter_item">
                <button className="common_button full flex" onMouseDown={(e) => {
                    changeDate('DESC')
                }}>
                    Created at Max Date
                    <div className="account_icon_kalendar"></div>
                </button>
            </div>

            <div className="filter_item">
                <button className="common_button full flex" onMouseDown={(e) => openFilter(e)}>
                    Oder By
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" name='order' onMouseDown={(e) => changeFilters(e, 'order')}>
                    <p className={params.order === 'ASC' ? 'active' : ''}>ASC</p>
                    <p className={params.order === 'DESC' ? 'active' : ''}>DESC</p>
                </div>
            </div>

            <div className="filter_item">
                <button className="common_button full flex" onMouseDown={(e) => openFilter(e)}>
                    Sort by
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" id='sort_list' onMouseDown={(e) => changeFilters(e, 'field')}>
                    <p className='active'>id</p>
                    <p>name</p>
                    <p>date</p>
                    <p>bet</p>
                    <p>multiplier</p>
                    <p>payout</p>
                </div>
            </div>

            {/* <div className="filter_item">
                <button className="common_button full flex" onMouseDown={(e) => openFilter(e)}>
                    Strategies
                    <div className="account_icon_chevron_right"></div>
                </button>

                <div className="filter_list" name='order'>
                    <p className='active'>ASC</p>
                    <p>DESC</p>
                </div>
            </div> */}

        </div>
    );
};

export default Filters;