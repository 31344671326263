import React, { createElement, useEffect, useRef, useState } from 'react';
import Games from '../../../Games';
import Store from '../../../Store';

const LastResults = () => {
    
    const [results, setResults] = useState(false)
    const params = {
        size: 10,
        page: 1,
        field: 'id',
        order: 'DESC',
    }
    const last = useRef(false)

    Store.useListener('update_last', (data) => {
        if(data?.items?.length && last.current) {
            const item = data?.items[0]
            const newItem = document.createElement('div')
            newItem.className = `last_item anim ${item.totalPayout > 0 ? 'win' : 'lose'}`
            newItem.innerHTML = '89.78'
            last.current.append(newItem)
            setTimeout(() => {
                newItem.classList.add('show')
            }, 1)
    
            const newItems = last.current.querySelectorAll('.last_item')
            if(last.current) {
                if(newItems.length > 10) {
                    newItems[0].remove()
                }
            }
        }
    })

    useEffect(() => {
        (async () => {
            const query = new URLSearchParams(params).toString();

            let res = await Games.gameHistory(`api/v1/game-dice/me/?${query}`)

            if(res === 'error') {
                return 
            }
            setResults(res.items)
        })()
    }, [])

    return (
        <div className="last_results" ref={last}>
            {results?.length ? 
                <>
                    {results.map((el) => (
                        <div className={`last_item ${el.totalPayout > 0 ? 'win' : 'lose'}`}>98.54</div>
                    ))}
                </>
            : <></>}
        </div>
    );
};

export default LastResults;