import React, { useEffect, useState } from 'react';

const ChatOnline = ({online}) => {
    
    return (
        <>
            {online}
        </>
    );
};

export default ChatOnline;