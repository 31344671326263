import React from 'react';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg';
import Price from '../../../../../components/price/Price';


const JoinBattle = ({type, joinGame, joinTeam, position, totalCost}) => {

    return (
        <div className='button_block'>
            <button className='common_button green flex full' onClick={() => joinGame(position)}>
                <p>Join</p>
                <img src={coin} alt="" />
                <p><Price price={totalCost}/></p>
            </button>
        </div>
    );
};

export default JoinBattle;