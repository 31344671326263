import React from 'react';
import Avatar from '../../avatar/Avatar';
import Store from '../../../Store';


const ChatMessage = ({el, role, func, setOpenPopUp, deleteMess}) => {

    const openMutePopUp = () => {
        setOpenPopUp(true)
        Store.setListener('userId', prev => ({...prev, userId: el.user.id}))
    }

    return (
        <div className="track_item main_card">
            
            {role === 'ADMIN' || role === 'MODERATOR'
            ?<div className="admin_chat_panel">
                <button className='mut' onClick={openMutePopUp}></button>
                <button className='clip' onClick={() => func(el.id)}></button>
                <button className='cross' onClick={() => deleteMess(el.id)}></button>
            </div>
            :<></>}

            <Avatar 
                img={el.user.avatar}
                lvl={el.user.client.playerAccount.level}
                percent={(el.user.client.playerAccount.experience / el.user.client.playerAccount.experienceBorderTillLevel) * 100}
                color={'rgb(233, 63, 63)'}
                size={40}
                rad={17}
                dasharray={107}
            />
            <div className="message_content">
                <div className="name_block">
                    {el.user.role === 'MODERATOR' && <div className="role moderator">{el.user.role}</div>}
                    <h4 className="name">{el.user.username}:</h4>
                </div>
                <div className="message">{el.message}</div>
            </div>
        </div>
    );
};

export default ChatMessage;