import { useEffect, useState } from "react"
import Api from "../../Api"
import Notice from "../../Notice"
import Store from "../../Store"

export default function useAllUsers() {
    let [allUserList, setAllUserList] = useState(false)
    let [load, setload] = useState(false)
    let [params, setParams] = useState({
        size: '10',
        page: 1,
        order: 'ASC',
        field: 'id',
        namePrefix: '',
    })
    
    let [roles, setRoles] = useState([
        'ADMIN',
        'MODERATOR',
        'PLAYER',
    ])

    useEffect(() => {

        (async () => {
            setload(prev => prev = false)

            var query = new URLSearchParams(params)

            roles.map(el => {
                query.append('roles', el)
            });

            let listInit = await Api.asyncGet(`api/v1/clients/all?${query}`)
            if (listInit === 'error') {
                return Notice.Send({type: 'error', text: 'Ошибка сервера'})
            }
            setAllUserList(listInit)
            setload(prev => prev = true)
        })()

    }, [params])

    function changeUserRole(data) {

        let clone = structuredClone(allUserList.items)
        clone = clone.map(el => {
            if(el.user.id === data.id){
                return {...el, user: {...el.user, role: data.role}}
            }
            return el
        })

        setAllUserList(prev => ({...prev, items: clone}))
    }

    Store.useListener('CHANGE_ROLE_IN_USERS', changeUserRole)

    function searchUser(e) {
        setload(prev => prev = false)
        setParams(prev => ({...prev, namePrefix: e.target.value, page: 1}))
    }

    function changeRole(name) {
        if(name === 'ALL') {
            const list = ['ADMIN','MODERATOR','PLAYER']
            if(JSON.stringify(roles) != JSON.stringify(list)) {
                setRoles(list)
            } else return
        } else {
            if(roles != name) {
                setRoles([name])
            } else return
        }
        setParams(prev => ({...prev, page: 1}))
    }

    function changeOrder(e, name) {
        setload(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, [name]: e.target.textContent}))
    }

    function closePopup() {
        document.getElementById('adminAllUsers').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')
    }

    return {load, allUserList, params, setParams, closePopup, changeOrder, changeRole, searchUser}
}