import React from 'react';
import Store from '../../../Store';

const GiveMoneyBtn = ({ userId }) => {


    function openPopUp () {
        document.getElementById('adminGiveMoney').classList.add('open')
        Store.setListener('giveMoneyUser', userId)
    }
    

    return (
        <button className="icon_button give_money" onMouseDown={(e) => openPopUp()}>
            <div className="admin_icon_box"></div>
        </button>
    );
};

export default GiveMoneyBtn;