import React, { useCallback, useEffect, useRef, useState } from 'react';
import NotItems from '../../../../../components/NotItems';
import LoadingItems from '../../../../../components/LoadingItems';
import Games from '../../../../../Games';
import Api from '../../../../../Api';
import useBattleRoulette from '../../../hooks/useBattleRoulette';

const BattleRoulette = ({round, result, cases}) => {

    const {itemsOpenList, winItem, roullete} = useBattleRoulette(round, result, cases)

    return (
        <div className="case_open_flex_item_wrapper">
                
            <div className="case_open_flex_item">
                
                <div className="case_open_flex_wrapper" >

                    {itemsOpenList ? 
                    <>
                        {itemsOpenList.length > 0 ?
                            
                            <div className="case_open_flex_roulete" ref={roullete}>
                                {itemsOpenList.map((el, i) => (
                                    <div key={i} ref={i === (itemsOpenList.length - 10) ? winItem : null} className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList.length - 10) ? 'Winner items' : ''}>
                                        <img className='case_battle_roll_image'
                                            src={el.fakeItem.image}
                                            alt="" 
                                        />
                                    </div>
                                ))}
                            </div>
                        :
                            <NotItems />}
                    </> 
                    : <LoadingItems />}

                </div>
                
                <div className="triangle_small_left" ></div>
                <div className="triangle_small_right"></div>

            </div>
        </div>
    );
};

export default BattleRoulette;