import React, { useEffect, useState } from 'react';
import './css/race.css'
import RaceHead from './components/RaceHead';
import RaceListItem from './components/RaceListItem';
import Places from './components/Places';
import Title from '../../mainTitle';
import Api from '../../Api';
import LoadingItems from '../../components/LoadingItems';


const Race = () => {

    const [raceList, setRaceList] = useState({
        first: null,
        second: null,
        third: null,
        last: [],
    })
    const [filter, setFilter] = useState('daily')
    const [load, setLoad] = useState(false)

    useEffect(() => {

        (async () => {

            let res = await Api.asyncGet(`api/v1/race/top-participants?type=${filter}`)

            if(res === 'error') {
                return
            }

            setRaceList({
                first: res[0],
                second: res[1],
                third: res[2],
                last: res.slice(3)
            })
            setLoad(true)
        })()

        Title.setListener('title', 'DAILY RACE')

    }, [filter])

    return (
        <div className='race_block container'>
            
            <RaceHead filter={filter} setFilter={setFilter}/>

            <Places raceList={raceList}/>

            <div className="race_list_block">
                {load ? 
                    <>
                        {raceList.last?.length ?
                            <>
                                {raceList.last.map((e, i) => (
                                    <RaceListItem number={i + 4} el={e} key={i} withBtn={true}/>
                                ))}
                            </>
                        :
                            <></>
                        }
                    </>
                :
                    <LoadingItems />
                }
            </div>

        </div>
    );
};

export default Race;