import React from 'react';
import Api from '../../Api';

const SignInSteam = () => {
    
    return (

        <a href={`${Api.url}/api/v1/auth/steam-sign-in`} className="common_button blue flex full">
            <div className="icon_steam"></div>
            Sign in with Steam
        </a>
        
    );
};

export default SignInSteam;