import React, { useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { NavLink } from 'react-router-dom';
import LoadingItems from '../../LoadingItems';
import NotItems from '../../NotItems';
import ak from './../../../images/ak.svg'
import './../css/skin_slider.css'


const SkinSplider = ({winSkins, main}) => {

    return (
        <Splide 
            ref={(splider) => (main.current = splider)}
            options={ {
                rewind: false,
                gap: '-50%',
                type: 'slide',
                drag: true,
                updateOnMove: true,
                trimSpace: false,
                autoplay     : false,
                autoWidth    : true,
                autoHeight   : true,
                perPage      : 1,
                perMove: 1,
                padding      : '0px',
                arrows       : true,
                pagination: false,
                classes: {
                    arrows: 'arrows_header',
                    arrow : 'splide__arrow',
                    prev  : 'splide__arrow--prev',
                    next  : 'splide__arrow--next',
                },
            } } 
            className='skin_splider'
            aria-label="Skin">
                {winSkins ? 
            <>
                {winSkins.length > 0 ? 
                    <>  
                        {winSkins.map((e, i) => (

                            <SplideSlide key={i}>

                                    <div className="skin_image">
                                        <img src={e.wonItem.image} alt="" />
                                    </div>
                                    
                            </SplideSlide>

                        ))}
                    </>
                :
                    <NotItems />
                }
            </>
        :
            <LoadingItems />
        }
        </Splide>
    );
};

export default SkinSplider;