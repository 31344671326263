import React, { useEffect, useRef, useState } from 'react';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg';
import { result } from 'lodash';
import NotItems from '../../../../../components/NotItems';
import Price from '../../../../../components/price/Price';


const Inventory = ({multi, resultList}) => {

    return (
        <div className="inventory_wrapper">
            <div className={`inventory_grid ${multi}`}>
                {resultList?.length ? 
                    <>
                        {resultList.map((el, i) => (
                            <div className={`drop_card ${el.fakeItem.type}`} key={i}>
                                    <img src={el.fakeItem.image} className={`drop_image ${el.fakeItem.type}`} alt="" />
                                <h3 className="title">{el.fakeItem.name}</h3>
                                <div className={`price main_card ${el.fakeItem.type}`}>
                                    <p><Price price={el.fakeItem.price}/></p>
                                    <img src={coin} alt="" />
                                </div>
                            </div>
                        ))}
                    </>
                :<></>}
            </div>
        </div>
        
    );
};

export default Inventory;