import React, { useEffect } from 'react';
import Emoji from '../../../Emoji';

const EmojiList = ({func}) => {

    function closeEmojiList() {
        document.querySelector('.emoji_list').classList.remove('open')
    }

    useEffect(() => {
        const emojiList = document.querySelector('.emoji_list')
        const emojiOpen = document.querySelector('.smiles')


        window.addEventListener('click', (e) => {
            const target = e.target

            if(target != emojiList && !emojiList.contains(target) && target != emojiOpen && !emojiOpen.contains(target)) {
                document.querySelector('.emoji_list').classList.remove('open')
            }
        })
    }, [])


    return (
        <div className="emoji_list" onClick={(e) => e.stopPropagation()}>
            <div className="emoji_wrapper">
                <div className="emoji_category">
                    <h3>Emoji</h3>
                    <div className="emoji_grid">
                        {Emoji.face.map((el, i) => (
                            <span key={i} onClick={(e) => func(e.target.textContent)}>{el}</span>
                        ))}
                    </div>
                </div>

                <div className="emoji_category">
                    <h3>Gestures</h3>
                    <div className="emoji_grid">
                        {Emoji.hands.map((el, i) => (
                            <span key={i} onClick={(e) => func(e.target.textContent)}>{el}</span>
                        ))}
                    </div>
                </div>

                <div className="emoji_category noline">
                    <h3>Food</h3>
                    <div className="emoji_grid">
                        {Emoji.food.map((el, i) => (
                            <span key={i} onClick={(e) => func(e.target.textContent)}>{el}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmojiList;