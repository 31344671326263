import { useEffect, useState } from "react"
import Api from "../../../Api";
import Notice from "../../../Notice";

export default function useCaseList(isAuth) {

    const [load, setLoad] = useState(false)
    const [cases, setCases] = useState(false)
    const [clientLvl, setClientLvl] = useState(false)
    const [params] = useState({
        size: 1000,
        page: 1,
        order: 'ASC',
        field: 'name',
    })

    useEffect(() => {
        (async () => {
            if(isAuth) {
                const client = JSON.parse(localStorage.getItem('client'))
                setClientLvl(client.playerAccount.level)
            }
            const query = new URLSearchParams(params).toString();
            const init = await Api.asyncGet(`api/v1/case/all?${query}`)
            if(init === 'error' || !init) {
                return Notice.Send({type: 'error', text: 'Ошибка сервера'})
            }
            const FREE_CASES = init.items.filter(el => el.type === 'free')
            setCases(FREE_CASES)
            setLoad(prev => prev = true)
        })()
    }, [])

    return {load, cases, clientLvl}
}