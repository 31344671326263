import { useCallback, useEffect, useRef, useState } from "react";
import Title from "../../mainTitle";
import { io } from "socket.io-client";
import Api from "../../Api";
import Notice from "../../Notice";
import Store from "../../Store";
import Bet from "../../Bet";


export default function useCrash(mode, isAuth) {

    const [profile, setProfile] = useState(() => {
        const id = JSON.parse(localStorage.getItem('client')) || {}
        return id
    })
    const [color, setColor] = useState(() => {
        return mode === 'light' ? 'rgba(0, 220, 154, 1)' : 'rgb(0, 133, 93)'
    })
    const [scaleList, setScaleList] = useState(['2.00', '3.00', '4.00', '5.00', '6.00', '7.00', '8.00', '9.00', '10.00'])
    const [ping, setPing] = useState('00')
    const [timeField, setTimefield] = useState('0.00')
    const [game, setGame] = useState('')
    const [multiplier, setMultiplier] = useState('0.00')
    const [last12, setLast12] = useState([])
    const [allBets, setAllBets] = useState([])
    const [bet, setBet] = useState('')
    const [auth, setAuth] = useState(false)
    const [zeroBet, setZeroBet] = useState(false)
    const [auto, setAuto] = useState('')
    const [betSum, setBetSum] = useState(0)
    const [error, setError] = useState(false)
    const [autoError, setAutoError] = useState(false)
    const [block, setBlock] = useState(false)

    const crashSocket = useRef(null)    
    const timer = useRef(null)
    const pensil = useRef(null)
    const blueRocket = useRef(null)
    const redRocket = useRef(null)
    const anim = useRef(null)
    const yellowRocket = useRef(null)
    const start = useRef(null)
    const progres = useRef(null)
    const finish = useRef(null)
    const restart = useRef(null)
    const restartPensil = useRef(null)
    const crashLast = useRef()

    useEffect(() => {
        Title.setListener('title', 'CRASH')

        crashSocket.current = io(`${Api.urlWs}/game-crash`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        
        const updatePing = setInterval(() => {
            crashSocket.current.emit('crash.latency', new Date().getTime());
        }, 1000);

        crashSocket.current.on('crash.crashPoint', (data) => {

            crashGame(data)
            
            const color = colorUpdate(data.multiplier * 100)

            const newitem = document.createElement('div')
            newitem.className = `crash_last_list_item anim nocopy`
            newitem.style = `background: ${color?.color}; border: 1px solid ${color?.border}`
            newitem.innerHTML = `<p>x${(data.multiplier).toFixed(2)}</p>`

            if(crashLast.current.firstChild) {
                crashLast.current.insertBefore(newitem, crashLast.current.firstChild)
            } else {
                crashLast.current.innerHTML(newitem)
            }
            

            const w = newitem.querySelector('p').offsetWidth
            setTimeout(() => {
                newitem.style.minWidth = `${w + 2}px`;
                newitem.classList.add('show')
            }, 1)

            newitem.addEventListener('mousedown', () => {
                Store.setListener('CRASH_PROVABLY_FAIR', data.gameHash)
            })

            const allItems = crashLast.current.querySelectorAll('.crash_last_list_item')
            if(allItems.length > 12) {
                allItems[allItems.length - 1].remove()
            }
        })
        crashSocket.current.on('crash.latency', (data) => setPing((Date.now() - data).toFixed(0)))
        crashSocket.current.on('isAuthorized', setAuth)
        crashSocket.current.on('crash.newBet', newBetSet)
        crashSocket.current.on('crash.ownBets', (data) => {
            if(data?.length) {
                data.forEach((el) => {
                    if(el.multiplier == 0) {
                        return setZeroBet(true)
                    }
                })
            }
        })
        crashSocket.current.on('crash.start', () => setBetSum(0))
        crashSocket.current.on('crash.updatedBet', updateAllBet)
        crashSocket.current.on('crash.currentProgress', (data) => {
            setGame('progres')
            const coef = (data).toFixed(2)
            setMultiplier(coef)
            setColor(colorUpdate(coef * 100)?.color)
        })
        crashSocket.current.on('crash.startTime', (data) => {
            setTimefield(data)
            timerNewFunc(data)
        })
        crashSocket.current.emit('crash.ownBets',  {page: 1, pageSize: 20})

        for(let i = 11; i < 1001; i++) {
            setScaleList(prev => [...prev, i.toFixed(2)])
        }

        return () => {
            clearInterval(updatePing)
            if(timer.current) {
                cancelAnimationFrame(timer.current)
            }
            if(anim.current) {
                clearInterval(anim.current)
            }
            if(restart.current) {
                clearTimeout(restart.current)
            }
            if(restartPensil.current) {
                clearTimeout(restartPensil.current)
            }
            if(crashSocket.current) {
                crashSocket.current.off('crash.latency')
                crashSocket.current.off('crash.startTime')
                crashSocket.current.off('crash.crashPoint')
                crashSocket.current.off('crash.start')
                crashSocket.current.off('isAuthorized')
                crashSocket.current.off('crash.currentProgress')
                crashSocket.current.off('crash.newBet')
                crashSocket.current.off('crash.ownBets')
                crashSocket.current.off('crash.updatedBet')
                crashSocket.current.disconnect()
            }
            if (finish.current) {
                clearTimeout(finish.current)
            }
            if(start.current) {
                clearTimeout(start.current)
            }
            if(progres.current) {
                clearTimeout(progres.current)
            }
        }

    }, [])

    useEffect(() => {
        if(game === 'progres') {
            animation()
        } else {
            clearInterval(anim.current)
        }
    }, [game])

    const sortByBet = (array) => {
        let sortList = array.sort((a, b) => b.bet - a.bet);
        if (profile) {
            console.log('profile', profile)
            console.log('array', array)
            const items = sortList.filter(item => item.clientId === profile.id); 
            sortList = sortList.filter(item => item.clientId !== profile.id); 
            sortList.unshift(...items);
        }
        return sortList;
    }

    const newBetSet = async (data) => {
        setAllBets(prev => {
            const newList = [...prev, data]
            return sortByBet(newList)
        })
        if(isAuth) {
            await Api.updateAccount()
        }
    }

    const updateAllBet = async (data) => {
        setAllBets(prev => 
            prev.map(el => 
                el.id === data.id ? {...el, totalPayout: data.totalPayout, multiplier: data.multiplier} : el
            )
        );
        if(isAuth) {
            await Api.updateAccount()
        }
    }

    const timerNewFunc = (value) => {
        let date = new Date().getTime();
        const finish = value * 1000 + date;

        let timerAnimation = () => {
            let currentTime = new Date().getTime();
            if (currentTime > finish) {
                setTimefield('0.00')
                cancelAnimationFrame(timer.current)
            } else {
                setTimefield(((finish - currentTime) / 1000).toFixed(2));
                timer.current = requestAnimationFrame(timerAnimation)
            }
        }

        timer.current = requestAnimationFrame(timerAnimation)
    }

    const crashGame = (data) => {
        setMultiplier(data.multiplier)
        //setLast12(prev => [data, ...prev])
        setGame('crash')
        setZeroBet(false)
        setAllBets([])
        if(mode === 'light') {
            setColor('rgb(233, 63, 63)')
        } else {
            setColor('rgba(233, 64, 64, 1)')
        }
        if(anim.current) {
            clearInterval(anim.current)
        }
        restartPensil.current = setTimeout(() => {
            if(pensil.current) {
                pensil.current.style.transform = 'translate(-50%, 0%)'
            }
        }, 2500)
        restart.current = setTimeout(() => {
            setGame(false)
            setMultiplier('0.00')
            if(mode === 'light') {

            } else {
                setColor('rgba(0, 220, 154, 1)')
            }
        }, 5000)
    }

    const colorUpdate = (lvl) => {

        let palitra = {}

        if(mode === 'light') {
            if (lvl > 0 && lvl < 200) {
                return 'rgba(0, 220, 154, 1)' //Поменять на светлые
            }else if (lvl > 200 && lvl < 1000) {
                return 'rgba(0, 178, 255, 1)' //Поменять на светлые
            } else if (lvl > 1000 && lvl < 2000) {
                return 'rgba(174, 121, 255, 1)' //Поменять на светлые
            } else if (lvl > 2000) {
                return 'rgba(220, 119, 0, 1)' //Поменять на светлые
            }
        } else {
            if (lvl > 0 && lvl < 200) {
                palitra = {color: 'rgba(0, 220, 154, 1)', border: '#009C6D'}
            }else if (lvl > 200 && lvl < 1000) {
                palitra = {color: 'rgba(0, 178, 255, 1)', border: '#274AA3'}
            } else if (lvl > 1000 && lvl < 2000) {
                palitra = {color: 'rgba(174, 121, 255, 1)', border: '#402469'}
            } else if (lvl > 2000) {
                palitra = {color: 'rgba(220, 119, 0, 1)', border: '#A34527'}
            }
        }

        return palitra
        
    }


    const animation = () => {
        if(pensil && yellowRocket && redRocket && blueRocket) {
            yellowRocket.current.style.transform = 'translateY(-20px)'
            redRocket.current.style.transform = 'translateY(-32px)'
            blueRocket.current.style.transform = 'translateY(-42px)'
            pensil.current.style.transform = 'translate(-50%, -45%)'

            anim.current = setInterval(() => {
                pensil.current.style.transition = '2s ease-out'
                pensil.current.style.transform = 'translate(-50%, -20%)'

                blueRocket.current.style.transform = 'translateY(-46px)'
                redRocket.current.style.transform = 'translateY(-40px)'

                start.current = setTimeout(() => {
                    redRocket.current.style.transform = 'translateY(-32px)'
                    blueRocket.current.style.transform = 'translateY(-42px)'
                    yellowRocket.current.style.transform = 'translateY(-30px)'
                }, 1000)

                progres.current = setTimeout(() => {
                    pensil.current.style.transition = '1s ease-out'
                    pensil.current.style.transform = 'translate(-50%, -45%)'
                    redRocket.current.style.transform = 'translateY(-50px)'
                    blueRocket.current.style.transform = 'translateY(-50px)'
                    yellowRocket.current.style.transform = 'translateY(-20px)'
                }, 2000)
                finish.current = setTimeout(() => {
                    yellowRocket.current.style.transform = 'translateY(-55px)'
                }, 2500)
            }, 3000)
        }
    }

    const takePayOut = async () => {

        if(!zeroBet) {
            Notice.Send({type: 'error', text: 'You are have not zero bet'})
            return
        }

        try {
            let res = await fetch(`${Api.url}/api/v1/game-crash/take-a-payout?timestamp=${Date.now()}`, {
                credentials: 'include'
            })

            res = await res.json()

            if(res.statusCode && res.statusCode === 401) {
                let ref = await Api.refresh()

                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    Store.setListener('openAuthPopUp', prev => prev = true)
                    document.querySelector('html').classList.add('hidden')
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/api/v1/game-crash/take-a-payout?timestamp=${Date.now()}`, {
                    credentials: 'include'
                })
                
                res = await res.json()
            }

            if(!res.statusCode) {
                setZeroBet(false)
                await Api.updateAccount()
                return
            } else {
                console.log(res)
                //Notice.Send({type: 'error', text: 'Server error'})
                return
            }
        }   
        catch(e) {
            Notice.Send({type: 'error', text: 'Server error'})
            return console.log(e)
        }
    }

    const placeBet = async () => {

        setError(false)
        setAutoError(false)

        if(validateBet(bet) === 'error') {
            setError(true)
            return
        }

        if(validateAuto(auto) === 'error') {
            setAutoError(true)
            return
        }

        if(zeroBet && !auto) {
            Notice.Send({type: 'error', text: 'There cannot be more than one zero bet'})
            return
        }

        if(game === 'progres') {
            return
        }

        const sum = parseFloat(bet) + parseFloat(betSum)

        if(sum > 1000) {
            Notice.Send({type: 'error', text: 'Max is 1000 coins'})
            return 
        }

        const wagers = localStorage.getItem('balanceField')
        const query = {
            bet: bet,
            multiplierToTakeOut: auto || 0,
            forWagers: wagers && wagers === 'wager' ? true : false
        }
        const params = new URLSearchParams(query).toString()

        try {
            let res = await fetch(`${Api.url}/api/v1/game-crash/place-a-bet?${params}`, {
                credentials: 'include'
            })

            res = await res.json()

            if(res.statusCode && res.statusCode === 401) {
                let ref = await Api.refresh()

                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    Store.setListener('openAuthPopUp', prev => prev = true)
                    document.querySelector('html').classList.add('hidden')
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/api/v1/game-crash/place-a-bet?${params}`, {
                    credentials: 'include'
                })
                
                res = await res.json()
            }


            if(res.statusCode && res.statusCode === 400) {
                if(res.exception.message === 'You do not have enough balance to place a bet!') {
                    Notice.Send({type: 'error', text: res.exception.message})
                }
                return
            }

            if(!res.statusCode) {
                if(!auto || auto == 0) {
                    setZeroBet(true)
                }
                setAuto('')
                setBetSum(prev => prev + parseFloat(bet))
                await Api.updateAccount()
                return 
            } else {
                console.log(res)
                Notice.Send({type: 'error', text: 'Server error'})
                return
            }
            
        }   
        catch(e) {
            Notice.Send({type: 'error', text: 'Server error'})
            return console.log(e)
        }
    }
    
    const validateBet = (value) => {
        setError(false)
        let val = value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        
        if(!val || val < 0.1) {
            setError(true)
            return 'error'
        } else {
            setError(false)
            return 'ok'
        }
    }

    const validateAuto = (value) => {
        setError(false)
        let val = value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        
        if(val < 1.01 && val > 0) {
            setAutoError(true)
            return 'error'
        } else {
            setAutoError(false)
            return 'ok'
        }
    }

    function autoChange(value) {
        if(block) {
            return
        }
        Bet.setBets(value, setAuto)
        validateAuto(value)
    }

    function betChange(value) {
        if(block) {
            return
        }
        Bet.setBets(value, setBet)
        validateBet(value)
    }

    function betPlus(value) {
        if(block) {
            return
        }
        Bet.plus(value, bet, setBet)
        setError(false)
    }

    function betMulti(value) {
        if(block) {
            return
        }
        Bet.multi(value, bet, setBet)
        setError(false)
    }

    function betDivide(value) {
        if(block) {
            return
        }
        Bet.divide(value, bet, setBet)
        setError(false)
    }

    function betClear() {
        if(block) {
            return
        }
        Bet.clear(setBet)
        setError(false)
    }

    /*const updateAllBet = async (data) => {
        setAllBets(prev => {
            const updatedBets = prev.map(el => 
                el.id === data.id ? {...el, totalPayout: data.totalPayout, multiplier: data.multiplier} : el
            );
            return sortByBet(updatedBets);
        });
        if(isAuth) {
            await Api.updateAccount()
        }
    }*/

    return {crashLast, betClear, auto, setAuto, timeField, multiplier, game, setGame, ping, pensil, yellowRocket, blueRocket, redRocket, scaleList, last12, setLast12, takePayOut, bet, setBet, allBets, setAllBets, placeBet, color, colorUpdate, zeroBet, error, setError, betDivide, betMulti, betChange, validateBet, betPlus, sortByBet, block, autoError, validateAuto, autoChange };
}

