import { useEffect, useState } from "react";
import Api from "../Api";
import { OverlayScrollbars } from 'overlayscrollbars';


export default function useAuth() {

    const [loader, setLoader] = useState(true)
    let [isAuth, setIsAuth] = useState(false)
    const [role, setRole] = useState(false)

    useEffect(() => {

        (async () => {

        let res = await Api.asyncAuth()

            if(res === 'error' || res === 'unAuth') {
                setIsAuth(false)
                setLoader(false)
                return
            }

            localStorage.setItem( 'client', JSON.stringify(res.client));

            setIsAuth(true)
            setRole(res.client.user.role)
            setLoader(false)

        })()

        OverlayScrollbars(document.body, {})

    }, [])

    return {isAuth, loader, role};
  }
