import React from 'react';
import coin from './../../../../fonts/main_icon_fonts/coin.svg';

import spectatorsImg from './../../images/spectators.svg'
import Price from '../../../../components/price/Price';


const BattleHeader = ({password, spectators, gameDetails}) => {

    return (
        <div className='statistic_header_block'>
            <div className="total_cost statistic_item">
                <img src={coin} alt="" />
                <p>Total cost:</p>
                <p><Price price={gameDetails?.totalCost}/></p>
            </div>
            <div className="rounds statistic_item">
                <div className='case_btl_icon_arrows'></div>
                <p>Rounds:</p>
                <p>{gameDetails?.rounds}</p>
            </div>
            <div className="type statistic_item">
                <div className='case_btl_icon_user'></div>
                <p>Type:</p>
                <p>{gameDetails?.gameType}</p>
            </div>
            <div className="spectacor statistic_item">
                <img src={spectatorsImg} alt="" />
                <p>Spectacor:</p>
                <p>{spectators}</p>
            </div>
            {password && 
            <div className="statistic_item">
                <img src={''} alt="" />
                <p>Password:</p>
                <p>{password}</p>
            </div>}
            
            
            <div className="fair statistic_item">
                <div className='case_btl_icon_lock'></div>
                Provably fair
            </div>
        </div>
    );
};

export default BattleHeader;