import { useEffect, useState } from "react"
import PromoServices from "../services/PromoServices"

export default function useDashBoard() {
    const [params, setParams] = useState(false)
    const [dash, setDash] = useState(false)
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState('year')
    const [currentData, setCurrentData] = useState(false)
    const [minDate, setMinDate] = useState(false)

    useEffect(() => {
        if(params) {
            (async () => {
                setLoad(prev => prev = false)
                let query = {}
                query = new URLSearchParams(params).toString();
                const init = await PromoServices.getReqCookie(`api/v1/referrals/me/dashboard?${query}`)
                if(!init || init === null || init === 'error') {
                    setDash(false)
                } else {
                    setDash(init)
                }
                setLoad(prev => prev = true)
            })()
        }
    }, [params])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('client'))
        if(user) {
            const DATE = new Date().toISOString().split('T')[0].split('-')
            setParams(prev => ({
                ...prev, 
                year: parseInt(DATE[0]),
                month: DATE[1],
                day: DATE[2],
                clientId: user.id
            }))
    
            setCurrentData({
                year: parseInt(DATE[0]),
                month: DATE[1],
                day: DATE[2]
            })
            console.log(user)
            const minDATE = user.user.steamProfile.createdAt.split('T')[0].split('-')
            
            setMinDate({
                year: parseInt(minDATE[0]),
                month: minDATE[1],
                day: minDATE[2]
            })
        }
        
    }, [])


    function changeFilter(e) {
        const tabs = document.querySelector('.tabs_block')
        const allTabs = tabs.querySelectorAll('button')

        allTabs.forEach(el => {
            if(el === e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        
        setFilter(e.target.name)
    }

    function increaseDate(e) {
        let value = parseInt(params[filter])
        value = value + 1
        switch (filter) {
            case 'month':
                if(params.year === currentData.year) {
                    if(value > parseInt(currentData.month)) {
                        return
                    }
                    if(value === parseInt(currentData.month) && params.day > currentData.day) {
                        setParams(prev => ({...prev, day: currentData.day}))
                    }
                }

                if(value === 2) {
                    if(PromoServices.isLeapYear(params.year)) {
                        if(params.day > 29) {
                            setParams(prev => ({...prev, day: 29}))
                        }
                    } else if (params.day > 28){
                        setParams(prev => ({...prev, day: 28}))
                    }
                }
                if([4, 6, 9, 10].includes(value) && params.day > 30){
                    setParams(prev => ({...prev, day: 30}))
                }

                if(value < 10) {
                    value = `0${value}`
                } else if(value > 12) {
                    return
                } else {
                    parseFloat(value)
                }
                break

            case 'year':
                if(params.month === '02' && !PromoServices.isLeapYear(value) && params.day > 28) {
                    setParams(prev => ({...prev, day: 28}))
                }
                if(value === currentData.year){
                    if(params.month > currentData.month) {
                        setParams(prev => ({...prev, month: currentData.month}))
                        if(params.day > currentData.day) {
                            setParams(prev => ({...prev, day: currentData.day}))
                        }
                    }
                    if(params.month === currentData.month && params.day > currentData.day) {
                        setParams(prev => ({...prev, day: currentData.day}))
                    }
                } else if(value > currentData.year){
                    return
                }
                break

            case 'day':

                if(params.month === '02') {
                    var max
                    if(PromoServices.isLeapYear(params.year)) {
                        max = 28
                    } else {
                        max = 27
                    }

                    if(params.day > max) {
                        return 
                    }
                }

                if(
                    (params.year === currentData.year && params.month === currentData.month && value > parseInt(currentData.day)) || 
                    (['04', '06', '09', '10'].includes(params.month) && value > 30) || 
                    value > 31
                ){ return}
                
                value = value < 10 ? `0${value}` : parseFloat(value)
                break
        }
       
        setParams(prev => ({...prev, [filter]: value}))
    }

    function decreaseDate() {
        let value = parseInt(params[filter])
        value = value - 1

        switch(filter) {
            case 'year':
                if(params.month === '02' && !PromoServices.isLeapYear(value) && params.day > 28) {
                    setParams(prev => ({...prev, day: 28}))
                }
                if(value === minDate.year) {
                    if(params.month < minDate.month) {
                        setParams(prev => ({...prev, month: minDate.month}))
                        if(params.day < minDate.day) {
                            setParams(prev => ({...prev, day: minDate.day}))
                        }
                    }
                    
                }
                if (value < minDate.year) {
                    return
                }
                break
            case 'month':
                if(params.year === minDate.year) {
                    if(value < parseInt(minDate.month)) {
                        return
                    }
                    if(value === parseInt(minDate.month) && params.day < minDate.day) {
                        setParams(prev => ({...prev, day: minDate.day}))
                    }
                }
                
                if(value === 2) {
                    if(PromoServices.isLeapYear(params.year)) {
                        if(params.day > 29) {
                            setParams(prev => ({...prev, day: 29}))
                        }
                    } else if (params.day > 28){
                        setParams(prev => ({...prev, day: 28}))
                    }
                }
            
                if([4, 6, 9, 10].includes(value) && params.day > 30){
                    setParams(prev => ({...prev, day: 30}))
                }
                if(value < 1) {
                    return
                } 
                value = value < 10 ? `0${value}` : parseFloat(value)
                break
            case 'day':
                if(
                    (params.year === minDate.year && params.month === minDate.month && value < parseInt(minDate.day)) || 
                    value < 1
                ){ return}

                value = value < 10 ? `0${value}` : parseFloat(value)
                break
        }

        setParams(prev => ({...prev, [filter]: value}))
    }

    return {load, params, dash, filter, changeFilter, increaseDate, decreaseDate}
}