import React from 'react';
import Api from '../../../Api';
import Administrator from '../../../admin/services/Administrator';
import Notice from '../../../Notice';
import Store from '../../../Store';

const AdminNewsDelBtn = ({id}) => {

    function delAdminNews() {
        fetch(`${Api.url}/api/v1/news/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
            credentials: 'include'
        })
        .then(res => Administrator.getRes(res))
        .then(data => {
            if(data === 'error') {
                Notice.Send({type: 'error', text: 'Оштбка сервера'})
                return
            }
            
            Store.setListener('ADMIN_DELETE_NEWS_GLOBAL', id)

            Administrator.onSuccess('adminMessageDeleteNews')
        })
    }

    return (
        <button className="icon_button red" onClick={() => delAdminNews()}>
            <div className="admin_icon_cross"></div>
        </button>    
    );
};

export default AdminNewsDelBtn;