import React from 'react';

import coin from './../../../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../../../components/price/Price';

const HighestCrashWin = ({maxCrashWinData}) => {
    return (
        <div className="highest_crash_win">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_stats"></div>
                </div>
                <div className='game_title'>Highest crash win</div>
                <div className="decor pink"></div>
            </div>
            <div className="body_content">
                <p className='win_title'>Highest crash win:</p>
                <div className="monet_price">
                    <img src={coin} alt="" />
                    <p><Price price={maxCrashWinData ? maxCrashWinData.maxCrashWin : 0}/></p>
                </div>
                <p className='win_title'>Highest multipler:</p>
                <div className="monet_price">
                    <p>x <Price price={maxCrashWinData ? maxCrashWinData.crashMultiplier : 0}/></p>
                </div>
            </div>
        </div>
    );
};

export default HighestCrashWin;