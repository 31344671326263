import React, { useEffect } from 'react';
import { BrowserRouter as Router,  Routes, Route} from 'react-router-dom';

import Home from './pages/home/Home';
import Promo from './pages/promo/Promo';
import Roulette from './pages/roulette/Roulette';
import Crash from './pages/crash/Crash';
import Dice from './pages/dice/Dice';
import Upgrade from './pages/upgrade/Upgrade';
import Rewards from './pages/rewards/Rewards';
import Deposit from './pages/deposit/Deposit';
import Slots from './pages/slots/Slots';
import Cases from './pages/cases/Cases';
import OpenCase from './pages/cases/components/OpenCase';
import User from './pages/user/User';
import Withdraw from './pages/user/tabs/withdraw/Withdraw';
import NotFoundPage from './components/NotFoundPage';
import Help from './pages/help/Help';
import PromoDashboard from './pages/promo/PromoDashboard';
import CaseBattles from './pages/case_battles/CaseBattles';
import Battle1x1 from './pages/case_battles/games/Battle1x1';
import Battle3v3 from './pages/case_battles/games/Battle3v3';
import CreateBattles from './pages/case_battles/create_battles/CreateBattles';
import Footer from './components/footer/Footer';
import Battle2v2 from './pages/case_battles/games/Battle2v2';
import Race from './pages/race/Race';
import Battle4v4 from './pages/case_battles/games/Battle4v4';

const MainContainer = (props) => {

    return (
        <div className='main_container'>
            <Routes>
    
                <Route path='/' element={<Home isAuth={props.isAuth} role={props.role}/>}/>

                {/*-------------Games------------*/}
                <Route path='/roulette'       element={<Roulette isAuth={props.isAuth} />}/>
                <Route path='/crash'          element={<Crash   mode={props.mode} isAuth={props.isAuth}/>}/>
                <Route path='/dice'           element={<Dice isAuth={props.isAuth} />}/>
                <Route path='/upgrade'        element={<Upgrade  />}/>
                {/*<Route path='/slots'          element={<Slots    />}/>*/}
                <Route path='/cases'          element={<Cases role={props.role} />}/>
                <Route path='/cases/open/:id' element={<OpenCase />}/>
                
                <Route path='/case_battles'   element={<CaseBattles />}/>
                <Route path='/case/create_battle' element={<CreateBattles />}/>
                <Route path='/case_battles/1vs1/:id' element={<Battle1x1 />}/>
                <Route path='/case_battles/1vs1vs1/:id' element={<Battle3v3 />}/>
                <Route path='/case_battles/2vs2/:id' element={<Battle2v2 />}/>
                <Route path='/case_battles/1vs1vs1vs1/:id' element={<Battle4v4 />}/>
                {/*-------------Games------------*/}
                
                <Route path='/daily_race'     element={<Race />}/>
                <Route path='/rewards'        element={<Rewards  isAuth={props.isAuth}/>}/>
                <Route path='/deposit'        element={<Deposit  />}/>
                <Route path='/promo'          element={<Promo isAuth={props.isAuth}/>}/>
                <Route path='/promo/dashboard' element={<PromoDashboard />}/>
                <Route path='/user/*'         element={<User     />}/>
                <Route path='/help'           element={<Help     />}/>
                <Route path='/withdrow'       element={<Withdraw />}/>
            <Route path='*'                   element={<NotFoundPage />} />
                
            </Routes>

            <Footer mode={props.mode}/>
        </div>
    );
};

export default MainContainer;