import React from 'react';
import { NavLink } from 'react-router-dom';
import CaseDeleteBtn from '../CaseDeleteBtn';
import CaseEditDtn from '../CaseEditDtn';
import FreeCaseDelBtn from '../../../../admin/components/add_cases/FreeCaseDelBtn';

const FreeCase = ({el}) => {
    return (
        <div className='cases_grid_item' id={el.id}>
            <img src={el.image} alt="" />
            
            <NavLink to={`/cases/open/${el.id}`} className='title'>{el.name}</NavLink>
            <NavLink to={`/cases/open/${el.id}`} className="common_button">Open case</NavLink>
            <div className="demo_wrapper">

                <CaseEditDtn caseInfo={el}/>

                <FreeCaseDelBtn caseId={el.id}/>

            </div>
        </div>
    );
};

export default FreeCase;