import React from 'react';
import './css/admin.css';
import decor_left_dark from './images/decor_dark_left.svg'
import decor_right_dark from './images/decor_dark_right.svg'

import EditCatgoryBtn from './components/EditCatgoryBtn';
import AddItemBtn from './components/add_cases/AddItemBtn';
import AddCasesItem from './components/add_cases/AddCasesItem';
import AddCasesCategoriFilter from './components/add_cases/AddCasesCategoriFilter';
import CaseTypeFlex from './components/add_cases/CaseTypeFlex';
import useAdminCases from './admin_hooks/useAdminCases';
import ItemsStore from './ItemsStore';


const AddCases = () => {
    let {load, error, setError, edit, newList, setNewList, caseParams, setCaseParams, allCategories, updateList, changeCaseParams, confirmNewCase, closeAdminPopup} = useAdminCases()

    return (
        <>
            <div className='admin_popup_wrapper' id='adminAddCases' onMouseDown={closeAdminPopup}>
                <div className="admin_popup_scroll" >
                    <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="admin_icon_case"></div>
                            </div>
                            <div className='game_title'>{edit ? 'Modify case' : 'Create case'}</div>
                            <div className="decor pink" onMouseDown={closeAdminPopup}></div>
                        </div>

                        <form className="admin_popup_content">
                            <div id='err_case_fill_drop_chance' className={error.dropChance ? `err_mess error` : 'err_mess'} >Please, fill all drop chance fields</div>
                            <div id='err_case_exist' className={`err_mess`} >This case allready exist!</div>
                            <div id='err_case_drop_chance' className={`err_mess`} >The sum of all chances of falling out should be equal to 100!</div>

                            <label htmlFor="">Case name</label>
                            <input className={`admin_input ${error.name ? 'error' : ''}`} name='name' placeholder='Empty-Head' value={caseParams.name} onChange={(e) => changeCaseParams(e.target)} onBlur={(e) => setError(prev => ({...prev, ['name']: false }))}/>

                            <label htmlFor="">Image <span>[.svg]</span> </label>
                            <input className={`admin_input ${error.image ? 'error' : ''}`} name='image' placeholder='<svg width="59" height="59"...' value={caseParams.image} onChange={(e) => changeCaseParams(e.target)} onBlur={(e) => setError(prev => ({...prev, ['image']: false }))}/>

                            <label htmlFor="">Category</label>

                            <AddCasesCategoriFilter setCaseParams={setCaseParams} allCategories={allCategories} caseParams={caseParams}/>

                            
                            {allCategories && allCategories.length > 0 &&
                                <div className="category_preview_wrapper">
                                    <div className="category_title_preview">

                                        <div className="category">
                                            
                                            {allCategories.map(el => (
                                                el.name === caseParams.category &&
                                                <div className='category_name' key={el.id}>
                                                    <img src={el.image} alt="" />
                                                    <span>{el.name}</span>
                                                </div>
                                            ))}

                                            <img src={decor_left_dark} alt="" className="left_decor" />
                                            <img src={decor_right_dark} alt="" className="right_decor" />
                                        </div>

                                        {caseParams.categoryId !== '' && <EditCatgoryBtn categoryId={caseParams.categoryId}/>}

                                    </div>
                                </div>
                            }

                            <button 
                                className="common_button full green" 
                                onClick={(e) => confirmNewCase(e)}
                            >   
                                {load ? 
                                    'CONFIRM'
                                :
                                    'Loading...'
                                }
                            </button>             

                            <div className="case_type">

                                <h2>Case type</h2>

                                <CaseTypeFlex caseParams={caseParams} setCaseParams={setCaseParams}/>

                            </div>

                            <div className="what_inside_block">
                                <h2>What inside?</h2>
                                <div className={`err_mess ${error.list ? 'error' : ''}`} >Minimum two items require</div>
                                <div id='err_max_num' className={`err_mess`} >Еhe maximum number of items in a case cannot exceed 15</div>

                                <div className="what_inside_grid">

                                    {newList.length > 0 && newList.map((item) =>(
                                        <AddCasesItem error={error.dropChance} setError={setError} key={item.id} item={item} newList={newList} setNewList={setNewList}/>
                                    ))}

                                    <AddItemBtn updateList={updateList} error={error.list} setError={setError}/>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
            
            <ItemsStore/>
        </>
    );
};

export default AddCases;