import React, { useEffect, useRef, useState } from 'react';
import ak from './../../../images/ak.svg'
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import Store from '../../../Store';

const OpenTwoCase = ({roullete, itemsOpenList1, itemsOpenList2, roulleteTwo}) => {

    
    let [reren, setReren] = useState(false)
    
    Store.useListener('caseItems2', setReren)

    return (
        <div className='case_open_flex'>

            <div className="triangle_left"></div>
            <div className="triangle_right"></div>


            <div className="case_open_flex_item_wrapper">
                
                <div className="case_open_flex_item">
                    
                    <div className="case_open_flex_wrapper" >

                        {itemsOpenList1 ? 
                        <>
                            {itemsOpenList1.length > 0 ?
                                
                                <div className="case_open_flex_roulete" ref={roullete}>

                                    {itemsOpenList1.map((el, i) => (
                                        <div className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList1.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf12' : el.fakeItem.id} >
                                            <img 
                                                key={i} 
                                                src={el.fakeItem.image} 
                                                className={el.fakeItem.type}
                                                alt="" 
                                            />
                                        </div>
                                    ))}

                                </div>
                                  
                            : 
                            
                                <NotItems />}
                        </> 

                        :
                        
                        <LoadingItems />}

                    </div>

                    
                    <div className="triangle_small_left" ></div>
                    <div className="triangle_small_right"></div>

                </div>
            </div>
            
            <div className="case_open_flex_item_wrapper">
                <div className="case_open_flex_item">
                    
                    <div className="case_open_flex_wrapper" >

                        {itemsOpenList2 ? 
                        <>
                            {itemsOpenList2.length > 0 ?
                                
                                <div className="case_open_flex_roulete" ref={roulleteTwo}>
                                    {itemsOpenList2.map((el, i) => (
                                        <div className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList2.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf13' : el.fakeItem.id}>
                                            <img 
                                                key={i} 
                                                src={el.fakeItem.image} 
                                                className={el.fakeItem.type}
                                                alt="" 
                                            />
                                        </div>
                                    ))}
                                </div>
                                    
                            : 
                            
                                <NotItems />}
                        </> 

                        :
                        
                        <LoadingItems />}

                    </div>

                    
                    <div className="triangle_small_left"></div>
                    <div className="triangle_small_right"></div>

                </div>
            </div>

        </div>
    );
};

export default OpenTwoCase;