import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import Api from "../../../Api";
import Title from "../../../mainTitle";


export default function useMainCaseBattle() {

    const [gameHistory, setGameHistory] = useState([])
    const [lastBattles, setLastBattles] = useState([])
    const [load, setLoad] = useState(false)
    const [radio, setRadio] = useState(1)
    const socket = useRef(null)

    useEffect(() => {

        socket.current = io(`${Api.urlWs}/game-case-battle`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5
        })
        
        socket.current.on('case-battle.gameHistory', (data) => {
            setGameHistory(data?.sort((a, b) => b.gameCost - a.gameCost))
            setLoad(true)
        })
        socket.current.on('case-battle.lastBattles', setLastBattles)

        Title.setListener('title', 'CASE BATTLES')

        return () => {
            if(socket.current) {
                socket.current.off('case-battle.gameHistory')
                socket.current.off('case-battle.lastBattles')
                socket.current.disconnect()
            }
        }

    }, [])

    const getType = (type) => {

        let result;

        switch(type) {
            case '1vs1':
                result = 'Solo Battle'
                break
            case '1vs1vs1':
                result = 'Triple Battle'
                break
            case '1vs1vs1vs1':
                result = 'Quadro Battle'
                break
            case '2vs2':
                result = 'Team Battle'
                break
            default: 
                result = 'Solo Battle'
                break
        }

        return result
    }

    const filterList = () => {
        
        if(radio === 1) {
            setRadio(2)
            setGameHistory(prev => prev.sort((a, b) => a.gameCost - b.gameCost))
            return
        }
        if(radio === 2) {
            setRadio(1)
            setGameHistory(prev => prev.sort((a, b) => b.gameCost - a.gameCost))
            return
        }
    }

    return {gameHistory, lastBattles, getType, load, filterList, radio}
}