import { useEffect, useState } from "react"
import Store from "../../Store"
import Api from "../../Api"
import Administrator from "../services/Administrator"
import Notice from "../../Notice"
import { useNavigate } from "react-router-dom"

export default function useGiveMoney () {
    const [user, setUser] = useState(false)
    const [load, setLoad] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)
    const [error, setError] = useState(false)
    const [balance, setBalance] = useState('')
    const [issueList, setIssueList] = useState(false)
    const [params, setParams] = useState({
        size: 10,
        page: 1,
        order: 'ASC',
        field: 'date',
    })

    const navigate = useNavigate()
    const [userId, setUserId] = useState(false)
    const [userLoad, setUserLoad] = useState(false)

    Store.useListener('GIVE_MONEY', () => {
        Administrator.onSuccess('You gived money')
        setParams(prev => ({...prev, page: 1}))
    })

    useEffect(() => {
        if(userId) {
            (async () => {
                setUserLoad(prev => prev = false)
                await fetch(`${Api.url}/api/v1/clients?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    credentials: 'include',
                })
                .then(res => Administrator.getRes(res))
                .then(data => {
                    if(data === 'error') {
                        Notice.Send({type: 'error', text: 'Something went wrong'})
                        return
                    }
                    if (data === '404') {
                        Notice.Send({type: 'error', text: 'User not found'})
                        setUser(false)
                        return
                    }

                    setUser(data)
                })
                .catch(error => console.log(error))
                setUserLoad(prev => prev = true)
            })()
        }
        
    }, [userId])

    Store.useListener('giveMoneyUser', setUserId)

    useEffect(() => {
        
        if(user) {
            (async () => {
                setLoad(prev => prev = false)
                const query = new URLSearchParams(params).toString()
                await fetch(`${Api.url}/api/v1/replenishments/issued?${query}`, {
                    method: 'GET',
                    headers: {
                        'X-Client-Id': user.id
                    },
                    credentials: 'include',
                })
                .then(res => Administrator.getRes(res))
                .then(data => {
                    if(data === 'error') {
                        return Notice.Send({type: 'error', text: 'Something went wrong'})
                    }
                    if(data) {
                        setIssueList(data)
                    }
                })
                .catch(error => console.log(error))

                setLoad(prev => prev = true)
            })()
        }
    }, [params, user])

    function onInput(e) {
        setError(false)

        if(!e.target.value) {
            setError(true)
        }

        let value = e.target.value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');

        let str = value.split('.')

        if(str[1] && str[1].length > 2) {
            return
        } else {
            setBalance(str.join('.'))
        }
    }

    const checkForGive = async (path) => {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: 'GET',
                headers: {
                    'X-Client-Id': user.id,
                },
                credentials: 'include',
            })

            res = await res.json()

            if(res.statusCode === 401 && res?.exception?.message === 'Unauthorized') {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: 'GET',
                    headers: {
                        'X-Client-Id': user.id,
                    },
                    credentials: 'include',
                })

                res = res.json()
            }

            if(res.statusCode === 401 && res?.exception?.message === 'No OTP code provided!') {
                return 'tfa_confirm'
            }

            if (res.statusCode !== 200) {
                return 'error'
            }

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    function giveMoney() {
        if(balance === '') {
            setError(prev => prev = true)
            return
        }
        (async () => {
            setLoadBtn(prev => prev = false)

            const data = await checkForGive(`api/v1/replenishments/issue?balance=${balance}`)

            setLoadBtn(prev => prev = true)

            if(data === 'unAuth') {
                Notice.Send({type: 'error', text: 'The session has expired. You will be redirected to the main page'})
                setTimeout(() => {
                    navigate('/')
                    window.location.reload()
                }, 3000);
                return 
            }

            if (data === 'tfa_confirm') {
                const options = {
                    path: `api/v1/replenishments/issue?balance=${balance}`,
                    marker: 'GIVE_MONEY',
                    method: 'GET',
                    userId: user.id
                }
                Store.setListener('CONFIRM_TFA_POPUP', options)
                return
            }

            if(data === 'error') {
                return Notice.Send({type: 'error', text: 'Error. Try again later'})
            }

            Administrator.onSuccess('You gived money')

            setParams(prev => ({...prev, page: 1}))
        })()
    }

    function changeOrder(e, name) {
        setLoad(prev => prev = false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, [name]: e.target.textContent}))
    }

    function closePopUp() {
        document.getElementById('adminGiveMoney').classList.remove('open')

        setError(false)

        setBalance('')  

        setUserId(false)
    }

    return {load, loadBtn, userLoad, error, setError, params, setParams, issueList, balance, user, onInput, closePopUp, giveMoney, changeOrder}
}