import React, {useEffect} from 'react';
import './css/game_history.css';
import Store from '../../../../Store';
import HistoryHeader from './components/HistoryHeader';
import { Outlet } from 'react-router-dom';


const GameHistory = () => {

    useEffect(() => {
        Store.setNumListener('page', 4)
    }, [])
    
    useEffect(() => {
        let initialScroll = window.scrollY;
        
        window.scrollTo(0, initialScroll);
    });

    return (
        <div className='game_history_block'>

            <div className="history">

                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_squares"></div>
                    </div>
                    <div className='game_title'>Replenishment list</div>
                    <div className="decor light_green"></div>
                </div>

                <div className="history_content">

                    <HistoryHeader />

                    <Outlet />

                </div>


            </div>

        </div>
    );
};

export default GameHistory;