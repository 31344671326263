import React from 'react';

import './css/create_battles.css'
import Sets from './components/Sets';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import AddCard from './components/AddCard';
import TakeCasePopUp from '../../../components/take_case_popup/TakeCasePopUp';
import useDnD from '../hooks/useDnD';
import useCreateBattle from '../hooks/useCreateBattle';
import ManageBock from './components/ManageBock';
import MobileManageBlock from './components/MobileManageBlock';

const CreateBattles = () => {
    
    const {load, plusCard, minusCard, sortCards, updateList, battleParams, setNewList, newList, rounds, setMode, setPlayers, createBattle} = useCreateBattle()

    const {dragStart, dragOver, dragEnd, dragDrop} = useDnD(setNewList, newList)

    return (
        <div className='create_battle_block container'>

            <Sets setMode={setMode} setPlayers={setPlayers}/>

            <MobileManageBlock battleParams={battleParams} rounds={rounds}/>

            <div className="case_list">

                {newList.sort(sortCards).map((card) => (
                    <div className="case_list_item" 
                        onDragStart={(e) => dragStart(e, card)}
                        onDragLeave={(e) => dragEnd(e)}
                        onDragEnd={(e) => dragEnd(e)}
                        onDragOver={(e) => dragOver(e)} 
                        onDrop={(e) => dragDrop(e, card)}
                        draggable={true}
                        key={card.id}
                    >
                                
                        <img src={card.image} alt="" />

                        <div className="price">
                            <img src={coin} alt="" />
                            {card.price}
                        </div>

                        <h3>{card.name}</h3>

                        <div className="amount_block main_card">
                            <button className="icon_button red" onClick={() => minusCard(card)}>
                                <div className='account_icon_minus'></div>
                            </button>

                            <h4 className='amount'>{card.count}</h4>

                            <button className="icon_button green" onClick={() => plusCard(card.id)}>
                                <div className="account_icon_plus"></div>
                            </button>
                        </div>

                    </div>
                ))}

                <AddCard func={updateList} dragDrop={dragDrop} dragOver={dragOver} dragEnd={dragEnd}/>

            </div>

            <ManageBock load={load} battleParams={battleParams} rounds={rounds} createBattle={createBattle}/>

            <TakeCasePopUp />
            
        </div>
    );
};

export default CreateBattles;