import { useEffect, useState } from "react"
import Api from "../../../Api"
import Notice from "../../../Notice"
import Store from "../../../Store"
import useCase from "../../../socket/useCase"

export default function useCaseStore(role) {
    const [caseStore, setCaseStore] = useState(false)

    let [caseListForAdmin, setCaseListForAdmin] = useState(false)
    let [caseAllList, setCaseAllList] = useState(false)
    let [freeCases, setFreeCases] = useState(false)
    const [price, setPrice] = useState(['all'])

    const [categories, setCategories] = useState(false)
    let [params, setParams] = useState({
        size: 1000,
        page: 1,
        order: 'ASC',
        field: 'name',
    })
    let [load, setLoad] = useState(false)

    useCase() ///////

    function changeParams(e, name) {
        setLoad(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, [name]: e.target.textContent}))
    }

    function changePriceFilter(e) {
        setLoad(prev => prev = false)
        let filter = e.target.textContent
        const priceList = e.currentTarget.querySelectorAll('p')

        if(filter === 'all') {
            priceList.forEach(el => {
                if(el == e.target) {
                    e.target.classList.add('active')
                } else {
                    el.classList.remove('active')
                }
            })
            setPrice(['all'])
        } else {
            let clone = structuredClone(price.filter(el => el !== 'all'))
            priceList[0].classList.remove('active')
            if(e.target.classList.contains('active')){
                if(clone.length <= 1) {
                    return
                }
                setPrice(clone.filter(el => el !== filter))
                e.target.classList.remove('active')
            } else{
                clone.push(filter)
                setPrice(clone)
                e.target.classList.add('active')
            }
        }
    }

    useEffect(() => {

        (async () => {
            let query = new URLSearchParams(params)

            if(price[0] === 'all') {
                const range = ['0-10', '10-25', '25-50', '50-100', '100-250', '250-1000', '1000+']
                range.map(el => {
                    query.append('priceRanges', el)
                })
            } else {
                price.map(el => {
                    query.append('priceRanges', el)
                })
            }

            let init = await Api.asyncGet(`api/v1/case/all?${query}`)

            let categoryInit = await Api.asyncGet('api/v1/categories/all')

            if(!init || init === 'error' || !categoryInit || categoryInit === 'error') {
                return Notice.Send({type: 'error', text: 'Something went wrong'})
            }

            if(role === 'ADMIN') {
                setCaseListForAdmin(init.items.filter(el => el.categories === null))
                setFreeCases(init.items.filter(el => el.type === 'free' ))
            }

            const CASE_FOR_USERS = init.items.filter(el => (el.categories !== null && el.type === 'paid'))

            setCaseStore(CASE_FOR_USERS)
            setCaseAllList(CASE_FOR_USERS)
            setCategories(categoryInit)
            setLoad(true)
        })()

    }, [params, price])

    /* -------------Изменение списка категорий от админа----------- */

    async function deleteFromCategoryList(deleteId) {
        setParams(prev => ({...prev, page: 1}))
    }

    Store.useListener('UPDATE_CATEGORY_LIST_INCASE', deleteFromCategoryList)

    async function addCategoriesFromAdmin(data) {
        let categoryInit = await Api.asyncGet('api/v1/categories/all')

        if(categoryInit === 'error' || !categoryInit) {
            return Notice.Send({type: 'erro', text: 'Server error, try again'})
        }

        setCategories(categoryInit)
    }

    Store.useListener('UPDATE_CATEGORY_GLOBAL', addCategoriesFromAdmin)

    function editCategory(data) {
        setCategories(prev => prev.map(el => {
            if(el.id === data.id) {
                return data
            }
            return el
        }))
    }

    Store.useListener('ADMIN_EDIT_CATEGORY', editCategory)

    /* -------------добавление кейса от админа------------------- */

    function adminAddCase(data) {
        setCaseAllList(prev => [...prev, data])
    }

    Store.useListener('ADD_NEW_CASE_GLOBAL', adminAddCase)

    function adminAddFreeCase(data) {
        
        var clone = structuredClone(freeCases)
        clone.push(data)
        clone.sort(SortArray)

        setFreeCases(clone)
    }

    function SortArray(x, y) {
        return x.name.localeCompare(y.name, 'fr', {ignorePunctuation: true});
    }

    Store.useListener('ADD_NEW_FREE_CASE_GLOBAL', adminAddFreeCase)

    /* -------------Удаление кейса от админа----------*/

    function adminDelCase(id) {
        setCaseAllList(prev => prev.filter(el => el.id != id))
        setCaseListForAdmin(prev => prev.filter(el => el.id != id))
    }

    Store.useListener('DELETE_CASE_FROM_ADMIN', adminDelCase)

    function adminDelFreeCase(id) {
        setFreeCases(prev => prev.filter(el => el.id != id))
    }

    Store.useListener('DELETE_FREE_CASE_FROM_ADMIN', adminDelFreeCase)

    /* -------------Редактирвоание кейса-------------- */

    function adminEditCase(data) {
        setCaseAllList(prev => prev.map(el => {
            if(el.id === data.id) {
                return data
            }
            return el
        }))
    }

    Store.useListener('EDIT_CASE_GLOBAL', adminEditCase)

    function adminEditFreeCase(data) {
        setFreeCases(prev => prev.map(el => {
            if(el.id === data.id) {
                return data
            }
            return el
        }))
    }

    Store.useListener('EDIT_FREE_CASE_GLOBAL', adminEditFreeCase)

    return {load, price, caseAllList, categories, params, caseListForAdmin, freeCases, changeParams, changePriceFilter}
}