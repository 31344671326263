import React, { useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import AdminAllUsersBtn from './AdminAllUsersBtn';
import AdminAllUserBurgerBtn from './AdminAllUserBurgerBtn';

const Burger = (props) => {

    function OpenBurger (e) {
        e.stopPropagation()
        document.querySelector('.burger_list').classList.toggle('open')
    }

    function CloseBurgerMenu () {
        document.querySelector('.burger_list').classList.remove('open')
    }

    useEffect(() => {
        window.addEventListener('click', CloseBurgerMenu)

        return () => window.removeEventListener('click', CloseBurgerMenu)
    }, [])

    useCallback(() => {
        return () => window.removeEventListener('click', CloseBurgerMenu)
    }, [])

    return (
        <div className="burger_wrapper">
            <button className="icon_button burger" onClick={OpenBurger}>
                <span></span>
                <span></span>
                <span></span>
            </button>
            
            <div className='burger_list main_card'>
                <NavLink to='/'>
                    Home
                    <div className="header_icon_home"></div>
                </NavLink>

                {props.isAuth && 
                    <NavLink to='/user/account'>
                        Account
                        <div className="header_icon_account"></div>
                    </NavLink>
                }
                
                <NavLink to='/promo' className='mob'>
                    Promo
                    <div className="header_icon_promo"></div>
                </NavLink>

                <NavLink to='/rewards' className='mob'>
                    Rewards
                    <div className="header_icon_rewards"></div>
                </NavLink>

                <NavLink to='/daily_race'>
                    Race
                    <div className="race_iconrace"></div>
                </NavLink>

                <NavLink to='/withdrow'>
                    Withdraw
                    <div className="header_icon_rewards"></div>
                </NavLink>

                <NavLink to='/deposit'>
                    Deposit
                    <div className="header_icon_rewards"></div>
                </NavLink>

                {props.isAuth && 
                    <NavLink to='/user/inventory' className='mobile'>
                        Inventory
                        <div className='icon_bag' ></div>
                    </NavLink>
                }
                
                {props.role === 'ADMIN' && 
                    <AdminAllUserBurgerBtn />
                }

            </div>
        </div>
    );
};

export default Burger;