import React, { useState } from 'react';
import TFAPopup from '../../../../../components/TFAPopup';
import Disable2faPopUp from './Disable2faPopUp';

const TwoFactorAuth = ({profile}) => {
    const tfaPermission = profile.user.email === null || profile.user.username === null ? false : true
    
    const [tfaEnable, setTFAEnable] = useState(profile.user.twoFactorEnabled || false)
    
    const [open, setOpen] = useState({
        tfaPopup: false,
        disable2faPopup: false
    })

    const openPopUp = (name) => {
        document.querySelector('html').classList.add('hidden')
        setOpen(prev => ({...prev, [name]: true}))
    }

    return (
        <div className="two_factor_auth">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_gear"></div>
                </div>
                <div className='game_title'>Two-Factor authentication</div>
                <div className="decor pink"></div>
            </div>
            <div className="body_content">
                {tfaEnable ? 
                <>
                    <button className="common_button red full" onClick={() => openPopUp('disable2faPopup')}>disable 2fa</button>
                </>
                :
                <>
                    {tfaPermission ?
                        <p>Add an extra layer of security to your account and further prevent unauthorised acces by requiring an authentication code when signing in or requesting a withdrawal.</p>
                    :
                        <p>To be able to use two-stage authorization, you must specify the email, nickname and password above!</p>
                    }
                    <button className={`common_button green full ${tfaPermission ? '' : 'disable'}`} 
                        onClick={() => {
                            if(tfaPermission) {
                                openPopUp('tfaPopup')
                                return
                            }
                            return
                        }}
                    >TURN ON 2FA</button>
                </>
                }

            </div>

            {open.tfaPopup && <TFAPopup setOpen={setOpen} setTFAEnable={setTFAEnable}/>}
            {open.disable2faPopup && <Disable2faPopUp setOpen={setOpen} setTFAEnable={setTFAEnable}/>}
        </div>
    );
};

export default TwoFactorAuth;