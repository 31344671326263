import React from 'react';

const Price = ({price}) => {

    return (
        <>
            {(price)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </>
    );
};

export default Price;