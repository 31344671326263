import React, { useEffect, useRef, useState } from 'react';
import ak from './../../../images/ak.svg'
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import NotItems from '../../../components/NotItems';
import LoadingItems from '../../../components/LoadingItems';
import Price from '../../../components/price/Price';


const CaseItems = ({items}) => {

    const [itemsList, setitemsList] = useState(false)

    useEffect(() => {

        if(items?.length) {
            setitemsList(items.sort((a, b) => b.fakeItem.price - a.fakeItem.price))
        }
        
    }, [items])

    console.log(items)

    return (

        <div className='case_items_block'>

            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="home_icon_cases"></div>
                </div>   
                <h4 className='game_title'>Items in this case</h4>
                <div className="decor pink"></div>
            </div>
            
            <div className="case_items_list_wrapper">
            {itemsList ? 
                <>
                    {itemsList.length > 0 ?
                        <div className="case_items_list">

                            {itemsList.map((el, i) => (
                                <div className={`drop_card ${el.fakeItem.type}`} key={i}>

                                <img 
                                    src={el.fakeItem.image} 
                                    className={`image ${el.fakeItem.type}`}
                                    alt="" 
                                />

                                <div className="drop_card_content">

                                    <h4 className="title">{el.fakeItem.name}</h4>

                                    <p className={`price main_card ${el.fakeItem.type}`}>

                                        <p className="percent">{el.dropChance}%</p>

                                        <p className="cost">
                                            <Price price={el.fakeItem.price}/>
                                            <img src={coin} alt="" />
                                        </p>

                                    </p>

                                </div>
                            </div>
                            ))}
                            
                        </div>
                :
                    <NotItems />
                }
                </>
            :
                <LoadingItems />
            }
                
            </div>
            
        </div>
    );

    
};

export default CaseItems;