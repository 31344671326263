import React from 'react';

const BtnAdminAddNewItem = () => {
    function openAdminPopup() {
        document.getElementById('adminEditItems').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className="common_button green" onClick={openAdminPopup}>Add New Item</button>
    );
};

export default BtnAdminAddNewItem;