import React from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg';

const AmountBlock = () => {
    return (
        <div className="amount_block">
            <div className="amount main_card">
                <label htmlFor="amount">Deposit Amount*</label>
                <div className="price">
                    <img src={coin} alt="" />
                    100
                </div>
                <button className="common_button">
                    BUY FOR $70.00
                </button>
            </div>
            <div className="currency main_card">
                <label htmlFor="amount">Preferred Currency*</label>
                USD
            </div>
            <div className="bonus main_card">
                <label htmlFor="amount">5% Bonus*</label>
                <h4>XD</h4>
                <button className="common_button">Apply</button>
            </div>
        </div>
    );
};

export default AmountBlock;