import React from 'react';
import Store from '../../../Store';

const AdminEditNewsBtn = ({data}) => {

    function openAdminPopUp() {
        document.getElementById('adminNewsPopup').classList.add('open')
        document.querySelector('html').classList.add('hidden')

        Store.setListener('ADMIN_EDIT_NEWS_BTN', data)
    }

    return (
        <button className="icon_button green" onClick={() => openAdminPopUp()}>
            <div className="admin_icon_pencil"></div>
        </button>
    );
};

export default AdminEditNewsBtn;