import React from 'react';

const DepositGrid = () => {
    return (
        <div className="deposit_grid">
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    Cards
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    PAYPAL (Gift card)
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    RUST
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                Google Pay
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    BTC
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    ETH
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    P24
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    Bolleto
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    PIX
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    USDT (ERC20)
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    USDC (ERC20)
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    iDeal
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    Bancontact
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    LTC
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    XRP
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    DOGE
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
            <div className="deposit_grid_item">
                <div className="deposit_item_header main_card">
                    Bancontact
                </div>
                <div className="deposit_grid_item_window main_card">

                </div>
            </div>
        </div>
    );
};

export default DepositGrid;