import React from 'react';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg'

const TotalCost = ({totalCost}) => {
    
    return (
        <div className="total_cost_block">
            <div className="total_cost">
                <img src={coin} alt="" />
                <h4>Total Cost: {totalCost}</h4>
            </div>
            <button className="common_button green">WITHDRAW</button>
        </div>
    );
};

export default TotalCost;
