import React from 'react';
import useFilters from '../hooks/useFilters';

const Filters = ({serching, search, changeFilter, categoryFilter, categories}) => {

    const {openFilter} = useFilters()

    return (
        <div className="filters_block ">
            <div className="search main_card">
                <input 
                    type="text" 
                    placeholder='Lookup cases'
                    value={search}
                    onChange={(e) => serching(e)}
                />
                <div className="icon_search"></div>
            </div>

            <div className="filter main_card nocopy" onClick={(e) => openFilter(e)}>
                <span>Case category: {categoryFilter}</span>
                <div className="deposit_icon_arrow"></div>

                <div className="filter_list" onMouseDown={(e) => changeFilter(e)}>
                    <p className='active'>all</p>
                    {categories?.length && categories.map(el => (
                        <p>{el.name}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Filters;