import React from 'react';
import coin from './../../../../../../fonts/main_icon_fonts/coin.svg'

const HistoryList = ({list}) => {

    return (
        <div className="history_list case_battle">
            {list && list.map((e, i) => (
                <div className="history_list_item" key={i}>
                    <div className="id"><span>{e.id}</span></div>

                    <div className="created">
                        {e.time[0]}
                        <p className="time">{e.time[1]}</p>
                    </div>

                    <div className="initial_bet">
                        <img src={coin} alt="" />
                        <span>{e.bet}</span>
                    </div>

                    <div className="plurals">
                        {e.rounds}
                    </div>

                    <div className={`payout ${+e.totalWonSkinsValue < 0 ? 'red' : 'green'}`}>
                        <img src={coin} alt="" />
                        <span>{e.totalWonSkinsValue}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HistoryList;