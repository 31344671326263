import React from 'react';
import CaseList from './components/CaseList';
import coin from './../../fonts/main_icon_fonts/coin.svg'
import LoadingItems from '../LoadingItems';
import useTakeCase from './hooks/useTakeCase';
import Filters from './components/Filters';


const TakeCasePopUp = () => {

    const {load, serching, search, store, categories, categoryFilter, selectCases, closeTakeCasePopup, newList, caseSum, plusCard, minusCard, changeFilter} = useTakeCase()

    return (
        <div className='take_case_popup_wrapper' onMouseDown={closeTakeCasePopup}>
            <div className="take_case_popup_scroll">
                <div className="take_case_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="home_icon_cases"></div>
                        </div>
                        <div className='game_title'>Case</div>
                        <div className="decor pink" onMouseDown={closeTakeCasePopup}></div>
                    </div>

                    <div className="take_case_popup_content">

                        <Filters {...{serching, search, changeFilter, categoryFilter, categories}}/>

                        {load ? 
                            <>
                                {categories && categories.length > 0 ?
                                    <>
                                        {store && store.length > 0 ? 
                                            <div className="cases_grid_block">

                                                {categories.map((el, i) => (
                                                    <CaseList key={i} store={store} plusCard={plusCard} minusCard={minusCard} category={el} newList={newList}/>
                                                ))}

                                            </div>
                                        :
                                            <div className='not_cases'>No cases :&#40;</div>
                                        }
                                    </>
                                    : 
                                    <div className='not_items'>No categories</div>
                                }
                            </>
                            : 
                            <LoadingItems />
                        }
                        <div className="total_cost_block">

                            <div className="total_cost">
                                <img src={coin} alt="" />

                                <p>Total cost:</p>
                                <p>{caseSum}</p> 
                            </div>

                            <button className="common_button green" onClick={selectCases}>SELECT</button>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
};

export default TakeCasePopUp;