import React from 'react';
import Title from '../../../mainTitle';
import './../css/case_battles.css';
import BattleHeader from './components/BattleHeader';
import CaseRoulete from './components/case_roulete/CaseRoulete';
import Team from './components/Team';
import Player from './components/Player';
import { useParams } from 'react-router-dom';
import useCaseBattle from '../hooks/useCaseBatle';
import BattlePasswordPopUp from './components/BattlePasswordPopUp';

const Battle2v2 = () => {

    const screenWidth = window.innerWidth;

    const {id} = useParams()
    const caseBattlesObj = useCaseBattle(id)
    const list = [1, 2, 3, 4]

    return (
        <div className='case_battles_block container'>
            
            <BattleHeader password={caseBattlesObj.password} spectators={caseBattlesObj.spectators} gameDetails={caseBattlesObj.gameDetails}/>

            <CaseRoulete round={caseBattlesObj.round} cases={caseBattlesObj.formed?.cases}/>

            {screenWidth > 768 ? 
                <div className="battle_grid">
                    {caseBattlesObj.timer && <div className="timer">{caseBattlesObj.timer}</div>}
                    <div className="team_block">
                        {structuredClone(list).slice(0, 2).map((i) => (
                            <Player multi={'multi'} load={caseBattlesObj.load} joinTeam={caseBattlesObj.joinTeam}  callTeamBots={caseBattlesObj.callTeamBots} type={caseBattlesObj.formed.battleType} inGame={caseBattlesObj.inGame} cases={caseBattlesObj.cases} round={caseBattlesObj.round} gameStatus={caseBattlesObj.gameStatus} inventory={caseBattlesObj.inventory} result={caseBattlesObj.result} joinGame={caseBattlesObj.joinGame} callBots={caseBattlesObj.callBots} position={i} player={caseBattlesObj.formed.client?.find(el => el.position == i)} totalCost={caseBattlesObj.gameDetails?.totalCost} bot={caseBattlesObj.formed.bot?.find(el => el.position == i)} leaveClient={caseBattlesObj.leaveClient}/>
                        ))}
                    </div>
                    <div className="team_block">
                        {structuredClone(list).slice(2, 5).map((i) => (
                            <Player multi={'multi'} load={caseBattlesObj.load} joinTeam={caseBattlesObj.joinTeam}  callTeamBots={caseBattlesObj.callTeamBots} type={caseBattlesObj.formed.battleType} inGame={caseBattlesObj.inGame} cases={caseBattlesObj.cases} round={caseBattlesObj.round} gameStatus={caseBattlesObj.gameStatus} inventory={caseBattlesObj.inventory} result={caseBattlesObj.result} joinGame={caseBattlesObj.joinGame} callBots={caseBattlesObj.callBots} position={i} player={caseBattlesObj.formed.client?.find(el => el.position == i)} totalCost={caseBattlesObj.gameDetails?.totalCost} bot={caseBattlesObj.formed.bot?.find(el => el.position == i)} leaveClient={caseBattlesObj.leaveClient}/>
                        ))}
                    </div>
                </div>
            : 
            <div className="battle_grid">
                {caseBattlesObj.timer && <div className="timer">{caseBattlesObj.timer}</div>}
                {list.map((i) => (
                    <Player multi={'multi'} load={caseBattlesObj.load} joinTeam={caseBattlesObj.joinTeam}  callTeamBots={caseBattlesObj.callTeamBots} type={caseBattlesObj.formed.battleType} inGame={caseBattlesObj.inGame} cases={caseBattlesObj.cases} round={caseBattlesObj.round} gameStatus={caseBattlesObj.gameStatus} inventory={caseBattlesObj.inventory} result={caseBattlesObj.result} joinGame={caseBattlesObj.joinGame} callBots={caseBattlesObj.callBots} position={i} player={caseBattlesObj.formed.client?.find(el => el.position == i)} totalCost={caseBattlesObj.gameDetails?.totalCost} bot={caseBattlesObj.formed.bot?.find(el => el.position == i)} leaveClient={caseBattlesObj.leaveClient}/>
                ))}
            </div>
            }
            
            {caseBattlesObj.roomsType === 'PRIVATE' && <BattlePasswordPopUp totalCost={caseBattlesObj.gameDetails?.totalCost}/>}
        </div>
    );
};

export default Battle2v2;