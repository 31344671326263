import React from 'react';
import coin from '../../../../../fonts/main_icon_fonts/coin.svg'
import cart from './../images/cart.svg'
import Price from '../../../../../components/price/Price';

export const InventoryItem = ({item, sellItem}) => {
    return (
        <div className="inventory_list_item">

            <div className={`drop_card ${item.fakeItem.type}`}>
                <img src={item.fakeItem.image} className={`drop_image ${item.fakeItem.type}`} alt="" />
                <div className="drop_card_content">
                    <h4 className="title">{item.fakeItem.name}</h4>
                    <p className={`price main_card ${item.fakeItem.type}`}>
                        <p><Price price={item.fakeItem.price}/></p>
                        <img src={coin} alt="" />
                    </p>
                </div>
            </div>

            <button className="common_button flex full" onClick={() => sellItem(item.fakeItem.id, item.index)}>
                <p>SELL</p>
                <img src={cart} alt="" />
            </button>
        </div>
    );
};