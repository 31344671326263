import React, { useRef } from 'react';

const CaseItem = ({i, element, round, gameEnd}) => {

    const item = useRef(null)
    
    const getCaseActivity = () => {
        if(gameEnd) {
            return ''
        } else {
            if(i < round - 1) {
                return 'disable'
            } else if (i == round - 1) {
                return 'active'
            } else {
                return ''
            }
        }
    }

    return (
        <div className={`case_list_item ${getCaseActivity()}`}>
            <div className="case_list_item_wrapper">
                <img src={element.image} alt="" />
            </div>
        </div>
    );
};

export default CaseItem;