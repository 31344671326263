import React, { useState } from 'react';
import SignInSteam from './SignInSteam';
import Api from '../../Api';
import Notice from '../../Notice';
import Store from '../../Store';
import TFAConfirmModal from '../tfa_confirm_modal/TFAConfirmModal';

const LoginPopUp = () => {

    const [backError, setBackError] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [sessionError, setSessionError] = useState()
    const [open2fa, setOpen2fa] = useState(false)

    const [input, setInput] = useState({
      login: '',
      password: ''
    });
   
    const [error, setError] = useState({
      login: false,
      password: false
    })
    
    Store.useListener('openAuthPopUp', setIsOpen)
    Store.useListener('sessionTimeError', setSessionError)

    const closeLogin = () => {
       // if(open2fa) return
        document.querySelector('html').classList.remove('hidden')
        setIsOpen(false)
        setError({login: false, password: false})
        setBackError(false)
        setSessionError(false)
    }
   
    const onInputChange = e => {
      const { name, value } = e;
      setInput(prev => ({
        ...prev,
        [name]: value
      }));
      validateInput(e);
    }
   
    const validateInput = e => {
      setBackError(false)
      document.getElementById('email_input').classList.remove('error_back')
      document.getElementById('password_input').classList.remove('error_back')
      let { name, value } = e;
      setError(prev => {
        const stateObj = { ...prev, [name]: "" }; 
   
        switch (name) {
   
          case "password":
            if (!value) {
              stateObj[name] = "Please enter password.";
            }  else {
              stateObj[name] = 'OK'
            }
            break;
   
          case "login":
            if (!value) {
              stateObj[name] = "Please enter Login.";
            }  else {
              stateObj[name] = 'OK'
            }
            break;
   
          default:
            break;
        }

        return stateObj;
      });
    }


    const logIn = async (e) => {
      e.preventDefault()

        if (error.login === 'OK' && error.password === 'OK') {
          console.log('вход')
          try {
            let res = await fetch(`${Api.url}/api/v1/auth/alt-sign-in`, {
              method: 'POST',
              headers: {
                'Content-type': 'application/json; charset=UTF-8'
              },
              body: JSON.stringify(input)
            })
  
            res = await res.json()
  
            if(res.statusCode && res.statusCode === 400) {
                setBackError(true)
                document.getElementById('email_input').classList.add('error_back')
                document.getElementById('password_input').classList.add('error_back')
                return
            }

            if(res.statusCode && res.statusCode === 401 && res.exception.message === 'No OTP code provided!') { //открытие модалки 2fa, если включена 2fa
              setOpen2fa(prev => prev = true)
              return
            }

            if(res.statusCode && res.statusCode === 404) { //нерпвильный логин или пароль
              setBackError(true)
              document.getElementById('email_input').classList.add('error_back')
              document.getElementById('password_input').classList.add('error_back')
              return
          }
            
            if(res.statusCode && res.statusCode === 500) {
                return Notice.Send({type: 'error', text: 'Server error'})
            } 
            
            if(!res.statusCode){
                localStorage.setItem('client', JSON.stringify(res.client))
                window.location.reload()
                return
            }

          } catch(e) {
            console.log(e)
            return 
          }
      } else {
          if(!input.login) {
              setError(prev => ({...prev, login: 'Please enter Login.'}))
          }
          if(!input.password) {
              setError(prev => ({...prev, password: 'Please enter password.'}))
          }
      }
    }

   if(!isOpen) return null

    return (
      <>
        <div className={`auth_pop_up_wrapper ${isOpen ? 'open': ''}`} onMouseDown={() => closeLogin()}>
            <div className="auth_pop_up" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Authorization</div>
                    <div className="decor pink" onMouseDown={() => closeLogin()}></div>
                </div>
                <div className="auth_pop_up_content">

                    <SignInSteam />

                    <form action="" className='' id='login_form' onSubmit={logIn}>
                        {backError && <p className="error_massage">Invalid login or password</p>}
                        {sessionError && <p className="error_massage">Your session has expired, please update the data</p>}
                        <label htmlFor="email_input">Login</label>
                        <input className={`main_input main_card ${error.login && error.login !== 'OK' ? 'error_input': ''}`} id='email_input' name='login' type="text" placeholder='Enter login' value={input.login} onChange={(e) => onInputChange(e.target)} onBlur={(e) => validateInput(e.target)}/>
                        {error.login && error.login !== 'OK' ? <div className='error'>{error.login}</div> : <></>}
                        <label htmlFor="password_input">Password</label>
                        <input className={`main_input main_card ${error.password && error.password !== 'OK' ? 'error_input': ''}`} id='password_input' name='password' type="password" placeholder='Enter password' value={input.password} onChange={(e) => onInputChange(e.target)} onBlur={(e) => validateInput(e.target)}/>
                        {error.password && error.password !== 'OK' ? <div className='error'>{error.password}</div> : <></>}
                        <button className='common_button full green'>Sign in</button>
                    </form>
                    
                </div>
            </div>
        </div>

        {open2fa && <TFAConfirmModal 
                        setOpen={setOpen2fa}
                        path={'api/v1/auth/alt-sign-in'}
                        method={'POST'}
                        body={JSON.stringify(input)}
                        marker={'LOGIN'}
                    />}
      </>
    );
};

export default LoginPopUp;