import React from 'react';

import ChatMessage from './ChatMessage';
import ReainPoolMessage from './ReainPoolMessage';

const ChatList = (props) => {
    
    return (
        
        <div className="chat_track" id='chat_message_track'>

            {props.chatMessages.map((element, i) => (
                element.rainPool ? 
                        <ReainPoolMessage message={element.message} key={i}/>
                    :
                        <ChatMessage role={props.role} key={i} el={element} func={props.pinMessageFunc} setOpenPopUp={props.setOpenPopUp} deleteMess={props.deleteMess}/>
                ))}

        </div>
        
    );
};

export default ChatList;