import React from 'react';
import PasswordRequire from './PasswordRequire';
import {usePersonalInfo} from "../hooks/usePersonalInfo";
import TFAConfirmModal from '../../../../../components/tfa_confirm_modal/TFAConfirmModal';

const PersonalInfo = ({profile}) => {

    const {error, onInputChange, changeLogin, open2fa, body, setOpen2fa, validateInput, input, openRequire, load} = usePersonalInfo(profile);
 
    return (
        <>
            <div className="personal_info">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Personal information</div>
                    <div className="decor pink"></div>
                </div>
                <form action="" className='body_content' onSubmit={changeLogin}>
                    <div className={`email_block main_card ${error.email && error.email !== 'OK' ? 'error_input' : ''}`}>
                        <input type="email" name='email' placeholder='Email' className='main_input' value={input.email} onChange={(e) => onInputChange(e.target)} />
                    </div>
                    {error.email && error.email !== 'OK' ? <div className='error'>{error.email}</div> : <></>}
                    <input type="text" placeholder='Username' name='username'
                        className={`main_input main_card ${error.username && error.username !== 'OK' ? 'error_input' : ''}`}
                        value={input.username} onChange={(e) => onInputChange(e.target)}
                        />
                    {error.username && error.username !== 'OK' ? <div className='error'>{error.username}</div> : <></>}
                    <div className="password_block">
                        <PasswordRequire/>
                        <input type="password" name='password' placeholder='Password'
                            className={`main_input main_card ${error.password && error.password !== 'OK' ? 'error_input' : ''}`}
                            value={input.password} onChange={(e) => onInputChange(e.target)}
                            onClick={(e) => openRequire(e)}/>
                    </div>
                    {error.password && error.password !== 'OK' ? <div className='error'>{error.password}</div> : <></>}
                    <div className="password_block">
                        <input type="password" name='repeatPassword' placeholder='Repeat the password'
                            className={`main_input main_card ${error.repeatPassword && error.repeatPassword !== 'OK' ? 'error_input' : ''}`}
                            value={input.repeatPassword} onChange={(e) => onInputChange(e.target)}
                        />
                    </div>
                    {error.repeatPassword && error.repeatPassword !== 'OK' ? <div className='error'>{error.repeatPassword}</div> : <></>}
                    <button className="common_button green full" type='submit'>{load ? "Loading..." :"SAVE INFORMATION"}</button>
                </form>
            </div>

            {open2fa && <TFAConfirmModal 
                        setOpen={setOpen2fa}
                        path={'api/v1/auth/credentials'}
                        method={'PUT'}
                        body={JSON.stringify(body)}
                        marker={'PERSONAL'}
                    />}
        </>
    );
};

export default PersonalInfo;