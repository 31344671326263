import React from 'react';
import useItemFilters from '../hooks/useItemFilters';

const SearchBlock = ({changeParams, params, price, changePriceFilter, searching}) => {

    const {openFilter} = useItemFilters()

    return (
        <div className="search_block">

            <div className="search main_card">
                <input 
                    type="text" 
                    placeholder='Search for items' 
                    value={params.namePrefix}
                    onChange={(e) => searching(e)}
                />
                <button>
                    <div className="icon_search"></div>
                </button>
            </div>

            <div className='filters'>

                <div className='filters_item'>
                    <div className="filter_content main_card">
                        Type: <span> {params.type}</span>
                    </div>

                    <button className="icon_button" onMouseDown={(e) => openFilter(e)}>
                        <div className="icon_arrow_right"></div>
                    </button>

                    <div className="filter_list" onMouseDown={(e) => changeParams(e, 'type')}>
                        <p className='active'>all</p>
                        <p>red</p>
                        <p>green</p>
                        <p>blue</p>
                        <p>orange</p>
                        <p>purple</p>
                    </div>
                </div>

                <div className='filters_item' >
                    <div className="filter_content main_card grid">
                        <p>Price: <span>{price.length > 1 ? price.map((el, i )=> (<span>{i > 0 && ','} {el}</span>)) : price[0]}</span></p>
                    </div>

                    <button className="icon_button" onMouseDown={(e) => openFilter(e)}>
                        <div className="icon_arrow_right"></div>
                    </button>

                    <div className="filter_list" onMouseDown={(e) => changePriceFilter(e)}>
                        <p className='active'>all</p>
                        <p>0-10</p>
                        <p>10-25</p>
                        <p>25-50</p>
                        <p>50-100</p>
                        <p>100-250</p>
                        <p>250-1000</p>
                        <p>1000+</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SearchBlock;