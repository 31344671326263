import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import Api from '../../../Api';

const RaceHead = ({filter, setFilter}) => {
    
    const [race, setRace] = useState('')
    const socketRace = useRef(null)

    useEffect(() => {

        const socketRace2 = io(`${Api.urlWs}/race`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        });

        socketRace.current = io(`${Api.urlWs}/race?room=daily`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        });
        
        socketRace.current.on('race.finishIn', setRace);
        socketRace.current.on('connect_error', (error) => console.log('Connect Error:', error));
        
        return () => {
            if(socketRace.current) {
                socketRace.current.off('race.finishIn')
                socketRace.current.off('connect_error')
                socketRace.current.disconnect()
            }
            if(socketRace2) {
                socketRace2.disconnect()
            }
        }

    }, [])

    const switchRoom = (room) => {
        socketRace.current.emit('changeRoom', room)
        setFilter(room)
    }

    return (
        <div className='race_head'>
            <div className="daily_race_window">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="race_iconrace"></div>
                    </div>
                    <h4 to='/roulette' className='game_title'>Daily race</h4>
                    <div className="decor pink"></div>
                </div>
                <div className="race_head_window">
                    <h3>These dudes won the most SpinCoins today!</h3>
                    <p>Reloaded every 5 minute.</p>
                </div>
            </div>
            <div className="resets">
                <div className="nav_block">
                    <button className={`common_button full capitalize ${filter === 'daily' ? 'active' : ''}`} onClick={() => switchRoom('daily')}>Today</button>
                    <button className={`common_button full capitalize ${filter === 'weekly' ? 'active' : ''}`} onClick={() => switchRoom('weekly')}>Week</button>
                    <button className={`common_button full capitalize ${filter === 'monthly' ? 'active' : ''}`} onClick={() => switchRoom('monthly')}>Month</button>
                </div>
                <div className="resets_window">
                    {race ? 
                        <>
                            <h4>Resets in:</h4>
                            <p className='time'>{race}</p>
                        </>
                    :<p>Loading...</p>}
                </div>
            </div>
        </div>
    );
};

export default RaceHead;