import axios from "axios"
import Api from "./Api"

const listener = {}
const data = {}

const Store = {}

Store.useListener = (name, func) => {
    return listener[name]=func
}

Store.setListener = (name, data) => {
    return listener[name](data)
}

Store.getAccess = () => {
    if(data.access) {
        return data.access
    } else {
        let local = localStorage.getItem('access_token')

        if(local && local != null) {
            return data.access = local
        } else {
            return false
        }
    }
}

Store.logout = () => {
    
    fetch(`${Api.url}/api/v1/auth/logout`, {
        method: 'POST',
        headers: { },
        credentials: 'include'
    })
    .then(res => res.json())
    .then(data => {
        console.log(data)
        localStorage.removeItem('client')
        return window.location.replace('/');
    } )
    .catch(error => {
        console.log(error)
        window.location.replace('/404');
    })
}


const numListener = {}

Store.useNumListener = (name, func) => {
    return numListener[name]=func
}

Store.setNumListener = (name, data) => {
    return numListener[name](data)
}

export default Store;

