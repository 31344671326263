import React from 'react';

import coin from './../../../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../../../components/price/Price';

const BestUnboxedItem = ({bestWonItem}) => {
    return (
        <div className="best_unboxed_item">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_stats"></div>
                </div>
                <div className='game_title'>Best unboxed item</div>
                <div className="decor pink"></div>
            </div>
            <div className="body_content">
                <div className="monet_price">
                    <img src={coin} alt="" />
                    <p><Price price={bestWonItem?.itemPrice ? bestWonItem?.itemPrice : 0}/></p>
                </div>
                <div className="box_name">
                    {bestWonItem?.itemName ? bestWonItem?.itemName : 'NONE'}
                </div>
            </div>
        </div>
    );
};

export default BestUnboxedItem;