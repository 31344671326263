import React from 'react';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg';


const Ready = ({id, clientId, leaveClient}) => {
    return (
        <div className='ready_block'>
            <div className="ready main_card">
                <h5>Ready</h5>
                {/*<div className='total'>
                    <p>Total win:</p>
                    <img src={coin} alt="" />
                    <p>12.03</p>
                </div>*/}
            </div>
            
            {id === clientId ? <button className="common_button white full" onClick={leaveClient}>Leave</button> :<></>}
        </div>
    );
};

export default Ready;