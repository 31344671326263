import React, { useEffect } from 'react';
import './css/rewards.css';
import Promocode from './components/Promocode';
import DailyFree from './components/DailyFree';
import Title from '../../mainTitle';
import CaseList from './components/CaseList';


const Rewards = ({isAuth}) => {

    useEffect(() => {
        Title.setListener('title', 'Rewards')
    }, [])

    return (
        <div className='rewards_block container'>
            
            <DailyFree />
            
            <Promocode isAuth={isAuth}/>

            <CaseList isAuth={isAuth}/>

        </div>
    );
};

export default Rewards;