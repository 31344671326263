import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './css/deposits.css'
import { NavLink } from 'react-router-dom';
import Pagination from './components/Pagination';
import DepositList from './components/DepositList';
import Store from '../../../../Store';
import useDeposits from './hooks/useDeposits';
import Filters from './components/Filters';
import CommonPagination from '../../../../components/pagination/CommonPagination';

const Deposits = () => {

    useEffect(() => {
        Store.setNumListener('page', 3)
    }, [])

    const {load, list, params, setParams, changeFilters} = useDeposits()

    return (
        <div className='deposits_block'>

            <div className="replenishment_list">
                
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_squares"></div>
                    </div>
                    <div className='game_title'>Replenishment list</div>
                    <div className="decor light_green"></div>
                </div>

                <div className="replenishment_list_content">

                    <Filters params={params} changeFilters={changeFilters}/>

                    <NavLink to='/withdrow' className="common_button green full">WITDRAW</NavLink>

                    <div className="replenishment_table">
                        <div className="replenishment_table_header">
                            <h5>SOURCE</h5>
                            <h5>TYPE</h5>
                            <h5>VALUE</h5>
                            <h5>DATE</h5>
                        </div>

                        <DepositList list={list.items} load={load}/>

                        <CommonPagination maxPage={Math.ceil(list.total / params.size)} params={params} setParams={setParams} />
                    </div>
                    
                </div>
            </div>
            
            
        </div>
    );
};

export default Deposits;