import React, { useEffect, useState } from 'react';
import Avatar from '../../../components/avatar/Avatar';
import avatar from './../../../images/avatar.svg'
import caseImage from './../images/case.png'
import Players from './Players';
import CaseItem from './CaseItem';
import battle from './../images/battle.svg'

const GameView = ({el, status, getType}) => {
    
    const getTrianglePosition = () => {

        const width = window.innerWidth > 768 ? 75 : 49
        if(el.round >= 0 && el.round < 4) {
            return `calc(${(el.round * width) - (width / 2)}px - 50% + 7.5px)`
        }
        if(el.round > 3) {
            return `calc(${(width * 3) - (width / 2)}px - 50% + 7.5px)`
        }
    }

    const getCaseListPositon = () => {

        const width = window.innerWidth > 768 ? 75 : 49

        if(el.round > 3) {
            return `-${(el.round - 3) * width}px`
        } else {
            return `0px`
        }
    }

    return (
        <div className="game_view">

            <div className="players_block">
                {el.gameType === '1vs1' ? 
                    <div className="players">

                        <Players el={el} position={1}/>
                        
                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={2}/>

                    </div>
                :el.gameType === '1vs1vs1' ?
                    <div className="players">
                        <Players el={el} position={1}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={2}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={3}/>
                    </div>
                :el.gameType === '2vs2' ?
                    <div className="players">
                        <Players el={el} position={1} type={el.gameType}/>

                        <Players el={el} position={2} type={el.gameType}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={3} type={el.gameType}/>

                        <Players el={el} position={4} type={el.gameType}/>
                    </div>
                :el.gameType === '1vs1vs1vs1' ?
                    <div className="players multi">
                        <Players el={el} position={1}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={2}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={3}/>

                        <img src={battle} alt="" className='case_btl_icon_swords'/>

                        <Players el={el} position={4}/>
                    </div> 
                :<></>}

                <div className="settings">
                    <div>
                        <div className="type">{getType(el.gameType)}</div>
                        <div className="mode fast">Fast open mode</div>
                    </div>
                    
                    <div className="condition">{(el.gameType).replace(/vs/g, ' vs ')}</div>
                </div>

            </div>
            

            <div className={`case_list_wrapper ${el.round > 1 ? status : ''}`}>

                <div className="rounds"><b>{el.round}</b> / <span>{el.cases?.length}</span> Rounds</div>
                <div className="shadow"></div>
                <div className="case_roulette_view">
                    <div className="case_list" style={{transform: `translateX(${getCaseListPositon()})`}}>
                        {el.cases?.length ? 
                            <>
                                {el.cases.map((element, i) => (
                                    <CaseItem i={i} element={element} round={el.round} key={i} gameEnd={el.gameEnd}/>
                                ))}
                            </>
                        :<></>}
                    </div>
                </div>

                <div className="triangle_block" style={{transform: `translateX(${getTrianglePosition()})`}}>
                    <div className="triangle top"></div>
                    <div className="triangle"></div>
                </div>
            </div>
        </div>
    );
};

export default GameView;