import React, {useEffect, useState} from 'react';
import Api from '../Api';
import Notice from '../Notice';
import NotItems from '../components/NotItems';
import CommonPagination from '../components/pagination/CommonPagination';
import Store from '../Store';
import ItemsStoreFilters from './components/item_store/ItemsStoreFilters';
import ItemsStoreCard from './components/item_store/ItemsStoreCard';
import LoadingItems from '../components/LoadingItems';


const ItemsStore = () => {
    let [storeList, setStoreList] = useState(false)
    let [newList, setNewList] = useState([])
    let [load, setLoad] = useState(false)

    Store.useListener('updateAdminItemStore', setNewList)

    let [paramsUrl, setParamsUrl] = useState({
        size: 10,
        page: 1,
        order: 'ASC',
        field: 'id',
        type: 'all',
    })

    useEffect(() => {

        (async() => {
            setLoad(prev => prev = false)
            const params = new URLSearchParams(paramsUrl).toString();
            let init = await Api.asyncGet(`api/v1/fake-item/all?${params}`)
            if(init == 'error') {
                return Notice.Send({type: 'error', text: 'Ошибка сервера'})
            }
            init.items = init.items.filter(el => !el.name.includes("(Case Battle)"))
            setStoreList(init)
            setLoad(prev => prev = true)
        })()

    }, [paramsUrl])

    /* -------------Изменение списка предметов от админа--------------- */

    function deleteItem(id) {
        setStoreList(prev => ({...prev, items: prev.items.filter(el => el.id != id)}))
    }

    Store.useListener('DELETE_ITEM', deleteItem)

    function adminAddItem(data) {
        let clone = structuredClone(storeList.items)
        clone.push(data)
        setStoreList(prev => ({...prev, items: clone}))
    }

    Store.useListener('CREATE_NEW_ITEM', adminAddItem)

    function adminEditItem(data) {
        let clone = structuredClone(storeList.items)
        clone = clone.map(el => {
            if(el.id === data.id) {
                return {...el, 
                    id: data.id, 
                    name: data.name, 
                    image: data.image, 
                    price: data.price,
                    type: data.type,
                }
            }
            return el
        })
        if(newList.find(el => el.id === data.id) ) {
            setNewList(newList.map(el => {
                if(el.id === data.id) {
                    return {...el,
                        id: data.id, 
                        name: data.name, 
                        image: data.image, 
                        price: data.price,
                        type: data.type,
                    }
                }
                return el
            }))
        }
        setStoreList(prev => ({...prev, items: clone}))
    }

    Store.useListener('EDIT_EXIST_ITEM', adminEditItem)

    /* ----------------------------------- */

    function closeAdminPopup () {
        document.getElementById('adminItemStore').classList.remove('open')
    }

    function selectItemsInCase() {
        Store.setListener('newAdminItemList', newList)

        closeAdminPopup()
    }

    return (
        <div className='admin_popup_wrapper' id='adminItemStore' onMouseDown={closeAdminPopup}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_category"></div>
                        </div>
                        <div className='game_title'>Item</div>
                        <div className="decor pink" onMouseDown={closeAdminPopup}></div>
                    </div>

                    <div className="admin_popup_content">
                        <div className="items_store">

                           <ItemsStoreFilters paramsUrl={paramsUrl} setParamsUrl={setParamsUrl} />

                            {load ? 
                                <>
                                    {storeList && storeList.items.length > 0 ? 
                                        <div className='store_grid'>
                                            {storeList.items.map((el) => (
                                                <ItemsStoreCard key={el.id} card={el} newList={newList} setNewList={setNewList} />
                                            ))}
                                        </div>
                                        : 
                                        <NotItems />
                                    }
                                </>
                            :
                                <div className="loading_wrapper">
                                    <LoadingItems />
                                </div>
                            }
                            
                            
                            <div className="manage_block">
                                
                                <CommonPagination maxPage={Math.ceil(storeList.total / paramsUrl.size)} params={paramsUrl} setParams={setParamsUrl} />

                                <button className="common_button green full" onClick={() => selectItemsInCase()}>Select</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemsStore;