import React, { useCallback, useEffect, useState } from 'react';

export const Search = ({block, searchItem, search, order, orderBy, openPopUp}) => {

    const [isOpen, setIsOpen] = useState(false)
    const menuList = [
        {name: 'Price High to Low'},
        {name: 'Price Low to High'},
        {name: 'Name A-Z'},
        {name: 'Name Z-A'}
    ]
    const closeMenu = () => {
        setIsOpen(false)
    }

    const toggleMenu = (e) => {
        e.stopPropagation()
        setIsOpen(prev => !prev)
    }

    useEffect(() => {
        window.addEventListener('click', closeMenu)

        return () => window.removeEventListener('click', closeMenu)
    }, [])

    useCallback(() => {
        return () => window.removeEventListener('click', closeMenu)
    }, [])

    return (
        <div className="search_block">

            <div className="inventory_search" onSubmit={(e) => searchItem(e)}>
                <p className="inventory_tag">Fast searh:</p>
                <div className='main_card'>
                    <div className="icon_search"></div>
                    <input type="text" placeholder='Search...' value={search} onChange={(e) => searchItem(e.target.value)}/>
                </div>
            </div>

            <div className="inventory_filter_block">

                <div className="inventory_filter">
                    <p className="inventory_tag">Order by:</p>
                    <button className='common_button full flex' onClick={(e) => toggleMenu(e)}>
                        {order}
                        <div className="account_icon_chevron_right"></div>
                    </button>
                    {isOpen && 
                        <div className="inventory_filter_menu" >
                            {menuList.map((el) => (
                                <p className={`${el.name === order ? 'active' : ''}`} onClick={() => orderBy(el.name)}>{el.name}</p>
                            ))}
                        </div>
                    }
                </div>

                <button className="common_button full green" onClick={openPopUp} disabled={block}>Sell All</button>

            </div>
            
        </div>
    );
};