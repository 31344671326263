import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './../css/cases.css';
import LiveDrops from './LiveDrops';
import CaseItems from './CaseItems';

import GameOpenOne from '../tabs/GameOpenOne';
import GameOpenTwo from '../tabs/GameOpenTwo';
import GameOpenThree from '../tabs/GameOpenThree';
import GameOpenFour from '../tabs/GameOpenFour';
import useOpenCase from '../useOpenCase';


const OpenCase = () => {

    const[tab, isTab] = useState('one')
    const {id} = useParams()
    let [fast, setFast] = useState(false)

    const {items, caseDetail, free} = useOpenCase(id)

    return (

        <div className='open_case_block container'>
        
            {tab ==='one' && <GameOpenOne free={free} caseDetail={caseDetail} tab={tab} isTab={isTab} items={items} fast={fast} setFast={setFast}/>}
            {!free && 
                <>
                    {tab === 'two' && <GameOpenTwo caseDetail={caseDetail} tab={tab} isTab={isTab} items={items} fast={fast} setFast={setFast}/>}
                    {tab === 'three' && <GameOpenThree caseDetail={caseDetail} tab={tab} isTab={isTab} items={items} fast={fast} setFast={setFast}/>}
                    {tab === 'four' && <GameOpenFour caseDetail={caseDetail} tab={tab} isTab={isTab} items={items} fast={fast} setFast={setFast}/>}
                </>
            }

            <CaseItems items={caseDetail.fakeItems}/>

            <LiveDrops id={caseDetail.id} fast={fast}/>

        </div>
        
    );
};

export default OpenCase;