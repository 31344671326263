import React, { useState } from 'react';
import Store from '../../../Store';

const ChatMutePopUp = ({banUser, setOpenPopUp, openPopUp}) => {

    const [options, setOptions] = useState({
        userId: 0,
        duration: 0
    })

    Store.useListener('userId', setOptions)

    const [duration, setDuration] = useState({
        month: 0,
        week: 0,
        day: 0,
        hours: 0
    })

    const close = () => {
        setOpenPopUp(false)
        setOptions({
            userId: 0,
            duration: 0 
        })
        setDuration({
            month: 0,
            week: 0,
            day: 0,
            hours: 0
        })
    }

    const getCoef = (type) => {

        switch (type) {
            case 'hours':
                return 3600
            case 'week':
                return 604800
            case 'day':
                return 86400
            case 'month':
                return 2678400
            default: 
                return null
        }
    }

    const minusTime = (param) => {
        if(duration[param] <= 0) {
            setDuration(prev => ({...prev, [param]: 0}))
            return
        }
        setDuration(prev => ({...prev, [param]: prev[param] - 1}))
        setOptions(prev => ({...prev, duration: prev.duration - getCoef(param)}))
    }

    const plusTime = (param, max) => {
        if(duration[param] >= max) {
            setDuration(prev => ({...prev, [param]: max}))
            return
        }
        setDuration(prev => ({...prev, [param]: prev[param] + 1}))
        setOptions(prev => ({...prev, duration: prev.duration + getCoef(param)}))
    }

    if(!openPopUp) return null

    return (
        <div className={`admin_popup_wrapper ${openPopUp ? 'open' : ''}`} onMouseDown={close}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_chat"></div>
                        </div>
                        <div className='game_title'>Mute</div>
                        <div className="decor pink" onMouseDown={close}></div>
                    </div>

                    <div className="admin_popup_content">

                        <div className="mute_time">
                            <div className="mute_time_item">

                                <div className="mute_item">
                                    <h3>Month</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red" onClick={() => minusTime('month')}>
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">{duration.month}m</div>

                                        <button className="icon_button green" onClick={() => plusTime('month', 12)}>
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                                <div className="mute_item">
                                    <h3>Week</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red" onClick={() => minusTime('week')}>
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">{duration.week}w</div>
                                        
                                        <button className="icon_button green" onClick={() => plusTime('week', 4)}>
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="mute_time_item">

                                <div className="mute_item">
                                    <h3>Day</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red" onClick={() => minusTime('day')}>
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">{duration.day}d</div>
                                        
                                        <button className="icon_button green" onClick={() => plusTime('day', 7)}>
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                                <div className="mute_item">
                                    <h3>Hours</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red" onClick={() => minusTime('hours')}>
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">{duration.hours}h</div>
                                        
                                        <button className="icon_button green" onClick={() => plusTime('hours', 23)}>
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label htmlFor="">Reason:</label>
                        <input 
                            type="text" 
                            className="admin_input" 
                            placeholder='Enter the reason below, read the rules before doing so'
                        />

                        <button className="common_button full green" onClick={() => banUser(options)}>MUTE THIS F*CKING PID*RAS</button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatMutePopUp;