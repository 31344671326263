import { useEffect, useState } from "react";
import Store from "../../../Store";
import PromoServices from "../services/PromoServices";
import Notice from "../../../Notice";

export default function useAvailableFunds() {

    const [funds, setFunds] = useState(false)
    const [user, setUser] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)

    Store.useListener('AVAILABLE_FUNDS', setFunds)

    function claimFunds() {
        if(funds.availableFunds === 0) {
            Notice.Send({type: 'error', text: 'No available funds!'})
            return
        }

        (async () => {
            setLoadBtn(prev => prev = false)
            const init = await PromoServices.getReqCookie('api/v1/referrals/me/available-funds')
            if(!init || init === 'error') {
                setUser(false)
                return
            }
            setUser(init)
            const user = JSON.parse(localStorage.getItem('client'))
            const initFunds = await PromoServices.getReqCookie(`api/v1/referrals/me/privileges?clientId=${user.id}`)
            if(!initFunds || initFunds === 'error') {
                Notice.Send({type: 'error', text: 'Ошибка сервера'})
                setFunds(false)
            } else {
                setFunds(initFunds)
            }
            setLoadBtn(prev => prev = true)
        })()
    }

    return {funds, loadBtn, claimFunds}
}