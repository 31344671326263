import React, { useRef, useState } from 'react';

import ErrorWindow from '../../../components/ErrorWindow';
import LastResults from './LastResults';
import thumb from './../images/thumb.svg'
import arrows from './../images/arrows_roll.svg'


const DiceGame = ({ winnings, setWinnigs, result, range, setRange, roll, setRoll, setCoef, rollUnder, setRollUnder, error, setError, block}) => {
    
    let range_slider = useRef()
    
    /* -------------Изменения ползунка и других полей-----------------*/

    function changeValue(){

        if(block) {
            return
        }

        setError({
            roll: false,
            win: false,
            chance: false
        })

        let value = range_slider.current.value
        setRange(value)
        setWinnigs((96 / value).toFixed(2))

        if(roll === 'under') {
            setRollUnder(value)
        } else {
            setRollUnder((100 - value).toFixed(2))
        }
    }

    function rollChange(){

        if(!rollUnder || rollUnder < (roll === 'under' ? 0.1: 5)) {
            return setError(prev => ({...prev, ['roll']: true}))
        }

        setRoll(roll === 'under' ? 'over' : 'under')
        if(roll === 'over') {
            setRollUnder(range)
        } else {
            setRollUnder((100 - range).toFixed(2))
        }
        setCoef(prev => prev = roll === 'under' ? 100 : 0)
    }

    function changeRange (e) {

        setError({
            roll: false,
            win: false,
            chance: false
        })

        if(!e) {
            setError(prev => ({...prev, ['chance']: true}))
        }

        let value = e.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');

        if (value < 0.1) {
            setError(prev => ({...prev, ['chance']: true}))
        }

        if(value > 95) {
            setRange((95).toFixed(2))
            setWinnigs((96 / 95).toFixed(2))
            if(roll === 'under') {
                setRollUnder((95).toFixed(2))
                
            } else {
                setRollUnder(5)
            }
        } else {
            let str = value.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setRange(str.join('.'))
                setWinnigs((96 / str.join('.')).toFixed(2))
                if(roll === 'under') {
                    setRollUnder(str.join('.'))
                } else {
                    setRollUnder(100 - str.join('.'))
                }
            }
        }

    }

    function changeWinnings(e) {

        setError({
            roll: false,
            win: false,
            chance: false
        })

        if(!e) {
            setError(prev => ({...prev, ['win']: true}))
        }

        let value = e.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');

        if(value < 1.02) {
            setError(prev => ({...prev, ['win']: true}))
        }
        if(value > 960) {
            setWinnigs(960)
            setRange(0.1)
            if(roll === 'under') {
                setRollUnder(5)
            } else {
                setRollUnder(95)
            }
        } else {
            let str = value.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setWinnigs(str.join('.'))
                setRange((96 / str.join('.')).toFixed(2))
                if(roll === 'under') {
                    setRollUnder((96 / (str.join('.'))).toFixed(2))
                } else {
                    setRollUnder((100 - 96 / str.join('.')).toFixed(2))
                }
            }
        }
    }

    function changeRollUnder(e) {

        setError({
            roll: false,
            win: false,
            chance: false
        })

        if(!e) {
            setError(prev => ({...prev, ['roll']: true}))
        }

        let value = e.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');

        if(value < (roll === 'under' ? 0.1: 5)) {
            setError(prev => ({...prev, ['roll']: true}))
        }

        if(roll === 'under') {
            if(value > 95) {
                setRollUnder(95)
                setRange(95)
                setWinnigs((96 / 95).toFixed(2))
            } else {
                let str = value.split('.')
                if(str[1] && str[1].length > 2) {
                    return
                } else {
                    setRollUnder(str.join('.'))
                    setRange(str.join('.'))
                    setWinnigs((96 / str.join('.')).toFixed(2))
                }
            }

        } else {
            if(value > 99.9) {
                setRollUnder(99.9)
                setRange(0.1)
                setWinnigs((96 / 0.1).toFixed(2))
            } else {
                let str = value.split('.')
                if(str[1] && str[1].length > 2) {
                    return
                } else {
                    setRollUnder(str.join('.'))
                    setRange((100 - str.join('.')).toFixed(2))
                    setWinnigs((96 / ((100 - str.join('.')).toFixed(2))).toFixed(2))
                }
            }
        }

    }

    return (
        <div className="dice_game">
            <div className={`dice_game_window ${result ? result.totalPayout > 0 ? 'win' : 'lose' : ''}`}>

                <LastResults />

                <div className="range_slider_container">
                    <div className="range_slider_turn" style={roll === 'under' ? {} : {transform: 'scale(-1, 1)'}}>
                        <div className="value_track">
                            <div className='track_fill green' style={{width: `${range}%`}}></div>

                            <div className='track_fill red' style={{width: `${100 - range}%`}}></div> 
                        </div>
                        
                        <div 
                            className={`game_result ${result ? 'active': ''} ${result.totalPayout > 0 ? 'win' : 'lose'}`} 
                            style={{left: `${roll === 'under' ? result.diceResult/100 : Math.abs(result.diceResult / 100 - 100)}%`}}
                        >
                            <div className="value">
                                <span style={{transform: `${roll === 'over'? 'scale(-1, 1)' : 'scale(1)'}`}}>{result.diceResult/100}</span>
                            </div>

                            <div className="triangle"></div>
                        </div>
                        
                        <div className="percent_show active" style={{left: `${range}%`}}>
                            <div className="triangle"></div>
                            <div className="value" style={{transform: `${roll === 'over'? 'scale(-1, 1)' : 'scale(1)'}`}}>{range}</div>
                        </div>

                        <img className='thumb' src={thumb} alt="" style={{left: `${range}%`}}/>
                        <input ref={range_slider} className='' type="range" min='0.1' max='95' step='0.01' value={range} onInput={changeValue}/> 
                    </div>
                </div>

                <div className="statistics">
                    <div className="statistics_item">
                        {error.win && <ErrorWindow text={'Minimum is 1.02'}/>}
                        <label htmlFor="" className="stat_label">Winnings</label>
                        <div className={`stat_block main_card ${error.win && 'error'}`}>
                            <input className='stat_input' type="text" value={winnings} onChange={(e) => changeWinnings(e.target.value)} onBlur={(e) => !e.target.value || e.target.value < 1.02 ? setError(prev => ({...prev, ['win']: true})) : setError(prev => ({...prev, ['win']: false}))}/>
                            <div className="dice_icon_Vectorcross"></div>
                        </div>
                    </div>

                    <div className='statistics_item'>
                        {error.roll && <ErrorWindow text={roll === 'under' ? 'Minimum is 0.1': 'Minimum is 5'}/>}
                        <label htmlFor="" className="stat_label">Roll {roll}</label>
                        <div className={`stat_block main_card ${error.roll && 'error'}`}>
                            <input className='stat_input' type="text" value={rollUnder}  onChange={(e) => changeRollUnder(e.target.value)} onBlur={(e) => !e.target.value || e.target.value < (roll === 'under' ? 0.1: 5) ? setError(prev => ({...prev, ['roll']: true})) : setError(prev => ({...prev, ['roll']: false}))}/>
                            <div className="roll_button" onClick={rollChange}>
                                <img src={arrows} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="statistics_item">
                        {error.chance && <ErrorWindow text={'Minimum is 0.1'}/>}
                        <label htmlFor="" className="stat_label">Win Chance</label>
                        <div className={`stat_block main_card ${error.chance && 'error'}`}>
                            <input className='stat_input' type="text" value={range} onChange={(e) => changeRange(e.target.value)} onBlur={(e) => !e.target.value || e.target.value < 0.1 ? setError(prev => ({...prev, ['chance']: true})) : setError(prev => ({...prev, ['chance']: false}))}/>
                            <div className="dice_icon_percent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiceGame;