import { useEffect, useState } from "react";
import Api from "../../../../Api";
import Notice from "../../../../Notice";
import Title from "../../../../mainTitle";


export default function useWithdraw() {

    const [botItems, setBotItems] = useState([])
    const [load, setLoad] = useState(false)
    const [totalCost, setTotalCost] = useState(0)
    const [totalCostList, setTotalCostList] = useState([])
    const [filter, setFilter] = useState('all')
    const [priceOrder, setPriceOrder] = useState('ALL PRICES')
    const [search, setSearch] = useState('')

    useEffect(() => {
        
        (async () => {

            let res = await Api.asyncGet('api/v1/items-bots/all');
            if (res === 'error') {
                Notice.Send({ type: 'error', text: 'Ошибка сервера' });
                return;
            }

            res = getAllItems(res)
            setBotItems(structuredClone(res))
            setLoad(true)

        })()

        Title.setListener('title', 'WITHDRAW')
    }, []);

    const addItem = (item) => {
        setTotalCostList(prev => [...prev, item])
        setTotalCost(prev => Math.round(Math.round(prev * 100) + Math.round(item.item.priceInCoins * 100))/100)
    }

    const deleteItem = (item) => {
        setTotalCostList(prev => prev.filter(el => el.index !== item.index))
        setTotalCost(prev => Math.round(Math.round(prev * 100) - Math.round(item.item.priceInCoins * 100))/100)
    }

    const getAllItems = (list) => {
        
        let result = []

        if(list?.length) {
            list.forEach((el) => {
                for (let i = 0; i < el.quantity; i++) {
                    result.push({...el})
                }
            })
    
            result.forEach((el, i) => {
                el.index = i
            })

            result = sortByPrice(result)
        }

        return result
    }

    const sortByPrice = (array) => {
        let list = array.sort((a, b) => b.item.priceInCoins - a.item.priceInCoins);
        return list
    }

    const orederBy = (filter) => {
        setPriceOrder(filter)
    }

    return {load, addItem, deleteItem, totalCost, setFilter, filter, totalCostList, botItems, search, priceOrder, orederBy, setSearch};
}