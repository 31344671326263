import React from 'react';
import Store from '../../../Store';

const CaseEditDtn = ({caseInfo}) => {
    function openAdminPopup() {
        document.getElementById('adminAddCases').classList.add('open')
        document.querySelector('html').classList.add('hidden')

        Store.setListener('caseToEdit', caseInfo)
    }


    return (
        <button className="common_button red" onClick={() => openAdminPopup()}>
            <div className='admin_icon_pencil'></div>
        </button>
    );
};

export default CaseEditDtn;