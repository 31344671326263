import React from 'react';

import new_case from './../../../images/new_case.svg'

const RouletteItem = ({el, round, index}) => {

    return (
        <div className={`roulete_item ${round === index ? 'green' : round > index || round === 'end' ? 'disable' : 'white'}`}>
            <img src={el.image} alt="" />
        </div>
    );
};

export default RouletteItem;