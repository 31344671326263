import React from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import useDashBoard from '../hooks/useDashBoard';
import AvailableFunds from './dash_board/AvailableFunds';
import DashboardFilters from './dash_board/DashboardFilters';

const Dashboard = () => {

    const {load, params, dash, filter, changeFilter, increaseDate, decreaseDate} = useDashBoard()

    return (
        <div className="dashboard_grid_item dashboard">

            <DashboardFilters {...{filter, changeFilter, increaseDate, decreaseDate, params}}/>

            <div className="dashboard_item">
                <div className="grid_nav_item_header ">
                    <div className="game_icon">
                        <div className="promo_icon_all_referees"></div>
                    </div>
                    <h4 className='game_title'>All Referees</h4>
                    <div className="decor"></div>
                </div>

                <div className="dashboard_item_window">
                    <h5 className="title">
                        Amount: {dash ? dash.referralsAmount : '0'}
                    </h5>
                    <p className='descript'>-(Equal)<span>vs prev. year</span></p>
                </div>
            </div>

            <div className="dashboard_item">
                <div className="grid_nav_item_header ">
                    <div className="game_icon">
                        <div className="promo_icon_comission"></div>
                    </div>
                    <h4 className='game_title'>Commission paid</h4>
                    <div className="decor" ></div>
                </div>

                <div className="dashboard_item_window">
                    <div className="coins descript">
                        <img src={coin} alt="" />
                        <p>{dash ? dash.commissioned : '0'}</p>
                    </div>
                    <p className='descript'>-(Equal)<span>vs prev. year</span></p>
                </div>  
            </div>

            <div className="dashboard_item">
                <div className="grid_nav_item_header ">
                    <div className="game_icon">
                        <div className="promo_icon_deposit"></div>
                    </div>
                    <h4 className='game_title'>Total deposited</h4>
                    <div className="decor"></div>
                </div>

                <div className="dashboard_item_window">
                    <div className="coins descript">
                        <img src={coin} alt="" />
                        <p>{dash ? dash.totalDeposited : '0'}</p>
                    </div>
                    <p className='descript'>-(Equal)<span>vs prev. year</span></p>
                </div>
            </div>

            <AvailableFunds />

        </div>
    );
};

export default Dashboard;