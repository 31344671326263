import React from 'react';

const DailyFree = () => {
    return (
        <div className="daily_free_block">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="rewards_icon_daily_free"></div>
                </div>   
                <h4 className='game_title'>Daily free cases</h4>
                <div className="decor pink"></div>
            </div>
            <div className="daily_free_window">
                <h2 className="daily_title">
                    DAILY FREE CASES
                </h2>
                <p className="warning">
                    Levelling up should not be seen as an investment, daily frees are prone to change at all times, do not expect that they will stay.
                </p>
                <h3 className="base_rewards">
                    The base rewards (average payout per open) are:
                </h3>
                <p className="levels">
                    Level 2-0.01 (very low) | Level 10-0.05 | Level 20-0.1 | Level 30-0.24 | Level 40-0.66| Level 50-1.76| Level 60-4.72
                </p>
            </div>
        </div>
    );
};

export default DailyFree;