const listener = {}

const Title = {}

Title.useListener = (name, func) => {
    return listener[name]=func
}

Title.setListener = (name, data) => {
    return listener[name](data)
}

export default Title;