import React from 'react';

const Sets = ({ setMode, setPlayers}) => {

    return (
        <div className="create_battle_sets">
            <div className="filters">
                <button className="common_button full noborder flex active" onMouseDown={(e) => setMode(e, 'PUBLIC')}>
                    <div className="case_btl_icon_mark"></div>
                    Public battle
                </button>
                <button className="common_button full noborder flex " onMouseDown={(e) => setMode(e, 'PRIVATE')}>
                    <div className="case_btl_icon_mark"></div>
                    Private battle
                </button>
            </div>

            <div className="battle_type">
                <button className="common_button full noborder flex active" onMouseDown={(e) => setPlayers(e, '1vs1')}>
                    <div className="case_btl_icon_mark"></div>
                    2
                </button>
                <button className="common_button full noborder flex" onMouseDown={(e) => setPlayers(e, '1vs1vs1')}>
                    <div className="case_btl_icon_mark"></div>
                    3
                </button>
                <button className="common_button full noborder flex" onMouseDown={(e) => setPlayers(e, '1vs1vs1vs1')}>
                    <div className="case_btl_icon_mark"></div>
                    4
                </button> 
                <button className="common_button full noborder flex" onMouseDown={(e) => setPlayers(e, '2vs2')}>
                    <div className="case_btl_icon_mark"></div>
                    2x2
                </button>
            </div>
        </div>
    );
};

export default Sets;