import React from 'react';
import BtnAdminAddNewItem from './BtnAdminAddNewItem';
import BtnAdminAddNewCase from './BtnAdminAddNewCase';
import BtnAdminAddNewCategory from './BtnAdminAddNewCategory';

const AdminPanel = () => {
    return (
        <div className='admin_panel'>
            <BtnAdminAddNewCase />
            <BtnAdminAddNewItem />
            <BtnAdminAddNewCategory />
        </div>
    );
};

export default AdminPanel;