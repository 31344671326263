import React, { useEffect, useRef, useState } from 'react';
import Store from '../../../../Store';
import HeadSplider from './HeadSplider';
import MainSplider from './MainSplider';
import FooterSplider from './FooterSplider';

import './css/skin_slider.css'

const Modal = () => {

    const [winSkins, setWinSkins] = useState(false)
    const [open, setOpen] = useState(false)
    const [background, setBackground] = useState('')

    Store.useListener('WIN_MODAL_UPGRADE', (items) => {
        setWinSkins(items)
        setOpen(prev => prev = true)
    })

    const head = useRef()
    const main = useRef()
    const footer = useRef()

    function closePopUp () {
        setOpen(prev => prev = false)
        document.querySelector('html').classList.remove('hidden')
        setWinSkins(false)
    }

    useEffect(() => {
        if(open) {
            main.current.sync(head.current.splide);
            main.current.sync(footer.current.splide);
        }
    }, [open, main, head, footer])

    useEffect(() => {

        let splide;

        if(main.current) {
            splide = main.current.splide;
            
            if(winSkins?.length) {
                console.log(winSkins)
                setBackground(winSkins[0].type.type)
    
                splide.on('moved', (newIndex) => {
                    setBackground(winSkins[newIndex].type.type)
                });
            }

        }
        
        return () => {
            if(splide) {
                splide.off('moved');
            }
        };
        
    }, [winSkins]);

    if(!open) return null

    return (
        <div className='skin_pop_up_wrapper open' onMouseDown={closePopUp}>
            <div className="skin_pop_up_scroll"> 
                <div className="skin_pop_up" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="upgrade_icon_upgrade"></div>
                        </div>   
                        <h4 className='game_title'>Success</h4>
                        <div className="decor pink" onMouseDown={closePopUp}></div>
                    </div>

                    <div className={`skins_popup_grid ${background}`}>

                        <HeadSplider winSkins={winSkins} head={head}/>

                        <MainSplider winSkins={winSkins} main={main}/>

                        <FooterSplider winSkins={winSkins} setWinSkins={setWinSkins} footer={footer} closePopUp={closePopUp}/>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Modal;