import React from 'react';

const AdminAllUserBurgerBtn = () => {

    function openAdminPopup() {
        document.getElementById('adminAllUsers').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <p className='mobile'  onClick={() => openAdminPopup()}>
            Users
            <div className="admin_icon_users"></div>
        </p>
    );
};

export default AdminAllUserBurgerBtn;