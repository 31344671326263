import React, { useState } from 'react';
import Api from '../../../Api';
import Store from '../../../Store';
import Notice from '../../../Notice';

const CategoryDelBtn = ({id, closeAdminPopup}) => {
    const [load, setLoad] = useState(true)

    function deleteCategory(e) {
        (async () => {
            setLoad(prev => prev = false)
            await fetch(`${Api.url}/api/v1/categories/${id}`, {
                method: 'DELETE',
                credentials: 'include',
            })
            .then(res => {
                if (!res.ok) {
                    return 'error'
                }
                return res.json()
            })
            .then(data => {
                setLoad(prev => prev = true)

                if(data === 'error'){
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
    
                Store.setListener('UPDATE_CATEGORY_LIST', id)
                Store.setListener('UPDATE_CATEGORY_LIST_INCASE', id)
    
                closeAdminPopup()
    
                Notice.Send({type: 'error', text: 'Category deleted'})
            })
            .catch(error => console.log(error))
        })()
    }

    return (
        <button className="common_button full red delete" onClick={(e) => deleteCategory(e)}>
            {load ? 'DELETE CATEGORY' : 'Loading...'}
        </button>
    );
};

export default CategoryDelBtn;