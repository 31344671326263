import React from 'react';

const BtnAdminAddNewCase = () => {

    function openAdminPopup() {
        document.getElementById('adminAddCases').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className="common_button green" onClick={openAdminPopup}>Add New Case</button>
    );
};

export default BtnAdminAddNewCase;