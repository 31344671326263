import React from 'react';
import { NavLink } from 'react-router-dom';

const HistoryHeader = () => {
    return (
        <div className="game_filters">
            <NavLink className="common_button full flex" to='/user/game_history/' end>
                <div className="case_btl_icon_mark"></div>
                Case
            </NavLink>
            
            <NavLink className="common_button full flex" to='case_battles'>
                <div className="case_btl_icon_mark"></div>
                Case battles
            </NavLink>

            <NavLink className="common_button full flex" to='roulette'>
                <div className="case_btl_icon_mark"></div>
                Roullette
            </NavLink>

            <NavLink className="common_button full flex" to='crash'>
                <div className="case_btl_icon_mark"></div>
                Crash
            </NavLink>

            <NavLink className="common_button full flex" to='dice'>
                <div className="case_btl_icon_mark"></div>
                Dice
            </NavLink>

            <NavLink className="common_button full flex" to='upgrade'>
                <div className="case_btl_icon_mark"></div>
                Upgrade
            </NavLink>
        </div>
    );
};

export default HistoryHeader;