import React, { useState } from 'react';
import Store from '../Store';

const DropsFlex = () => {

    const [isOpen, setIsOpen] = useState(false)
    Store.useListener('dropFlex', setIsOpen)

    return <div className={`drops_flex ${isOpen ? 'close' : ''}`}></div>
};

export default DropsFlex;