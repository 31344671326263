import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import avatar from './../../../images/avatar.svg'
import helmet from './../images/helmet.png'
import helmet2 from './../images/helmet2.png'
import helmet3 from './../images/helmet3.png'
import header from './../images/header.png'
import truba from './../images/truba.png'
import Api from '../../../Api';
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import Avatar from '../../../components/avatar/Avatar';
import Price from '../../../components/price/Price';

const PlaceBetList = ({myUpdateBet, myBet, loader, sortByBet, newList, block, setBetList, updateList, stats, type, func, coef, newBet}) => {

    const [load, setLoad] = useState(false)

    useEffect(() => {

        if(loader) {
            (async () => {
                const query = {
                    size: 50,
                    page: 1,
                    betPosition: type
                }
        
                const params = new URLSearchParams(query).toString();
    
                let res = await Api.asyncGet(`api/v1/game-roulette?${params}`)
    
                if (res === 'error') {
                    return
                }
    
                res.items.forEach((element) => {

                    newBet(type, element)

                })
    
                setLoad(true)
            })()
        }

    }, [loader])

    return (
        <div className="place_bet_list">
            <div className={`place_bet_header ${type}`}>
            <button className={`place_bet_header_button ${type} ${myUpdateBet[type] > 0 ? 'not-active' : ''}`} disabled={myUpdateBet[type] > 0 ? false : block} onClick={(e) => func(type)}>
                <p className={` ${myUpdateBet[type] > 0 ? 'plus' : myUpdateBet[type] < 0 ? 'minus' : ''}`}>{myUpdateBet[type] ? <Price price={myUpdateBet[type]}/> : myBet[type] ?  <Price price={myBet[type]}/>: 'Place Bet' }</p>
            </button> 
                <div className="win_rat_icons" disabled={myUpdateBet[type] > 0 ? false : block}>
                    <h5 className="win_rat">{coef}x</h5>
                    {type === 'red' && 
                    <>
                        <div className="win_rat_icons_item red1">
                            <img src={helmet2} alt="" />
                        </div>
                        <div className="win_rat_icons_item red2">
                            <img src={helmet} alt="" />
                        </div>
                    </>
                    }
                    {type === 'black' && 
                    <>
                        <div className="win_rat_icons_item black1">
                            <img src={header} alt="" />
                        </div>
                        <div className="win_rat_icons_item black2">
                            <img src={helmet3} alt="" />
                        </div>
                    </>
                    }
                    {type === 'green' && 
                    <>
                        <div className="win_rat_icons_item green">
                            <img src={truba} alt="" />
                        </div>
                    </>
                    }
                    {type === 'adjacent' && 
                    <>
                        <div className="win_rat_icons_item black2">
                            <img src={helmet3} alt="" />
                        </div>
                        <div className="win_rat_icons_item red2">
                            <img src={helmet} alt="" />
                        </div>
                    </>
                    }
                    
                </div>
            </div>
            
            {stats ? 
                <div className={`place_bet_list_item main ${type}`}>
                    <h5 className="place_bet_list_title">Total bet:</h5>
                    <div className={`coin`}><Price price={stats?.bets.total}/><img src={coin} alt="" /></div>
                </div>
            :
                <div className={`place_bet_list_item main ${type}`}>
                    <h5 className="place_bet_list_title">Total bet:</h5>
                    <div className={`coin`}>0.00<img src={coin} alt="" /></div>
                </div>
            }
            
                {load ? 
                    <>
                        {updateList?.length ? 
                            <div className={`list_container ${type}`}>
                                {updateList.map((el, i) => (
                                    <div className={`place_bet_list_item ${(i + 1) % 2 === 0 ? 'second' : ''}`} key={i}>
                                        <Avatar 
                                            img={el.avatar}
                                            lvl={el.level}
                                            percent={(el.experience / el.experienceBorderTillLevel) * 100}
                                            color={'#30dba8'}
                                            size={24}
                                            rad={11}
                                            dasharray={69}
                                            lvlFont={8}
                                            lvlPos={-3}
                                            lvlSize={14}
                                        />
                                        <h4 className="name">{el.username}</h4>
                                        <div className={`coin ${el.bet >= 0 ? 'plus' : 'minus'}`}><Price price={el.bet}/> <img src={coin} alt="" /></div>
                                    </div>
                                ))}
                            </div>
                        :
                            <></>
                        }
                        {newList?.length ? 
                            <div className={`list_container ${type}`}>
                                {newList.map((el, i) => (
                                    <div className={`place_bet_list_item ${(i + 1) % 2 === 0 ? 'second' : ''}`} key={i}>
                                        <Avatar 
                                            img={el.avatar}
                                            lvl={el.level}
                                            percent={(el.experience / el.experienceBorderTillLevel) * 100}
                                            color={'#30dba8'}
                                            size={24}
                                            rad={11}
                                            dasharray={69}
                                            lvlFont={8}
                                            lvlPos={-3}
                                            lvlSize={14}
                                        />
                                        <h4 className="name">{el.username}</h4>
                                        <div className={`coin`}><Price price={el.bet}/> <img src={coin} alt="" /></div>
                                    </div>
                                ))}
                            </div>
                        :
                            <></>
                        }
                    </>
                :
                    <></>
                }
        </div>
    );
};

export default PlaceBetList;