import React, { useEffect } from 'react';
import './css/deposit.css';
import AmountBlock from './components/AmountBlock';
import DepositGrid from './components/DepositGrid';
import Title from '../../mainTitle';


const Deposit = () => {

    useEffect(() => {
        Title.setListener('title', 'DEPOSIT')
    }, [])

    return (
        <div className='deposit_block container'>
            
            <h2 className="deposit_title">
                DEPOSIT WITH CARDS
            </h2>

            <p className='warning'>Please enter the amount of balance you would like to be credited.</p>

            <AmountBlock />

            <h2 className="deposit_title">SELECT METHOD</h2>

            <DepositGrid />

        </div>
    );
};

export default Deposit;