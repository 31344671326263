import React, { useEffect, useState } from 'react';
import CommonPagination from '../../../components/pagination/CommonPagination';
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import ListItem from './ListItem';
import Store from '../../../Store';
import Games from '../../../Games';

const BetList = () => {

    let [load, setLoad] = useState(false)
    let [allBetsQuery, setAllBetsQuery] = useState({
        size: 10,
        page: 1,
        field: 'id',
        order: 'DESC'
    })
    let [allBets, setAllBets] = useState([])
    let [allBetsList, setAllBetsList] = useState([])

    Store.useListener('updateList', setAllBetsList)
    Store.useListener('updateTotal', setAllBets)

    useEffect(() => {

        (async () => {
            const params = new URLSearchParams(allBetsQuery).toString();

            let res = await Games.gameHistory(`api/v1/game-dice/me/?${params}`)

            if(res === 'error') {
                return 
            }
            
            setAllBetsList(res.items)
            setAllBets(res)
            setLoad(true)
        })()

    }, [allBetsQuery])

    return (
        <div className="all_bet">
            <div className="all_bet_list_wrapper">
                
                {load ? 
                    <>
                        <div className="all_bet_list">
                            <div className="all_bet_list_item header">
                                <div className="user">User</div>
                                <div className="bet_id">Bet info</div>
                                <div className="bet">Bet</div>
                                <div className="time">Time</div>
                                <div className="multiplier">Multiplier</div>
                                <div className="payout">Payout</div>
                            </div>
                            {allBetsList && allBetsList.length > 0 ?
                                <>
                                    {allBetsList.map((el, i) => (
                                        <ListItem key={i} el={el} index={i + 1}/>
                                    ))}
                                </>
                            :
                                <NotItems />
                            }
                        </div>
                        
                    </>
                :
                    <LoadingItems />
                }
                <CommonPagination maxPage={Math.ceil(allBets.total / allBetsQuery.size) || 1} params={allBetsQuery} setParams={setAllBetsQuery} />
            </div>
            
        </div>  
    );
};

export default BetList;