import React from 'react';
import s from './s.svg';

import './css/coin.css';

const Coin = ({size}) => {
    return (
        <div className="common_coin"
            style={{
                minWidth: `${size ? size : 15}px`,
                minHeight: `${size ? size : 15}px`,
                maxHeight: `${size ? size : 15}px`,
                maxWidth: `${size ? size : 15}px`,
                width: `${size ? size : 15}px `,
                height: `${size ? size : 15}px `,
            }}
        >
            <img style={{width: '33%'} } src={s} alt="" />
        </div>
    )
};

export default Coin;