import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import EditItemBtn from '../EditItemBtn';

const ItemsStoreCard = ({card, setNewList, newList}) => {

    let [isAdd, setIsAdd] = useState(false)

    function addItems(item) {
        let existItem = newList.find(el => el.id === item.id) 
        if(existItem) {
            console.log('уже есть в кейсе', existItem)
            return
        }
        setNewList(prev => [...prev, item])

        setIsAdd(true)
    }

    function deleteItem(id) {
        setNewList(prev => prev.filter((el) => el.id !== id))
        setIsAdd(false)
    }

useEffect(() => {
    if(newList.length > 0) {
        let existItem = newList.find(el => el.id === card.id) 
        if(existItem) {
            setIsAdd(true)
        } else {
            setIsAdd(false)
        }
    } else {
        setIsAdd(false)
    }
}, [newList])

    return (
        <div className="item" id={card.id}>

            <div className="avatar">
                <img 
                    src={card.image}
                    className={card.type}
                    alt=""
                />
                {!isAdd ?

                <button className="icon_button add" onClick={() => addItems(card)}>
                    <div className="case_btl_icon_plus"></div>   
                </button>
                :
                <button className="icon_button add" onClick={() => deleteItem(card.id)}>
                    <div className="case_btl_icon_minus"></div>   
                </button>

                }
                
                <EditItemBtn item={card}/>
            </div>

            <div className="info">
                <div> 
                    <h3>Price</h3>
                    <div className="info_item main_card">
                        <img src={coin} alt="" />
                        {card.price}
                    </div>
                </div>

                <div> 
                    <h3>Item name</h3>
                    <div className="info_item main_card">{card.name}</div>
                </div>
            </div>

        </div>
    );
};

export default ItemsStoreCard;