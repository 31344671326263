import React from 'react';
import useAllUserFilters from '../../admin_hooks/useAllUserFilters';

const UserFilters = ({changeOrder, params, changeRole, searchUser}) => {

    const {openFilter} = useAllUserFilters()

    return (
        <>
            <div className="search_item">
                <h3>Search</h3>

                <div className="search_wrapper main_card">
                    <input 
                        type="text" 
                        placeholder='Find by name'
                        value={params.namePrefix}
                        onChange={(e) => searchUser(e)}
                    />
                    <div className="admin_icon_search"></div>
                </div>
            </div>

            <div className="user_filters">

                <div className="filter_item">

                    <h3>Fast sort</h3>

                    <div className="sort_filter_wrapper">

                        <div className="sort_name main_card">Field: <span>{params.field}</span></div>

                        <button className="icon_button" onClick={(e) => openFilter(e)}>
                            <div className="admin_icon_arrows_right"></div>
                        </button>

                        <div className="user_filter_list" onClick={(e) => changeOrder(e, 'field')}>
                            <p className='active'>id</p>
                            <p>name</p>
                        </div>

                    </div>
                </div>

                <div className="filter_item">

                    <h3>Fast sort</h3>

                    <div className="sort_filter_wrapper">

                        <div className="sort_name main_card">Order: <span>{params.order}</span></div>

                        <button className="icon_button " onClick={(e) => openFilter(e)}>
                            <div className="admin_icon_arrows_right"></div>
                        </button>

                        <div className="user_filter_list" onClick={(e) => changeOrder(e, 'order')}>
                            <p className='active'>ASC</p>
                            <p>DESC</p>
                        </div>

                    </div>
                </div>

                <div className="filter_item role">

                    <h3>Search by role</h3>

                    <div className="roles_flex">
                        <div className="icon_button pink" onClick={(e) => changeRole('ALL')}>
                            <div className="admin_icon_userCrown"></div>
                        </div>
                        <div className="icon_button red" onClick={(e) => changeRole('ADMIN')}>
                            <div className="admin_icon_crown"></div>
                        </div>
                        <div className="icon_button blue" onClick={(e) => changeRole('MODERATOR')}>
                            <div className="admin_icon_help"></div>
                        </div>
                        <div className="icon_button orange" onClick={(e) => changeRole('PLAYER')}>
                            <div className="admin_icon_player"></div>
                        </div>
                    </div>

                </div>


            </div>
        </>
        
    );
};

export default UserFilters;