import React, { useEffect} from 'react';
import './css/cases.css';
import LastDrop from './components/LastDrop';

import Title from '../../mainTitle';
import CaseStore from './components/case_store/CaseStore';
import AdminPanel from './components/AdminPanel';


const Cases = ({role}) => {

    useEffect(() => {
        Title.setListener('title', 'Cases')
    }, [])

    return (
        <div className='cases_block container'>

            {/* <LastDrop /> */}

            {role === 'ADMIN' && <AdminPanel />}   

            <CaseStore role={role}/>

        </div>
    );
};

export default Cases;