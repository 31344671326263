import React, { useEffect, useState } from 'react';
import CaseCard from './CaseCard';

import left_decor from './../images/category_decor_left.svg'
import right_decor from './../images/category_decor_right.svg'

const CaseList = ({store, category, newList, plusCard, minusCard}) => {

    let [caseCat, setCaseCat] = useState([])

    useEffect(() => {

        if (store) {
            let cases = store && store?.filter((cases) => cases.categories === category.name)
            setCaseCat(cases)
        }

    }, [store])

    return (
        <>
            {caseCat?.length ? 

                <div className="take_case_list">

                    <div className="info">
                        <img src={left_decor} alt="" className="left_decor" />
                        <img src={right_decor} alt="" className="right_decor" />
                        <img src={category.image} alt="" className="icon_cat" />
                        <p>{category.name}</p>
                    </div>

                    {caseCat?.length && caseCat.map((card) => (
                        <CaseCard key={card.id} card={card} newList={newList} plusCard={plusCard} minusCard={minusCard}/>
                    ))}
                        
                </div>
                :
                <></>
            }   
        </>
    );
};

export default CaseList;