import React, { useState } from 'react';
import './../css/case_battles.css';
import Store from '../../../Store';
import docs from './../../../pages/promo/images/docs.svg'
import Notice from '../../../Notice';


const CaseBattlesPreviewPasswordPopUp = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [password, setPassword] = useState('')
    Store.useListener('openPreviewPassword', setIsOpen)
    Store.useListener('previewPassword', setPassword)

    const closePopUp = () => {
        setIsOpen(false)
        setPassword('')
        document.querySelector('html').classList.remove('hidden')
    }

    const copy = async () => {

        Notice.Send({type: 'success', text: 'Copied to clipboard!'})

        await navigator.clipboard.writeText(password);
    }

    return (
        <div className={`battle_password_popup_wrapper ${isOpen ? 'open' : ''}`} onMouseDown={closePopUp}>
            <div className="battle_password_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="case_btl_icon_folder"></div>
                    </div>   
                    <h4 className='game_title'>Password</h4>
                    <div className="decor pink" onMouseDown={closePopUp} style={{cursor: 'pointer'}}></div>
                </div>
                <div className="battle_password">
                    <div className="preview_password main_card">
                        <div className="copy" onMouseDown={copy}>
                            <img src={docs} alt="" />
                        </div>
                        <p>{password}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaseBattlesPreviewPasswordPopUp;