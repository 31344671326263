import React from 'react';

const AddCard = ({ func, dragDrop, dragOver, dragEnd}) => {
    function openTakeCase() {
        document.querySelector('.take_case_popup_wrapper').classList.add('open')
        document.querySelector('html').classList.add('hidden')
        func()
    }

    return (
        <div 
            data-add='add'
            className="case_list_item add"  
            onClick={openTakeCase} 
            onDragOver={(e) => dragOver(e)} 
            onDragEnd={(e) => dragEnd(e)}
            onDragLeave={(e) => dragEnd(e)}
            onDrop={(e) => dragDrop(e)}
        >
            <div className="case_btl_icon_plus"></div>
        </div>
    );
};

export default AddCard;