import React, { useEffect, useState } from 'react';


import coin from './../../../fonts/main_icon_fonts/coin.svg'

const CaseCard = ({card, newList,  plusCard, minusCard}) => {
    const [count, setCount] = useState(0)

    useEffect(() => {

        if(newList.length > 0) {
            let cardItem = newList.find(el => el.id === card.id)
            if(cardItem) {
                setCount(cardItem.count)
            } else {
                setCount(0)
            }
        } else {
            setCount(0)
        }
        
    }, [newList])


    return (
        <div className="case_card">

            <img src={card.image} alt="" />

            <div className="price">
                <img src={coin} alt="" />
                {card.price}
            </div>

            <p>{card.name}</p>

            <div className="amount_block main_card">

                <button className="icon_button red" onClick={() => minusCard(card.id, setCount)}>
                    <div className='account_icon_minus'></div>
                </button>

                <h4 className='amount'>{count}</h4>

                <button className="icon_button green" onClick={(e) => plusCard(card, setCount)}>
                    <div className="account_icon_plus" ></div>
                </button>

            </div>
            
        </div>
    );
};

export default CaseCard;