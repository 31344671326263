import Store from "./Store"
import Notice from "./Notice"

const Api = {}

Api.url = 'https://api.baldmonkey.shop'
Api.urlWs = 'wss://api.baldmonkey.shop'



Api.refresh = async () => {
    try {
        let res = await fetch(`${Api.url}/api/v1/auth/refresh`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
            credentials: 'include'
        })

        if(res.status === 400) {
            return 'no balance'
        }

        if(res.status === 401) {
            return 'unAuthorization'
        }

        if(!res.ok) {
            return 'error'
        }

        res = await res.json()

        return 'ok'

    } catch(e) {
        return '401'
    }
}

/*-----авторизация-----*/
Api.asyncAuth = async () => {
    try {
        let res = await fetch(`${Api.url}/api/v1/auth/me/profile`, { //////Check token
            credentials: 'include'
        })

        if(res.status === 401) {

            let ref = await Api.refresh()

            if (ref === 'unAuthorization') {
                localStorage.removeItem('client')
                return 'error'
            }

            if(ref !== 'ok') {
                return 'error'
            }

            res = await fetch(`${Api.url}/api/v1/auth/me/profile`, {
                credentials: 'include'
            })

            if(!res.ok) {
                return 'error'
            }
            
            res = await res.json()
            return res
        }

        if(!res.ok) {
            return 'error'
        }
        
        res = await res.json()
        return res

    } catch(e) {
        return 'error'
    }
}

Api.asyncGet = async (path) => {
    try {
        let res = await fetch(`${Api.url}/${path}`)

        if(res.status != 200) {
            return 'error'
        }
        
        res = await res.json()

        return res

    } catch(e) {
        return 'error'
    }
}

Api.asyncGetCred = async (path) => {
    try {
        let res = await fetch(`${Api.url}/${path}`, {
            credentials: 'include'
        })

        if(res.status === 401) {
            let ref = await Api.refresh()

            if (ref === 'unAuthorization') {
                localStorage.removeItem('client')
                return 'unAuth'
            }

            if(ref !== 'ok') {
                return 'error'
            }

            res = await fetch(`${Api.url}/${path}`, {
                credentials: 'include'
            })
        }

        if(res.status === 400) {
            return 'no balance'
        }

        if(res.status !== 200) {
            Notice.Send({type: 'error', text: 'Server error'})
            return 'error'
        }
        
        res = await res.json()

        return res

    } catch(e) {
        return 'error'
    }
}

Api.asyncGetWithMessage = async (path) => {
    try {
        let res = await fetch(`${Api.url}/${path}`, {
            credentials: 'include'
        })
        
        res = await res.json()

        if(res.statusCode === 401) {
            let ref = await Api.refresh()

            if (ref === 'unAuthorization') {
                localStorage.removeItem('client')
                return 'unAuth'
            }

            if(ref !== 'ok') {
                return 'error'
            }

            res = await fetch(`${Api.url}/${path}`, {
                credentials: 'include'
            })
            
            res = await res.json()

            return res
        }

        return res

    } catch(e) {
        return 'error'
    }
}

Api.asyncPost = async (path) => {
    try {
        const res = await fetch(`${Api.url}/${path}`, {
            method: 'POST',
            headers: {},
            credentials: 'include'
        })

        return res.json()

    } catch(e) {
        return 'error'
    }
}

Api.updateAccount = async () => {
    try {

        const profileId = JSON.parse(localStorage.getItem('client')).playerAccount.id
        const clientId = JSON.parse(localStorage.getItem('client')).id

        let account = await fetch(`${Api.url}/api/v1/player-accounts/${profileId}`)
        let balance = await fetch(`${Api.url}/api/v1/billing-accounts?clientId=${clientId}`)

        if(account.status === 200) {
            account = await account.json()
            Store.setListener('updateAccount', account)
        } 

        if(balance.status === 200) {
            balance = await balance.json()
            Store.setListener('updateBalance', balance)
        }

        return null

    } catch(e) {
        return 'error'
    }
}

export default Api;
