import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../components/button/Button';
import Radio from '../../../components/radio/Radio';


const Filters = ({radio, filterList, gameHistory}) => {

    return (
        <div className="case_batles_filters">

            <div className="title">
                <h2>Case Battles</h2>
                <div className="active_battles">
                    <span>{gameHistory?.length}</span>
                    <p>active battles</p>
                </div>
            </div>

            <div className="filters">
                <div className="filter_item">
                    <p>Highest price</p>
                    <Radio radio={radio === 1 ? true : false} callback={filterList} />
                </div>
                <div className="filter_item">
                    <p>Lowest price</p>
                    <Radio radio={radio === 2 ? true : false} callback={filterList} />
                </div>
            </div>

            <NavLink to='/case/create_battle'><Button mode={'flex green full'} content={'Create battle'}/></NavLink>

        </div>
    );
};

export default Filters;