import React, {useEffect, useState} from 'react';

import './css/inventory.css'
import Store from '../../../../Store';
import { Search } from './components/Search';
import InventoryList from './components/InventoryList';
import useInventory from './useInventory';
import InventoryPopUp from './components/InventoryPopUp';

const Inventory = ({profileId}) => {

    const inventory = useInventory(profileId)

    return (
        <div className='inventory_block'>
            <Search block={inventory.block} search={inventory.search} searchItem={inventory.searchItem} order={inventory.order} orderBy={inventory.orderBy}  openPopUp={inventory.openPopUp}/>
            <InventoryList inventory={inventory}/>

            <InventoryPopUp originalList={inventory.originalList} isOpen={inventory.isOpen} closePopUp={inventory.closePoPup} selAllItems={inventory.selAllItems}/>
        </div>
    );
};

export default Inventory;