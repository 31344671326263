import { useCallback, useEffect } from "react"

export default function useFilters() {

    function closeFilters() {
        const currentList = document.querySelector('.filter_list')
        if(currentList) {
            currentList.classList.remove('open')
        }
    }

    function openFilter (e) {
        e.stopPropagation()

        const currentList = document.querySelector('.filter_list')

        currentList.classList.toggle('open')
    }

    useEffect(() => {

        window.addEventListener('click',() => closeFilters())
        
        return () => window.removeEventListener('click', () => closeFilters())
    }, [])


    useCallback(() => {
        window.removeEventListener('click', () => closeFilters())
    })

    return {openFilter}
}