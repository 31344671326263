import React, { useEffect, useState } from 'react';
import defaultImage from './../../../images/default.jpeg'
import Api from '../../../Api';

const AccountInfo = ({profile}) => {

    const [lvl, setLvl] = useState({})

    useEffect(() => {

        (async () => {
            const id = JSON.parse(localStorage.getItem('client')).playerAccount.id
            let res = await Api.asyncGet(`api/v1/player-accounts/${id}`)

            if(res === 'error') {
                return
            }

            setLvl(res)

        })()
    }, [])

    return (
        <div className="account_info">
            <div className="avatar">
                <img src={profile ? profile.user.steamProfile.avatar : defaultImage} alt="" />
            </div>

            <div className="level_progres">
                <div className="user_name_block">
                    <div className="avatar mobile">
                        <img src={profile ? profile.user.steamProfile.avatar : defaultImage} alt="" />
                    </div>
                    <div className="user_name">{profile?.user.username}</div>
                </div>

                <div className="progres_track">
                    <div className="level">{lvl ? lvl.level : '0'}</div>
                    <div className="track">
                        <div className="track_fill" style={{width: `${(lvl?.experience / lvl?.experienceBorderTillLevel) * 100}%`}}></div>
                    </div>
                    <div className="level">{lvl ? lvl.level + 1 : '0'}</div>
                </div>
            </div>
        </div>
    );
};

export default AccountInfo;