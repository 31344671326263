import React, {useEffect} from 'react';

import './css/account.css'
import PersonalInfo from './components/PersonalInfo';
import TwoFactorAuth from './components/TwoFactorAuth';
import ProfileVerification from './components/ProfileVerification';
import Store from '../../../../Store';

const Account = ({profile}) => {

    useEffect(() => {
        Store.setNumListener('page', 0)
    })

    return (
        <div className='account_block'>
            <PersonalInfo profile={profile}/>
            <TwoFactorAuth profile={profile}/>
            <ProfileVerification/>
        </div>
    );
};

export default Account;