import { useEffect, useRef, useState } from "react"
import PromoServices from "../services/PromoServices"
import Api from "../../../Api"
import Notice from "../../../Notice"
import Store from "../../../Store"

export default function useCollect(userId) {
    const [load, setLoad] = useState(true)
    const [funds, setFunds] = useState(false)
    const [error, setError] = useState({
        value: false,
        enough: false
    })
    const [coins, setCoins] = useState('')

    const getFunds = () => PromoServices.getReqCookie(`api/v1/referrals/me/privileges?clientId=${userId}`)

    useEffect(() => {
        if(userId) {
            (async () => {
                let init = await getFunds()
                if(init === 'error' || !init) {
                    setFunds(false)
                } else {
                    setFunds(init)
                    Store.setListener('AVAILABLE_FUNDS', init)
                }
            })()
        }
    }, [userId])

    function onInput(value) {
        setError(prev => ({...prev, value: false, borrow: false}))

        const max = funds.availableFundsToBorrow
       
        if(!value) {
            setError(prev => ({...prev, value: true}))
        }

       // setCoins(value)

        if(max === 0) {
            Notice.Send({type: 'error', text: 'You have no coins to borrow'})
            return
        }

        let currentValue = value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        
        if(currentValue > max) {
            setCoins(max)
        } else {
            let str = currentValue.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setCoins(str.join('.'))
            }
        }
    }

    function increase () {
        setError(prev => ({...prev, value: false,}))

        const max = funds.availableFundsToBorrow
       
        if(max === 0) {
            Notice.Send({type: 'error', text: 'You have no coins to borrow'})
            return
        }

        let value = parseInt(coins)

        value = isNaN(value) ? 0 : value;

        value = value + 1

        if(value >= max) {
            setCoins(max)
            return
        }

        setCoins(value)
    }

    function decrease() {
        setError(prev => ({...prev, value: false}))

        let value = parseInt(coins)

        value = isNaN(value) ? 0 : value;

        value = value - 1

        if(value < 0) {
            return
        }

        setCoins(value)
    }

    function sendBorrow() {
        (async () => {
            setError(prev => ({...prev, value: false, enough: false}))

            let err = false

            if(coins === '') {
                setError(prev => ({...prev, value: true}))
                err = true
            }
            if(err) {
                return
            }
            setLoad(prev => prev = false)
            await fetch(`${Api.url}/api/v1/referrals/me/borrow-funds?coinsToBorrow=${coins}`, {
                method: 'GET',
                credentials: 'include',
            })
            .then(res => {
                if(res.status === 400) {
                    return 'zero'
                }
                if(!res.ok)  {
                    return 'error'
                }
            })
            .then(data => {
                if(data === 'zero') {
                    return setError(prev => ({...prev, value: true, enough: true}))
                }
                if(data === 'error') {
                    return Notice.Send({type: 'error', text: 'Ошибка сервера'})
                }
                Notice.Send({type: 'success', text: 'Success!'})
            })
            .catch(error => console.log(error))
            setLoad(prev => prev = true)
        })()
    }

    return {load, error, setError, funds, coins, onInput, increase, decrease, sendBorrow}
}