import React from 'react';
import Avatar from '../../avatar/Avatar';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import logo from './../../images/SpinRustLogo.png'
import Price from '../../price/Price';

const ReainPoolMessage = ({message}) => {

    return (
        <div className="track_item main_card">

            <div className="avatar">
                <img src={logo} alt="" />
            </div>

            <div className="message_content">
                <div className="name_block">
                    <h4 className="name">RAINPOOL WINNERS:</h4>
                </div>

                <div className="message rain">

                    {message?.map((el) => (
                        <div className="rain_item">
                            <Avatar
                                img={el.avatar}
                                lvl={el.level}
                                percent={(el.experience / el.experienceBorderTillLevel) * 100}
                                color={'rgb(233, 63, 63)'}
                                size={24}
                                rad={11}
                                dasharray={69}
                                lvlFont={8}
                                lvlPos={-3}
                                lvlSize={14}
                            />
                            <div className="rain_name">{el.username}:</div>
                            <div className="winning">
                                {(el.winning).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                <img src={coin} alt="" />
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </div>
    );
};

export default ReainPoolMessage;