import React from 'react';
import CasesGridItem from '../CasesGridItem';

const CaseListForAdmin = ({list, role}) => {

    return (
        <>
            <div className='cases_grid_admin_title'><span>FOR ADMIN</span>  Cases with no category !</div>

            <div className='cases_grid admin'>
                {list.map((el) => (
                    <CasesGridItem key={el.id} el={el} role={role}/>
                ))}
            </div>
        </>
        
    );
};

export default CaseListForAdmin;