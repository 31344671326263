import React, { useState } from 'react';
import Api from '../../../Api';
import Store from '../../../Store';
import Notice from '../../../Notice';

const AdminItemDelBtn = ({id, closeAdminEditPopup}) => {
    const [load, setLoad] = useState(true)

    function deleteItem(e) {
        (async () => {
            setLoad(prev => prev = false)
            await fetch(`${Api.url}/api/v1/fake-item/delete/${id}`, {
                method: 'DELETE',
                credentials: 'include',
            })
            .then(res => {
                if (!res.ok) {
                    return 'error'
                }
                return res.json()
            })
            .then(data => {
                setLoad(prev => prev = true)

                if(data === 'error'){
                    console.log('error')
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
    
                Store.setListener('DELETE_ITEM', id)
                Store.setListener('DELETE_ITEM_INCASE', id)
    
                closeAdminEditPopup()

                Notice.Send({type: 'error', text: 'Item deleted'})
            })
            .catch(error => console.log(error))
        })()
    }

    return (
        <button className="common_button full red delete" onClick={(e) => deleteItem(e)}>
            {load ? 'DELETE ITEM' : 'Loading...'}
        </button>
    );
};

export default AdminItemDelBtn;