import React from 'react';
import coin from './../../../../fonts/main_icon_fonts/coin.svg'
import useAvailableFunds from '../../hooks/useAvailableFunds';
import LoadingItems from '../../../../components/LoadingItems';

const AvailableFunds = () => {

    const {funds, loadBtn, claimFunds} = useAvailableFunds()

    return (
        <div className="dashboard_item">
            <div className="grid_nav_item_header ">
                <div className="game_icon">
                    <div className="promo_icon_available"></div>
                </div>
                <h4 className='game_title'>Available funds</h4>
                <div className="decor"></div>
            </div>

            <div className="dashboard_item_window height">
                <div className="coins avail">
                    <img src={coin} alt="" />
                    <p>{funds ? funds.availableFunds : '0'}</p>
                </div>
                <button className="common_button full green" onMouseDown={() => claimFunds()}>
                    {loadBtn ? 'Claim' : 'Loading...'}
                </button>
            </div>
        </div>
    );
};

export default AvailableFunds;