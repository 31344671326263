import React, { useEffect, useState } from 'react';
import sell from './../images/sell.svg'
import coin from './../../../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../../../components/price/Price';
import { result } from 'lodash';

const InventoryPopUp = ({isOpen, closePopUp, selAllItems, originalList}) => {

    const [price, setPrice] = useState(0)

    useEffect(() => {
        
        if(originalList?.length) {

            let result = 0;

            originalList.map((el) => {
                result += el.fakeItem.price
            })

            setPrice(result)
        }

    }, [originalList])

    if(!isOpen) return null

    return (
        <div className='inventory_popup_wrapper' onMouseDown={closePopUp}>
            <div className="inventory_popup" onMouseDown={(e) => e.stopPropagation()}>

                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="admin_icon_chat"></div>
                    </div>
                    <div className='game_title'>Warning</div>
                    <div className="decor" onMouseDown={closePopUp}></div>
                </div>

                <div className="inventory_window">
                    <p>Are you sure about what you want to do?</p>

                    <div className="sell_all">
                        <img src={sell} alt="" className="sell_img" />
                        <h5>Sell for</h5>
                        <div className="price">
                            <img src={coin} alt="" className="coin" />
                            <Price price={price}/>
                        </div>
                    </div>

                    <div className="button_block">
                        <button className="common_button full flex green" onClick={selAllItems}>Yes</button>
                        <button className="common_button full flex red" onMouseDown={closePopUp}>No</button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default InventoryPopUp;