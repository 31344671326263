import React, { useEffect, useState } from 'react';
import CategoryDelBtn from './components/categories/CategoryDelBtn';
import useAdminCategories from './admin_hooks/useAdminCategories';
import LoadingItems from '../components/LoadingItems';

const EditCategories = () => {
    let {load, loadBtn, error, setError, edit, categoryParams, categoryId, changeCatParams, closeAdminPopup, confirmCategory} = useAdminCategories()

    return (
        <div className='admin_popup_wrapper' id='adminEditCategories' onMouseDown={closeAdminPopup}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_category"></div>
                        </div>
                        <div className='game_title'>{edit ? 'Modify' : 'Create'} category</div>
                        <div className="decor pink" onMouseDown={closeAdminPopup}></div>
                    </div>

                    <div className="admin_popup_content">

                        <div id='err_category_exist' className={`err_mess`} >The category allready exist!</div>

                        {load ? 
                            <>
                                <label htmlFor="">Category name</label>
                                <input 
                                    className={`admin_input ${error.name ? 'error' : ''}`}  
                                    placeholder='Empty-Head' 
                                    name='name'
                                    value={categoryParams.name} 
                                    onChange={(e) => changeCatParams(e)}
                                    onBlur={(e) => setError(prev => ({...prev, name: false}))}
                                />

                                <label htmlFor="">Icon <span>[.jpg]  (Link)</span> </label>
                                <input 
                                    className={`admin_input ${error.image ? 'error' : ''}`}  
                                    placeholder='https://i.yapx.ru/XfM5L.png' 
                                    name='image'
                                    value={categoryParams.image} 
                                    onChange={(e) => changeCatParams(e)}
                                    onBlur={(e) => setError(prev => ({...prev, image: false}))}
                                />
                            </>
                        : 
                            <LoadingItems />
                        }
 
                        
                        <button className="common_button full green" onClick={(e) => confirmCategory(e)}>
                            {loadBtn ? 'CONFIRM' : 'Loading...'}
                        </button>

                        {edit && 
                            <CategoryDelBtn id={categoryId} closeAdminPopup={closeAdminPopup}/>
                        }            

                        {/*<div className="add_to_case_block">
                            <h2>Add case to category</h2>

                            <div className="add_to_case_grid">

                                <div className="case_item">

                                    <img src={caseItem} alt="" />

                                    <div className="price">
                                        <img src={coin} alt="" />
                                        2999
                                    </div>

                                    <div className="case_title">
                                        My name is Borat.
                                    </div>

                                    <button className="icon_button close">
                                        <div className="admin_icon_cross"></div>
                                    </button>
                                </div>

                                <div className="new_case_add">
                                    <span>+</span>
                                    <p>add new case</p>
                                </div>
                            </div>

                        </div>*/}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategories;