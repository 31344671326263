import React, { useEffect, useState } from 'react';
import Avatar from '../../../components/avatar/Avatar';
import bot from './../images/bot.png'

const Players = ({el, position, type}) => {

    const [player, setPlayer] = useState()

    useEffect(() => {

        if(el) {
            const player = el.clients?.find(player => player.position == position)
            const bot = el.bots?.find(bot => bot.position == position)
            if(player) {
                setPlayer(player)
            } else if(bot) {
                setPlayer('bot')
            } else {
                setPlayer('default')
            }
        }
        
    }, [el])

    const getTeam = () => {
        if(type === '2vs2') {
            if(position < 3) {
                return 'border1'
            } 
            if(position > 2) {
                return 'border3'
            }
        } else {
            return 'border' + position
        }
    }

    return (
        <>
            {player === 'default' 
            ?<p className={`default_user`}>?</p>
            :player === 'bot'
            ?<div className={`botImage ${getTeam()}`}>
            </div>
            :
            <Avatar 
                img={player?.avatar}
                lvl={player?.level}
                percent={(player?.experience / player?.experienceBorderTillLevel) * 100}
                color={'rgb(233, 63, 63)'}
                size={40}
                rad={17}
                dasharray={107}
            />}
        </>
    );
};

export default Players;