import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import GameView from './GameView';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../components/price/Price';
import Button from '../../../components/button/Button';


const GameHistoryItem = ({getType, el}) => {

    const [status, setStatus] = useState('white')

    const getStatus = () => {
        if(el.gameEnd) {
            return setStatus('red')
        } else {
            if(el.round > 0) {
                return setStatus('green')
            } else {
                return setStatus('white')
            }
        }
    }

    useEffect(() => {

        if(el) {
            getStatus()
        }

    }, [el])

    return (
        <div className={`list_item ${status} fast`}>

            <GameView el={el} status={status} getType={getType}/>

            <div className="functions">

                <div className="functions_item">
                    <div className="price">
                        <img src={coin} alt="" />
                        <Price price={el.gameCost}/>
                    </div>
                    <p className="description on_price">Price of battle</p>
                </div>
                <div className="functions_item">
                    <NavLink to={`/case_battles/${el.gameType}/${el.id}`}><Button content={'Watch'} mode={'full flex white'}/></NavLink>
                    <p className="description">{status === 'red' ? 'Finished' : 'Started'}</p>
                </div>

            </div>

        </div>  
    );
};

export default GameHistoryItem;