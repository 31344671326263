import React from 'react';
import './css/radios.css';

const Radio = ({radio, callback}) => {

    return (
        <div className={`radio ${radio ? 'active' : ''}`} onClick={callback}>
            <div className={`flag`}></div>
        </div>
    );
};

export default Radio;