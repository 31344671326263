import React, { useCallback, useEffect } from 'react';
import ChatOnline from './ChatOnline';
import Store from '../../../Store';

import russia from './../images/russia.svg'
import english from './../images/english.svg'
import deutch from './../images/germany.svg'
import china from './../images/china.svg'
import spain from './../images/spain.svg'   

const LocationBlock = ({online, changeRoom}) => {

    const location = JSON.parse(localStorage.getItem('chat_room'))

    let locationList = [
        {'id': 'sp', 'location': 'Español', 'flag': spain, 'room': 'spanish'}, 
        {'id': 'ru', 'location': 'Русский', 'flag': russia, 'room': 'russian'},
        {'id': 'en', 'location': 'English', 'flag': english, 'room': 'english'},
        {'id': 'gr', 'location': 'Deutch', 'flag': deutch, 'room': 'german'},
        {'id': 'ch', 'location': '中文', 'flag': china, 'room': 'chinese'}].filter((el) => location ? el.id !== location.id: el.id !== 'en')


    function openLocationList(e) {
        e.stopPropagation()
        document.querySelector('.location_list').classList.toggle('open')
        document.getElementById('chat_location_arrow').classList.toggle('active')
    }

    function closeLocationList() {
        document.querySelector('.location_list').classList.remove('open')
        document.getElementById('chat_location_arrow').classList.remove('active')
    }

    useEffect(() => {
        window.addEventListener('click', ()=> closeLocationList())

        return () => {
            window.removeEventListener('click', ()=> closeLocationList())
        }
    }, [])

    useCallback(() => {
        return () => {
            window.removeEventListener('click', ()=> closeLocationList())
        }
    }, [])

    return (
        <div className="location_block">
            <div className='location_list main_card'>
                {locationList.map((el) => (
                    <div key={el.id} id={el.id} className="location_item" onClick={() => changeRoom(el)}>
                        <img src={el.flag} alt="" />
                        <h4>{el.location}</h4>
                        <p className='online'><span></span>{online}</p>
                    </div>
                ))}
            </div>
            {location ? 
            <div className="location main_card">
                <h4><img src={location.flag} alt="" />{location.location}</h4>
                <p className='online'><span></span> <ChatOnline online={online}/> </p>
            </div>
            :
            <div className="location main_card">
                <h4><img src={english} alt="" />English</h4>
                <p className='online'><span></span> <ChatOnline online={online}/> </p>
            </div>
            }   
            <button onClick={openLocationList}><div className='region' id='chat_location_arrow'></div></button>
        </div>
    );
};

export default LocationBlock;