import { useState } from "react"

export default function useDnD (setNewList, newList) {
    /* --------------Обратботка перемещения карточек-------------- */

    let [draggedCard, setDraggedCard] = useState()


    function dragStart (e, card) {
        setDraggedCard({
            'id': card.id,
            'index': card.index,
            'current': e.currentTarget,
        })
    }


    function dragOver (e) {
        e.preventDefault()
        const target = e.currentTarget

        if(target === draggedCard.current) {
            return
        }
        if(target.getAttribute('data-add')){
            target.classList.add('show_pos_left')
            return
        }

        let cardPos = target.getBoundingClientRect()
        let center = cardPos.x + (cardPos.width / 2)

        if(e.clientX < center) {
            target.classList.remove('show_pos_right')
            target.classList.add('show_pos_left')
        } else if(e.clientX > center) {
            target.classList.remove('show_pos_left')
            target.classList.add('show_pos_right')
        }
    }


    function dragEnd(e) {
        const target = e.currentTarget

        target.classList.remove('show_pos_left')
        target.classList.remove('show_pos_right')
    }


    function dragDrop (e, card) {
        e.preventDefault()
        const target = e.currentTarget

        /* -----DnD на кнопке добавить кейс---- */
        if(target.getAttribute('data-add')){
            const lastIndex = newList[newList.length - 1].index
            setNewList(newList.map(c => {
                if(c.id === draggedCard.id) {
                    return {...c, index: lastIndex + 1}
                }
                return c
            }))
        } 
        /* -----DnD на кнопке добавить кейс---- */
        
        else {
            let cardPos = target.getBoundingClientRect()
            let center = cardPos.x + (cardPos.width / 2)
    
            if(e.clientX < center) {
                setNewList(newList.map(c => {
                    if (c.id === draggedCard.id) {
                        return {...c, index: card.index}   
                    }
                    if (c.index >= card.index) {
                        return {...c, index: c.index + 1}
                    }
                    return c
                }))
            } else if(e.clientX > center) {
                setNewList(newList.map(c => {
                    if (c.id === draggedCard.id) {
                        return {...c, index: card.index + 1}   
                    }
                    if (c.index >= card.index + 1) {
                        return {...c, index: c.index + 1}
                    }
                    return c
                }))
            }
        }
        
        target.classList.remove('show_pos_left')
        target.classList.remove('show_pos_right')
    }

    return {dragStart, dragOver, dragEnd, dragDrop}
}