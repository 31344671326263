import React from 'react';

const MutePopUp = () => {

    function closeAdminPopup () {
        document.getElementById('adminMutePopup').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')
    }

    return (
        <div className='admin_popup_wrapper' id='adminMutePopup' onMouseDown={closeAdminPopup}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_chat"></div>
                        </div>
                        <div className='game_title'>Mute</div>
                        <div className="decor pink" onMouseDown={closeAdminPopup}></div>
                    </div>

                    <div className="admin_popup_content">

                        <div className="mute_time">
                            <div className="mute_time_item">

                                <div className="mute_item">
                                    <h3>Month</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red">
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">00m</div>

                                        <button className="icon_button green">
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                                <div className="mute_item">
                                    <h3>Week</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red">
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">00w</div>
                                        
                                        <button className="icon_button green">
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="mute_time_item">

                                <div className="mute_item">
                                    <h3>Day</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red">
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">00d</div>
                                        
                                        <button className="icon_button green">
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>

                                <div className="mute_item">
                                    <h3>Hours</h3>

                                    <div className="mute_set">
                                        <button className="icon_button red">
                                            <div className="account_icon_minus"></div>
                                        </button>

                                        <div className="mute_set_time">00h</div>
                                        
                                        <button className="icon_button green">
                                            <div className="account_icon_plus"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label htmlFor="">Reason:</label>
                        <input 
                            type="text" 
                            className="admin_input" 
                            placeholder='Enter the reason below, read the rules before doing so'
                        />

                        <button className="common_button full green">MUTE THIS F*CKING PID*RAS</button>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default MutePopUp;