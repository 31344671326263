import React, { useState } from 'react';
import Filters from './Filters';
import HistoryList from './HistoryList';
import NotItems from '../../../../../../components/NotItems';
import LoadingItems from '../../../../../../components/LoadingItems';
import CommonPagination from '../../../../../../components/pagination/CommonPagination';
import useHistory from '../../hooks/useHistory';

const CrashHistory = () => {
    const [params, setParams] = useState({
        page: 1,
        size: 10,
        order: 'ASC',
        field: 'id'
    })

    const URL = 'game-crash/me'

    const {load, list, changeFilters, changeDate} = useHistory(params, setParams, URL)
    
    return (
        <>

            <Filters changeDate={changeDate} changeFilters={changeFilters} params={params}/>

            <div className="history_list_wrapper">

                <div className="history_list_header crash">
                    <h5 className='id'>ID</h5>
                    <h5>Created</h5>
                    <h5>Bet</h5>
                    <h5>X</h5>
                    <h5>Payout</h5>
                </div>

                {load ? 
                    <>
                        {list.items?.length ? 
                            <HistoryList list={list.items} />
                        :
                            <NotItems />
                        }
                    </>
                :
                    <LoadingItems />
                }

            </div>

            <CommonPagination maxPage={Math.ceil(list.total / params.size)} params={params} setParams={setParams} />
        </>
    );
};

export default CrashHistory;