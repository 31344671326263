import React, { useState } from 'react';

import './css/tfa_confirm.css'
import Api from '../../Api';
import Notice from '../../Notice';

const TFAConfirmModal = ({setOpen, path, method, body, marker}) => {
    const [authCode, setAuthCode] = useState('')
    const [error, setError] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)

    function onInput(e) {
        setError(false)

        if(!e.target.value) {
            setError(true)
        }

        let value = e.target.value.replace(/[^0-9]/g,'')

        setAuthCode(value)
    }

    async function sendCode (e) {

        if(authCode === '') {
            setError(true)
            return
        }

        setLoadBtn(prev => prev = false)

        try {
            let res = await fetch(`${Api.url}/${path}`,{
                method: method,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-OTP-Code': authCode.toString(),
                },
                credentials: 'include',
                body: body || '',
            })

            res = await res.json()
            setLoadBtn(prev => prev = true)

            if(res.statusCode) {
                setError(true)
                Notice.Send({type: 'error', text: `${res?.exception?.message || 'Error. Try again later'}`})
                return
            }

            if(!res.statusCode) {
                if(marker === 'LOGIN') {
                    localStorage.setItem('client', JSON.stringify(res.client))
                }
                window.location.reload()
                return
            }
            
        } catch (e) {
            Notice.Send({type: 'error', text: 'Error. Try again later'})
            return 
        }
    }

    function closeQR () {
        setOpen(prev => prev = false)
        setAuthCode('')
    }

    return (
        <div className='tfa_confirm_wrapper' onMouseDown={closeQR}>
            <div className="tfa_confirm_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Cofirm 2fa</div>
                    <div className="decor pink" onMouseDown={closeQR}></div>
                </div>

                <div className="tfa_confirm_popup_content">

                    <p>This account is protected by Two-Factor Authentication. Please enter the code below to continue.</p>

                    <input
                        type="text"
                        inputMode='numeric' 
                        className={`main_input main_card ${error ? 'error' : ''}`} 
                        placeholder='Enter code...'
                        value={authCode}
                        onChange={(e) => onInput(e)}
                        onBlur={(e) => setError(false)}
                    />

                    <button className="common_button full green"
                        onMouseDown={() => sendCode()}
                    >
                        {loadBtn ? 'confirm' : 'Loading...'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TFAConfirmModal;