import React from 'react';
import EditItemBtn from './../EditItemBtn';

const AddCasesItem = ({ setError, newList, setNewList, item}) => {


    function addDropChance(e, val) {

        e.currentTarget.closest('.info_item').classList.remove('error')
        setError(prev => ({...prev, dropChance: false}))

        let value = val.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        if(value > 99.99) {
            setNewList(newList.map(el => {
                if (el.id === item.id) {
                    return {...el, dropChance: '99.99'}
                } else {
                    return el
                }
            })
        )
        } else {
            let str = value.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setNewList(newList.map(el => {
                    if (el.id === item.id) {
                        return {...el, dropChance: str.join('.')}
                    } else {
                        return el
                    }
                })
            )
            }
        }
    }

    function deleteItem(e) {
        e.preventDefault()
        setNewList(prev => prev.filter((el) => el.id !== item.id))
        if(newList.length <= 15) {
            document.getElementById('err_max_num').classList.remove('error')
        }
    }


    return (
        <div className="item" id={item.id}>
                                    
            <div className="avatar">
                <img src={item.image} alt="" className={item.type}/>
                <EditItemBtn item={item}/>
            </div>

            <div className="info">
                <div> 
                    <h3>Drop chance</h3>
                    <div className='info_item main_card'>
                        <input type="text" placeholder='Set chance' value={Object.hasOwn(item, 'dropChance') ? `${item.dropChance}` : ''} onChange={(e) => addDropChance(e, e.target.value)}/>
                        <span>%</span>
                    </div>
                </div>

                <div> 
                    <h3>Item name</h3>
                    <div className="info_item main_card">{item.name}</div>
                </div>
            </div>

            <button className="icon_button close" onClick={(e) => deleteItem(e)}>
                <div className="admin_icon_cross"></div>
            </button>
        </div>
    );
};

export default AddCasesItem;