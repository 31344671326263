import React, { useEffect, useState } from 'react';
import NotItems from '../../../../../components/NotItems';
import LoadingItems from '../../../../../components/LoadingItems';
import WithdrawItem from "./WithdrawItem";

const WithdrawList = ({withdraw}) => {

    const [filterBotItems, setFilterBotItems] = useState([])

    useEffect(() => {

        if(withdraw.filter && withdraw.botItems && withdraw.priceOrder) {
            let list = structuredClone(withdraw.botItems)
            let filter = withdraw.priceOrder

            if(withdraw.filter !== 'all') {
                list = list.filter(el => el.item.type === withdraw.filter)
            } 

            switch(filter) {
                case 'ALL PRICES':
                    break
                case '1000+':
                    list = list.filter(el => el.item.priceInCoins >= 1000)
                    break
                default:
                    filter = filter.split('-')
                    list = list.filter(el => (el.item.priceInCoins >= filter[0] && el.item.priceInCoins <= filter[1]))
            }

            if(withdraw.search) {
                list = list.filter(el => el.item.name.toLowerCase().includes(withdraw.search.toLowerCase()))
            }

            setFilterBotItems(list)
        }

    }, [withdraw.filter, withdraw.botItems, withdraw.priceOrder, withdraw.search])

    return (
        <div className="withdraw_list_wrapper">
            {withdraw.load ? (
                <>
                    {filterBotItems?.length ? (
                        <div className="withdraw_list">
                            {filterBotItems.map((el) => (
                                <WithdrawItem totalCostList={withdraw.totalCostList} item={el} addItem={withdraw.addItem} deleteItem={withdraw.deleteItem}/>
                            ))}
                        </div>
                    ) : 
                    <NotItems />
                    }
                </>
            ) : 
            <LoadingItems />
            }
        </div>
    );
};

export default WithdrawList;
