import React from 'react';
import Store from '../../../Store';

const RouletteLastItem = ({e}) => {

    function openModal (e) {
       Store.setListener('ROULETTE_PROVABLY_FAIR', e.gameHash)
    }

    return (
        <div 
            className={`last_item
                 ${e.index === 1 ? 'red1' 
                    : e.type === 'green' ? 'green'
                    : e.index === 14 ? 'black2' 
                    : e.index % 2 === 0 ? 'black1' : 'red2'}`} 
            onMouseDown={() => openModal(e)}>

            <img src={e.img} alt="" />
        </div>
    );
};

export default RouletteLastItem;