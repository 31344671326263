import React from 'react';

import coin from './../../../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../../../components/price/Price';

const LuckiestBox = ({bestCase}) => {
    return (
        <div className="luckiest_box">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_stats"></div>
                </div>
                <div className='game_title'>Luckiest box</div>
                <div className="decor pink"></div>
            </div>
            <div className="body_content">
                <p className="win_title">Opened {bestCase?.countOpened ? bestCase?.countOpened : '0'} times</p>
                <div className="monet_price">
                    <img src={coin} alt="" />
                    <p><Price price={bestCase?.casePrice ? bestCase?.casePrice : 0}/></p>
                </div>
                <div className="box_name">
                    {bestCase?.caseName ? bestCase?.caseName : 'NONE'}
                </div>
            </div>
        </div>
    );
};

export default LuckiestBox;