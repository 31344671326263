import React, { useCallback, useEffect } from 'react';

const ItemsStoreFilters = ({paramsUrl, setParamsUrl,}) => {
    
    function openFilter(e) {
        e.stopPropagation()
        
        const currentList = e.currentTarget.querySelector('.filters_list')

        const allFilt = document.querySelectorAll('.filters_list')

        allFilt.forEach(el => {
            if(el == currentList) {
                return
            } else {
                el.classList.remove('open')
            }
        })

        currentList.classList.toggle('open')
    }


    function closeFilters() {
        const allFilt = document.querySelectorAll('.filters_list')
        allFilt.forEach((el) => {
            if(el.classList.contains('open')){
                el.classList.remove('open')
            } else {
                return 
            }
        })
    }


    function choseFilter(e, filterName) {
        const itemList = e.currentTarget.querySelectorAll('p')

        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParamsUrl(prev => ({...prev, [filterName]: e.target.textContent}))
    }


    useEffect(() => {

        window.addEventListener('click',() => closeFilters())
        
        return () => window.removeEventListener('click', () => closeFilters())
    }, [])


    useCallback(() => {
        window.removeEventListener('click', () => closeFilters())
    })

    /* ------------Фильтры-------------- */



    return (
        <div className="store_filters">

            <div className="filter_wrapper" onClick={(e) => openFilter(e)}>
                <button className="common_button filter_item">
                    <p>Field: {paramsUrl.field}</p> 
                    <div className="deposit_icon_arrow"></div>
                </button>
                
                <div className="filters_list" onClick={(e) => choseFilter(e, 'field')}>
                    <p className='active'>id</p>
                    <p>name</p>
                    <p>price</p>
                </div>
            </div>
            
            <div className="filter_wrapper" onClick={(e) => openFilter(e)}>
                <button className="common_button filter_item">
                    <p>Type: {paramsUrl.type}</p> 
                    <div className="deposit_icon_arrow"></div>
                </button>

                <div className="filters_list" onClick={(e) => choseFilter(e, 'type')}>
                    <p className='active'>all</p>
                    <p>red</p>
                    <p>purple</p>
                    <p>orange</p>
                    <p>blue</p>
                    <p>green</p>
                </div>
            </div>
            
            <div className="filter_wrapper" onClick={(e) => openFilter(e)}>
                <button className="common_button filter_item">
                    <p>Order: {paramsUrl.order}</p> 
                    <div className="deposit_icon_arrow"></div>
                </button>

                <div className="filters_list" onClick={(e) => choseFilter(e, 'order')}>
                    <p className='active'>ASC</p>
                    <p>DESC</p>
                </div>
            </div>
        
        </div>
    );
};

export default ItemsStoreFilters;