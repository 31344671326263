import React, { useCallback, useEffect, useRef, useState } from 'react';
import Store from '../Store';
import DropsItems from './drops/DropsItems';
import coin from './../fonts/main_icon_fonts/coin.svg'
import { io } from 'socket.io-client';
import Api from '../Api';


const Drops = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [tabs, setTabs] = useState('TOP')
    
    function openDropMenu() {
        setIsOpen(prev => !prev)
        Store.setListener('dropFlex', prev => !prev)
    }
    
    function changeTab (e) {
        if(e.target.name === 'TOP') {
            setDropsTopList(topStore.current)
        } else {
            setDropsAllList(allStore.current)
        }
        setTabs(e.target.name)
    }
    
    const [dropsAllList, setDropsAllList] = useState([])
    const [dropsTopList, setDropsTopList] = useState([])
    
    const btnWrapper = useRef(null)
    const dropSocketMain = useRef(null)
    const topList = useRef()
    const allList = useRef()
    const all = useRef(null)
    const top = useRef(null)
    const topStore = useRef([])
    const allStore = useRef([])

    useEffect(() => {

        dropSocketMain.current = io(`${Api.urlWs}/game-open-case`, {
            transports: ["websocket",],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        
        dropSocketMain.current.on('open-case.lastOpenCaseBestRareItems', (data) => {
            if(data instanceof Array) {
                setDropsTopList(data)
                topStore.current = data
            } else {
                top.current = setTimeout(() => {

                    if(topList.current && data.bestRareItems?.length && topStore.current?.length) {
                        const drop = data.bestRareItems[0]
                        const newitem = document.createElement('div')
                        newitem.className = `drop_card_wrapper anim ${drop?.type}`
                        newitem.innerHTML = `<div class="drop_card nocopy hidden ${drop?.type}">
                                                <img src='${drop?.image}' class='drop_image ${drop?.type}' alt="" />
                                                <div class="drop_card_content">
                                                <h4 class="title">${drop?.name}</h4>
                                                <p class="price main_card ${drop?.type}">${drop?.price} <img src='${coin}' alt="" /></p>
                                                </div>
                                            </div>`
                        topList.current.prepend(newitem)
                        setTimeout(() => {
                            newitem.classList.add('show')
                        }, 1)

                        const newItems = topList.current.querySelectorAll('.drop_card_wrapper')
                        if(topList.current) {
                            if(newItems.length > 10) {
                                newItems[newItems.length - 1].remove()
                            }
                        } else {
                            setDropsTopList(data.bestRareItems)
                        }
                    } else {
                        setDropsTopList(data.bestRareItems)
                    }

                    topStore.current = data.bestRareItems
                }, 7500)
            }
        })

        dropSocketMain.current.on('open-case.lastOpenCaseAllItems', (data) => {
            if(data instanceof Array) {
                setDropsAllList(data)
                allStore.current = data
            } else {
                all.current = setTimeout(() => {
                    if(allList.current && data.allItems?.length && allStore.current?.length) {
                        const drop = data.allItems[0]
                        const newitem = document.createElement('div')
                        newitem.className = `drop_card_wrapper anim ${drop.type}`
                        newitem.innerHTML = `<div class="drop_card nocopy hidden ${drop.type}">
                                                <img src='${drop.image}' class='drop_image ${drop.type}' alt="" />
                                                <div class="drop_card_content">
                                                <h4 class="title">${drop.name}</h4>
                                                <p class="price main_card ${drop.type}">${drop.price} <img src='${coin}' alt="" /></p>
                                                </div>
                                            </div>`

                        allList.current.prepend(newitem)
                        // allList.current.insertBefore(newitem, allList.current.firstChild)
                        
                        setTimeout(() => {
                            newitem.classList.add('show')
                        }, 1)

                        const newItems = allList.current.querySelectorAll('.drop_card_wrapper')
                        if(allList.current) {
                            if(newItems.length > 10) {
                                newItems[newItems.length - 1].remove()
                            }
                        } else {
                            setDropsAllList(data.allItems)
                        }
                    } else {
                        setDropsAllList(data.allItems)
                    }

                    allStore.current = data.allItems
                }, 7500)
            }
        })
        
        dropSocketMain.current.on('connect_error', (error) => console.log(error))
        
        return () => {
            if (dropSocketMain.current) {
                dropSocketMain.current.off('open-case.lastOpenCaseBestRareItems');
                dropSocketMain.current.off('open-case.lastOpenCaseAllItems');
                dropSocketMain.current.off('connect_error');
                dropSocketMain.current.disconnect();
            }

            if(all.current) {
                clearTimeout(all.current)
            }

            if(top.current) {
                clearTimeout(top.current)
            }
        }
        
    }, [])

    function updateDistance() {
        if (btnWrapper.current) {
            const rect = btnWrapper.current.getBoundingClientRect();
            const elementBottomPosition = rect.bottom + window.scrollY;
            const screenWidth = window.innerWidth;
            
            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );

            
            const distanceToBottom = pageHeight - elementBottomPosition;
            
            if (distanceToBottom <= 250) {
                if(screenWidth > 1700) {
                    btnWrapper.current.style.paddingBottom = `${220 - distanceToBottom}px`;
                } else {
                    btnWrapper.current.style.paddingBottom = `${250 - distanceToBottom}px`;
                }
            } else {
                btnWrapper.current.style.paddingBottom = `0px`;
            }
        }
    }

    useEffect(() => {

        window.addEventListener('scroll', updateDistance);

        return () => {
            window.removeEventListener('scroll', updateDistance);
        };

    }, [])

    useCallback(() => {
        return () => {
            window.removeEventListener('scroll', updateDistance);
        };
    }, [])

    return (
        <div className={`drops_menu_block ${isOpen ? 'close' : ''}`}>
            
            <div className="sticky_btn_wrapper left" ref={btnWrapper}>
                <button className={`icon_button sticky_btn left ${isOpen ? 'close' : ''}`} onClick={openDropMenu}>
                    <div className="icon_arrow_right"></div>
                </button>
            </div>

            <div className='drops_menu'>
                <div className="drop_header main_card">
                    <h4>Live drop</h4>

                    <div className='switch'>
                        <button name='TOP' className={`common_button ${tabs === 'TOP' ? 'green' : ''}`} onClick={(e) => changeTab(e)}>Top</button>
                        <button name='ALL' className={`common_button ${tabs === 'ALL' ? 'green' : ''}`} onClick={(e) => changeTab(e)}>All</button>
                    </div>
                </div>

                {tabs === 'ALL' && <DropsItems list={dropsAllList} refer={allList}/>}
                {tabs === 'TOP' && <DropsItems list={dropsTopList} refer={topList}/>}

            </div>
        </div>
    );
};

export default Drops;