import React, { useCallback, useEffect } from 'react';
import pinkCoin from './../../fonts/main_icon_fonts/pink_coin.svg'
import coin from './../../fonts/main_icon_fonts/coin.svg'
import Notice from '../../Notice';
import Api from '../../Api';
import Price from '../price/Price';

const BalanceList = ({field, balance, func, wager}) => {

    async function getDailyWager(e) {
        e.stopPropagation()

        const dailyWager = await Api.asyncGetWithMessage('api/v1/wagers/daily')

        if(dailyWager.statusCode === 400) {
            return Notice.Send({type: 'error', text: dailyWager.exception.message})
        }

        if(dailyWager.statusCode === 500) {
            Notice.Send({type: 'error', text: 'Server error'})
            return
        }

        await Api.updateAccount()
    }

    async function takeWager(e) {
        e.stopPropagation()

        const init = await Api.asyncGetWithMessage('api/v1/wagers/exchange')

        if(init.statusCode === 400) {
            return Notice.Send({type: 'error', text: init.exception.message})
        }

        if(init.statusCode === 500) {
            Notice.Send({type: 'error', text: 'Server error'})
            return
        }

        await Api.updateAccount()
    }

    function closeBalance () {
        document.querySelector('.balance_list').classList.remove('open')
    }

    useEffect(() => {

        window.addEventListener('click', () => closeBalance())

        return () => window.removeEventListener('click', () => closeBalance())

    }, [])

    useCallback(() => {
        window.removeEventListener('click', () => closeBalance())
    })

    return (
        <div className="balance_list">

            {field === 'coin' ? 
                <div className='balance_list_item wager' onClick={(e) => func('wager')}>
                    <div 
                        className="upgrade_icon_upgrade" 
                        onClick={e => getDailyWager(e)}
                    ></div>

                    <div className="flex" >
                        <Price price={balance.wagers}/>
                        <img src={pinkCoin} alt="" />
                    </div>
                </div>
            :<div className='balance_list_item' onClick={(e) => func('coin')}>
                <div className='balance_field'>
                    <Price price={balance.balance}/>
                    {/* <span className="tooltip" data-tooltip={(balance.balance).toFixed(2)}></span> */}
                </div>

                <img src={coin} alt="" />
            </div>}

            <div className="balance_list_marja">
                <div className="flex">
                    {wager >= 5 ?
                            <button className='main_card' onClick={e => takeWager(e)}>take</button>
                        :
                            <>
                                {wager}/5.00
                            </>
                    }
                    <img src={pinkCoin} alt="" />
                </div>
                <div className="progress_bar">
                    <span className="fill" style={{width: `${((wager/5) * 100).toFixed(2)}%`}}></span>
                </div>
            </div>
        </div>
    );
};

export default BalanceList;