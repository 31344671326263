import React, { useCallback, useEffect, useRef, useState } from 'react';
import OpenOneCase from '../components/OpenOneCase';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import Games from '../../../Games';
import Store from '../../../Store';
import Api from '../../../Api';
import Notice from '../../../Notice';
import Price from '../../../components/price/Price';


const GameOpenOne = ({free, caseDetail, tab, isTab, items, fast, setFast}) => {

    let [itemsOpenList, setItemsOpenList] = useState(false)
    
    let block = useRef(false)
    let roullete = useRef()
    let play = useRef()
    let startPlay = useRef()
    let finishPlay = useRef()

    function spinRoullete(){

        const winCase = document.querySelector(`[data-id="2320--dfdad=a=dsf=f=asd--asaf1"]`)
        let randomize = Math.floor(Math.random() * 150) - (150/2);
        const elHeight = Games.getHeight(roullete)
        const screenWidth = window.innerWidth;

        if(fast) {  

            roullete.current.style.transitionTimingFunction = ``
            roullete.current.style.transitionDuration = '1s'
            roullete.current.style.transform = `translate3d(calc(-50% + ${(elHeight + 30) * 10}px - ${(elHeight + 30) / 2}px), 0px, 0px)`

            play.current = setTimeout(() => {
                roullete.current.style.transitionDuration = '1s'
                roullete.current.style.transform = `translate3d(calc(-50% + ${(elHeight + 30) * 10}px - ${(elHeight + 30) / 2}px), 0px, 0px)`
                winCase.style.transitionDuration = '1s'

                if(screenWidth > 768) {
                    winCase.style.transform = 'scale(1.15)'
                } else {
                    winCase.style.transform = 'scale(1.33)'
                }
                finishPlay.current = setTimeout(() => {
                    block.current = false
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    document.getElementById('skin_dice').classList.add('open')
                    document.querySelector('html').classList.add('hidden')
                }, 1000)

            }, 1500)

        } else {

            roullete.current.style.transitionTimingFunction = `cubic-bezier(0.2, 0, 0, 1)`
            roullete.current.style.transitionDuration = '6s'
            roullete.current.style.transform = `translate3d(calc(-50% + ${(elHeight + 30) * 10}px - ${(elHeight + 30) / 2}px + ${randomize}px), 0px, 0px)`

            play.current = setTimeout(() => {
                roullete.current.style.transitionDuration = '1s'
                roullete.current.style.transform = `translate3d(calc(-50% + ${(elHeight + 30) * 10}px - ${(elHeight + 30) / 2}px), 0px, 0px)`
                winCase.style.transitionDuration = '1s'
                if(screenWidth > 768) {
                    winCase.style.transform = 'scale(1.15)'
                } else {
                    winCase.style.transform = 'scale(1.33)'
                }
                finishPlay.current = setTimeout(() => {
                    block.current = false
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    document.getElementById('skin_dice').classList.add('open')
                    document.querySelector('html').classList.add('hidden')
                }, 1000)

            }, 6500)
        }
    }

    function stopBlock()  {
        block.current = false
        document.querySelectorAll('.case_open_button').forEach((e) => {
            e.classList.remove('close')
        })
    }

    async function launchGame(init) {
        setItemsOpenList(prev => {
            prev = Games.shuffle(prev)
            prev[itemsOpenList.length - 10] = {
                fakeItem: {
                    id: init[0].wonItem.id,
                    name: init[0].wonItem.name,
                    image: init[0].wonItem.image,
                    type: init[0].wonItem.type,
                    price: init[0].wonItem.price
                }
            }
            return prev
        })

        Store.setListener('winSkins', init)

        await Api.updateAccount()
        
        Store.setListener('caseItems', prev => !prev)

        startPlay.current = setTimeout(() => {
            spinRoullete()
        }, 500)
    }

    function resetRoulete() {
        const elHeight = Games.getHeight(roullete)
        Store.setListener('winSkins', false)
        roullete.current.style.transitionTimingFunction = ''
        roullete.current.style.transitionDuration = ''
        roullete.current.style.transform = `translate3d(calc(50% - ${(elHeight + 30) * 5}px + ${(elHeight + 30) / 2}px), 0px, 0px)`
        const winCase = document.querySelector(`[data-id="2320--dfdad=a=dsf=f=asd--asaf1"]`)
        winCase.style.transitionDuration = ''
        winCase.style.transform = 'scale(1)'
        block.current = true
        document.querySelectorAll('.case_open_button').forEach((e) => {
            e.classList.add('close')
        })
    }

    /* ---------Запуск игры----------- */
    function caseOpen() {

        if(!items) {
            return
        }

        if(block.current) {
            return
        }

        (async () =>{
            if(free) {
                let init = await Api.asyncGetWithMessage(`api/v1/game-open-case/open-free-case?caseId=${caseDetail.id}`)

                if(init === 'unAuth') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return stopBlock()
                }
                if(init === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return stopBlock()
                }

                if(init.statusCode === 400) {
                    var time = await Api.asyncGetWithMessage(`api/v1/game-open-case/remaining-time?caseId=${caseDetail.id}`)

                    if(time !== 'error') {
                        time = time.remainingTime.split(':')
                    } 

                    Notice.Send({type: 'error', text: `${init.exception.message}! ${time === 'error' ? '' : `Remaining: ${time[0]}h:${time[1]}m`}`})
                    return stopBlock()
                }
                
                resetRoulete()
                launchGame(init)
                
            } else {
                resetRoulete()
                
                let wagers = localStorage.getItem('balanceField')
                let query = {
                    openings: 1,
                    bet: (caseDetail.price).toFixed(2),
                    caseId: caseDetail.id,
                    forWagers: wagers && wagers === 'wager' ? true : false
                }

                let params = new URLSearchParams(query).toString()

                let init = await Games.dicePlay(`api/v1/game-open-case/play?${params}`)
                if (init === 'no balance') {
                    Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    block.current = false
                    return
                } 
                if (init === 'unAuthorization') {
                    document.querySelector('html').classList.add('hidden')
                    Store.setListener('openAuthPopUp', true)
                    block.current = false
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    return
                }
                if (init === 'error') {
                    block.current = false
                    document.querySelectorAll('.case_open_button').forEach((e) => {
                        e.classList.remove('close')
                    })
                    return
                }
                
                launchGame(init)
            }
            
        })()
    }

    /*------------- Демо игра -------------*/
    function caseDemoOpen() {

        if(!items) {
            return
        }

        if(block.current) {
            return
        }

        (async () =>{
            Store.setListener('winSkins', false)
            roullete.current.style.transitionTimingFunction = ''
            roullete.current.style.transitionDuration = ''
            roullete.current.style.transform = `translate3d(calc(50% - 675px), 0px, 0px)`
            const winCase = document.querySelector(`[data-id="2320--dfdad=a=dsf=f=asd--asaf1"]`)
            winCase.style.transitionDuration = ''
            winCase.style.transform = 'scale(1)'
            block.current = true
            document.querySelectorAll('.case_open_button').forEach((e) => {
                e.classList.add('close')
            })

            let query = {
                openings: 1,
                caseId: caseDetail.id,
            }

            let params = new URLSearchParams(query).toString()

            let init = await Games.dicePlay(`api/v1/game-open-case/demo-play?${params}`)

            if (init === 'error') {
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }

            if (init === 'unAuthorization') {
                document.querySelector('html').classList.add('hidden')
                Store.setListener('openAuthPopUp', true)
                block.current = false
                document.querySelectorAll('.case_open_button').forEach((e) => {
                    e.classList.remove('close')
                })
                return
            }
            
            setItemsOpenList(prev => {
                prev = Games.shuffle(prev)
                prev[itemsOpenList.length - 10] = {
                    fakeItem: {
                        id: init[0].wonItem.id,
                        name: init[0].wonItem.name,
                        image: init[0].wonItem.image,
                        type: init[0].wonItem.type,
                        price: init[0].wonItem.price
                    }
                }
                return prev
            })

            Store.setListener('winSkins', init)
            Store.setListener('caseItems', prev => !prev)

            startPlay.current = setTimeout(() => {
                spinRoullete() 
            }, 500)
            
        })()
    }

    useEffect(() => {

        setItemsOpenList(Games.shuffle(structuredClone(items)))

        return () => {
            clearTimeout(play.current)
            clearTimeout(startPlay.current)
            clearTimeout(finishPlay.current)
        }

    }, [items])

    useCallback(() => {
        return () => {
            clearTimeout(play.current)
            clearTimeout(finishPlay.current)
            clearTimeout(startPlay.current)
        }
    }, [])

    return (
        <>
            <div className="case_window_block">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="home_icon_cases"></div>
                    </div>   
                    <h4 className='game_title'>{caseDetail.categories} case</h4>
                    <div className="decor pink"></div>
                </div>

                <div className="case_window">
                    <div className="image">
                        <img src={caseDetail.image} alt="" />
                        <div className="provably mob">
                            <div className="rewards_icon_lock"></div>
                            PROVABLY FAIR
                        </div>
                    </div>
                    <div className="case_window_navigatoin_block">
                        <div className="navigation">
                            <h2 className="title">{caseDetail.name}</h2>

                            {free && <p className="free_case_message">You can only open this case once every 24 hours!</p>}
                            
                            <div className="tabs_buttons_block">
                                {!free && 
                                    <>
                                        <button className={`common_button icon_button ${tab === 'one' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'one', isTab)}>1</button>
                                        <button className={`common_button icon_button ${tab === 'two' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'two', isTab)}>2</button>
                                        <button className={`common_button icon_button ${tab === 'three' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'three', isTab)}>3</button>
                                        <button className={`common_button icon_button ${tab === 'four' ? 'active': ''}`} onClick={() => Games.switchPage(block, 'four', isTab)}>4</button>
                                    </>
                                }
                                <div className="check_block">
                                    <input type="checkbox" checked={fast} onChange={(e) => setFast(prev => !prev)}/>
                                    FAST OPENING
                                </div>
                            </div>
                            <div className="open_demo_block">
                                <button className="common_button green case_open_button" onClick={() => caseOpen()}>
                                    {free ? 
                                        'OPEN FREE CASE'
                                    :
                                        <>
                                            OPEN 1 TIME
                                            <div className="coin_block">
                                                <img src={coin} alt="" />
                                                <Price price={caseDetail.price}/>
                                            </div>
                                        </>
                                    }
                                </button>
                                <button className="common_button white case_open_button" onClick={caseDemoOpen}>Demo spin</button>
                            </div>
                        </div>
                        <div className="provably desktop">
                            <div className="rewards_icon_lock"></div>
                            PROVABLY FAIR
                        </div>
                    </div>
                </div>
            </div>

            <div className="case_open_block">
                <OpenOneCase
                    roullete={roullete} 
                    itemsOpenList={itemsOpenList}
                />
            </div>
            
        </>
            
    );
};

export default GameOpenOne;