import { useState } from "react"
import Store from "../../Store"
import Notice from "../../Notice"
import Administrator from "../services/Administrator"
import Api from "../../Api"

export default function useAdminNews() {
    let [edit, setEdit] = useState(false)
    const [load, setLoad] = useState(true)
    let [error, setError] = useState({
        name: false,
        image: false,
    })
    let [newsParams, setNewsParams] = useState({
        name: '',
        image: '',
    })

    function changeNewsParams(e) {
        setError(prev => ({...prev, [e.target.name]: false}))

        if(!e.target.value) {
            setError(prev => ({...prev, [e.target.name]: true}))
        }

        setNewsParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    /* ---------------Получение существубщей новости-------------- */

    function getEditNews(data) {
        setNewsParams({
            id: data.id,
            name: data.name,
            image: data.image,
        })

        setEdit(prev => prev = true)
    }

    Store.useListener('ADMIN_EDIT_NEWS_BTN', getEditNews)

    async function sendNews(method, path) {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: method,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
                credentials: 'include',
                body: JSON.stringify({
                    name: newsParams.name,
                    image: newsParams.image,
                })
            })
            if(res.status === 401) {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: method,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        name: newsParams.name,
                        image: newsParams.image,
                    })
                })
            }
            if (res.status === 409) {
                return 'exist'
            }
            if (!res.ok) {
                return 'error'
            } 

            res = await res.json()

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    function createNews() {
        setError(prev => ({...prev, name: false, image: false}))
        document.getElementById('err_news_exist').classList.remove('error')

        let err = false
        if(newsParams.name === '') {
            err = true
            setError(prev => ({...prev, name: true}))
        }
        if(newsParams.image === '') {
            err = true
            setError(prev => ({...prev, image: true}))
        }
        if(err) {
            return
        }
        (async () => {
            setLoad(prev => prev = false)
            if(edit) {
                const data = await sendNews('PUT', `api/v1/news/${newsParams.id}`)

                setLoad(prev => prev = true)

                if (data === 'error') {
                    Notice.Send({type: 'error', text: 'Server error'})
                    return
                }
                if (data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true}))
                    document.getElementById('err_news_exist').classList.add('error')
                    return
                }

                Store.setListener('ADMIN_EDIT_NEWS_GLOBAL', data)
                
                Administrator.onSuccess('News edit!')

            } else{
                const data = await sendNews('POST', `api/v1/news/create`)
                setLoad(prev => prev = true)
                if (data === 'error') {
                    Notice.Send({type: 'error', text: 'Server error'})
                    return
                }
                if (data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true}))
                    document.getElementById('err_news_exist').classList.add('error')
                    return
                }

                Store.setListener('ADMIN_CREATE_NEWS_GLOBAL', data)
                
                Administrator.onSuccess('News create!')

            }
        })()
    }

    function closeAdminPopup () {
        document.getElementById('adminNewsPopup').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')

        setError({
            name: false,
            image: false,
        })
        setNewsParams({
            name: '',
            image: '',
        })
    }

    return {load, error, setError, edit, newsParams, setNewsParams, changeNewsParams, closeAdminPopup, createNews}
}