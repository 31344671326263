import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './css/user_components.css'

import UserHeader from './components/UserHeader';
import Account from './tabs/account/Account';
import Stats from './tabs/stats/Stats';
import Inventory from './tabs/inventory/Inventory';
import Deposits from './tabs/deposits/Deposits';
import AccountInfo from './components/AccountInfo';
import GameHistory from './tabs/game_history/GameHistory';
import Title from '../../mainTitle';
import NotFoundPage from '../../components/NotFoundPage';
import Api from '../../Api';
import Store from '../../Store';
import DiceHistory from './tabs/game_history/histoty_tabs/dice/DiceHistory';
import CrashHistory from './tabs/game_history/histoty_tabs/crash/CrashHistory';
import CasesHistory from './tabs/game_history/histoty_tabs/cases/CasesHistory';
import RouletteHistory from './tabs/game_history/histoty_tabs/roulette/RouletteHistory';
import UpgradeHistory from './tabs/game_history/histoty_tabs/upgrade/UpgradeHistory';
import CaseBattleHistory from './tabs/game_history/histoty_tabs/case_battles/CaseBattleHistory';


const User = () => {

    const [profile, setProfile] = useState()
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {

        (async () => {

            let res = await Api.asyncGetCred('api/v1/auth/me/profile')
            
            if (res === 'unAuth' || res === 'error') {
                Store.setListener('openAuthPopUp', prev => prev = true)
                document.querySelector('html').classList.add('hidden')
                return navigate('/')
            } else {
                setProfile(res.client)
                setLoad(true)
            }

        })()

        Title.setListener('title', 'USER')
    }, [])


    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    return (
        <div className="user_page container">
            {load ? 
            <>
                <UserHeader screenWidth={screenWidth}/>
                <AccountInfo profile={profile} load={load}/>
                <Routes>
                    <Route path='account' element={<Account profile={profile}/>}/>
                    <Route path='stats' element={<Stats />}/>
                    <Route path='inventory' element={<Inventory profileId={profile?.id} />}/>
                    <Route path='deposits' element={<Deposits />}/>
                    <Route path='game_history' element={<GameHistory />}>
                    
                        <Route index element={<CasesHistory />}/>
                        <Route path='dice' element={<DiceHistory />}/>
                        <Route path='case_battles' element={<CaseBattleHistory />} />
                        <Route path='crash' element={<CrashHistory />}/>
                        <Route path='roulette' element={<RouletteHistory />}/>
                        <Route path='upgrade' element={<UpgradeHistory />}/>

                    </Route>
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </>
            :
                <></>
            }
        </div>
    );
};

export default User;