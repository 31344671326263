import React from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import avatar from './../../../images/avatar.svg'
import Avatar from '../../../components/avatar/Avatar';
import Price from '../../../components/price/Price';


const RaceListItem = (props) => {
    return (
        <div className='race_list_item main_card'>
            <div className="player_grid">
                <p className="number">#{props.number}</p>
                <Avatar 
                    img={props.el.avatar}
                    lvl={props.el.level}
                    percent={(props.el.experience / props.el.experienceBorderTillLevel) * 100}
                    color={'#30dba8'}
                    size={40}
                    rad={17}
                    dasharray={107}
                />
                <h6 className="name">{props.el.username}</h6>
                <div className="price">
                    <img src={coin} alt="" />
                    <Price price={props.el.total}/>
                </div>
                
            </div>
            {props.withBtn ?
                <button className='common_button flex green full'>
                    PRIZE
                    <img src={coin} alt="" />
                    <Price price={props.el.won}/>
                </button>
            : <></>}
        </div>
    );
};

export default RaceListItem;