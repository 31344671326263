import { useEffect, useState } from "react"
import PromoServices from "../services/PromoServices"
import Store from "../../../Store"
import Notice from "../../../Notice"

export default function useReferalList () {
    let [load, setLoad] = useState(false)

    let [refereesList, setRefereesList] = useState([])
    let [paramsUrl, setParamsUrl] = useState({
        size: 5,
        page: 1,
        status: 'ACTIVE',
        clientId: JSON.parse(localStorage.getItem('client')).id,
    })

    //Store.useListener('status', setParamsUrl)

    useEffect(() => {
        (async () => {
            setLoad(prev => prev = false)
            const params = new URLSearchParams(paramsUrl).toString();
            let init = await PromoServices.getReqCookie(`api/v1/referrals/me?${params}`)
            if(init == 'error') {
                return Notice.Send({type: 'error', text: 'Ошибка сервера'})
            }
            console.log(init)
            setRefereesList(init)
            setLoad(true)
        })()

    }, [paramsUrl])

    function changeFilter(e) {
        const refer = document.querySelector('.referees_filters')
        const allBtn = refer.querySelectorAll('button')
        allBtn.forEach(el => {
            if(el === e.target) {
                el.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParamsUrl(prev => ({...prev, status: e.target.name}))
    }

    return {load, refereesList, paramsUrl, setParamsUrl, changeFilter}
}