import React from 'react';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg';
import Price from '../../../../../components/price/Price';

const StatusBlock = ({type, totalCost, position, status}) => {

    /*const getColor = () => {
        if(type === '2vs2') {
            return position < 3 ? 'color1' : 'color3'
        } else {
            return 'color' + position
        }
    }*/

    return (
        <div className={`status_block main_card ${status}`}>
            <h5>{status}</h5>
            <div className='total'>
                <p>Total win:</p>
                <div className="flex">
                    <img src={coin} alt="" />
                    <p><Price price={totalCost}/></p>
                </div>
                
            </div>
        </div>
    );
};

export default StatusBlock;