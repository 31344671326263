import React from 'react';

const AdminAllUsersBtn = () => {

    function openAdminPopup() {
        document.getElementById('adminAllUsers').classList.add('open')
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className='icon_button admin mobile'  onClick={() => openAdminPopup()}>
            <div className="admin_icon_users"></div>
        </button>
    );
};

export default AdminAllUsersBtn;