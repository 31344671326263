import React from 'react';
import FreeCase from './FreeCase';

const FreeCaseList = ({list}) => {

    return (
        <>
            <div className='cases_grid_admin_title'><span>FOR ADMIN</span>Free cases</div>

            <div className='cases_grid admin'>
                {list.map((el) => (
                    <FreeCase key={el.id} el={el}/>
                ))}
            </div>
        </>
    );
};

export default FreeCaseList;