import { useEffect, useMemo, useRef, useState } from "react"
import Store from "../../../Store"
import Api from "../../../Api"
import Notice from "../../../Notice"
import Games from "../../../Games"
import UpgradeServices from "../services/UpgradeService"

export default function useUpgradeGame() {
    const [range, setRange] = useState(50)
    const [bet, setBet] = useState(false)
    const [percent, setPercent] = useState(0)
    const [itemList, setItemList] = useState(false)
    const [arrow, setArrow] = useState(0)
    const [roll, setRoll] = useState('under')
    const [error, setError] = useState({
        value: false
    })
    const maxBet = useRef(0)
    const arrowRef = useRef()
    const arrowPointer = useRef()
    const decor = useRef()
    const fill = useRef()
    const inProgress = useRef(false)
    const timer = useRef()
    const rangetimer = useRef()
    const arrowTimer = useRef()
    const coef = useRef(0)
    const isWager = useRef(false)
    const percentSymb = useRef(null)

    Store.useListener('ADD_NEW_ITEM_UPGRADE', (data) => {
        maxBet.current = 0
        arrowPointer.current.style.color = '#AE79FF'
        decor.current.style.stroke = '#AE79FF'
        fill.current.style.stroke = '#AE79FF'
        percentSymb.current.style.color = '#AE79FF'
        if(!data?.length) {
            setPercent(0)
            setBet(false)
            if(arrow !== 0) {
                zeroArrow()
            }
        } else {
            data.map(el => {
                maxBet.current = maxBet.current + el.price
            })
            if(+bet > 0 && data.length > 0 && (itemList.length === 1 || itemList === false)) {
                if(bet > maxBet.current * 0.99) {
                    countBet('max', data)
                    setRange(95)
                } else if(+bet < maxBet.current * 0.0001) {
                    countBet('0.1', data)
                    setRange(0.1)
                } else {
                    let percent = (bet / maxBet.current) * 100
                    countBet(percent, data)
                    setRange(percent)
                }
                
                
            } else {
                countBet(range, data)
            }
        }
        setItemList(data)
    })

    function zeroArrow() {
        const zero = (1080 - +arrow) + +arrow
        arrowRef.current.style.transitionDuration = '1.5s'
        arrowRef.current.style.transform = `rotate(${zero}deg)`
        arrowTimer.current = setTimeout(() => {
            arrowRef.current.style.transitionDuration = ''
            setArrow(prev => prev = 0)
        }, 1550)
    }

    const inputBet = (value) => {

        if(inProgress.current) {
            return
        }

        setError(prev => ({...prev, value: false}))

        arrowPointer.current.style.color = '#AE79FF'
        decor.current.style.stroke = '#AE79FF'
        percentSymb.current.style.color = '#AE79FF'
        fill.current.style.stroke = '#AE79FF'

        if(!value) {
            setPercent('0.00')
            setError(prev => ({...prev, value: true}))
        }

        let bet = value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');

        if(itemList?.length) {
            const max = maxBet.current * 0.99
            const min = maxBet.current* 0.0001

            let betRange = (bet / maxBet.current) * 100

            if(+bet < +min.toFixed(2) || bet < 0.01) {
                setError(prev => ({...prev, value: true}))
                setBet(bet)
                setPercent('0.00')
                return
            }
            if(+bet > +max.toFixed(2)) {
                betRange = 95
                bet = max.toFixed(2)
            }

            setRange(betRange)

            UpgradeServices.getPercent(bet, setPercent, itemList)
        }

        let str = bet.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setBet(str.join('.'))
            }
            
        setBet(bet)
    }

    const countBet = (value, list) => {
        let price 

        if(value === 'max') {
            price = maxBet.current * 0.99
            setBet(price.toFixed(2))
        } else {
            price = maxBet.current * (value / 96)

            setBet(price.toFixed(2))
        }

        if(price < 0.01) {
            setError(prev => ({...prev, value: true}))
            return
        }

        setError(prev => ({...prev, value: false}))

        UpgradeServices.getPercent(price.toFixed(2), setPercent, list)
    }   

    const changeRange = (value) => {
        if(inProgress.current) {
            return
        }

        if(itemList?.length) {
            countBet(value, itemList)
        }

        arrowPointer.current.style.color = '#AE79FF' 
        decor.current.style.stroke = '#AE79FF'
        percentSymb.current.style.color = '#AE79FF'
        fill.current.style.stroke = '#AE79FF'

        rangetimer.current = setTimeout(() => {
            if(value === 'max'){
                setRange(95)
            } else {
                setRange(value)
            }
        }, 50)
    }

    const changeRoll = () => {
        if(inProgress.current) {
            return
        }
        
        setRoll(roll === 'under' ? 'over' : 'under')

        coef.current = roll === 'under' ? 100 : 0
        arrowRef.current.style.transitionDuration = '1s'
        if(arrow !== 0) {
            setArrow(prev => ((360 - (+prev - 720)) - (+prev - 720)) + +prev)
        }
    }

    const startGame = async () => {

        if(inProgress.current) {
            return
        }

        decor.current.style.stroke = '#AE79FF'
        fill.current.style.stroke = '#AE79FF'
        arrowPointer.current.style.color = '#AE79FF'
        percentSymb.current.style.color = '#AE79FF'

        if(!itemList?.length) {
            Notice.Send({type: 'error', text: `Choose item before play!`})
            return
        }

        let min = maxBet.current* 0.0001
        if(min < 0.01) {
            min = 0.01
        }
        min = min.toFixed(2)

        if(+bet < +min) {
            setError(prev => ({...prev, value: true}))
            Notice.Send({type: 'error', text: `Sorry, your bet can't be less then: ${min}`})
            return
        }

        document.getElementById('playUpgrade').classList.add('close')
        document.getElementById('upgradeRangeInput').classList.add('close')
        document.querySelector('.case_btl_icon_arrows').classList.add('close')
        inProgress.current = true

        let items = new URLSearchParams()

        itemList.map(el => {
            items.append('fakeItemsIds', el.id)
        })

        const wagers = localStorage.getItem('balanceField')
        const forWagers = wagers && wagers === 'wager' ? true : false

        if(isWager.current !== forWagers) {
            let item = new URLSearchParams()

            itemList.map(el => {
                item.append('fakeItemIds', el.id)
            })

            await fetch(`${Api.url}/api/v1/game-upgrade/calculate?${item}&forWagers=${forWagers}&bet=${bet}`, {
                credentials: 'include'
            })
            .then(res => {
                if(!res.ok) {
                    return 
                }
            })
            .catch(error => console.log(error))

            isWager.current = forWagers
        }
        
        let rollValue
        
        if(roll === 'under') {
            rollValue = percent
        } else {
            rollValue = 100 - percent
        }
        
        const rollDec = Games.rollUnder(coef.current, parseFloat(rollValue))

        const init = await Games.dicePlay(`api/v1/game-upgrade/play?rollUnder=${rollDec}&${items}`)

        if (init === 'no balance') {
            Notice.Send({type: 'error', text: 'There are not enough funds on the balance sheet'})
            inStopGame(inProgress)
            return
        } 
        if (init === 'unAuthorization') {
            Store.setListener('openAuthPopUp', prev => prev = true)
            document.querySelector('html').classList.add('hidden')
            inStopGame(inProgress)
            return
        }
        if (init === 'error') {
            Notice.Send({type: 'error', text: 'Something went wrong'})
            inStopGame(inProgress)
            return
        }

        if(arrow !== 0) {
            arrowRef.current.style.transitionDuration = ''
            setArrow(prev => prev - 720) 
        }

        await Api.updateAccount()

        arrowRef.current.style.transitionDuration = '3s'

        if(init.items?.length) {
            if(roll === 'under') {
                let arrowAngle = 360 * (percent / 100)
                randomNumber(0.1, arrowAngle, setArrow)
            } else {
                let arrowAngle = 360 * ((100 - percent )/ 100)
                randomNumber(arrowAngle, 359, setArrow)
            }
            timer.current = setTimeout(() => {
                Store.setListener('WIN_MODAL_UPGRADE', init.items)
                decor.current.style.stroke = '#00DC9A'
                fill.current.style.stroke = '#00DC9A'
                arrowPointer.current.style.color = '#00DC9A'
                percentSymb.current.style.color = '#00DC9A'
                inStopGame(inProgress)
            }, 3000)
        } else {
            if(roll === 'under') {
                let arrowAngle = 360 * (percent / 100)
                randomNumber(arrowAngle, 358, setArrow)
            } else {
                let arrowAngle = 360 * ((100 - percent )/ 100)
                randomNumber(0, arrowAngle, setArrow)
            }
            timer.current = setTimeout(() => {
                decor.current.style.stroke = '#E94040'
                fill.current.style.stroke = '#E94040'
                arrowPointer.current.style.color = '#E94040'
                percentSymb.current.style.color = '#E94040'
                inStopGame(inProgress)
            }, 3000)
        }
    }

    function inStopGame(ref) {
        document.getElementById('playUpgrade').classList.remove('close')
        document.getElementById('upgradeRangeInput').classList.remove('close')
        document.querySelector('.case_btl_icon_arrows').classList.remove('close')
        ref.current = false
    }

    function randomNumber(min, max, callback) {
        let angle = Math.floor(Math.random() * (max - min + 1)) + min;
        if(arrow !== 0) {
            angle = +angle.toFixed(2)
            callback(angle + 720)
        } else {
            callback((angle + 720).toFixed(2))
        }
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
            clearTimeout(arrowTimer.current)
            clearTimeout(rangetimer.current)
        }
    }, [])

    return {error, arrow, arrowRef, arrowPointer, decor, fill, roll, bet, maxBet, percent, range, percentSymb,  changeRoll, changeRange, inputBet, setError, startGame}
};