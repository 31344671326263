import { useEffect, useState } from "react";
import Api from "../Api";
import Notice from "../Notice";

export default function useProvably(config) {

    const {setOpen, params, LINK, VLINK} = config

    const [salt, setSalt] = useState(false)
    const [paramsUrl, setParamsUrl] = useState(params)
    const [loadBtn, setLoadBtn] = useState(true)
    const [loadSeed, setLoadSeed] = useState(true)
    const [result, setResult] = useState(false)

    useEffect(() => {
        (async () => {
            await fetch(`${Api.url}/${LINK}`, {
                credentials: 'include'
            })
            .then(res => {
                if(!res.ok) {
                    return 'error'
                }
                return res.text()
            })
            .then(data => {
                if(data === 'error'){
                    return Notice.Send({type: 'error', text: 'Something went wrong'})
                }
                setSalt(data)
            })
            .catch(error => console.log(error))
            
        })()
    }, [])

    function verify () {
        if(!salt) {
            Notice.Send({type: 'error', text: 'Waiting for Salt!'})
            return
        }
        (async () => {
            setLoadBtn(prev => prev = false)
            const query = new URLSearchParams(paramsUrl).toString()
            await fetch(`${Api.url}/${VLINK}${query}&salt=${salt}`, {
                credentials: 'include'
            })
            .then(res => {
                if(!res.ok) {
                    return 'error'
                }
                return res.text()
            })
            .then(data => {
                setLoadBtn(prev => prev = true)
                if(data === 'error'){
                    return Notice.Send({type: 'error', text: 'Something went wrong'})
                }
                setResult(data)
            })
            .catch(error => console.log(error))
        })()
    }

    async function changeSeed() {
        setLoadSeed(prev => prev = false)
        await fetch(`${Api.url}/api/v1/client-seeds`, {
            credentials: 'include',
        }) 
        .then(res => {
            if (!res.ok) {
                return 'error'
            }
            return res.json()
        })
        .then(data => {
            setLoadSeed(prev => prev = true)
            if(data === 'error') {
                return Notice.Send({type: 'error', text: 'Something went wrong'})
            }
            setParamsUrl(prev => ({...prev, clientSeed: data.value}))
        })
        .catch(error => console.log(error))
    }    

    const closeModal = () => {
        setOpen(prev => prev = false)
        setResult(false)
        document.querySelector('html').classList.remove('hidden')
    }

    return {result, setResult, loadBtn, loadSeed, paramsUrl, setParamsUrl, salt, closeModal, verify, changeSeed}
}