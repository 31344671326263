import React, { useEffect, useState } from 'react';

import Api from '../Api';
import Notice from '../Notice';
import { type } from '@testing-library/user-event/dist/type';

const TFAPopup = ({setOpen, setTFAEnable}) => {

    const [authCode, setAuthCode] = useState('')
    const [qrCode, setQrCode] = useState(false)
    const [error, setError] = useState(false)
    const [loadBtn, setLoadBtn] = useState(true)

    useEffect(() => {
        (async () => {
            await fetch(`${Api.url}/api/v1/auth/generate/2fa`, {
                method: 'POST',
                credentials: 'include',
                redirect: 'follow',
            })
            .then(res => {
                if(res.status != 200){
                    return 'error'
                }
                return res.text()
            })
            .then(data => {
                if(data === 'error') {
                    return
                }
                setQrCode(data)
            })
            .catch(error => console.log(error))
        })()
    }, [])

    function onInput(e) {
        setError(false)

        if(!e.target.value) {
            setError(true)
        }

        let value = e.target.value.replace(/[^0-9]/g,'')

        setAuthCode(value)
    }

    async function sendCode (e) {
        e.preventDefault()

        if(authCode === '') {
            setError(true)
            return
        }
        setLoadBtn(prev => prev = false)

        await fetch(`${Api.url}/api/v1/auth/enable/2fa`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'X-OTP-Code': authCode.toString(),
            },
            credentials: 'include'
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            setLoadBtn(prev => prev = true)

            if(data.statusCode) {
                Notice.Send({type: 'error', text: `${data?.exception?.message || 'Error. Try again later'}`})
                return
            }

            setTFAEnable(prev => prev = true)
            Notice.Send({type: 'success', text: 'You turn on 2FA'})
            closeQR()
        })
        .catch(error => console.log(error))
        setAuthCode('')
    }

    function closeQR () {
        setOpen(prev => ({...prev, tfaPopup: false}))
        document.querySelector('html').classList.remove('hidden')
    }

    return (
        <div className='two_factor_auth_popup_wrapper' onClick={closeQR}>
            <div className="two_factor_auth_popup" onClick={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Two-factor authorization</div>
                    <div className="decor pink" onClick={closeQR}></div>
                </div>
                <div className="two_factor_auth_content">
                    {qrCode ? 
                        <img src={qrCode} alt="" />
                        
                        : 
                        
                        <div className='qr_loader'>
                            Loading...
                        </div>
                    }
                    
                    <p>To enable two-factor authorization, scan the QR code, then enter the code below:</p>

                    <form>
                        <input 
                            type="text"
                            inputMode='numeric' 
                            className={`main_input main_card ${error ? 'error' : ''}`} 
                            placeholder='Enter code...'
                            id='qr_code_input'
                            value={authCode}
                            onChange={(e) => onInput(e)}
                            onBlur={(e) => setError(false)}
                        />
                        <button className="common_button green full" onClick={(e) => sendCode(e)}>
                            {loadBtn ?
                                'Send code'
                            :
                                'Loading...'
                            }
                        </button>
                    </form>

                    
                </div>   
            </div>
        </div>
    );
};

export default TFAPopup;