import React, { useState } from 'react';

import promo_smile from './../images/promo_smile.svg'
import usePromoCode from '../hooks/usePromoCode';

const EarnBlock = ({isAuth}) => {

    const {load, error, setError, messageContent, promoCode, inputCode, sendPromoCode} = usePromoCode(isAuth)

    return (
        <div className="earn_block">
            <h1>EARN UP TO 10%</h1>
            <p>ON DEPOSITS FROM <br />
                YOUR FRIENDS
            </p>

            <form className={error.value && 'error'} action="" onSubmit={sendPromoCode} id='earn_input'>

                {error.mess && <div className="error_message">{messageContent}</div> }
                {error.nocode && <div className="error_message">There is no such client with this referral code!</div>}
                
                <input 
                    type="text" 
                    placeholder='Enter referral code*'  
                    value={promoCode} 
                    onInput={(e) => inputCode(e.target.value)}
                    onBlur={() => setError(prev => ({...prev, value: false}))}
                />

                <button className='common_button pink'>
                    {load ? 'CLAIM NOW' : 'Loading...'}
                </button>

            </form>

            {/*refError && <div className="error_message">{refError}</div>*/}

            {/*<div className="link_block">
                <img src={docs} alt="" onClick={Copy}/>
                <a id='earnLink' href={referalLink ? referalLink : 'https://spinrust.com'}>{referalLink ? referalLink : 'https://spinrust.com'}</a>
            </div>*/}
            <img src={promo_smile}  alt="" className="promo_smile" />
        </div>
    );
};

export default EarnBlock;