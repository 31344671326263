import React, { useState } from 'react';
import './../../../../components/css/components.css'
import useProvably from '../../../../hooks/useProvably';
import Store from '../../../../Store';

const ProvablyModal = () => {
    const [open, setOpen] = useState(false)

    Store.useListener('CRASH_PROVABLY_FAIR', (data) => {
        setParamsUrl(prev => ({...prev, gameHash: data}))
        setOpen(prev => prev = true)
    })
    
    const LINK = 'api/v1/game-crash/public-bitcoin-hash'
    const VLINK = 'api/v1/game-crash/verify?'
    
    const {result, loadBtn, paramsUrl, setParamsUrl, salt, closeModal, verify} = useProvably({setOpen, LINK, VLINK})
    
    if(!open) return null

    return (
        <div className='provably_modal_wrapper' onMouseDown={() => closeModal()}>
            <div className="provably_modal_window" onMouseDown={(e) => e.stopPropagation()}>

                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_scales"></div>
                    </div>   
                    <h4 className='game_title'>Provably fair</h4>
                    <div className="decor pink" onMouseDown={() => closeModal()}></div>
                </div>

                <div className="provably_modal_content">

                    <div className="item">
                        <h3>Game hash</h3>

                        <div className="info"> <span>{paramsUrl.gameHash}</span> </div>
                    </div>

                    <div className="item">
                        
                        <h3>Salt</h3>
                        
                        <div className="info">
                            {salt ?
                                <span>{salt}</span>
                            :
                                'Loading...'
                            }
                        </div>

                    </div>

                    <div className="item">
                        <h3>Result</h3>

                        <div className="item_flex">
                            <div className="result main_card">
                                {result ? result : '????'}
                            </div>

                            <button className='common_button green full flex' onMouseDown={() => verify()}>
                                {loadBtn ? 'Verify' : 'Loading...'}
                            </button>
                        </div>

                    </div>

                </div>

            </div>            
        </div>
    );
};

export default ProvablyModal;