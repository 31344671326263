import React, { useEffect, useState } from 'react';
import Avatar from '../../../components/avatar/Avatar';

const LastListItemPlayer = ({player, totalWin, player2, position}) => {

    const [isWinner, setIsWinner] = useState('lose')

    const getWinner = () => {
        if(player2) {
            if(totalWin === player?.totalPayout || player2?.totalPayout === totalWin) {
                return 'win'
            } else {
                return 'lose'
            }
        } else {
            return totalWin == player?.totalPayout ? 'win' : 'lose'
        }
    }

    useEffect(() => {

        setIsWinner(getWinner())

    }, [totalWin, player, player2])

    return (
        <div className={`last_battle_list_item ${isWinner}`}>
            {player?.clientId 
            ?<Avatar 
                img={player?.avatar}
                lvl={player?.level}
                percent={(player?.experience / player?.experienceBorderTillLevel) * 100}
                color={'rgb(233, 63, 63)'}
                size={40}
                rad={17}
                dasharray={107}
            />
            :<div className={`bot border${position}`}></div>
            }
            
        </div>
    );
};

export default LastListItemPlayer;