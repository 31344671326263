import React from 'react';

import avatar from './../../../images/avatar.svg'
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import LastListItem from './LastListItem';


const LastBattles = ({lastBattles, getType}) => {
    return (
        <>
            {lastBattles?.length 
            ? 
                <div className="last_batttles_block">

                    <div className="title">Last battles</div>

                    <div className="last_battles_list_wrapper">
                        <div className="last_battles_list">

                            {lastBattles.map((el, i) => (
                                <LastListItem getType={getType} el={el} key={i}/>
                            ))}

                        </div>
                        
                        
                    </div>

                </div>
            :<></>}
           
        </>
        
    );
};

export default LastBattles;