import React, { useEffect } from 'react';

import './css/stats.css'

import TotalWegared from './components/TotalWegared';
import HighestRouletteWin from './components/HighestRouletteWin';
import HighestCrashWin from './components/HighestCrashWin';
import BestUnboxedItem from './components/BestUnboxedItem';
import LuckiestBox from './components/LuckiestBox';
import Store from '../../../../Store';
import useStats from './hooks/useStats';

const Stats = () => {

    useEffect(() => {
        Store.setNumListener('page', 1)
    }, [])

    const {userStat} = useStats()

    return (
        <div className='stats_block'>
            
            <TotalWegared totalWagered={userStat.totalWagered}/>

            <div className="stats_grid">

                <HighestRouletteWin maxRouletteWin={userStat.maxRouletteWin}/>

            </div>

            <HighestCrashWin maxCrashWinData={userStat.maxCrashWinData}/>

            <BestUnboxedItem bestWonItem={userStat.bestWonItem}/>

            <LuckiestBox bestCase={userStat.bestCase}/>

        </div>
    );
};

export default Stats;