import React, { useEffect, useRef, useState } from 'react';
import helmet from './../images/helmet.png'
import helmet2 from './../images/helmet2.png'
import helmet3 from './../images/helmet3.png'
import header from './../images/header.png'
import truba from './../images/truba.png'


const RouletteWrapper = ({roullete}) => {

    let [clone, setClone] = useState([])

    useEffect(() => {
        let list = []

        for(let i = 0; i<8; i++) {
            list.push(i)
        }

        setClone(list)

    }, [])

    return (
        <div className="roulette_wrapper">

            <div className="roulette" ref={roullete}>

                {clone.map((e, i) => (
                    <div className="roulette_track" key={i}>

                        <div className="roulette_item green">
                            <img src={truba} alt="" />
                        </div>

                        <div className="roulette_item red1">
                            <img src={helmet2} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black1">
                            <img src={header} alt="" />
                        </div>

                        <div className="roulette_item red2">
                            <img src={helmet} alt="" />
                        </div>

                        <div className="roulette_item black2">
                            <img src={helmet3} alt="" />
                        </div>

                    </div>
                ))}
            </div>


            <div className="triangle top"></div>
            <div className="triangle"></div>
        </div>
    );
};

export default RouletteWrapper;