import { useEffect, useState } from "react"
import Api from "../../Api"
import Store from "../../Store"
import Notice from "../../Notice"
import { useNavigate } from "react-router-dom"

export default function useGiveRole(el) {
    let [role, setRole] = useState(false)
    let [load, setLoad] = useState(true)
    const navigate = useNavigate()

    const PATH_TO_ASSIGN = `api/v1/moderation/assign-moderator`
    const PATH_TO_REMOVE = 'api/v1/moderation/remove-moderator'

    Store.useListener('TFA_CHANGE_USER_ROLE', (data) => {
        setRole(prev => prev = data)
    })

    const assignModerator = async (path) => {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json;'
                },
                credentials: 'include'
            })

            res = await res.json()

            if(res.statusCode === 401 && res?.exception?.message === 'Unauthorized') {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json;'
                    },
                    credentials: 'include'
                })

                res = res.json()
            }

            if(res.statusCode === 401 && res?.exception?.message === 'No OTP code provided!') {
                return 'tfa_confirm'
            }

            if (res.statusCode !== 200) {
                return 'error'
            }

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    const changeRole = async (path) => {
        setLoad(prev => prev = false)

        const data = await assignModerator(`${path}/${el.user.id}`)

        setLoad(prev => prev = true)
        
        if(data === 'unAuth') {
            Notice.Send({type: 'error', text: 'The session has expired. You will be redirected to the main page'})
            setTimeout(() => {
                navigate('/')
                window.location.reload()
            }, 3000);
            return
        }

        if(data === 'tfa_confirm') {
            const options = {
                path: `${path}/${el.user.id}`,
                marker: 'CHANGE_ROLE2',
                method: 'PATCH'
            }
            Store.setListener('CONFIRM_TFA_POPUP', options)
            return
        }

        if(data === 'error'){
            Notice.Send({type: 'error', text: 'Error. Try again later'})
            return
        }

        setRole(data.role)
        return
    }

    useEffect(() => {
        setRole(el.user.role)
    }, [el])

    return {role, load, PATH_TO_ASSIGN, PATH_TO_REMOVE, changeRole}
}