import { useEffect, useRef, useState } from "react"
import Api from "../../../Api"
import Notice from "../../../Notice"

export default function useItemsStore() {
    const [load, setLoad] = useState(false)
    const [itemList, setItemList] = useState(false)
    const [price, setPrice] = useState(['all'])
    const [params, setParams] = useState({
        size: 1000,
        page: 1,
        order: 'ASC',
        field: 'id',
        type: 'all',
        namePrefix: '',
    })
    const itemStore = useRef()

    const searching = (e) => {
        setLoad(prev => prev = false)
        setParams(prev => ({...prev, namePrefix: e.target.value}))
    }

    useEffect(() => {
        (async () => {
            const query = new URLSearchParams(params).toString()
            await fetch(`${Api.url}/api/v1/fake-item/all?${query}`)
            .then(res => {
                if(!res.ok) {
                    return 'error'
                }
                return res.json()
            })
            .then(data => {
                setLoad(prev => prev = true)
                if(data === 'error') {
                    return Notice.Send({type: 'error', text: 'Something went wrong'})
                }

                if(!itemList) {
                    itemStore.current = data.items
                }

                const list = data.items.filter(el => !el.name.includes("(Case Battle)"))
                
                if(price[0] === 'all') {
                    setItemList(list)
                    return
                }
                if(price.length > 1) {
                    let fakeList = []
                    for(let i = 0; i < price.length; i++) {
                        if(price[i] === '1000+') {
                            list.map(el => {
                                if(+el.price >= 1000) {
                                    if(fakeList.find(c => c.id === el.id)) {
                                        return
                                    } else {
                                        fakeList.push(el)
                                    }
                                } else {
                                    return
                                }
                            })
                        } else {
                            const prevFilter = price[i].split('-')
                            
                            list.map(el => {
                                if(+el.price >= +prevFilter[0] && +el.price <= +prevFilter[1]) {
                                    if(fakeList.find(c => c.id === el.id)) {
                                        return
                                    } else {
                                        fakeList.push(el)
                                    }
                                } else {
                                    return
                                }
                            })
                        }
                    }
                    setItemList(fakeList)
                } else {
                    if(price[0] === '1000+') {
                        setItemList(list.filter(el => +el.price >= 1000))
                    } else {
                        const prevFilter = price[0].split('-')
                        setItemList(list.filter(el => (+el.price >= +prevFilter[0] && +el.price <= +prevFilter[1])))
                    }
                }
            })
            .catch(error => console.log(error))
        })()
    }, [params, price])

    function changePriceFilter(e) {
        if(!itemList) {
            return
        }
        let filter = e.target.textContent
        const priceList = e.currentTarget.querySelectorAll('p')

        if(filter === 'all') {
            setLoad(prev => prev  = false)
            priceList.forEach(el => {
                if(el == e.target) {
                    e.target.classList.add('active')
                } else {
                    el.classList.remove('active')
                }
            })
            setPrice(['all'])
        } else {
            let clone = structuredClone(price.filter(el => el !== 'all'))
            priceList[0].classList.remove('active')
            if(e.target.classList.contains('active')){
                if(clone.length <= 1) {
                    return
                }
                setLoad(prev => prev  = false)
                setPrice(clone.filter(el => el !== filter))
                e.target.classList.remove('active')
            } else{
                setLoad(prev => prev  = false)
                clone.push(filter)
                setPrice(clone)
                e.target.classList.add('active')
            }
        }
    }

    function changeParams(e, name) {
        setLoad(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, [name]: e.target.textContent}))
    }

    return {load, itemList, params, changeParams, price, changePriceFilter, searching}
}