import React from 'react';
import './../css/components.css'
import logo from './../images/load_logo.png'
import spinner from './../images/spinner.png'
import werden from './../images/werden.svg'

const Preloader = ({mode}) => {

    return (
        <div className={`preloader_block ${mode}`}>
            <div className="preloader">
                <img src={spinner} className="spinner" />
                <img src={logo} alt='SPIN RUST' className="load_logo" />
            </div>
            <div className="button_block">
                <button className="common_button pink">Designed by <img src={werden} alt="" /></button>
            </div>
        </div>
    );
};

export default Preloader;