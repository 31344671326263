import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Store from '../../Store';

const MobilePanel = (props) => {

    const [isChatOpen, setIsChatOpen] = useState(false)

    function toggleChatMenu() {
        if(isChatOpen) {
            closeChat()
        } else {
            document.querySelector('html').classList.add('hidden')
            setIsChatOpen(true)
            Store.setListener('openChat', true)
        }
    }

    function closeChat() {
        document.querySelector('html').classList.remove('hidden')
        setIsChatOpen(false)
        Store.setListener('openChat', false)
    }

    return (
        <nav className='mobile_panel'>

            <div className='mobile_panel_item'>
                <div className="mobile_btn_shadow"></div>
                <NavLink to='/' className="mobile_btn_wrapper" onClick={closeChat}>
                    <button className="">
                        <div className='header_icon_games'></div>
                    </button>
                </NavLink>
                <p>GAMES</p>
            </div>

            <div className='mobile_panel_item'>
                <div className="mobile_btn_shadow"></div>
                <button className={`mobile_btn_wrapper ${isChatOpen ? 'active' : ''}`} id='chat_mobile_btn' onClick={toggleChatMenu}>
                    <button className="">
                        <div className='header_icon_chat'></div>
                    </button>
                </button>
                <p>CHAT</p>
            </div>
            
            {props.isAuth && 
            
            <div className='mobile_panel_item'>
                <div className="mobile_btn_shadow"></div>
                <NavLink to='/user/account' className="mobile_btn_wrapper" onClick={closeChat}>
                    <button className="">
                        <div className='header_icon_mob_acc'></div>
                    </button>
                </NavLink>
                <p>ACCOUNT</p>
            </div>

            }
            
        </nav>
    );
};

export default MobilePanel;