import { useEffect, useState } from "react";
import Api from "../../../../Api";
import Notice from "../../../../Notice";
import Store from "../../../../Store";


export default function useInventory(profileId) {

    const [load, setLoad] = useState(false)
    const [page, setPage] = useState(1)
    const [inventoryItems, setInventoryItems] = useState([])
    const [originalList, setOriginalList] = useState([])
    const [search, setSearch] = useState('')
    const [order, setOrder] = useState('Price High to Low')
    const [isOpen, setIsOpen] = useState(false)
    const [block, setBlock] = useState(false)
    const [params, setParams] = useState({
        order: 'DESC',
        field: 'price',
        size: 100000000000,
        page: 1
    })

    useEffect(() => {

        (async () => {

            if(profileId) {
                const id = profileId
            
                const query = new URLSearchParams(params).toString()

                let res = await Api.asyncGet(`api/v1/fake-items-clients/${id}?${query}`)

                if(res === 'error') {
                    return Notice.Send({type: 'error', text: 'Server error'})
                }

                res = getAllItems(res.items)
                setOriginalList(structuredClone(res))
                if(res.length < 1) {
                    setBlock(true)
                }
                setInventoryItems(chunkList(res))
                setLoad(true)
            }
            
        })()

    }, [params, profileId])

    useEffect(() => {
        Store.setNumListener('page', 2)
    }, [])

    const sellItem = async (id, index) => {
        let res = await Api.asyncGetCred(`api/v1/game-open-case/sell-item?fakeItemId=${id}`)

        if(res === 'unAuth') {
            document.querySelector('html').classList.add('hidden')
            Store.setListener('openAuthPopUp', prev => prev = true)
            return
        }

        if(res === 'no balance') {
            return
        }
        if(res === 'error') {
            return
        }
        await Api.updateAccount()

        let newList = structuredClone(originalList.filter(el => el.index !== index))

        if(search) {
            newList = newList.filter(el => el.fakeItem.name.toLowerCase().includes(search.toLowerCase()))
        }

        if(order) {
            let filter = order
            switch(filter) {
                case 'Price High to Low':
                    newList = newList.sort((a, b) => b.fakeItem.price - a.fakeItem.price)
                    break
                case 'Price Low to High':
                    newList = newList.sort((a, b) => a.fakeItem.price - b.fakeItem.price)
                    break
                case 'Name A-Z':
                    newList = newList.sort((a, b) => a.fakeItem.name.localeCompare(b.fakeItem.name));
                    break
                case 'Name Z-A':
                    newList = newList.sort((a, b) => b.fakeItem.name.localeCompare(a.fakeItem.name));
                    break
            }
        }
        
        if(newList.length < 1) {
            setBlock(true)
        }
        setInventoryItems(chunkList(newList))
        setOriginalList(prev => prev.filter(el => el.index !== index))
        Notice.Send({type: 'success', text: 'The item has been successfully sold!'})
    }

    const getAllItems = (list) => {
        
        let result = []

        if(list?.length) {
            list.forEach((el) => {
                for (let i = 0; i < el.quantity; i++) {
                    result.push({...el})
                }
            })
    
            result.forEach((el, i) => {
                el.index = i
            })

            result = result.sort((a, b) => b.fakeItem.price - a.fakeItem.price)
        }

        return result
    }

    const chunkList = (list) => {
        let pagination = {}
        let page = 1
        
        while (list.length > 0) {
            pagination[page++] = list.splice(0, 15)
        }
        return pagination
    }

    const searchItem = (name) => {
        setSearch(name)
        setPage(1)
        let list = structuredClone(originalList)

        if(name) {
            list = list.filter(el => el.fakeItem.name.toLowerCase().includes(name.toLowerCase()))
        }

        if(order) {
            let filter = order
            switch(filter) {
                case 'Price High to Low':
                    list = list.sort((a, b) => b.fakeItem.price - a.fakeItem.price)
                    break
                case 'Price Low to High':
                    list = list.sort((a, b) => a.fakeItem.price - b.fakeItem.price)
                    break
                case 'Name A-Z':
                    list = list.sort((a, b) => a.fakeItem.name.localeCompare(b.fakeItem.name));
                    break
                case 'Name Z-A':
                    list = list.sort((a, b) => b.fakeItem.name.localeCompare(a.fakeItem.name));
                    break
            }
        }

        setInventoryItems(chunkList(list))
    }

    const orderBy = (filter) => {

        if(!filter) {
            return
        }
        
        let list = structuredClone(originalList)

        setPage(1)
        setOrder(filter)

        switch(filter) {
            case 'Price High to Low':
                list = list.sort((a, b) => b.fakeItem.price - a.fakeItem.price)
                break
            case 'Price Low to High':
                list = list.sort((a, b) => a.fakeItem.price - b.fakeItem.price)
                break
            case 'Name A-Z':
                list = list.sort((a, b) => a.fakeItem.name.localeCompare(b.fakeItem.name));
                break
            case 'Name Z-A':
                list = list.sort((a, b) => b.fakeItem.name.localeCompare(a.fakeItem.name));
                break
        }

        if(search) {
            list = list.filter(el => el.fakeItem.name.toLowerCase().includes(search.toLowerCase()))
        }

        setInventoryItems(chunkList(list))
    }

    const selAllItems = async () => {

        if(block) {
            return
        }
        
        let res = await Api.asyncGetCred('api/v1/fake-items-clients/sell-all-items')
        
        if(res === 'error') {
            return
        }

        if(res === 'unAuth') {
            document.querySelector('html').classList.add('hidden')
            Store.setListener('openAuthPopUp', prev => prev = true)
            return
        }

        if(res.success) {
            setInventoryItems([])
            setOriginalList([])
            Notice.Send({type: 'success', text: 'The all items has been successfully sold!'})
            await Api.updateAccount()
            closePoPup()
            setBlock(true)
            return 
        }
    }

    const openPopUp = () => {
        document.querySelector('html').classList.add('hidden')
        setIsOpen(true)
    }

    const closePoPup = () => {
        document.querySelector('html').classList.remove('hidden')
        setIsOpen(false)
    }

    return { load, inventoryItems, sellItem, page, setPage, searchItem, search, order, setOrder, orderBy, selAllItems, isOpen, closePoPup, openPopUp, originalList, block }

}