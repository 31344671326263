import React from 'react';
import LoadingItems from '../../../../../components/LoadingItems';
import NotItems from '../../../../../components/NotItems';

const DepositList = ({list, load}) => {
    return (
        <div className="replenishment_table_list">
            
            {load ?
                <>
                    {list?.length ?
                    <>
                        {list.map((e, i) => (
                            <div className="replenishment_table_list_item" key={e.id} style={{borderTop: `${i === 0 ? '1px solid #000' : ''}`}}>
                                <div className="list_item">{e.source}</div>
                                <div className="list_item">{e.type}</div>
                                <div className="list_item">{e.value}</div>
                                <div className="list_item">{e.createdAt}</div>
                            </div>
                        ))}
                    </>
                    :
                        <NotItems />
                    }          
                </>
            :
                <LoadingItems />
            }

        </div>
    );
};

export default DepositList;