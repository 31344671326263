import React from 'react';
import usePromoCode from '../../promo/hooks/usePromoCode';

const Promocode = ({isAuth}) => {

    const {load, error, setError, messageContent, promoCode, inputCode, sendPromoCode} = usePromoCode(isAuth)

    return (
        <div className="promo_code">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="rewards_icon_promocode2"></div>
                </div>   
                <h4 className='game_title'>Promo code</h4>
                <div className="decor green"></div>
            </div>

            <form className="promo_code_window" onSubmit={(e) => sendPromoCode(e)}>
                <div className="promo_title">
                    USE PROMO CODE
                </div>
                <div className="input_wrapper">

                    {error.mess && <div className="error_message">{messageContent}</div>}

                    <input 
                        className={`promo_code_input main_card ${error.value && 'error'}`} 
                        type="text" 
                        placeholder='Enter code...'
                        value={promoCode}
                        onChange={(e) => inputCode(e.target.value)}
                        onBlur={() => setError(prev => ({...prev, value: false}))}
                    />

                    
                </div>
                
                <button className="common_button green">
                    {load ? 'Just give me my prize and that`s it...' : 'Loading...'}
                </button>
            </form>
        </div>
    );
};

export default Promocode;