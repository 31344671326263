import React from 'react';

import './css/crash.css';

import CrashGame from './components/CrashGame';
import CrashLastList from './components/CrashLastList';
import AllBet from './components/AllBet';
import Timer from './components/Timer';
import useCrash from './useCrash';


const Crash = ({mode, isAuth}) => {

    const crashObj = useCrash(mode, isAuth)

    return (
        <div className='crash_block container'>
            <Timer timeField={crashObj.timeField}/>
            <CrashGame crashObj={crashObj}/>
            <CrashLastList crashLast={crashObj.crashLast} colorUpdate={crashObj.colorUpdate} last12={crashObj.last12} setLast12={crashObj.setLast12}/>
            <AllBet allBets={crashObj.allBets} setAllBets={crashObj.setAllBets} sortByBet={crashObj.sortByBet} colorUpdate={crashObj.colorUpdate}/>
        </div>
    );
};

export default Crash;