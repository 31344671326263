import React from 'react';

import coin from './../../../../../../fonts/main_icon_fonts/coin.svg'

const HistoryList = ({list}) => {

    const getBestBet = () => {
        const newlist = list.map((el) => {
            return el.game.bets.filter(el => el.totalPayout > 0)
        })

        console.log(newlist)
    }

    getBestBet()

    return (
        <div className="history_list roulette">
            {list && list.map((e, i) => (
                <div className="history_list_item" key={i}>
                    <div className="id"><span>{e.id}</span></div>

                    <div className="created">
                        {e.time[0]}
                        <p className="time">{e.time[1]}</p>
                    </div>

                    <div className="initial_bet">
                        <img src={coin} alt="" />
                        <span>{e.totalBetValue}</span>
                    </div>

                    <div className="color">
                        
                    </div>

                    <div className={`payout ${+e.totalPayout < 0 ? 'red' : 'green'}`}>
                        <img src={coin} alt="" />
                        {e.totalPayout}
                    </div>

                </div>
            ))}
        </div>
    );
};

export default HistoryList;