import React, { useEffect, useState } from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import LastListItemPlayer from './LastListItemPlayer';
import battle from './../images/battle.svg'
import Price from '../../../components/price/Price';


const LastListItem = ({getType, el}) => {

    const [players, setPlayers] = useState([])
    const [totalWin, setTotalWin] = useState(0)

    useEffect(() => {

        if (el) {
            let total = 0;
            let allPlayers = [];

            if (el.clients?.length) {
                el.clients.forEach(element => {
                    if (element.totalPayout > total) {
                        total = element.totalPayout;
                    }
                    allPlayers.push(element);
                });
            }
            if (el.bots?.length) {
                el.bots.forEach(element => {
                    if (element.totalPayout > total) {
                        total = element.totalPayout;
                    }
                    allPlayers.push(element);
                });
            }

            setPlayers(allPlayers);
            setTotalWin(total);
        }

    }, [el])

    const getPlayer = (position, team) => {
        if(players?.length) {
            if(el[team]?.length) {
                const id = el[team][position]
                return players.find(el => el.clientId === id) || players.find(el => el.internalId === id) || {}
            }
        } else {
            return {}
        }
    }

    return (
        <div className="list_item">
            <div className="list_item_head">
                <div className="type">{getType(el.gameType)}</div>
                <div className="price">
                    <img src={coin} alt="" />
                    <p><Price price={totalWin}/></p>
                </div>
            </div>

            <div className="list_item_players">
                {el.gameType === '1vs1' ? 
                <>  
                    <LastListItemPlayer player={players[0]} totalWin={totalWin} position={1}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[1]} totalWin={totalWin} position={2}/>
                </>
                :el.gameType === '1vs1vs1' ?
                <>
                    <LastListItemPlayer player={players[0]} totalWin={totalWin} position={1}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[1]} totalWin={totalWin} position={2}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[2]} totalWin={totalWin} position={3}/>

                </>
                :el.gameType === '2vs2' ?
                <>
                    <LastListItemPlayer 
                        player={getPlayer(0, 'teamOne')} 
                        totalWin={totalWin}
                        player2={getPlayer(1, 'teamOne')}
                        position={1}
                    />

                    <LastListItemPlayer 
                        player={getPlayer(1, 'teamOne')} 
                        totalWin={totalWin}
                        player2={getPlayer(0, 'teamOne')}
                        position={1}
                    />

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer 
                        player={getPlayer(0, 'teamTwo')}
                        totalWin={totalWin}
                        player2={getPlayer(1, 'teamTwo')}
                        position={2}
                    />

                    <LastListItemPlayer 
                        player={getPlayer(1, 'teamTwo')} 
                        totalWin={totalWin}
                        player2={getPlayer(0, 'teamTwo')}
                        position={2}
                    />
                </>
                :el.gameType === '1vs1vs1vs1' ?
                <>
                    <LastListItemPlayer player={players[0]} totalWin={totalWin} position={1}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[1]} totalWin={totalWin} position={2}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[2]} totalWin={totalWin} position={3}/>

                    <img src={battle} alt="" className='case_btl_icon_swords'/>

                    <LastListItemPlayer player={players[3]} totalWin={totalWin} position={4}/>
                </>
                :<></>}
            </div>
        </div>
    );
};

export default LastListItem;