import { useEffect, useState } from "react"
import Api from "../../../Api"
import Notice from "../../../Notice"
import Store from "../../../Store"

export default function usePromoCode(isAuth) {

    let [promoCode, setPromoCode] = useState('')
    let [error, setError] = useState({
        mess: false,
        value: false,
    })
    const [messageContent, setMessageContent] = useState('')
    const [load, setLoad] = useState(true)
    const [clientId, setClientId] = useState(false)

    useEffect(() => {
        if(isAuth) {
            const client = JSON.parse(localStorage.getItem('client'))
            setClientId(client.id)
        }
    },[])

    function inputCode(value) {
        setError(prev => ({...prev, value: false}))
        setPromoCode(value)
    }

    function sendPromoCode (e) {
        e.preventDefault()
        setError(prev => ({...prev, value: false, mess: false}))
        if(!isAuth) {
            document.querySelector('html').classList.add('hidden')
            Store.setListener('openAuthPopUp', true)
            return
        }

        if(promoCode.trim() === '' || !promoCode) {
            setError(prev => ({...prev, value: true}))
            return
        } 

        (async () => {
            setLoad(prev => prev = false)
            await fetch(`${Api.url}/api/v1/referrals?referralCode=${promoCode}&clientId=${clientId}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                credentials: 'include',
            })
            .then(res => {
                return res.json()
            })
            .then((data) => {
                if(data.statusCode === 404) {
                    setMessageContent(data.exception.message)
                    return setError(prev => ({...prev, mess: true}))
                }
                if(data.statusCode === 409) {
                    setMessageContent(data.exception.message)
                    return setError(prev => ({...prev, mess: true}))
                } 
                if(data.statusCode === 500) {
                    return Notice.Send({type: 'error', text: 'Ошибка сервера'})
                }
                Notice.Send({type: 'success', text: 'You earned a code!'})
            })
            .catch(error => console.error(error))
            setLoad(prev => prev = true)
        })()

        setPromoCode('')
    }

    return {load, error, setError, messageContent, promoCode, inputCode, sendPromoCode} 
}