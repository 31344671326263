import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './css/promo.css'

import promo_smile from './images/promo_smile.svg'
import docs from './images/docs.svg'
import bow1 from './images/bow1.svg'
import bow2 from './images/bow2.svg'
import magnet from './images/magnet.svg'
import list from './images/list.svg'
import one from './images/one.svg'
import two from './images/two.svg'
import three from './images/three.svg'
import analitics from './images/analitics.svg'
import multiple from './images/multiple.png'
import human from './images/human.png'
import flower1 from './images/flower1.svg'
import flower2 from './images/flower2.svg'
import coin from './../../fonts/main_icon_fonts/coin.svg'
import bear from './images/bear.png'
import first_cupon from './images/first_cupon.png'
import center_cupon from './images/center_cupon.png'
import last_cupon from './images/last_cupon.png'
import smile_cupon from './images/cupon_smile.png'
import m1 from './images/m1.svg'
import m2 from './images/m2.svg'
import burger from './images/burger.svg'
import arrow from './images/arrow.svg'
import Title from '../../mainTitle';
import EarnBlock from './components/EarnBlock';


const Promo = ({isAuth}) => {
    
    useEffect(() => {
        Title.setListener('title', 'PROMO')
    }, [])

    function openTierPopUp() {
        document.querySelector('.referal_tier_popup_wrapper').classList.add('open')
        document.querySelector('html').classlist.add('hidden');
    }

    return (
        <div className='promo_block'>
           
            <EarnBlock isAuth={isAuth}/>

            <div className="cupon_block">
                <div className="cupon_item first">
                    <img className='back' src={first_cupon} alt="" />
                    <img src={smile_cupon} alt="" className="smile" />
                    <h3>
                        INVITE <br />
                        <span>OTHERS</span> 
                    </h3>
                    <p>SPINRUST is a social site.</p>
                    <p>So invite your friends, get them playing and let them take advantage of our many offers.</p>
                </div>
                <div className="cupon_item center">
                    <img className='back' src={center_cupon} alt="" />
                    <img src={m1} alt="" className='m1'/>
                    <img src={m2} alt="" className="m2" />
                    <h3>MAKE PROFIT</h3>
                    <p>Man, just make money!</p>
                    <p className='profit'>You’ll profit each time one of your</p>
                    <p className='friends'>friends make a cash deposit when they use your code</p>
                </div>
                <div className="cupon_item last">
                    <img className='back' src={last_cupon} alt="" />
                    <img src={burger} alt="" className="burger" />
                    <img src={arrow} alt="" className="arrow" />
                    <h3>CASH OUT</h3>
                    <p className='man'>Man, just make money!</p>
                    <p>Any earnings you make can be claimed and used to withdraw CS:GO Skins.</p>
                </div>
            </div>

            <div className="how_works_block">
                <div className="items">
                    <div className="image">
                        <img className='list' src={list} alt="" />
                        <img className='magnet' src={magnet} alt="" />
                    </div>
                </div>
                <div className="items">
                    <h3>HOW IT WORKS</h3>
                    <div className="items_content">
                        <img src={one} alt="" />
                        <p>Get players to sign up using your unique referral link or code.</p>
                    </div>
                    <div className="items_content">
                        <img src={two} alt="" />
                        <p>Each friend will receive 3 free cases and a 5% deposit bonus on cash deposits.</p>
                    </div>
                    <div className="items_content">
                        <img src={three} alt="" />
                        <p>Earn commission for every cash deposit they make while using your code.</p>
                    </div>
                </div>
            </div>
            
            <div className="analitic_block">
                <div className='content_block'>
                    <button className="common_button pink big">
                        <img src={analitics} alt="" />
                        MULTIPLIE TIERS
                    </button>
                    <h3>MULTI-TIER <br />
                        AFFILATE SYSTEM</h3>
                    <p>Climb the ranks by referring more friends to increase your reward and receive up to 10% of every cash deposit that’s made along with additional special unlockable perks and bonuses.</p>
                    <button className="common_button pink" onClick={openTierPopUp}>VIEW TIERS</button>
                </div>
                <img className='image' src={multiple} alt="" />
            </div>

            <div className="fame_block">
                <h3>HALL OF FAME</h3>
                <div className="grid_block">

                    <div className="grid_item">
                        <div className="content">
                            <img src={two} alt="" className="number" />
                            <img src={human} alt="" />
                        </div>
                        <p className="nick">
                            <img src={two} alt="" />
                            ANONYMOUS
                        </p>
                        <p className="score">
                            5,999,999.99
                            <img src={coin} alt="" />
                        </p>
                    </div>

                    <div className="grid_item">
                        <div className="content">
                            <img src={one} alt="" className="number" />
                            <img src={flower1} alt="" />
                        </div>
                        <p className="nick">
                            <img src={one} alt="" />
                            ANONYMOUS
                        </p>
                        <p className="score">
                            5,999,999.99
                            <img src={coin} alt="" />
                        </p>
                    </div>

                    <div className="grid_item">
                        <div className="content">
                            <img src={three} alt="" className="number" />
                            <img src={flower2} alt="" />
                        </div>
                        <p className="nick">
                            <img src={three} alt="" />
                            ANONYMOUS
                        </p>
                        <p className="score">
                            5,999,999.99
                            <img src={coin} alt="" />
                        </p>
                    </div>

                </div>
                
            </div>

            <div className="total_block">
                <img src={bear} alt="" />
                <div className="total_content">
                    <h2>44,027,384</h2>
                    <p>TOTAL AFFILIATE COMMISSION EARNED</p>
                    {isAuth && <NavLink to='/promo/dashboard' className='common_button pink'>View dashboard</NavLink>}
                </div>
            </div>


            <img src={bow1} alt="" className="bow_left" />
            <img src={bow2} alt="" className="bow_right" /> 

        </div>
    );
};

export default Promo;