import React from 'react';
import { NavLink } from 'react-router-dom'


const GameList = ({isOpen}) => {

    if(!isOpen) return null

    return (
        <div className={`game_list ${isOpen ? 'open' : ''}`}>
            <NavLink to='/roulette' className='game_title'>
                Roullette
                <div className="home_icon_roullete"></div>
            </NavLink>
            <NavLink to='/case_battles' className='game_title'>
                Case Battles
                <div className="home_icon_battles"></div>
            </NavLink>
            <NavLink to='/crash' className='game_title'>
                Crash
                <div className="home_icon_crash"></div>
            </NavLink>
            <NavLink to='/cases' className='game_title'>
                Cases
                <div className="home_icon_cases"></div>
            </NavLink>
            <NavLink to='/upgrade' className='game_title'>
                Upgrade
                <div className="home_icon_upgrade"></div>
            </NavLink>
            <NavLink to='/dice' className='game_title'>
                Dice
                <div className="home_icon_dice"></div>
            </NavLink>
            {/*<NavLink to='/slots' className='game_title'>
                Slots
                <div className="home_icon_slots"></div>
            </NavLink>*/}
        </div>
    );
};

export default GameList;