import React from 'react';
import coin from './../../../../fonts/main_icon_fonts/coin.svg'


const MobileManageBlock = ({battleParams, rounds}) => {
    return (
        <>
          <div className="statistic_header_block mobile_cost">
                <div className="total_cost statistic_item">
                    <img src={coin} alt="" />
                    <p>Total cost:</p>
                    <p>{battleParams.bet}</p>
                </div>
            </div>

            <div className="statistic_header_block mobile">
                <div className="header_item right">

                    <div className="rounds statistic_item">
                        <div className='case_btl_icon_arrows'></div>
                        <p>Rounds:</p>
                        <p>{rounds}</p>
                    </div>

                    <div className="type statistic_item">
                        <div className='case_btl_icon_user'></div>
                        <p>Type:</p>
                        <p><span>{battleParams.roomType}</span> battle</p>
                    </div>
                </div>
            </div>  
        </>
    );
};

export default MobileManageBlock;