import React from 'react';

const ReferesListFilter = ({changeFilter}) => {

    return (
        <div className="referees_filters">
            <div className='referees_filters_left'>
                <button name='ALL' className="common_button pink active" onMouseDown={(e) => changeFilter(e)}>All referees </button>
                <button name='INACTIVE' className="common_button pink" onMouseDown={(e) => changeFilter(e)}>Inactive</button>
            </div>
            <button name='ACTIVE' className="common_button pink" onMouseDown={(e) => changeFilter(e)}>active</button>
        </div>
    );
};

export default ReferesListFilter;