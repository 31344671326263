import { useEffect, useState } from "react";
import Api from "../../../../../Api";
import Notice from "../../../../../Notice";

export default function useDeposits () {
    const [list, setList] = useState(false)
    const [load, setLoad] = useState(false)
    const [params, setParams] = useState({
        size: 10,
        page: 1,
        order: 'ASC',
        field: 'id',
        source: 'TRANSACTION',
        type: 'DEPOSIT',
    })

    useEffect(() => {
        (async () => {
            setLoad(prev => prev = false)

            let query = new URLSearchParams(params)

            if(params.source === 'ALL') {
                const sources = ['TRANSACTION', 'TRADE']
                sources.map(el => {
                    query.append('source', el)
                })
            }
            if(params.type === 'ALL') {
                const types = ['DEPOSIT', 'WITHDRAWAL', 'WAGER', 'ISSUED']
                types.map(el => {
                    query.append('type', el)
                })
            }
            
            const init = await Api.asyncGetCred(`api/v1/replenishments/me?${query}`)

            setLoad(prev => prev = true)

            if(!init || init === 'error') {
                return Notice.Send({type: 'error', text: 'Server Error. Try again later'})
            } 

            setList(init)
        })()
    }, [params])

    const changeFilters = (e, name) => {
        setLoad(false)
        const itemList = e.currentTarget.querySelectorAll('p')
        itemList.forEach(el => {
            if(el == e.target) {
                e.target.classList.add('active')
            } else {
                el.classList.remove('active')
            }
        })
        setParams(prev => ({...prev, [name]: e.target.textContent}))
    }

    return {load, list, params, setParams, changeFilters}
}