import { useEffect, useRef } from "react"
import { io } from "socket.io-client"
import Api from "../Api"

export default function useCase() {

    const socket = useRef(null)

    useEffect(() => {
        socket.current = io(`${Api.urlWs}/case`, {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        socket.current.on('case.newPrice', (data) => {
            console.log('cases', data)
        })

        return () => {
            if(socket.current) {
                socket.current.off('case.newPrice')
                socket.current.disconnect()
            }
        }

    }, [])

    return 
}