import React from 'react';

import monet from '../../../fonts/main_icon_fonts/monet.svg'
import useUpgradeGame from '../hooks/useUpgradeGame';
import Modal from './modal/Modal';

const BalanceGrid = () => {

    const {error, arrow, arrowRef, arrowPointer, decor, fill, roll, bet, maxBet, percent, range, percentSymb, changeRoll, changeRange, inputBet, setError, startGame} = useUpgradeGame() 

    return (
        <div className="balance_grid">

            <div className="balance_grid_item">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="upgrade_icon_upgrade"></div>
                    </div>
                    <h4 className='game_title'>Upgrade your balance</h4>
                    <div className="decor pink"></div>
                </div>

                <div className="balance_field">
                    <div className={`coin_balance ${error.value && 'error'}`}>
                        <img src={monet} alt=""/>
                        <input 
                            type="text" 
                            inputMode='numeric'
                            placeholder='0' 
                            value={bet ? (bet).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''} 
                            onChange={(e) => inputBet(e.target.value)}
                            onBlur={() => setError(prev => ({...prev, value: false}))}
                        />
                    </div>

                    <div className="handle_track">
                        <div className="track_fill" style={{background: `linear-gradient(90deg, #30dba8 0 ${range}%, transparent ${range}% 100%)`}}></div>
                        <input 
                            id='upgradeRangeInput'
                            type="range" 
                            value={range} 
                            onChange={(e) => changeRange(e.target.value)}
                            min={0.01}
                            max={95}
                            step='0.01'
                        />
                    </div>

                    <div className="multiples_block">
                        <button className="common_button full" onMouseDown={() => changeRange(10)}>10%</button>
                        <button className="common_button full" onMouseDown={() => changeRange(25)}>25%</button>
                        <button className="common_button full" onMouseDown={() => changeRange(50)}>50%</button>
                        <button className="common_button full" onMouseDown={() => changeRange('max')}>MAX</button>
                    </div>
                    <button id='playUpgrade' className="common_button green full" onClick={() => startGame()}>PLAY</button>
                </div>
            </div>

            <div className={`circle_block`}>
                <div className="circle_wrapper">
                    <div className="percent" >
                        {percent > 0 ? (percent).toFixed(2) : '0.00'}<span style={{transition: '0.5s'}} ref={percentSymb}>%</span>
                    </div>
                    <svg width="260" height="260" >
                        <circle
                            className="circle"
                            cx="130"
                            cy="130"
                            r="123"
                        />
                        <circle
                            ref={fill}
                            className="circle_fill"
                            cx="130"
                            cy="130"
                            r="123"
                            style={{strokeDashoffset: `calc(773 - (773 * ${
                                maxBet.current > 0 && bet >= 0.1 ? 
                                    (roll === 'under' ? percent : -percent)
                                : 
                                    0
                            }) / 100)`}}
                        />
                        
                    </svg>
                    <svg width="300" height="300" className='decor'>
                        <circle
                            ref={decor}
                            className="circle_decor"
                            cx="150"
                            cy="150"
                            r="139"
                            style={{strokeDashoffset: `calc(874 - (874 * ${
                                maxBet.current > 0 && bet >= 0.1 ? 
                                    (roll === 'under' ? percent : -percent)
                                : 
                                    0
                            }) / 100)`}}
                        />
                    </svg>
                    <div className="arrow_wrapper" ref={arrowRef} style={{transform: `rotate(${arrow}deg)`}}>
                        <div className="upgrade_icon_arrow" ref={arrowPointer}></div>
                    </div>
                </div>

                <div className="roll_block">
                    <div className="case_btl_icon_arrows" onClick={() => changeRoll()}></div>
                    <p>Roll {roll}</p>
                </div>


                            
            </div>

            <Modal />
        </div>
    );
};

export default BalanceGrid;

