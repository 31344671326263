import React from 'react';
import { InventoryItem } from './InventoryItem';
import LoadingItems from '../../../../../components/LoadingItems';
import NotItems from '../../../../../components/NotItems';
import Pagination from './Pagination';

const InventoryList = ({inventory}) => {

    return (
        <>
            {inventory.load ?
                <>
                    {inventory.inventoryItems[inventory.page]?.length ? 
                        <>
                            <div className='inventory_list'>
                                {inventory.inventoryItems[inventory.page].map((el, i) => (
                                    <InventoryItem item={el} key={i} sellItem={inventory.sellItem}/>
                                ))}
                            </div>
                        </>
                    :
                        <NotItems />
                    }
                </>
            :
                <LoadingItems />
            }
            {inventory.inventoryItems && Object.keys(inventory.inventoryItems).length ? <Pagination maxPage={Object.keys(inventory.inventoryItems).length} page={inventory.page} setPage={inventory.setPage}/> : <></>}
        </>
        
    );
};

export default InventoryList;