import React from 'react';

const DashboardFilters = (props) => {

    const {filter, changeFilter, increaseDate, decreaseDate, params} = props

    return (    
        <div className="dashboard_item">

            <div className="grid_nav_item_header ">
                <div className="game_icon">
                    <div className="promo_icon_date"></div>
                </div>
                <h4 className='game_title'>Date</h4>
                <div className="decor blue"></div>
            </div>

            <div className="dashboard_item_window">
                <div className="date_block">

                    <div className="date_filed">
                        <button className="common_button pink full" onMouseDown={() => increaseDate()}>+</button>
                        <div className="field">
                            {filter === 'year' && params.year}
                            {filter === 'month' && params.month}
                            {filter === 'day' && params.day}
                        </div>
                        <button className="common_button pink full" onMouseDown={() => decreaseDate()}>-</button>
                    </div>
                    
                    <div className="tabs_block">
                        <button name='year' className="common_button green active" onMouseDown={(e) => changeFilter(e)}>Y</button>
                        <button name='month' className="common_button green" onMouseDown={(e) => changeFilter(e)}>M</button>
                        <button name='day' className="common_button green" onMouseDown={(e) => changeFilter(e)}>D</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DashboardFilters;