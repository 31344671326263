import React from 'react';
import emoji1 from './../../../images/emoji1.svg'
import emoji2 from './../../../images/emoji2.svg'
import emoji3 from './../../../images/emoji3.svg'
import emoji4 from './../../../images/emoji4.svg'
import emoji5 from './../../../images/emoji5.svg'



const Emoji = () => {

    function openEmoji(e) {
        e = e.target
        let activeSuccess = true
        const emoji = e.querySelectorAll('.emoji_item')

        emoji.forEach(element => {
            if(element.classList.contains('animation')) {
                activeSuccess = false
            }
        })

        if (activeSuccess === true) {
            emoji.forEach(element => {
                element.classList.add('active')
                element.classList.remove('close')
            })
        } else {
            return false
        }
    }
    
    function closeEmoji() {
        const emoji = document.querySelectorAll('.emoji_item')
        
        emoji.forEach(element => {
            if(element.classList.contains('active')){
                element.classList.remove('active')
                element.classList.add('close')
            }
        });
    }

    function animation(e) {
        e.target.classList.add('animation')

        document.querySelectorAll('.emoji_item').forEach(element => {
            if(element.classList.contains('active')){
                element.classList.remove('active')
                element.classList.add('close')
            }
        });

        setTimeout(() => {
            e.target.classList.remove('animation')
        }, 1300)
    }

    return (
        <div className='emoji_block' onMouseEnter={(e) => openEmoji(e)} onMouseLeave={closeEmoji}>
            <img src={emoji1} alt="" className="emoji_item first" onClick={(e) => animation(e)}/>
            <img src={emoji2} alt="" className="emoji_item second" onClick={(e) => animation(e)}/>
            <img src={emoji3} alt="" className="emoji_item third" onClick={(e) => animation(e)}/>
            <img src={emoji4} alt="" className="emoji_item fourth" onClick={(e) => animation(e)}/>
            <img src={emoji5} alt="" className="emoji_item fiveth" onClick={(e) => animation(e)}/>
        </div>
    );
};

export default Emoji;