import { useCallback, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import Api from "../../Api";
import Games from "../../Games";
import Store from "../../Store";
import Notice from "../../Notice";


export default function useRoulette(isAuth) {

    const [profile, setProfile] = useState(() => {
        const local = JSON.parse(localStorage.getItem('client')) || false
        return local
    })
    const [last, setLast] = useState(false)
    const [lastHundred, setLastHundred] = useState(false)
    const [progress, setProgress] = useState(false)
    const [columnStats, setColumnStats] = useState(false)
    const [newBetList, setNewBetList] = useState({
        red: [],
        black: [], 
        green: [],
        adjacent: []
    })
    const [updateBetList, setUpdateBetList] = useState({
        red: [],
        black: [],
        green: [],
        adjacent: []
    })
    const [timefield, setTimefield] = useState('0.00')
    const [bet, setBet] = useState()
    const [betSum, setBetSum] = useState(0)
    const [block, setBlock] = useState(true)
    const [loader, setLoader] = useState(false)
    const [inputError, setInputError] = useState(false)
    const [myBet, setMyBet] = useState({
        red: 0,
        black: 0, 
        green: 0,
        adjacent: 0
    })
    const [myUpdateBet, setMyUpdateBet] = useState({
        red: 0,
        black: 0, 
        green: 0,
        adjacent: 0
    })

    let socketRoulette = useRef()
    let timer = useRef()
    let roullete = useRef()
    let rouletteStart = useRef()
    let rouletteFinish = useRef()
    const lastList = useRef()

    useEffect(() => {

        socketRoulette.current = io(`${Api.urlWs}/game-roulette`, {
            transports: ["websocket",],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        socketRoulette.current.on('roulette.result', (data) => spinRoullete(data.position))
        socketRoulette.current.on('roulette.progress', setProgress)
        socketRoulette.current.on('roulette.columnStats', setColumnStats)
        socketRoulette.current.on('roulette.startTime', (data) => {
            setBetSum(0)
            setColumnStats(false)
            setBlock(false)
            setNewBetList({red: [], black: [], green: [], adjacent: []})
            setUpdateBetList({red: [], black: [], green: [], adjacent: []})
            setMyBet({red: 0, black: 0, green: 0, adjacent: 0})
            setMyUpdateBet({red: 0, black: 0, green: 0, adjacent: 0})
            setTimefield(data)
            timerNewFunc(data)
            setLoader(true)
        })
        socketRoulette.current.on('roulette.last100ResultsStats', setLastHundred)
        socketRoulette.current.on('roulette.last17Positions', (data) => {
            
            const allItems = lastList.current.querySelectorAll('.last_item')
            if(!allItems?.length) {
                newListFunc(data)
                return
            }
            Games.rouletteLastList.forEach((el) => {
                if(data[0].position === el.index) {
                    const newitem = document.createElement('div')
                    newitem.className = `last_item ${el.index === 1 ? 'red1' 
                        : el.type === 'green' ? 'green'
                        : el.index === 14 ? 'black2' 
                        : el.index % 2 === 0 ? 'black1' : 'red2'} anim`
                    newitem.innerHTML = ` <img src='${el.img}' alt="" />`
                    
                    if(lastList.current.firstChild) {
                        lastList.current.insertBefore(newitem, lastList.current.firstChild)
                    } else {
                        lastList.current.innerHTML(newitem)
                    }

                    setTimeout(() => {
                        newitem.classList.add('show')
                    }, 1)
                    newitem.addEventListener('mousedown', () => {
                        Store.setListener('ROULETTE_PROVABLY_FAIR', data[0].gameHash)
                    })
                    if(allItems.length > 17) {
                        allItems[allItems.length - 1].remove()
                    }
                    return
                }
            })

        })
        socketRoulette.current.on('roulette.newBetAdjacent', (data) => newBet('adjacent', data))
        socketRoulette.current.on('roulette.newBetGreen', (data) => newBet('green', data))
        socketRoulette.current.on('roulette.newBetBlack', (data) => newBet('black', data))
        socketRoulette.current.on('roulette.newBetRed', (data) => newBet('red', data))
        socketRoulette.current.on('roulette.updatedBetAdjacent', (data) => updateBet('adjacent', data))
        socketRoulette.current.on('roulette.updatedBetGreen', (data) => updateBet('green', data))
        socketRoulette.current.on('roulette.updatedBetBlack', (data) => updateBet('black', data))
        socketRoulette.current.on('roulette.updatedBetRed', (data) => updateBet('red', data))
        socketRoulette.current.on('error', (error) => console.log(error))

        return () => {
            clearTimeout(roullete.current)
            clearTimeout(rouletteFinish.current)
            clearTimeout(rouletteStart.current)
            cancelAnimationFrame(timer.current)
            if(socketRoulette.current) {
                socketRoulette.current.off('roulette.result')
                socketRoulette.current.off('roulette.progress')
                socketRoulette.current.off('roulette.columnStats')
                socketRoulette.current.off('roulette.startTime')
                socketRoulette.current.off('roulette.last100ResultsStats')
                socketRoulette.current.off('roulette.last17Positions')
                socketRoulette.current.off('roulette.newBetAdjacent')
                socketRoulette.current.off('roulette.newBetGreen')
                socketRoulette.current.off('roulette.newBetBlack')
                socketRoulette.current.off('roulette.newBetRed')
                socketRoulette.current.off('roulette.updatedBetAdjacent')
                socketRoulette.current.off('roulette.updatedBetGreen')
                socketRoulette.current.off('roulette.updatedBetBlack')
                socketRoulette.current.off('roulette.updatedBetRed')
                socketRoulette.current.disconnect()
            }
        }
    }, [])

    useCallback(() => {
        return () => {
            clearTimeout(roullete.current)
            clearTimeout(rouletteFinish.current)
            clearTimeout(rouletteStart.current)
            cancelAnimationFrame(timer.current)
        }
    }, [])

    const sortByBet = (array) => {
        let sortList = array.sort((a, b) => b.bet - a.bet);
        if(profile) {
            const targetIndex = sortList.findIndex(item => item.id === profile.id);
            if (targetIndex > -1) {
              const [item] = sortList.splice(targetIndex, 1);
              sortList.unshift(item);
            }
        }
        return sortList;
    }
    
    const newBet = (type, data) => {

        if(data.id === profile.id) {
            setMyBet(prev => ({...prev, [type]: prev[type] + data.bet}))
        }

        setNewBetList(prev => {
            let list = [...prev[type]];
            const userIndex = list.findIndex(el => el.id === data.id); 
            if(userIndex >= 0) {
                const updatedUser = {...list[userIndex], bet: list[userIndex].bet + data.bet};
                list[userIndex] = updatedUser;
            } else {
                list.unshift(data);
            }
            return {...prev, [type]: sortByBet(list)};
        });
    }

    const updateBet = async (type, data) => {

        if(data.id === profile.id) {
            setMyBet(prev => ({...prev, [type]: 0}))
            setMyUpdateBet(prev => ({...prev, [type]: data.bet}))
        }

        setNewBetList(prev => ({...prev, [type]: []}))
        setUpdateBetList(prev => {
            let list = [...prev[type]];
            const userIndex = list.findIndex(el => el.id === data.id); 
            if(userIndex >= 0) {
                const updatedUser = {...list[userIndex], bet: list[userIndex].bet + data.bet};
                list[userIndex] = updatedUser;
            } else {
                list.unshift(data);
            }
            return {...prev, [type]: sortByBet(list)};
        })
        if(isAuth) {
            await Api.updateAccount()
        }
    }

    const timerNewFunc = (value) => {
        let date = new Date().getTime();
        const finish = value * 1000 + date;

        let timerAnimation = () => {
            let currentTime = new Date().getTime();
            if (currentTime > finish) {
                setTimefield('0.00')
                setBlock(true)
                cancelAnimationFrame(timer.current)
            } else {
                setTimefield(((finish - currentTime) / 1000).toFixed(2));
                timer.current = requestAnimationFrame(timerAnimation)
            }
        }

        timer.current = requestAnimationFrame(timerAnimation)
    }

    const spinRoullete = (index) =>{
        let card = 120 + 10 
        let landingPosition = (2 * 15 * card) + (index * card); 
        
        var randomize = Math.floor(Math.random() * 120) - (120/2);
        
        if(roullete.current) {
            roullete.current.style.transitionTimingFunction = `cubic-bezier(.2, 0, 0, 1)`
            roullete.current.style.transitionDuration = '6s'
            roullete.current.style.transform = `translate3d(-${landingPosition + 65 + randomize}px, 0px, 0px)`

            rouletteStart.current = setTimeout(() => {
                roullete.current.style.transitionDuration = '0.4s'
                roullete.current.style.transform = `translate3d(-${landingPosition + 65}px, 0px, 0px)`
            }, 6500)
            
            rouletteFinish.current = setTimeout(function(){
                roullete.current.style.transitionTimingFunction = ''
                roullete.current.style.transitionDuration = ''
                
                var resetTo = -(index * card)

                roullete.current.style.transform = `translate3d(${resetTo - 65}px, 0px, 0px)`

                document.querySelectorAll('.roulette_item')[index].style.border = '1px solid red'
            }, 6900);
        }
        
    }

    const newListFunc = (data) => {
        const list = []
        data.forEach((i) => {
            Games.rouletteLastList.forEach((el) => {
                if(i.position === el.index) {
                    el = {...el, gameHash: i.gameHash}
                    list.push(el)
                    //setLast(prev => [...prev, el])
                    return
                }
            })
        })
        setLast(list)
    }

    const setBetsRoulette = async (type) => {
        setInputError(false)

        if(!isAuth) {
            document.querySelector('html').classList.add('hidden')
            Store.setListener('openAuthPopUp', prev => prev = true)
            return
        }

        if(block) {
            return
        }

        if(!bet) {
            setInputError(true)
            return
        }

        const sum = parseFloat(bet) + parseFloat(betSum)

        if(sum > 1000) {
            Notice.Send({type: 'error', text: 'Max is 1000 coins'})
            return 
        }

        const wagers = localStorage.getItem('balanceField')
        const query = {
            bet: bet,
            forWagers: wagers && wagers === 'wager' ? true : false,
            betPosition: type
        }

        const params = new URLSearchParams(query).toString();

        try {
            let res = await fetch(`${Api.url}/api/v1/game-roulette/place-a-bet?${params}`, {
                credentials: 'include'
            })

            res = await res.json()

            if(res.statusCode && res.statusCode === 401) {

                let ref = await Api.refresh()

                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    Store.setListener('openAuthPopUp', prev => prev = true)
                    document.querySelector('html').classList.add('hidden')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/api/v1/game-roulette/place-a-bet?${params}`, {
                    credentials: 'include'
                })
                
                res = await res.json()
            }

            if(res.statusCode && res.statusCode === 400) {
                if(res.exception.message === 'You do not have enough balance to place a bet!') {
                    Notice.Send({type: 'error', text: res.exception.message})
                }
                return
            }

            if(!res.statusCode) {
                setBetSum(prev => prev + parseFloat(bet))
                await Api.updateAccount()
                return res
            } else {
                Notice.Send({type: 'error', text: 'Server error'})
                return
            }
        }   
        catch(e) {
            Notice.Send({type: 'error', text: 'Server error'})
            return 
        }
    }

    return { last, lastList, lastHundred, progress, columnStats, timefield, roullete, bet, setBet, setBetsRoulette, block, updateBetList, setUpdateBetList, newBetList, setNewBetList, sortByBet, loader, inputError, setInputError, myBet, myUpdateBet, newBet };
}