import React from 'react';

import coin from './../../../../../fonts/main_icon_fonts/coin.svg'
import Price from '../../../../../components/price/Price';

const TotalWegared = ({totalWagered}) => {

    console.log(totalWagered)

    return (
        <div className="total_wagered">
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="account_icon_stats"></div>
                </div>
                <div className='game_title'>Total wagered</div>
                <div className="decor pink"></div>
            </div>
            <div className="body_content">
                <div className="monet_price">
                    <img src={coin} alt="" />
                    <p><Price price={totalWagered ? totalWagered : 0}/></p>
                </div>
            </div>
        </div>
    );
};

export default TotalWegared;