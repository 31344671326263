import { useEffect, useRef, useState } from "react"
import { io } from "socket.io-client"
import Api from "../Api"


export default function useSocketAuth(isAuth) {

    const [wager, setWager] = useState()

    const socketAuth = useRef(null)

    useEffect(() => {

        if(isAuth) {
            socketAuth.current = io(`${Api.urlWs}/auth`, {
                transports: ["websocket",],
                withCredentials: true,
                reconnection: true,
                reconnectionDelay: 2000,
                reconnectionAttempts: 5,
            })

            socketAuth.current.on('auth.wagerPlayOut', setWager)
        }

        return () => socketAuth.current ? socketAuth.current.disconnect() : null

    }, [isAuth])


    return [wager]
}