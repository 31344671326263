import React, { useEffect, useState } from 'react';
import Emoji from './Emoji';
import Avatar from '../../../../../components/avatar/Avatar';
import coin from './../../../../../fonts/main_icon_fonts/coin.svg';
import Bet from '../../../../../Bet';
import bot from './../../../images/bot.png'
import Price from '../../../../../components/price/Price';


const PlayerActive = ({total, id, clientId,  player, position, type, status}) => {

    const [totalPay, setTotalPay] = useState(0)

    const getColor = () => {
        if(type === '2vs2') {
            return position < 3 ? 'color1' : 'color3'
        } else {
            return 'color' + position
        }
    }

    useEffect(() => {

        if(total) {
            Bet.plusAnim(total - totalPay, totalPay, setTotalPay)
        }

    }, [total])
    
    return (
        <div className="player_active_wrapper">
            {id === clientId && <Emoji />}
            
            <div className="emoji_shuter"></div>
            <div className={`player_active main_card ${getColor()} ${status === 'Loser' ? 'lose' : ''}`}>
                <div className="active_user">
                    {player?.avatar === 'bot'
                    ?<div className='botImage'>
                        <img src={bot} alt="" />
                    </div>
                    :<Avatar 
                            img={player?.avatar}
                            lvl={player?.level}
                            percent={(player?.experience / player?.experienceBorderTillLevel) * 100}
                            color={'#30dba8'}
                            size={24}
                            rad={11}
                            dasharray={69}
                            lvlFont={8}
                            lvlPos={-3}
                            lvlSize={14}
                        />
                    }
                    <p className="user_active_name">{player?.username}</p>
                </div>
                {total && 
                <div className='total_user'>
                    <img src={coin} alt="" />
                    <p>{(totalPay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>}
            </div>
        </div>
        
    );
};

export default PlayerActive;