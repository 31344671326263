import Api from "./Api"
import Notice from "./Notice"
import helmet from './pages/roulette/images/helmet.png'
import truba from './pages/roulette/images/truba.png'
import helmet2 from './pages/roulette/images/helmet2.png'
import header from './pages/roulette/images/header.png'
import helmet3 from './pages/roulette/images/helmet3.png'

const Games = {}

Games.rollUnder = (A, B) => {
    if ( A <= B ) {
        return 1000 * A + B
    } else {
        return 100000 + 1000 * A + B
    }
}

Games.winnings = (x) => {
    return (100 / x) / 100
}

Games.dicePlay = async (path) => {
    try {
        let res =  await fetch(`${Api.url}/${path}`, {
            credentials: 'include'
        })

        if(res.status === 400) {
            return 'no balance'
        }
        if(res.status === 401) {
            let ref = await Api.refresh()

            if (ref === 'unAuthorization') {
                localStorage.removeItem('client')
                return 'unAuthorization'
            }

            if(ref === 'no balance') {
                return 'no balance'
            }

            if(ref !== 'ok') {
                return 'error'
            }

            res = await fetch(`${Api.url}/${path}`, {
                credentials: 'include'
            })

            if(!res.ok) {
                console.log(res)
                return 'error'
            }
            
            res = await res.json()

            return res
        }
        if (res.status === 200) {
            return res.json()
        }

        return 'error'

    } catch(e) {
        console.log(e)
        Notice.Send({type: 'error', text: 'Ошибка сервера'})
        return 'error'
    }
}

Games.gameHistory = async (path) => {
    try {
        let res =  await fetch(`${Api.url}/${path}`, {
            credentials: 'include'
        })

        if(res.status !== 200) {
            Notice.Send({type: 'error', text: 'Ошибка сервера'})
            return 'error'
        }

        return res.json()
    } catch(e) {
        console.log(e)
        Notice.Send({type: 'error', text: 'Ошибка сервера'})
        return 'error'
    }
}

Games.switchHistoryPage = (value, params, setParams, list) => {

    let maxPage = Math.ceil(list.total / params.size)

    let pages = params.page + value
        if(pages >= maxPage + 1) {
            return
        } else if (pages < 1) {
            return
        } else {
            setParams(prev => ({...prev, ['page']: pages}))
        }
}

Games.defaultPosition = (rull, height, dataId) => {
    rull.current.style.transitionTimingFunction = ''
    rull.current.style.transitionDuration = ''
    rull.current.style.transform = `translateY(calc(50% - ${(height * 2) + (height / 2)}px))`

    const winCase = document.querySelector(dataId)
    winCase.style.transitionDuration = ''
    winCase.style.transform = 'scale(1)'
}

Games.shuffle = (array) => {
    let m = array.length, t, i;
      
    while (m) {
  
      i = Math.floor(Math.random() * m--);
  
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
  
    return array;
}

Games.getHeight = (rull) => {
    const el = rull.current.querySelector('.case_open_img_wrapper')
    let style = el.currentStyle || window.getComputedStyle(el);

    const elHeight = el.getBoundingClientRect().height + parseInt(style.marginTop) * 2
    return elHeight
}

Games.verticalSpinRoullete = (rull, win, fast, ref, randomize, elHeight) => {
    const winCase = document.querySelector(`[data-id="2320--dfdad=a=dsf=f=asd--asaf1${win}"]`)
    const screenWidth = window.innerWidth;

    if(fast) {

        rull.current.style.transitionTimingFunction = ``
        rull.current.style.transitionDuration = '1s'
        rull.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px  - ${elHeight / 2}px + ${randomize}px))`

        ref.current = setTimeout(() => {
            rull.current.style.transitionDuration = '1s'
            rull.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px  - ${elHeight / 2}px))`
            winCase.style.transitionDuration = '1s'

            if(screenWidth > 768) {
                winCase.style.transform = 'scale(1.2)'
            } else {
                winCase.style.transform = 'scale(1.4)'
            }
        }, 1500)

    } else {

        rull.current.style.transitionTimingFunction = `cubic-bezier(0.2, 0, 0, 1)`
        rull.current.style.transitionDuration = '6s'
        rull.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px - ${elHeight / 2}px + ${randomize}px))`

        ref.current = setTimeout(() => {
            rull.current.style.transitionDuration = '1s'
            rull.current.style.transform = `translateY(calc(-50% + ${elHeight * 10}px - ${elHeight / 2}px))`
            winCase.style.transitionDuration = '1s'
            if(screenWidth > 768) {
                winCase.style.transform = 'scale(1.2)'
            } else {
                winCase.style.transform = 'scale(1.4)'
            }
        }, 6500)

    }
}

Games.switchPage = (ref, page, callback) => {
    if(!ref.current) {
        callback(page)
    }
}

Games.rouletteLastList = [
    {
        index: 0,
        type: 'green',
        img: truba,
        name: 'зелёное'
    },
    {
        index: 1,
        type: 'red',
        img: helmet2,
        name: 'красное'
    },
    {
        index: 2,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 3,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 4,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 5,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 6,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 7,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 8,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 9,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 10,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 11,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 12,
        type: 'black',
        img: header,
        name: 'черное'
    },
    {
        index: 13,
        type: 'red',
        img: helmet,
        name: 'красное'
    },
    {
        index: 14,
        type: 'black',
        img: helmet3,
        name: 'черное'
    }
]

export default Games;