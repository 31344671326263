import { useEffect, useState } from "react"
import Api from "../../Api"
import Store from "../../Store"
import Administrator from "../services/Administrator"
import Notice from "../../Notice"


export default function useAdminCases() {
    let [newList, setNewList] = useState([])
    let [allCategories, setAllCategories] = useState([])
    let [load, setLoad] = useState(true)
    let [error, setError] = useState({
        name: false,
        image: false,
        dropChance: false,
        list: false,
    })

    let [caseParams, setCaseParams] = useState({
        category: '',
        categoryId: '',
        name: '',
        image: '',
        type: 'paid',
        caseId: '',
    })

    useEffect(() => {
        (async () => {
            let categoriesInit = await Api.asyncGet('api/v1/categories/all')
            if(categoriesInit === 'error') {
                Notice.Send({type: 'error', text: 'Ошибка сервера при получении категорий'})
                return
            }
            setAllCategories(categoriesInit)
            setCaseParams(prev => ({...prev, ['category']: categoriesInit[0]?.name, ['categoryId']: categoriesInit[0]?.id}))
        })()

    }, [])

    Store.useListener('newAdminItemList', setNewList)

    /* ----------Получение данных существующего кейса по кнопке CaseEditDtn---------- */

    let [edit, setEdit] = useState(false)

    function getExistCase(data) {

        setNewList(data.fakeItems.map(el => {
            return ({
                id: el.fakeItem.id,
                name: el.fakeItem.name,
                image: el.fakeItem.image,
                price: el.fakeItem.price,
                type: el.fakeItem.type,
                dropChance: el.dropChance,
            })
        }))

        let categId

        allCategories.map(el => {
            if(el.name === data.categories){
               return categId = el.id
            }

        })

        setCaseParams(prev => ({
            ...prev, name: data.name,
            image: data.image,
            category: data.categories,
            type: data.type,
            caseId: data.id,
            categoryId: categId,
        }))

        setEdit(prev => prev = true)
    }

    Store.useListener('caseToEdit', getExistCase)

    /* -------------Изменение списка категорий от админа----------- */

    function deleteFromCategoryList(deleteId) {
        let clone = structuredClone(allCategories.filter(el => el.id != deleteId))
        setAllCategories(clone)
        if(clone.length > 0) {
            setCaseParams(prev => ({...prev, ['category']: clone[0].name, ['categoryId']: clone[0].id}))
        }
    }

    Store.useListener('UPDATE_CATEGORY_LIST', deleteFromCategoryList)

    function addNewCategory(data) {
        
        let clone = structuredClone(allCategories)
        clone.push(data)
        
        if(allCategories.length > 0) {
            setAllCategories(clone)
        } else {
            setAllCategories([data])
            setCaseParams(prev => ({...prev, ['category']: data.name, ['categoryId']: data.id}))
        }

    }

    Store.useListener('UPDATE_CATEGORY_LIST_ADD', addNewCategory)

    function editCategory(data) {
        let clone = structuredClone(allCategories)
        clone = clone.map(el => {
            if(el.id === data.id) {
                return data
            }
            return el
        })
        setAllCategories(clone)
        setCaseParams(prev => ({...prev, ['category']: data.name, ['categoryId']: data.id}))
    }

    Store.useListener('UPDATE_CATEGORY_LIST_EDIT', editCategory)

    /* --------------Изменение списка предметов от админа-------- */

    function deleteItemInList(id) {
        if(newList.find(el => el.id === id) ) {
            setNewList(prev => prev.filter(el => el.id != id))
        }
    }

    Store.useListener('DELETE_ITEM_INCASE', deleteItemInList)

    function editItemInCase(data) {
        if(newList && newList.length > 0 && newList.find(el => el.id === data.id)) {
            setNewList(prev => prev.map(el => {
                if(el.id === data.id) {
                    return data
                }
                return el
            }))
        }
    }
    
    Store.useListener('EDIT_EXIST_ITEM_INCASE', editItemInCase)
    
    /* -------------Ввод данных кейса----------------- */

    function changeCaseParams(value) {
        setError(prev => ({...prev, [value.name]: false}))

        if(!value.value) {
            setError(prev => ({...prev, [value.name]: true}))
        }

        setCaseParams(prev => ({...prev, [value.name]: value.value}))
    }

    /* ----------Срабатывает по кнопке Add Items------------ */

    function updateList () {
        Store.setListener('updateAdminItemStore', newList)
    }

    function closeAdminPopup () {
        document.getElementById('adminAddCases').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')
        document.getElementById('err_case_exist').classList.remove('error')
        document.getElementById('err_case_drop_chance').classList.remove('error')
        setCaseParams(prev => ({
            ...prev, name: '',
            image: '',
            type: 'paid',
            category: allCategories[0]?.name  ,
            categoryId: allCategories[0]?.id,
        }))

        setNewList([])
        setEdit(false)
        setError({
            name: false,
            image: false,
            dropChance: false,
            list: false,
        })
    }

    async function sendCase(method, path, cloneItemList) {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: method,
                headers: {
                    'Content-type': 'application/json; charset=utf-8'
                },
                credentials: 'include',
                body: JSON.stringify({
                    "categoryId": caseParams.categoryId,
                    "name": caseParams.name,
                    "image": caseParams.image,
                    "type": caseParams.type,
                    "items": cloneItemList,
                })
            })
            if(res.status === 401) {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: method,
                    headers: {
                        'Content-type': 'application/json; charset=utf-8'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        "categoryId": caseParams.categoryId,
                        "name": caseParams.name,
                        "image": caseParams.image,
                        "type": caseParams.type,
                        "items": cloneItemList,
                    })
                })
            }
            if (res.status === 400 || res.statusCode === 400) {
                return '400'
            }
            if (res.status === 409 || res.statusCode === 409) {
                return 'exist'
            }
            if (!res.ok) {
                return 'error'
            } 

            res = await res.json()

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    function confirmNewCase (e) {

        Administrator.resetErrorAddCases(newList, setError)
    
        e.preventDefault()
    
        let err = Administrator.checkForErrorAddCases(newList, setError, caseParams)
        
        if(err) {
            return
        }
        
        (async () => {
            let cloneItemList = structuredClone(newList)
        
            cloneItemList = cloneItemList.map(el => {
                return {...el, itemId: el.id, dropChance: JSON.parse(el.dropChance)}
            })
            setLoad(prev => prev = false)

            if(edit) {
                const data = await sendCase('PUT', `api/v1/case/${caseParams.caseId}`, cloneItemList)

                setLoad(prev => prev = true)
    
                if(data === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
                if(data === 'exist') {
                    setError(prev => ({...prev, name: true}))
                    document.getElementById('err_case_exist').classList.add('error')
                    return
                }
                if(data === '400') {
                    newList.map(el => {
                        document.getElementById(`${el.id}`).querySelector('.info_item').classList.add('error')
                    })
                    document.getElementById('err_case_drop_chance').classList.add('error')
                    return
                }
                
                Administrator.onSuccess('Case modified!')
                
                if(caseParams.type === 'paid') {
                    Store.setListener('EDIT_CASE_GLOBAL', data)
                }
                if(caseParams.type === 'free') {
                    Store.setListener('EDIT_FREE_CASE_GLOBAL', data)
                }
    
                closeAdminPopup()
            } else {

                const data = await sendCase('POST', `api/v1/case/create-case`, cloneItemList)

                setLoad(prev => prev = true)
    
                if(data === 'error') {
                    Notice.Send({type: 'error', text: 'Ошибка сервера'})
                    return
                }
                if(data === 'exist') {
                    setError(prev => ({...prev, name: true}))
                    document.getElementById('err_case_exist').classList.add('error')
                    return
                }
                if(data === '400') {
                    newList.map(el => {
                        document.getElementById(`${el.id}`).querySelector('.info_item').classList.add('error')
                    })
                    document.getElementById('err_case_drop_chance').classList.add('error')
                    return
                }

                if(caseParams.type === 'paid') {
                    Store.setListener('ADD_NEW_CASE_GLOBAL', data)
                }
                if(caseParams.type === 'free') {
                    Store.setListener('ADD_NEW_FREE_CASE_GLOBAL', data)
                }
        
                Administrator.onSuccess('You create a case!')
            }
        })()

    }

    return {load, error, setError, edit, newList, setNewList, caseParams, setCaseParams, allCategories, updateList, changeCaseParams, confirmNewCase, closeAdminPopup}
}