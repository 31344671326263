import React from 'react';

const Pagination = ({ maxPage, page, setPage }) => {
    function switchPage(value) {
        let pages = page + value
        if(pages > maxPage) {
            return
        } else if (pages < 1) {
            return
        } else {
            setPage(pages)
        }   
    }

    function switchMaxPage(value){
        if((page === 1 && value === 1) || (page === maxPage && value === maxPage)) {
            return
        }
        setPage(value)
    }

    return (
        <nav className='common_pagination'>
            <div className="page_number">{page} - {maxPage} of {maxPage}</div>
            <div className={`account_icon_arrow_left ${page === 1 ? 'disable' : ''}`} onClick={() => switchMaxPage(1)}></div>
            <div className={`account_icon_big_arrow_left ${page === 1 ? 'disable' : ''}`} onClick={() => switchPage(-1)}></div>
            <div className={`account_icon_big_arrow_right ${page === maxPage ? 'disable' : ''}`} onClick={() => switchPage(1)}></div>
            <div className={`account_icon_arrow_right ${page === maxPage ? 'disable' : ''}`} onClick={() => switchMaxPage(maxPage)}></div>
        </nav>
    );
};

export default Pagination;