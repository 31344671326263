import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Avatar from '../avatar/Avatar';
import defaultImage from './../../images/default.jpeg'
import Api from '../../Api';
import Store from '../../Store';

const AccountBtn = () => {

    const [avatar, setAvatar] = useState(() => {
        const img = JSON.parse(localStorage.getItem('client')).user.steamProfile.avatar
        return img
    })
    const [profile, setProfile] = useState([])
    const [load, setLoad] = useState(false)

    Store.useListener('updateAccount', setProfile)

    useEffect(() => {

        (async () => {
            const id = JSON.parse(localStorage.getItem('client')).playerAccount.id
            let res = await Api.asyncGet(`api/v1/player-accounts/${id}`)

            if(res === 'error') {
                return
            }

            setProfile(res)
            setLoad(true)

        })()

    }, [])

    return (
        <NavLink to='/user/account' className='login_button'>
            <Avatar 
                img={avatar ? avatar : defaultImage}
                lvl={load ? profile.level : '0'}
                percent={load ? (profile?.experience / profile?.experienceBorderTillLevel) * 100 : 0}
                color={'#30dba8'}
                size={40}
                rad={17}
                dasharray={108}
            />
        </NavLink>
    );
};

export default AccountBtn;