import React, { useState } from 'react';
import './css/admin.css';
import LoadingItems from '../components/LoadingItems';
import NotItems from '../components/NotItems';
import User from './components/all_users/User';
import CommonPagination from '../components/pagination/CommonPagination';
import useAllUsers from './admin_hooks/useAllUsers';
import UserFilters from './components/all_users/UserFilters';
import TfaConfirm from './components/all_users/TfaConfirm';
import GiveMoneyPopUp from './components/all_users/GiveMoneyPopUp';


const AllUsers = () => {

    const {load, allUserList, params, setParams, closePopup, changeOrder, changeRole, searchUser} = useAllUsers()

    return (
        <>
            <div className='admin_popup_wrapper' id='adminAllUsers' onMouseDown={() => closePopup()}>
                <div className="admin_popup_scroll">
                    <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="admin_icon_userCrown"></div>
                            </div>
                            <div className='game_title'>User interaction</div>
                            <div className="decor pink" onMouseDown={() => closePopup()}></div>
                        </div>

                        <div className="admin_popup_content">

                            <UserFilters searchUser={searchUser} changeRole={changeRole} changeOrder={changeOrder} params={params}/>

                            <div className="users_list_wrapper">
                                <h3>All users:</h3>

                                {load ?
                                    <div className="user_list">
                                        {allUserList && allUserList.items.length > 0 ?

                                            <>
                                                {allUserList.items.map(el => (
                                                    <User key={el.id} el={el}/>
                                                ))}

                                                <CommonPagination 
                                                    maxPage={Math.ceil(allUserList.total / params.size) || 1}
                                                    params={params} 
                                                    setParams={setParams}
                                                />
                                            </>

                                            :

                                            <NotItems/>
                                        }

                                    </div>

                                    :

                                    <LoadingItems/>
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <TfaConfirm />
            <GiveMoneyPopUp/>
        </>
    );
};

export default AllUsers;