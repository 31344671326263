import React, { useState } from 'react';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import { NavLink } from 'react-router-dom';
import CaseEditDtn from './CaseEditDtn';
import CaseDeleteBtn from './CaseDeleteBtn';
import Price from '../../../components/price/Price';


const CasesGridItem = ({el, role}) => {

    return (
        <div className='cases_grid_item' id={el.id}>
            <img src={el.image} alt="" />
            <p className="price">
                <img src={coin} alt="" />
                <Price price={el.price}/>
            </p>
            <NavLink to={`/cases/open/${el.id}`} className='title'>{el.name}</NavLink>
            <NavLink to={`/cases/open/${el.id}`} className="common_button">Open case</NavLink>
                {role === 'ADMIN' && 
                    <div className="demo_wrapper">
                        <CaseEditDtn caseInfo={el}/>

                        <CaseDeleteBtn caseId={el.id}/>
                    </div>
                }
        </div>
    );
};

export default CasesGridItem;