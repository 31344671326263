import React, { useEffect, useState } from 'react';
import JoinBattle from './player_components/JoinBattle';
import Ready from './player_components/Ready';
import BattleRoulette from './player_components/BattleRoulette';
import WaitingPlayer from './player_components/WaitingPlayer';
import PlayerActive from './player_components/PlayerActive';
import Inventory from './player_components/Inventory';
import Winner from './player_components/Winner';
import Loser from './player_components/Loser';
import CallBots from './player_components/CallBots';
import StatusBlock from './player_components/StatusBlock';
import usePlayer from '../../hooks/usePlayer';


const Player = ({multi, load, joinTeam, callTeamBots, type, inGame, cases, round, gameStatus, inventory, result, joinGame, callBots, position, player, totalCost, bot, leaveClient}) => {

    const playerSts = usePlayer(load, joinTeam, callTeamBots, type, inGame, cases, round, gameStatus, inventory, result, joinGame, callBots, position, player, totalCost, bot, leaveClient)

    return (
        <div className='player_block'>

            <div className={`battle_window ${'type' + type}`}>
                {playerSts?.renderGameStatus()}
            </div>

            <div className="player_waiting">
                {playerSts?.renderPlayerStatus()}
            </div>
            <Inventory multi={multi} resultList={playerSts?.inventoryesult?.items}/>
        </div>
    );
};

export default Player;