import React, { useState } from 'react';
import Store from '../../Store';

const ChatFlex = () => {

    const [isOpen, setIsOpen] = useState(false)

    Store.useListener('chatFlex', setIsOpen)

    return <div className={`chat_flex ${isOpen ? 'close' : ''}`}></div>
};

export default ChatFlex;