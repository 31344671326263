import React from 'react';

const AddItemBtn = ({ updateList, error, setError }) => {
    function openItemStore(e) {
        e.preventDefault()
        document.getElementById('adminItemStore').classList.add('open')
        document.querySelector('html').classList.add('hidden')

        updateList()
        setError(prev => ({
            ...prev, list: false,
            dropChance: false,
        }))
        document.getElementById('err_max_num').classList.remove('error')
    }

    return (
        <div className={`new_item_add ${error ? 'error' : ''}`} onClick={openItemStore}>
            <span>+</span>
            <p>add new item</p>
        </div>
    );
};

export default AddItemBtn;