import { useEffect, useRef, useState } from "react";
import Api from "../Api";
import { io } from "socket.io-client";
import Store from "../Store";
import Notice from "../Notice";

export default function useChat(isAuth, role) {

    const [wsAuth, setWsAuth] = useState(false)
    const [pinMessage, setPinMessage] = useState(false)
    const [chatMessages, setChatMessages] = useState(false)
    const [rainPool, setRainPool] = useState('0.00')
    const [online, setOnline] = useState(0)
    const [isBanned, setIsBanned] = useState(false)
    const [rainTimer, setRainTimer] = useState('0.00')
    const [participant, setParticipant] = useState(false)
    const [gameStatus, setGameStatus] = useState(false)
    const [load, setLoad] = useState(true)
    const [updateSession, setUpdateSession] = useState(false)
    
    const [room, setRoom] = useState(() => {
        const locationRoom = JSON.parse(localStorage.getItem('chat_room'))
        if(locationRoom) {
            return locationRoom.room
        } else {
            return 'english'
        }
    })

    Store.useListener('room', setRoom)
    Store.useListener('updateSession', setUpdateSession)

    const socket = useRef(null)

    useEffect(() => {

        const socketOptions = {
            transports: ["websocket"],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        };

        if(isAuth) {
            socketOptions.withCredentials = true;
        }

        socket.current = io(`${Api.urlWs}/chat?room=${room}`, socketOptions);
        socket.current.on('isAuthorized', setWsAuth)
        socket.current.on('messageChunk', setChatMessages)
        socket.current.on('pinMessage', setPinMessage)
        socket.current.on('unpinMessage', () => setPinMessage(false))
        socket.current.on('message', (data) => {
            const chat_list = document.getElementById('chat_message_track')

            const newitem = document.createElement('div')
            newitem.className = 'new_chat_message main_card'
            newitem.innerHTML = messageMaket(data)

            if(chat_list) {
                chat_list.insertBefore(newitem, chat_list.firstChild)
            } else {
                setChatMessages((prev) => [data, ...prev])
                return
            }

            const w = newitem.querySelector('.track_item').offsetHeight
            setTimeout(() => {
                newitem.style.minHeight = `${w + 2}px`;
                newitem.classList.add('show')
            }, 1)
            if(role === 'ADMIN' || role === 'MODERATOR') {
                setTimeout(() => {
                    setChatMessages((prev) => [data, ...prev])
                    newitem.remove()
                }, 210)
            }
        })
        socket.current.on('online', setOnline)
        socket.current.on('rain-pool.totalContributions', (data) => setRainPool((data).toFixed(2)))
        socket.current.on('rain-pool.finishIn', (data) => {
            setRainTimer(100 - ((data / 1800) * 100))
            if(data >= 0 && data <= 60) {
                setGameStatus(true)
            } else {
                setGameStatus(false)
                setParticipant(false)
            }
        })
        socket.current.on('isRainPoolParticipant', (data) => {
            setLoad(false)
            setParticipant(data)
        })
        socket.current.on('banChatForUser', (data) => console.log('ban user', data))
        socket.current.on('isBanned', setIsBanned)
        socket.current.on('deleteMessage', (data) => setChatMessages(prev => prev.filter(el => el.id !== data)))
        socket.current.on('connect_error', (error) => console.log(error))
        socket.current.on('unbanUser', () => socket.current.emit('updateSession'))
        socket.current.on('rainPoolWinners', (data) => {
            setChatMessages(prev => [{rainPool: true, message: data}, ...prev])
        })
        socket.current.on('changeRoom', (data) => {
            console.log('room', data)
        })

        return () => {
            if(socket.current) {
                socket.current.off('isAuthorized');
                socket.current.off('messageChunk');
                socket.current.off('pinMessage');
                socket.current.off('unpinMessage');
                socket.current.off('message');
                socket.current.off('banChatForUser');
                socket.current.off('online');
                socket.current.off('changeRoom');
                socket.current.off('rainPoolWinners');
                socket.current.off('rain-pool.totalContributions');
                socket.current.off('rain-pool.finishIn');
                socket.current.off('isRainPoolParticipant');
                socket.current.off('isBanned');
                socket.current.off('deleteMessage');
                socket.current.off('unbanUser');
                socket.current.off('connect_error');
                socket.current.disconnect()
            }
        } 
        
    }, [isAuth])

    const showAuthPopUp = () => {
        Store.setListener('openAuthPopUp', prev => prev = true)
        document.querySelector('html').classList.add('hidden')
    }

    const sendMassage = (e, mess, callback) => {
        e.preventDefault();
        document.querySelector('.emoji_list').classList.remove('open')

        if(isBanned) {
            return console.log('banned')
        }
        
        if(wsAuth) {
            if(mess.trim()) {
                socket.current.emit('message', mess)
            }
        } else {
            showAuthPopUp()
        }
        callback('')
    }

    const pinMessageFunc = (mess) => {
        if(!wsAuth) {
            showAuthPopUp()
            return
        }

        if(role === 'ADMIN' || role === 'MODERATOR') {
            socket.current.emit('pinMessage', mess)
        } else {
            return
        }
    }

    const unPinMessageFunc = () => {
        if(!wsAuth) {
            showAuthPopUp()
            return
        }

        if(role === 'ADMIN' || role === 'MODERATOR') {
            socket.current.emit('unpinMessage')
        } else {
            return
        }
    }

    const deleteMess = (id) => {
        if(!wsAuth) {
            showAuthPopUp()
            return
        }

        if(role === 'ADMIN' || role === 'MODERATOR') {
            socket.current.emit('deleteMessage', id)
        } else {
            return
        }
    }

    const banUser = (options) => {
        if(!wsAuth) {
            showAuthPopUp()
            return
        }

        if(role === 'ADMIN' || role === 'MODERATOR') {
            console.log(options)
            socket.current.emit('banChatForUser', options)
        } else {
            return
        }
    }

    const joinRainPool = async () => {
        if(participant) {
            return
        }
        setLoad(true)
        let res = await Api.asyncGetCred(`api/v1/rain-pool/participate`)

        setLoad(false)
        if(res === 'no balance') {
            return Notice.Send({type: 'success', text: 'You already participate in rain-pool!'})
        }
        if(res === 'error') {
            return 
        }

        setParticipant(true)
        return
    }

    const changeRoom = (e) => {
        setRoom(e.room)
        localStorage.setItem('chat_room', JSON.stringify(e))
        socket.current.emit('changeRoom', e.room)
    }

    const messageMaket = (el) => {

        const circleLvl = (107 - (107 * (el.user.client.playerAccount.experience / el.user.client.playerAccount.experienceBorderTillLevel) * 100) / 100)

        return `
            <div class='track_item nogap'>
            
                ${role === 'ADMIN' || role === 'MODERATOR'
                ? `<div class="admin_chat_panel">
                    <button class='mut' ></button>
                    <button class='clip' ></button>
                    <button class='cross' ></button>
                </div>`
                :''}

                <div class="avatar_wrapper">

                    <div class="avatar_icon" style='width: 40px; height: 40px'>

                        <img src='${el.user.avatar}' alt="" />

                        <svg width='40px' height='40'px>
                            <circle class="circle" 
                            cx = '20' 
                            cy = '20'  
                            r  = 17
                            style='stroke: rgb(233, 63, 63); stroke-dasharray: 107; stroke-dashoffset: ${circleLvl};'
                            ></circle>
                        </svg>

                    </div>

                    <div class="lvl_circle">
                        <span>
                            ${el.user.client.playerAccount.level}
                        </span>
                    </div>

                </div>

                <div class="message_content">
                    <div class="name_block">
                        ${el.user.role === 'MODERATOR' ? `<div class="role moderator">${el.user.role}</div>`: ''}
                        <h4 class="name">${el.user.username}:</h4>
                    </div>
                    <div class="message">${el.message}</div>
                </div>
            </div>
        `
    } 

    return {gameStatus, participant, joinRainPool, load, socket, chatMessages, online, pinMessage, sendMassage, pinMessageFunc, unPinMessageFunc, rainPool, banUser, deleteMess, rainTimer, changeRoom};
  }