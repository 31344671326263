import React, { useState } from 'react';
import useAdminNews from './admin_hooks/useAdminNews';

const AddNews = () => {
    let {load, error, setError, edit, newsParams, changeNewsParams, closeAdminPopup, createNews} = useAdminNews()

    return (
        <div className='admin_popup_wrapper' id='adminNewsPopup' onMouseDown={closeAdminPopup}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_news"></div>
                        </div>
                        <div className='game_title'>News {edit ? 'modify' : 'create'}</div>
                        <div className="decor pink" onMouseDown={closeAdminPopup}></div>
                    </div>

                    <div className="admin_popup_content">
                        <div id='err_news_exist' className={`err_mess`} >This news allready exist!</div>

                        <label htmlFor="">Name</label>
                        <input 
                            type="text" 
                            className={`admin_input ${error.name ? 'error' : ''}`} 
                            placeholder='Empty-head'
                            name='name'
                            value={newsParams.name}
                            onChange={(e) => changeNewsParams(e)}
                            onBlur={(e) => setError(prev => ({...prev, [e.target.name]: false}))}
                        />

                        <label htmlFor="">Image <span>[.png]</span></label>
                        <input 
                            type="text" 
                            className={`admin_input ${error.image ? 'error' : ''}`} 
                            placeholder='https://yourimage.com/YourCoolImage'
                            name='image'
                            value={newsParams.image}
                            onChange={(e) => changeNewsParams(e)}
                            onBlur={(e) => setError(prev => ({...prev, [e.target.name]: false}))}
                        />

                        <button className="common_button full green" onClick={() => createNews()}>
                            {load ?
                                'CONFIRM'
                            :
                                'Loading...'
                            }
                        </button>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default AddNews;