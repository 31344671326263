import Api from "../../../Api"
import Notice from "../../../Notice"

const PromoServices = {}

PromoServices.getReqCookie = async (path) => {
    try {
        let res = await fetch(`${Api.url}/${path}`, {
            credentials: 'include'
        })

        if(res.status === 400) {
            return '400'
        }

        if(!res.ok) {
            Notice.Send({type: 'error', text: 'Ошибка сервера'})
            return 'error'
        }
        
        res = await res.json()

        return res

    } catch(e) {
        console.log(e)
        return 'error'
    }
}

PromoServices.isLeapYear = (year) => {
    if (year % 4 === 0) {
        if (year % 100 === 0){
           if (year % 400 == 0){
              return true;
           } else {
              return false;
           }
        } else {
           return true;
        }
     } else{
        return false;
     }
}


export default PromoServices