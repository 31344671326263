import { useEffect, useState } from "react";
import BattleRoulette from "../games/components/player_components/BattleRoulette";
import StatusBlock from "../games/components/player_components/StatusBlock";
import Ready from "../games/components/player_components/Ready";
import JoinBattle from "../games/components/player_components/JoinBattle";
import WaitingPlayer from "../games/components/player_components/WaitingPlayer";
import CallBots from "../games/components/player_components/CallBots";
import PlayerActive from "../games/components/player_components/PlayerActive";
import LoadingItems from "../../../components/LoadingItems";


export default function usePlayer(load, joinTeam, callTeamBots, type, inGame, cases, round, gameStatus, inventory, result, joinGame, callBots, position, player, totalCost, bot, leaveClient) {

    const [id, setId] = useState(() => {
        try {
            const storedClient = JSON.parse(localStorage.getItem('client'));
            return storedClient?.id || null;
        } catch (error) {
            return null;
        }
    });

    const [resultList, setResultList] = useState(false)
    const [inventoryesult, setInventoryResult] = useState(false)

    useEffect(() => {
        if(result) {
            if(player) {
                setResultList(result?.clients?.find(el => el.clientId === player?.clientId))
            }
            if(bot) {
                setResultList(result?.bots?.find(el => el.internalId === bot?.internalId))
            }
        }
    }, [result, bot, player])

    useEffect(() => {
        if(inventory) {
            if(player) {
                setInventoryResult(inventory?.clients?.find(el => el.clientId === player?.clientId))
            }
            if(bot) {
                setInventoryResult(inventory?.bots?.find(el => el.internalId === bot?.internalId))
            }
        }
    }, [inventory, bot, player])

    const getStatus = () => {
        if(gameStatus === 'end') {
            if(resultList?.items) {
                if(resultList.items?.length > 0) {
                    return 'Winner'
                } else {
                    return 'Loser'
                }
            }
        }
        return ''
    }

    const renderGameStatus = () => {
        if(load) {
            if (gameStatus === 'play') {
                return <BattleRoulette round={round} result={resultList?.items} cases={cases}/>;
            }
            if (gameStatus === 'end') {
                return <StatusBlock type={type} position={position} id={id} clientId={player?.clientId} totalCost={resultList?.totalPayout} leaveClient={leaveClient} status={getStatus()}/>
            }
            if (player || bot) {
                return <Ready id={player ? id : 0} clientId={player ? player.clientId : 1} leaveClient={leaveClient} resultList={resultList?.items} />;
            }
            if(!inGame) {
                return <JoinBattle type={type} joinGame={type === '2vs2' ? joinTeam : joinGame} totalCost={totalCost} position={position} />;
            }
            return <CallBots type={type} callBots={type === '2vs2' ? callTeamBots : callBots} position={position}/>
        } else {
            return <LoadingItems />
        }
        
    };

    const renderPlayerStatus = () => {
        if (player || bot) {
            return <PlayerActive total={inventoryesult?.totalPayout} id={player ? id : 0} clientId={player ? player.clientId : 1} position={position} player={player || bot} type={type} status={getStatus()}/>;
        }
        return <WaitingPlayer />;
    };

    return {renderGameStatus, renderPlayerStatus, inventoryesult}
}