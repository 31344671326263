import './css/withdraw.css'

import TotalCost from './components/TotalCost';
import WithdrawList from './components/WithdrawList.js';
import Filters from './components/Filters';
import useWithdraw from './useWithdraw.js';

const Withdraw = () => {
        
    const withdraw = useWithdraw()

    return (
        <div className='withdraw_block container'>
            <Filters setFilter={withdraw.setFilter} filter={withdraw.filter} priceOrder={withdraw.priceOrder} search={withdraw.search} orederBy={withdraw.orederBy} setSearch={withdraw.setSearch}/>
            <WithdrawList withdraw={withdraw}/>
            <TotalCost totalCost={withdraw.totalCost}/>
        </div>
    );
};

export default Withdraw;