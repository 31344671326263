import React from 'react';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import CommonPagination from '../../../components/pagination/CommonPagination';

 const RefereesList = ({load, refereesList, paramsUrl, setParamsUrl}) => {

    return (
            <>
            {load ? 
                <div className="referees_list">
                    {refereesList && refereesList.items.length > 0 ? 
                        <>
                        {refereesList.items.map((e, i) => (
                            <>
                                <div className={`referees_list_item ${(i + 1) % 2 === 0 ? 'second' : ''}`} key={i}>
                                    <div className="user_name">
                                        <img src={e.avatar} alt="" />
                                        <span>{e.username}</span>
                                    </div>
                                    <div className="money">
                                        <img src={coin} alt="" />
                                        {e.totalDeposited}
                                    </div>
                                    <div className="money">
                                        <img src={coin} alt="" />
                                        {e.commissioned}
                                    </div>
                                    <div className="date right">
                                        <span></span>
                                        {e.seen}
                                    </div>
                                    <div className="date ">
                                        <span></span>
                                        {e.lastDeposit}
                                    </div>
                                    <div className="status red">
                                        {e.status}
                                    </div>
                                </div>
                                <CommonPagination 
                                    maxPage={Math.ceil(refereesList.total / paramsUrl.size)} 
                                    params={paramsUrl} 
                                    setParams={setParamsUrl}
                                />
                            </>
                        ))}
                        </>
                    :
                        <NotItems />
                    }
                    </div>
            :
                <LoadingItems />
            }
            
            </>
    );
 };
 
 export default RefereesList;