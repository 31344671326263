import { useEffect, useState } from "react";
import coin from "../../../../../fonts/main_icon_fonts/coin.svg";
import Price from "../../../../../components/price/Price";

const WithdrawItem = ({totalCostList, item, addItem, deleteItem}) => {

    const [active, setActive] = useState(false)

    useEffect(() => {
        if(totalCostList && item) {
            const el = totalCostList.find(el => el.index === item.index)
            if(el) {
                setActive(true)
            } else {
                setActive(false)
            }
        } else {
            setActive(false)
        }
    }, [totalCostList, item])

    return (
        <div className={`withdraw_card`} onClick={active ? () => deleteItem(item) : () => addItem(item)}>
            <div className={`drop_card streak ${active ? 'active' : ''} ${item.item.type}`}>
                <img className={`drop_image ${item.item.type}`} src={item.item.icon} alt="" />
                <div className="drop_card_content">
                    <h4 className="title">{item.item.name}</h4>
                    <p className={`price main_card ${item.item.type}`}>
                        <Price price={item.item.priceInCoins}/>
                        <img src={coin} alt="" />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WithdrawItem;
