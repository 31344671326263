import React from 'react';
import Store from '../../../Store';

const CrashLastListItem = ({colorUpdate, el}) => {

    function openModal () {
        Store.setListener('CRASH_PROVABLY_FAIR', el.gameHash)
    }

    return (
        <div className="crash_last_list_item nocopy" style={{background: colorUpdate(el.multiplier * 100)?.color, border: `1px solid ${colorUpdate(el.multiplier * 100)?.border}`}} onMouseDown={() => openModal()}>
            <p>x{(el.multiplier).toFixed(2)} </p>
        </div>
    );
};

export default CrashLastListItem;