import React from 'react';
import CaseGrids from '../CaseGrids';
import LoadingItems from '../../../../components/LoadingItems';
import Filters from '../Filters';
import useCaseStore from '../../hooks/useCaseStore';
import CaseListForAdmin from './CaseListForAdmin';
import FreeCaseList from './FreeCaseList';

const   CaseStore = ({role}) => {

    let {load, price, caseAllList, categories, params, caseListForAdmin, freeCases, changeParams, changePriceFilter} = useCaseStore(role)

    return (
        <>
        
            <Filters params={params} changeParams={changeParams} changePriceFilter={changePriceFilter} price={price}/>

            {load ? 
                <>  
                    {role === 'ADMIN' && freeCases?.length ?
                        <div className="cases_grid_block admin">
                            <FreeCaseList list={freeCases}/>
                        </div>
                        :
                        <></>
                    }  

                    {role === 'ADMIN' && caseListForAdmin?.length ?
                        <div className="cases_grid_block admin">
                            <CaseListForAdmin list={caseListForAdmin} role={role}/>
                        </div>
                        :
                        <></>
                    }       

                    {categories && categories.length > 0 ?
                        <>
                            {caseAllList && caseAllList.length > 0 ?
                                <div className="cases_grid_block">

                                    {categories.map((el, i) => (
                                        <CaseGrids role={role} key={i} category={el} casesList={caseAllList}/>
                                    ))}

                                </div>
                            : 
                                <div className="not_cases">No cases :&#40;</div>
                            }
                        </>
                        :
                        <div className="not_items">No categories</div>
                    }
                </>
            :
                <LoadingItems />
            }
        </>
            
    );
};

export default CaseStore;