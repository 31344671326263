import React from 'react';
import Avatar from '../../../components/avatar/Avatar';
import useGiveRole from '../../admin_hooks/useGiveRole';

const UserGiveMoney = ({user}) => {
    const {role, load, PATH_TO_ASSIGN, PATH_TO_REMOVE, changeRole} = useGiveRole(user)

    return (
        <div className='user_info_wrapper'>

            <div className="user_info give_money">
                                            
                <div className="user">
                    <Avatar 
                        img={user.user.steamProfile.avatar}
                        lvl={user.playerAccount.level}
                        percent={user.experienceBorderTillLevel}
                        color={'rgb(233, 63, 63)'}
                        size={24}
                        rad={11}
                        dasharray={69}
                        lvlFont={8}
                        lvlPos={-3}
                        lvlSize={14}
                    />
                    
                    <div className="user_name">{user.user.steamProfile.username}</div>
                </div>
                
                
                <a href={user.user.steamProfile.profileUrl}>{user.user.steamProfile.profileUrl}</a>

                <div className={`user_role ${role}`}>
                    {role}
                </div>

            </div>

            
            {role === 'ADMIN' && 
                <button className="icon_button red">
                    <div className="admin_icon_crown"></div>
                </button>
            }
            {role === 'PLAYER' &&
                <button className="icon_button orange" onClick={() => changeRole(PATH_TO_ASSIGN)}>
                    {load ?
                        <div className="admin_icon_player"></div>
                    :
                        <svg width = {`20px`} height={'20px'}>
                            <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                        </svg>
                    }
                </button>
            }
            {role === 'MODERATOR' &&
                <button className="icon_button blue" onClick={() => changeRole(PATH_TO_REMOVE)}>
                    {load ?
                        <div className="admin_icon_help"></div>
                    :
                        <svg width = {`20px`} height={'20px'}>
                            <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                        </svg>
                    }
                </button>
            }

        </div>
    );
};

export default UserGiveMoney;