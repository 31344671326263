import React from 'react';
import back from './iamges/avatar_back.svg'


const Avatar = (props) => {

    return (
        <div className="avatar_wrapper">

            <div className="avatar_icon" style={{width: `${props.size}px`, height: `${props.size}px`}}>

                <img src={props.img} alt="" />

                {/* <svg width={`${props.size}px`} height={`${props.size}px`}>
                    <circle className="circle" 
                    cx = {props.size / 2} 
                    cy = {props.size / 2}  
                    r  = {props.rad}

                    style={{
                        stroke: props.color,
                        strokeDasharray: props.dasharray,
                        strokeDashoffset: `calc(${props.dasharray} - (${props.dasharray} * ${props.percent}) / 100)`
                    }}
                    ></circle>
                </svg> */}

            </div>

            <div className="lvl_circle">
                <div className="lvl" style={{fontSize: `${props.lvlFont}px`}}>{props.lvl}</div>
                <img className='avatar_back' src={back} alt="" />
            </div>

        </div>
    );
};

export default Avatar;