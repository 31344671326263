import React, { useEffect, useState } from 'react';
import './../../../../components/css/components.css'
import Store from '../../../../Store';
import useProvably from '../../../../hooks/useProvably';
import Games from '../../../../Games';


const ProvablyModal = () => {
    const [open, setOpen] = useState(false)

    Store.useListener('ROULETTE_PROVABLY_FAIR', (data) => {
        setParamsUrl(prev => ({...prev, gameHash: data}))
        setOpen(prev => prev = true)
    })
    
    const LINK = 'api/v1/game-roulette/public-bitcoin-hash'
    const VLINK = 'api/v1/game-roulette/verify?'
    
    const {result, setResult, loadBtn, paramsUrl, setParamsUrl, salt, closeModal, verify} = useProvably({setOpen, LINK, VLINK})

    useEffect(() => {
        if(result) {
            Games.rouletteLastList.forEach((el) => {
                if(+result === el.index) {
                    setResult(<div className={`last_item ${el.type}`}>
                                    <img src={el.img} alt="" />
                                </div>)
                }
            })
        }
    }, [result])

    if(!open) return null

    return (
        <div className='provably_modal_wrapper' onMouseDown={() => closeModal()}>
            <div className="provably_modal_window" onMouseDown={(e) => e.stopPropagation()}>

                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_scales"></div>
                    </div>   
                    <h4 className='game_title'>Provably fair</h4>
                    <div className="decor pink" onMouseDown={() => closeModal()}></div>
                </div>

                <div className="provably_modal_content">

                    <div className="item">
                        <h3>Game hash</h3>

                        <div className="info"> <span>{paramsUrl.gameHash}</span> </div>
                    </div>

                    <div className="item">
                        
                        <h3>Salt</h3>
                        
                        <div className="info">
                            {salt ?
                                <span>{salt}</span>
                            :
                                'Loading...'
                            }
                        </div>

                    </div>

                    <div className="item">
                        <h3>Result</h3>

                        <div className="item_flex roulette nocopy">
                                {result ? 
                                    result 
                                :
                                    <div className={`last_item flex`}>
                                        <p>??</p>
                                    </div>
                                }

                            <button className='common_button green full flex' onMouseDown={() => verify()}>
                                {loadBtn ? 'Verify' : 'Loading...'}
                            </button>
                        </div>

                    </div>

                </div>

            </div>            
        </div>
    );
};

export default ProvablyModal;