import React from 'react';
import coin from './../../../../fonts/main_icon_fonts/coin.svg';
import collect from './../../images/collect.svg';
import useCollect from '../../hooks/useCollect';
import LoadingItems from '../../../../components/LoadingItems';
import NotItems from '../../../../components/NotItems';

const CollectBlock = ({userId}) => {

    const {load, error, setError, funds, coins, onInput, increase, decrease, sendBorrow} = useCollect(userId)

    return (
        <div className="collect_block">

            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <img src={collect} alt="" />
                </div>
                <h4 className='game_title'>Collect coins</h4>
                <div className="decor pink"></div>
            </div>
            
            <div className="collect_window" >
                <div className={`main_input main_card ${error.value ? 'error' : ''}`}>
                    {error.enough && <div className="error_message">You have provided zero value!</div> }
                    
                    <img src={coin} alt="" />
                    <input 
                        type="text"
                        inputMode='numeric'
                        value={coins}
                        onChange={(e) => onInput(e.target.value)}
                        onBlur={(e) => setError(prev => ({...prev, value: false, borrow: false}))}
                        placeholder={funds.availableFundsToBorrow ? `Max coins to borrow are ${funds.availableFundsToBorrow}` : `No coins to borrow`}
                    />
                    <button className="common_button pink full" onMouseDown={() => increase()}>+</button>
                    <button className="common_button pink full" onMouseDown={() => decrease()}>-</button>
                </div>
                <p>The loan will be deducted from your Affilia Balance and will be paid back using the earnings you generate from your referees</p>
                
                <button className="common_button full white" onMouseDown={() => sendBorrow()}>
                    {load ? 'Borrow' : 'Loading...'}
                </button>
            </div>
        </div>
    );
};

export default CollectBlock;