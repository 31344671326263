import React, { useEffect, useRef, useState } from 'react';

const Timer = ({timeField}) => {

    return (
        <div className='timer_block'>
            {timeField === '0.00'
            ?<div className="process">Game in process</div>
            :
            <>
                <p>Crash start in:</p>
                <div className='time'>{timeField}</div>
            </> 
            }
            
        </div>
    );
};

export default Timer;
