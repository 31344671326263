import React, { useState } from 'react';
import ak from './../../../images/ak.svg'
import LoadingItems from '../../../components/LoadingItems';
import NotItems from '../../../components/NotItems';
import Store from '../../../Store';


const OpenFourCase = ({ roullete, roulleteTwo, roulleteThree, roulleteFour, itemsOpenList1, itemsOpenList2, itemsOpenList3, itemsOpenList4 }) => {
    let [reren, setReren] = useState(false)
    
    Store.useListener('caseItems4', setReren)

    return (
        <div className='case_open_flex'>

            <div className="triangle_left"></div>
            <div className="triangle_right"></div>

            <div className="four_case_open_flex_item">
                
            <div className="four_case_open_flex_wrapper_main">

                <div className="four_case_open_flex_wrapper">
                    <div className="triangle_small_left"></div>

                    <div className="roulete_hidden">

                        {itemsOpenList1 ? 
                            <>
                                {itemsOpenList1.length > 0 ?
                                    
                                        <div className="four_case_open_flex_roulete" ref={roullete}>
                                            {itemsOpenList1.map((el, i) => (
                                                <div  className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList1.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf17' : el.fakeItem.id} >
                                                    <img  
                                                        key={i} 
                                                        src={el.fakeItem.image} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    
                                    :
                                    <NotItems />
                                }
                            </>
                                
                        : 
                        
                            <LoadingItems 
                        />}
                        
                    </div>

                </div>

                <div className="four_case_open_flex_wrapper">
                    <div className="triangle_small_right"></div>

                    <div className="roulete_hidden">
                    {itemsOpenList2 ? 
                            <>
                                {itemsOpenList2.length > 0 ?
                                    
                                        <div className="four_case_open_flex_roulete" ref={roulleteTwo}>
                                            {itemsOpenList2.map((el, i) => (
                                                <div  className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList2.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf18' : el.fakeItem.id} >
                                                    <img  
                                                        key={i} 
                                                        src={el.fakeItem.image} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    
                                    :
                                    <NotItems />
                                }
                            </>
                                
                        : 
                        
                            <LoadingItems 
                        />}
                    </div>

                </div>

            </div>

            </div>

            <div className="four_case_open_flex_item  four">

                <div className="four_case_open_flex_wrapper_main">

                    <div className="four_case_open_flex_wrapper">
                        <div className="triangle_small_left"></div>

                        <div className="roulete_hidden">
                            {itemsOpenList3 ? 
                                <>
                                    {itemsOpenList3.length > 0 ?
                                        
                                            <div className="four_case_open_flex_roulete" ref={roulleteThree}>
                                                {itemsOpenList3.map((el, i) => (
                                                    <div  className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList3.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf19' : el.fakeItem.id} >
                                                        <img  
                                                            key={i} 
                                                            src={el.fakeItem.image} 
                                                            alt="" 
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        
                                        :
                                        <NotItems />
                                    }
                                </>
                                    
                            : 
                            
                                <LoadingItems 
                            />}
                        </div>

                    </div>

                    <div className="four_case_open_flex_wrapper">
                        <div className="triangle_small_right"></div>

                        <div className="roulete_hidden">
                            {itemsOpenList4 ? 
                                <>
                                    {itemsOpenList4.length > 0 ?
                                        
                                            <div className="four_case_open_flex_roulete" ref={roulleteFour}>
                                                {itemsOpenList4.map((el, i) => (
                                                    <div  className={`case_open_img_wrapper ${el.fakeItem.type}`} data-id={i === (itemsOpenList4.length - 10) ? '2320--dfdad=a=dsf=f=asd--asaf110' : el.fakeItem.id} >
                                                        <img  
                                                            key={i} 
                                                            src={el.fakeItem.image} 
                                                            alt="" 
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        
                                        :
                                        <NotItems />
                                    }
                                </>
                                    
                            : 
                            
                                <LoadingItems 
                            />}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
};

export default OpenFourCase;