import React from 'react';
import RouletteItem from './RouletteItem';


const CaseRoulete = ({cases, round}) => {
    return (
        <div className="case_roulete_wrapper">

            <div className="case_roulete">

                {cases?.length ?
                    <div className="case_roulete_track" style={{transform: `translateX(-${60 + ((round - 1) * 120)}px)`}}>
                        {cases.map((el, i) => (
                            <RouletteItem el={el} round={round} key={i} index={i + 1}/>
                        ))}
                    </div>
                :
                    <></>
                }

            </div>

            <div className="triangle top"></div>
            <div className="triangle"></div>
        </div>
    );
};

export default CaseRoulete;