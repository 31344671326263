import React, { useCallback, useEffect, useRef, useState } from 'react';

import Store from '../../Store';
import SkinSplider from './components/SkinSplider';
import SkinHeadSplider from './components/SkinHeadSplider';
import SkinFooterSplider from './components/SkinFooterSplider';

const SkinPopUp = () => {

    const [winSkins, setWinSkins] = useState(false)
    const [background, setBackground] = useState('')
    Store.useListener('winSkins', setWinSkins)
    Store.useListener('background', setBackground)

    const head = useRef()
    const main = useRef()
    const footer = useRef()

    function closePopUp () {
        document.getElementById('skin_dice').classList.remove('open')
        document.querySelector('html').classList.remove('hidden')
        setWinSkins(false)
    }

    useEffect(() => {

        main.current.sync(head.current.splide);
        main.current.sync(footer.current.splide);
        
    }, [main, head, footer])

    useEffect(() => {
        const splide = main.current.splide;

        if(winSkins?.length) {
            setBackground(winSkins[0].wonItem.type)

            splide.on('moved', (newIndex) => {
                setBackground(winSkins[newIndex].wonItem.type)
            });
        }

        return () => {
            splide.off('moved');
        };
        
    }, [winSkins]);

    return (
        <div className='skin_pop_up_wrapper' id='skin_dice' onMouseDown={closePopUp}>
            <div className="skin_pop_up_scroll"> 
                <div className="skin_pop_up" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="case_btl_icon_folder"></div>
                        </div>   
                        <h4 className='game_title'>{winSkins[0] && winSkins[0].caseInfo[0].caseName}</h4>
                        <div className="decor pink" onMouseDown={closePopUp}></div>
                    </div>

                    <div className={`skins_popup_grid ${background}`}>

                        <SkinHeadSplider winSkins={winSkins} head={head}/>

                        <SkinSplider winSkins={winSkins} main={main}/>

                        <SkinFooterSplider winSkins={winSkins} setWinSkins={setWinSkins} footer={footer} closePopUp={closePopUp}/>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default SkinPopUp;