import React from 'react';
import Pensil from './Pensil';
import Bet from '../../../Bet';
import ErrorWindow from '../../../components/ErrorWindow';


const CrashGame = ({crashObj}) => {

    return (
        <div className="crash" >
            <div className="your_bet">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="home_icon_crash"></div>
                    </div>
                    <h4 className='game_title'>Your bet</h4>
                    <div className="decor pink"></div>
                </div>
                <div className="calculation_block">
                    <div>
                        <div className={`amount_filed main_card ${crashObj.error ? 'error' : ''}`}>
                            {crashObj.error && <ErrorWindow text={'Minimum is 0.1'}/>}
                            <input type="text" id='amount_field' placeholder='Enter amount' value={(crashObj.bet).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(e) => crashObj.betChange(e.target.value)} onBlur={(e) => crashObj.validateBet(e.target.value)}/>
                            <div className="multipliers_block">
                                <button className='common_button' onClick={(e) => crashObj.betDivide(2)}>1/2</button>
                                <button className='common_button' onClick={(e) => crashObj.betMulti(2)}>x2</button>
                            </div>
                        </div>
                        <div className="control_block">
                            <button className="common_button red trash" onClick={crashObj.betClear}>
                                <div className="crash_icon_trash"></div>
                            </button>
                            <button className='common_button' onClick={() => crashObj.betPlus(10)}>+10</button>
                            <button className='common_button' onClick={() => crashObj.betPlus(100)}>+100</button>
                            <button className='common_button' onClick={() => crashObj.betPlus(1000)}>+1,000</button>
                            <button className='common_button all' onClick={() => Bet.maxBet(crashObj.bet, crashObj.setBet)}>MAX</button>
                        </div>
                    </div>
                    <div className={`coeficient_block ${crashObj.autoError ? 'error' : ''}`}>
                        <label>Auto-output</label>
                        <div className="input_wrapper">
                            {crashObj.autoError && <ErrorWindow text={'Minimum is 1.01 or 0'}/>}
                            <input type="text" value={(crashObj.auto).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(e) => crashObj.autoChange(e.target.value)} className='main_card' id='coef_field' placeholder='Coeficient' onBlur={(e) => crashObj.validateAuto(e.target.value)}/>
                        </div>
                    </div>
                    {crashObj.game === 'progres' && crashObj.zeroBet
                    ? <button className="common_button pink place_bet" onClick={crashObj.takePayOut}>Take bet</button> 
                    :<button className={`common_button green place_bet ${crashObj.game === 'progres' ? 'close' : ''}`} disabled={crashObj.game === 'progres' ? true : false} onClick={crashObj.placeBet}>Place bet</button>
                    }
                </div>
            </div>
            <div className="crash_game">
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="home_icon_crash"></div>
                    </div>
                    <h4 className='game_title'>Crash</h4>
                    <div className="decor pink"></div>
                </div>
                <div className="game_window">
                    <div className={`game ${crashObj.game == 'crash' ? 'crash_window' : ''}`}>
                        <div className="status">
                            <p style={{background: crashObj.color}}>x{crashObj.multiplier}</p>
                            <div className="ping">
                                <div className="crash_icon_ping"></div>
                                {crashObj.ping} ms
                            </div>
                        </div>
                        <div className={`result_window ${crashObj.game === 'crash' ? 'active' : ''}`}>Crash x{crashObj.multiplier}</div>
                        <div className={`result_window ${crashObj.game === 'progres' ? 'progres' : ''}`}>Game in process</div>
                        <Pensil refs={{'redRocket': crashObj.redRocket, 'blueRocket': crashObj.blueRocket, 'pensil': crashObj.pensil, 'yellowRocket': crashObj.yellowRocket}} game={crashObj.game}/>
                        <div className="scale" id='scale' style={{transform: `translateY(${(crashObj.multiplier * 100) > 600 ? (crashObj.multiplier * 60) - 60 * 6 : 0 }px)`}}>
                            <div className="scale_item active">x1.00</div>
                            {crashObj.scaleList && crashObj.scaleList.map((e, i)=> (
                                <div key={i} className="scale_item">x{e}</div>
                            ))}
                        </div>
                        <div className={'scale_level'} style={{transform: `translateY(${crashObj.multiplier * 100 < 600 ? 500 - (crashObj.multiplier * 60 - 60) : 200}px)`, background: crashObj.color}}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrashGame;