import React from 'react';
import Store from '../../Store';

const LoginBtn = () => {

    function openLogin() {
        Store.setListener('openAuthPopUp', prev => prev = true)
        document.querySelector('html').classList.add('hidden')
    }

    return (
        <button className="common_button red flex" onClick={openLogin}>
            <div className='icon_steam'></div>
            <div>Login</div>
        </button>
    );
};

export default LoginBtn;