import React, {useEffect} from 'react';
import NewsSplider from './components/NewsSplider';
import Title from '../../mainTitle';

import '@splidejs/react-splide/css';
import './css/home.css'
import { NavLink } from 'react-router-dom';

import ak from './../../images/ak.svg'
import coin from './../../fonts/main_icon_fonts/coin.svg'
import MobileHead from './components/MobileHead';
import test from './images/sa.jpg'


const Home = (props) => {

    useEffect(() => {
        Title.setListener('title', 'HOME')
    }, [])

    return (
        <div className="home_block container">
            
            <MobileHead />

            <div id="home_top"></div>
            
            <NewsSplider role={props.role}/>

            <div className="grid_navigation">

                <div className="grid_nav_wrapper a">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_roullete"></div>
                            </div>
                            <NavLink to='/roulette' className='game_title'>Roullette</NavLink>
                            <div className="decor red"></div>
                        </div>
                        
                        <div className="game_content">
                            <NavLink to='/roulette' className=''><img src={''} alt="" className='link_image'/></NavLink>    
                        </div>
                    </div>
                </div>
                <div className="grid_nav_wrapper b">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item h">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_battles"></div>
                            </div>
                            <NavLink to='/case_battles' className='game_title'>Case Battles</NavLink>
                            <div className="decor light_yellow"></div>
                        </div>

                        <div className="game_content">
                            <NavLink to='/case_battles' className=''><img src="" alt="" className='link_image'/></NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="grid_nav_wrapper c">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item c">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_crash"></div>
                            </div>
                            <NavLink to='/crash' className='game_title'>Crash</NavLink>
                            <div className="decor green"></div>
                        </div>

                        <div className="game_content">
                            <NavLink to='/crash' className=''><img src="" alt="" className='link_image'/></NavLink>
                        </div>
                    </div>
                </div>

                <div className="grid_nav_wrapper d">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item d">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_cases"></div>
                            </div>   
                            <NavLink to='/cases' className='game_title'>Cases</NavLink>
                            <div className="decor blue"></div>
                        </div>

                        <div className="game_content">
                            <NavLink to='/cases' className=''><img src="" alt="" className='link_image'/></NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="grid_nav_wrapper e">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item e">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_upgrade"></div>
                            </div>   
                            <NavLink to='/upgrade' className='game_title'>Upgrade</NavLink>
                            <div className="decor orange"></div>
                        </div>

                        <div className="game_content">
                            <NavLink to='/upgrade' className=''><img src="" alt="" className='link_image'/></NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="grid_nav_wrapper f">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item f">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_dice"></div>
                            </div>   
                            <NavLink to='/dice' className='game_title'>Dice</NavLink>
                            <div className="decor light_blue"></div>
                        </div>

                        <div className="game_content">
                            <NavLink to='/dice' className=''><img src="" alt="" className='link_image'/></NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="grid_nav_wrapper h disabled">
                    <div className="head"></div>
                    <div className="content"></div>

                    <div className="grid_nav_item b">
                        <div className="grid_nav_item_header">
                            <div className="game_icon">
                                <div className="home_icon_slots"></div>
                            </div>
                            <div className='game_title'>Slots</div>
                            <div className="decor pink"></div>
                        </div>

                        <div className="game_content">
                            <div className=''><img src="" alt="" className='link_image disabled'/></div>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    );
};

export default Home;