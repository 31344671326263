import React from 'react';

import Case from './Case';
import useCaseList from '../hooks/useCaseList';
import NotItems from '../../../components/NotItems';
import LoadingItems from '../../../components/LoadingItems';

const CaseList = ({isAuth}) => {

    const {load, cases, clientLvl} = useCaseList(isAuth)

    return (
        <div className="case_grid">
            {load ? 
                <>
                    {cases?.length ? 
                        <>
                            {cases.map((el, i) => (
                                <Case 
                                    key={el.id} 
                                    el={el} 
                                    lvl={(i + 1)*10} 
                                    open={clientLvl && clientLvl >= ((i + 1)*10) ? true : false}
                                />
                            ))}
                        </>
                    :
                        <NotItems />
                    }
                </>
            :
                <LoadingItems />
            }
        </div>
    );
};

export default CaseList;