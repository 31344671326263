import React, { useEffect, useState } from 'react';
import Tier from './components/Tier';
import tier1 from './images/tier1.svg'
import tier2 from './images/tier2.svg'
import tier3 from './images/tier3.svg'
import tier4 from './images/tier4.svg'
import tier5 from './images/tier5.svg'

import './css/referal.css'
import LoadingItems from '../LoadingItems';
import PromoServices from '../../pages/promo/services/PromoServices';

const ReferalTierPopup = ({closeTierPopUp, progres, userLvl, userTier}) => {

    const [load, setload] = useState(false)
    const [tiers, setTiers] = useState(false)
    const image = [tier1, tier2, tier3, tier4, tier5]

    useEffect(() => {
        (async () => {
            let init = await PromoServices.getReqCookie(`api/v1/referral-tiers/all`)
            if(init === null || init === 'error') {
                setTiers(false)
                return
            }
            setTiers(init)
            setload(true)
        })()
    }, [])

    return (
        <div className='referal_tier_popup_wrapper open' onMouseDown={() => closeTierPopUp()}>
            <div className="referal_tier_popup" onMouseDown={(e) => e.stopPropagation()}>
                <div className="grid_nav_item_header">
                    <div className="game_icon">
                        <div className="account_icon_gear"></div>
                    </div>
                    <div className='game_title'>Referal tier</div>
                    <div className="decor pink" onMouseDown={() => closeTierPopUp()}></div>
                </div>

                <div className="referal_tier_popup_window">
                    {load ? 
                        <>  
                            {tiers?.length ? 
                                <>  
                                    {tiers.map((el, i) => (
                                        <Tier 
                                            userTier={userTier}
                                            lvl={el.level} 
                                            total={el.totalDeposited} 
                                            commission={el.commission} 
                                            image={image[i]} 
                                            progres={el.level === userLvl ? progres : el.level < userLvl ? 100 : 0} 
                                            referal={el.activeReferrals} 
                                            loan={el.coinsToBorrow}
                                        />
                                    ))} 
                                </>
                            :
                                <div className='wrong'>Sorry, something went wrong...</div>
                            }

                        </>
                    :
                        <LoadingItems />
                    }
                </div>
            </div>
        </div>
    );
};

export default ReferalTierPopup;