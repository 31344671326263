import { useState } from "react"
import Notice from "../../Notice"
import Administrator from "../services/Administrator"
import Store from "../../Store"
import Api from "../../Api"


export default function useAdminItem() {
    let [edit, setEdit] = useState(false)
    const [load, setLoad] = useState(true)
    let [error, setError] = useState({
        name: false,
        image: false,
        price: false,
    })
    let [itemParams, setItemparams] = useState({
        name: '',
        image: '',
        price: '',
    })

    /* ---------Получение существующего предмета по кнопке EditItemBtn----------- */
    function getExistItem(data) {
        setItemparams({
            name: data.name,
            image: data.image,
            price: data.price,
            id: data.id,
        })
        setEdit(true)
    }

    Store.useListener('itemToEdit', getExistItem)
    /* ---------Ввод параметров предмета----------- */

    function changeItemParams(e) {
        setError(prev => ({...prev, [e.target.name]: false}))

        if(!e.target.value) {
            setError(prev => ({...prev, [e.target.name]: true}))
        }

        setItemparams(prev => ({...prev, [e.target.name]: e.target.value}))
    }


    function changeItemPrice(e) {
        setError(prev => ({...prev, price: false}))

        if(!e.target.value) {
            setError(prev => ({...prev, price: true}))
        }

        let value = e.target.value.replace(/[^0-9.]/g, '').replace('.', 'x').replace(/\./g,'').replace('x','.');
        
        if(value > 20000.00) {
            setItemparams(prev => ({...prev, price: '20000.00'}))
        } else {
            let str = value.split('.')
            if(str[1] && str[1].length > 2) {
                return
            } else {
                setItemparams(prev => ({...prev, price: str.join('.')}))
            }
        }
    }


    /* ---------Создание / редактирование предмета----------- */
    async function sendItem(method, path) {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: method,
                headers: {
                    'Content-type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    name: itemParams.name,
                    image: itemParams.image,
                    price: JSON.parse(itemParams.price),
                })
            })
            if(res.status === 401) {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: method,
                    headers: {
                        'Content-type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        name: itemParams.name,
                        image: itemParams.image,
                        price: JSON.parse(itemParams.price),
                    })
                })
            }
            if(res.status === 400) {
                return '400'
            }
            if (res.status === 409) {
                return 'exist'
            }
            if (!res.ok) {
                return 'error'
            } 

            res = await res.json()
            return res
        }
        catch(e) {
            return 'error'
        }
    }

    function confirmItem(e) {
        setError(prev => ({...prev, name: false, image: false, price: false}))
        document.getElementById('err_item_exist').classList.remove('error')

        e.preventDefault()

        let err = false

        if(itemParams.name === ''){
            err = true
            setError(prev => ({...prev, name: true}))
        }
        if(itemParams.image === '') {
            err = true
            setError(prev => ({...prev, image: true}))
        }
        if(itemParams.price === '' || itemParams.price <= 0 || itemParams.price === '.'){
            err = true
            setError(prev => ({...prev, price: true}))
        }
        if(err){
            return
        }
        (async () => {
            setLoad(prev => prev = false)
            if(edit) {

                const data = await sendItem('PUT', `api/v1/fake-item/update/${itemParams.id}`)
                setLoad(prev => prev = true)
                if(data === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
                if(data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true, price: true}))
                    document.getElementById('err_item_exist').classList.add('error')
                    return
                }

                Store.setListener('EDIT_EXIST_ITEM', data)
                Store.setListener('EDIT_EXIST_ITEM_INCASE', data)

                Administrator.onSuccess('Item modified')

                //closeAdminEditPopup()

                return
    
            } else {
                const data = await sendItem('POST', `api/v1/fake-item/create`)
                setLoad(prev => prev = true)

                if(data === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
                if(data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true, price: true}))
                    document.getElementById('err_item_exist').classList.add('error')
                    return
                }

                Store.setListener('CREATE_NEW_ITEM', data)

                Administrator.onSuccess('You create item!')

                closeAdminEditPopup()
            }
        })()
    }


    function closeAdminEditPopup () {
        document.getElementById('adminEditItems').classList.remove('open')
        if(!document.getElementById('adminAddCases').classList.contains('open')) {
            document.querySelector('html').classList.remove('hidden')
        }

        setEdit(false)
        setItemparams({
            name: '',
            image: '',
            price: '',
        })
        setError({
            name: false,
            image: false,
            price: false,
        })
    }

    return {load, error, setError, edit, itemParams, setItemparams, changeItemParams, changeItemPrice, confirmItem, closeAdminEditPopup}
}