import React from 'react';
import AdminItemDelBtn from './components/items/AdminItemDelBtn';
import useAdminItem from './admin_hooks/useAdminItem';

const EditItem = () => {
    let {load, error, setError, edit, itemParams,  changeItemParams, changeItemPrice, confirmItem, closeAdminEditPopup} = useAdminItem()

    return (
        <div className='admin_popup_wrapper' id='adminEditItems' onMouseDown={closeAdminEditPopup}>
            <div className="admin_popup_scroll">
                <div className="admin_popup" onMouseDown={(e) => e.stopPropagation()}>

                    <div className="grid_nav_item_header">
                        <div className="game_icon">
                            <div className="admin_icon_star"></div>
                        </div>
                        <div className='game_title'>{edit ? 'Modify' : 'Create'} item</div>
                        <div className="decor pink" onMouseDown={closeAdminEditPopup}></div>
                    </div>

                    <div className="admin_popup_content">
                        <div id='err_item_exist' className={`err_mess`} >The item allready exist!</div>
                        
                        <label htmlFor="">Item name</label>
                        <input 
                            className={`admin_input ${error.name ? 'error' : ''}`} 
                            placeholder='Empty-Head' name='name' 
                            value={itemParams.name} 
                            onChange={(e) => changeItemParams(e)} 
                            onBlur={(e) => setError(prev => ({...prev, ['name']: false}))}
                        />

                        <label htmlFor="">Image <span>[.svg]</span> </label>
                        <input 
                            className={`admin_input ${error.image ? 'error' : ''}`}
                            placeholder='<svg width="59" height="59"...' name='image' 
                            value={itemParams.image} 
                            onChange={(e) => changeItemParams(e)} 
                            onBlur={(e) => setError(prev => ({...prev, ['image']: false}))}
                        />

                        <label htmlFor="">Price</label>
                        <input 
                            type='text'
                            inputMode='numeric' 
                            className={`admin_input ${error.price ? 'error' : ''}`}
                            placeholder='1987878.99' 
                            value={itemParams.price} 
                            onChange={(e) => changeItemPrice(e)} 
                            onBlur={(e) => setError(prev => ({...prev, ['price']: false}))}
                        />     
                        
                        <button className="common_button full green" onClick={(e) => confirmItem(e)}>
                            {load ? 'CONFIRM' : 'Loading...'}
                        </button>

                        {edit && 
                            <AdminItemDelBtn id={itemParams.id} closeAdminEditPopup={closeAdminEditPopup}/>
                        }            

                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditItem;