import React, { useState } from 'react';
import Notice from '../../../Notice';
import Store from '../../../Store';
import Api from '../../../Api';

const FreeCaseDelBtn = ({caseId}) => {

    const [load, setLoad] = useState(true)

    async function deleteCase() {

        setLoad(prev => prev = false)
        
        await fetch(`${Api.url}/api/v1/case/${caseId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
        .then(res => {
            if(res.status != 200) {
                return 'error'
            } else {
                return res.json()
            }
        })
        .then(data => {
            setLoad(prev => prev = true)
            if(data === 'error'){
                Notice.Send({type: 'error', text: 'Something went wrong'})
                return
            }

            Store.setListener('DELETE_FREE_CASE_FROM_ADMIN', caseId)

            Notice.Send({type: 'error', text: 'Case deleted'})
            
        })
        .catch(error =>  console.log(error))
    }

    return (
        <button className="common_button red" onClick={deleteCase}>
            {load ? 
                <div className='admin_icon_cross'></div>
            :
                <svg width = {`20px`}height={'20px'}>
                    <circle className="admin_load_button" cx = {10} cy = {10} r  = {9}></circle>
                </svg>
            }
            
        </button> 
    );
};

export default FreeCaseDelBtn;