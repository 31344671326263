import React from 'react';
import coin from './../../../fonts/main_icon_fonts/coin.svg'
import second_back from './../images/2nd_back.svg'
import second_footer from './../images/2nd_footer.svg'
import first_footer from './../images/1st_footer.svg'
import hat from './../images/2p_hat.svg'
import cigarette from './../images/cigarette.svg'
import crown from './../images/crown.png'
import first_back from './../images/1st_back.svg'
import edging from './../images/edging2.svg'
import third_back from './../images/3rd_back.svg'
import third_footer from './../images/3rd_footer.svg'
import shit from './../images/shit.png'
import third_avatar from './../images/third_avatar.png'
import pendant from './../images/pendant.png'
import Avatar from '../../../components/avatar/Avatar';
import Price from '../../../components/price/Price';


const Places = ({raceList}) => {

    return (
        <div className='places_block'>

            {raceList.second && 
                <div className="places_item second">
                    <div className="place_body">
                            <div className="place_hat second">2nd PLACE</div>
                        <img src={second_back} alt="" className='back'/>
                        <div className="avatar">
                            <Avatar 
                                img={raceList.second.avatar}
                                lvl={raceList.second.level}
                                percent={(raceList.first.experience / raceList.first.experienceBorderTillLevel) * 100}
                                color={'#30dba8'}
                                size={80}
                                rad={36}
                                dasharray={227}
                                lvlFont={20}
                                lvlSize={32}
                            />
                            <img src={hat} alt="" className="hat" />
                            <img src={cigarette} alt="" className="ciga" />
                        </div>
                        <h3 className="place_title">{raceList.second.username}</h3>
                        <div className="won_block">
                            <span>Won:</span>
                            <div className="price">
                                <Price price={raceList.second.won}/>
                                <img src={coin} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="place_footer">
                        <img src={second_footer} alt="" className='footer'/>
                        <div className="price">
                            <img src={coin} alt="" />
                            {(raceList.second.total).toFixed(2)}
                        </div>
                    </div>
                </div>
            }

            {raceList.first &&
                <div className="places_item first">
                    <div className="place_body first">
                        <div className="place_hat first">1st PLACE</div>
                        <img src={edging} alt="" className='edging'/>
                        <img src={first_back} alt="" className='back'/>
                        <div className="avatar">
                            <Avatar 
                                img={raceList.first.avatar}
                                lvl={raceList.first.level}
                                percent={(raceList.first.experience / raceList.first.experienceBorderTillLevel) * 100}
                                color={'#30dba8'}
                                size={80}
                                rad={36}
                                dasharray={227}
                                lvlFont={20}
                                lvlSize={32}
                            />
                            <img src={crown} alt="" className="hat first" />
                        </div>
                        <h3 className="place_title first">{raceList.first.username}</h3>
                        <div className="won_block">
                            <span>Won:</span>
                            <div className="price">
                                <Price price={raceList.first.won}/>
                                <img src={coin} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="place_footer">
                        <img src={first_footer} alt="" className='footer first'/>
                        <div className="price">
                            <img src={coin} alt="" />
                            {(raceList.first.total).toFixed(2)}
                        </div>
                    </div>
                </div>
            }

            {raceList.third &&
                <div className="places_item third">
                    <div className="place_body third">
                            <div className="place_hat third">3rd PLACE</div>
                        <img src={third_back} alt="" className='back third'/>
                        <div className="avatar">
                            <Avatar 
                                img={raceList.third.avatar}
                                lvl={raceList.third.level}
                                percent={(raceList.third.experience / raceList.third.experienceBorderTillLevel) * 100}
                                color={'#30dba8'}
                                size={80}
                                rad={36}
                                dasharray={227}
                                lvlFont={20}
                                lvlSize={32}
                            />
                            <img src={pendant} alt="" className="hat third" />
                        </div>
                        <h3 className="place_title">{raceList.third.username}</h3>
                        <div className="won_block">
                            <span>Won:</span>
                            <div className="price">
                                <Price price={raceList.third.won}/>
                                <img src={coin} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="place_footer">
                        <img src={shit} alt="" className='shit'/>
                        <img src={third_footer} alt="" className='footer'/>
                        <div className="price">
                            <img src={coin} alt="" />
                            {(raceList.third.total).toFixed(2)}
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default Places;