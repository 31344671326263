import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Api from '../../../Api';
import Notice from '../../../Notice';

const Case = ({el, lvl, open}) => {
    const [timeTo, setTimeTo] = useState(false)
    const [load, setLoad] = useState(true)

    useEffect(() => {
        if(open) {
            (async () => {
                setLoad(prev => prev = false)
                var time = await Api.asyncGetWithMessage(`api/v1/game-open-case/remaining-time?caseId=${el.id}`)
                setLoad(prev => prev = true)
                if(time.statusCode === 404) {
                    return
                }
                if(time === 'error') {
                    return Notice.Send({type: 'error', text: 'Something went wrong'})
                }
                if(time.remainingTime === '00:00') {
                    return
                }
                setTimeTo(time.remainingTime.split(':'))

            })()
        }
    }, [])

    return (
        <div className="case_grid_item">
            <img src={el.image} alt="" />
            <div className="level">
                {!open && <div className="rewards_icon_lock"></div>}
                Level {lvl}
            </div>
            <p>{el.name}</p>

            {load ? 
                <>
                    {open ? 
                    <>
                        {timeTo ? 
                                <button className="common_button full flex close">
                                    available in: 
                                    {timeTo[0] == '00' ? `${timeTo[1]} minutes` : `${timeTo[0]} hours`} 
                                </button>
                            :
                            <NavLink to={`/cases/open/${el.id}`} className='common_button full flex'>
                                Open Case
                            </NavLink> 
                        }
                    </>
                        :
                        <button className='common_button close red'>Close</button>
                    }
                </>
            :
                <button className="common_button full flex">Loading...</button>
            }
        </div>
    );
};

export default Case;