import React, { useEffect, useRef, useState } from 'react';

import coin from './../../../fonts/main_icon_fonts/coin.svg'
import Api from '../../../Api';
import { io } from 'socket.io-client';
import NotItems from '../../../components/NotItems';
import LoadingItems from '../../../components/LoadingItems';
import Price from '../../../components/price/Price';

const LiveUpgrade = () => {
    const socket = useRef()
    const timer = useRef()
    const listAnimate = useRef()
    const [liveList, setLiveList] = useState(false)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        socket.current = io(`${Api.urlWs}/game-upgrade`, {
            transports: ["websocket",],
            reconnection: true,
            reconnectionDelay: 2000,
            reconnectionAttempts: 5,
        })
        socket.current.on('upgrade.lastWins', (data) => {
            if(!data || data.length <= 0) {
                setLoad(prev => prev = true)
                return
            }
            if(data?.length > 1 || !listAnimate.current.firstChild) {
                const list = data.slice(0, 10)
                setLiveList(list)
            }  else {
                timer.current = setTimeout(() => {
                    const newitem = document.createElement('div')
                    newitem.className = `drop_card_wrapper anim ${data?.type}`
                    newitem.innerHTML = `<div class="drop_card nocopy hidden ${data?.type}">
                                            <img src='${data?.image}' class='drop_image ${data?.type}' alt="" />
                                            <div class="drop_card_content">
                                            <h4 class="title">${data?.name}</h4>
                                            <p class="price main_card ${data?.type}">${(data?.price)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <img src='${coin}' alt="" /></p>
                                            </div>
                                        </div>`
                    listAnimate.current.prepend(newitem)
                    
                    setTimeout(() => {
                        newitem.classList.add('show')
                    }, 1)

                    const allItems = listAnimate.current.querySelectorAll('.drop_card_wrapper')
                    if(allItems.length > 15) {
                        allItems[allItems.length - 1].remove()
                    }
                }, 4000)
            }
            setLoad(prev => prev = true)
        })

        return () => {
            if(socket.current) {
                socket.current.off('upgrade.lastWins')
                socket.current.disconnect()
                clearTimeout(timer.current)
            }
        }
    }, [])

    return (
        <div className='live_upgrade'>
            <div className="grid_nav_item_header">
                <div className="game_icon">
                    <div className="upgrade_icon_upgrade"></div> 
                </div>
                <h4 className='game_title'>Live upgrade</h4>
                <div className="decor pink"></div>
            </div>

                {load ?
                    <>
                        {liveList?.length ? 
                            <>
                                <div className="drop_card_list" ref={listAnimate}>
                                    {liveList.map(el => (
                                        <div className="drop_card_wrapper">
                                            <div className={`drop_card border nocopy ${el.type}`} key={el.id}>
                                                <img src={el.image} className={`drop_image ${el.type}`} alt="" />
                                                <div className="drop_card_content">
                                                    <h4 className="title">{el.name}</h4>
                                                    <p className={`price main_card ${el.type}`}><Price price={el.price}/> <img src={coin} alt="" /></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>   
                            </>
                        :
                            <div className="drop_card_list">
                                <NotItems />
                            </div>
                        }
                    </>
                :
                    <LoadingItems />
                }
        </div>
    );
};

export default LiveUpgrade;