import React, { useEffect } from 'react';

import './css/help.css'

import greenMan from './image/green_man.svg'
import pencil from './image/pencil.svg'
import cat from './image/cat.png'
import underline from './image/underline.svg'
import Title from '../../mainTitle';

const Help = () => {

    useEffect(() => {
        Title.setListener('title', 'HELP')
    }, [])


    return (
        <div className='help_block container'>

            <div className="help_hero">

                <div className="image">
                    <img src={cat} alt="" />
                </div>

                <div className="help_slogan">
                    <h3>This is your <span>problem</span>?</h3>
                    <h4>Just <span>call</span> me </h4>
                    <h5>I can <span>fix! <img src={underline} alt="" /></span> </h5>
                    <img src={pencil} alt="" />
                </div>

            </div>
            <div className="search main_card">
                <input type="text" className="main_input" placeholder='Search your problem...'/>
                <div className="icon_search"></div>
            </div>
            

            <div className="proplem_grid">
                <div className="proplem_grid_item main_card">
                    <a href="">Games</a>
                    <p>Just post your problem and we'll solve it!</p>
                </div>
                <div className="proplem_grid_item main_card">
                    <a href="">Deposit</a>
                    <p>Just post your problem and we'll solve it!</p>
                </div>
                <div className="proplem_grid_item main_card">
                    <a href="">Withdrawing</a>
                    <p>Just post your problem and we'll solve it!</p>
                </div>
                <div className="proplem_grid_item main_card">
                    <a href="">Offers & surveys</a>
                    <p>Just post your problem and we'll solve it!</p>
                </div>
                <div className="proplem_grid_item main_card">
                    <a href="">Account & settings</a>
                    <p>Just post your problem and we'll solve it!</p>
                </div>
            </div>


        </div>
    );
};

export default Help;