import React, { useState } from 'react';
import Bet from '../../../Bet';
import ErrorWindow from '../../../components/ErrorWindow';
import Price from '../../../components/price/Price';
import Coin from '../../../components/coin/Coin';

const YourBet = ({ winnings, blocked, play, bet, setBet, setBets, betDivivde, diceMultiPlay, iter, setIter, stopRef, error, setError}) => {

    let [variant, setVariant] = useState('manual')
    let [iterError, setIterError] = useState(false)

    function changeIter(value) {
        setIterError(false)
        if(!value) {
            setIterError(true)
        }
        if(value === '0') {
            setIterError(true)
            return
        }
        if (value > 10000) {
            return setIter(10000)
        }
        if(/\D/.test(value)) {
            return 
        } else {
            setIter(value)
        }
    }

    return (
        <div className="your_bet">

            <div className="your_bet_window">
                
                <div className="man_auto_block">
                    <button className={`common_button auto ${variant === 'manual' && 'active'}`} onClick={(e) => setVariant('manual')}>
                        <div className="account_icon_mark"></div>
                        Manual
                    </button>
                    <button className={`common_button auto ${variant === 'auto' && 'active'}`} onClick={(e) => setVariant('auto')}>
                        <div className="account_icon_mark"></div>
                        Auto
                    </button>
                </div>

                {variant === 'manual' && 
                    <div className="bet_block">

                        <div className="amount_filed main_card">
                            {error.bet && <ErrorWindow text={'Minimum is 0.1'}/>}
                            <Coin />

                            <label htmlFor="" className='new_label'>Your bet</label>

                            <input type="text" id='amount_field' placeholder='Enter amount' value={(bet)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(e) => setBets(e.target.value)} onBlur={(e) => e.target.value < 0.1 ? setError(prev => ({...prev, ['bet']: true})) : setError(prev => ({...prev, ['bet']: false})) }/>

                            <div className="multipliers_block">
                                <button className='common_button' onClick={() => betDivivde(2)}>1/2</button>
                                <button className='common_button' onClick={() => Bet.multi(2, bet, setBet)}>x2</button>
                                <button className='common_button' onClick={() => Bet.maxBet(1000, setBet)}>Max</button>
                            </div>
                        </div>

                        <div className="total_payout">
                            <label htmlFor="" className="new_label">Payout</label>
                            <Coin />
                            {bet ? <Price price={bet * winnings}/> : <p>Profit</p> }
                        </div>

                        <button className="common_button green play" onClick={play}>Play</button>

                    </div>
                }

                {variant === 'auto' && 
                    <div className="bet_block">

                        <div className="amount_filed main_card">
                            {error.bet && <ErrorWindow text={'Minimum is 0.1'}/>}

                            <label htmlFor="" className='new_label'>Your bet</label>

                            <Coin />

                            <input type="text" id='amount_field' placeholder='Enter amount' value={(bet)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(e) => setBets(e.target.value)} onBlur={(e) => e.target.value < 0.1 ? setError(prev => ({...prev, ['bet']: true})) : setError(prev => ({...prev, ['bet']: false})) }/>
                            
                            <div className="multipliers_block">
                                <button className='common_button' onClick={() => Bet.divide(2, bet, setBet)}>1/2</button>
                                <button className='common_button' onClick={() => Bet.multi(2, bet, setBet)}>x2</button>
                                <button className='common_button' onClick={() => Bet.maxBet(1000, setBet)}>Max</button>
                            </div>
                        </div>

                        <div className={`new_input_wrapper ${iterError ? 'error' : ''}`} >
                            <label htmlFor="" className="new_label">Bets</label>
                            <input type="text" placeholder='Number of bets' value={iter} onChange={(e) => changeIter(e.target.value)} onBlur={(e) => !e.target.value ? setIterError(prev => prev = true) : setIterError(prev => prev = false)}/>
                        </div>

                        <div className="total_payout">
                            <label htmlFor="" className="new_label">Payout</label>
                            <Coin />
                            {bet ? <Price price={bet * winnings}/> : <p>Profit</p> }
                        </div>

                        {blocked ? 
                            <button className="common_button pink play" onClick={() => stopRef.current = true}>Stop game</button>
                        :
                            <button className="common_button green play" onClick={diceMultiPlay}>Play auto</button> 
                        }
                    </div>
                }
                

            </div>
        </div>
    );
};

export default YourBet;