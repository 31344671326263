import React, { useState } from 'react';
import EmojiOpen from './EmojiOpen';
import EmojiList from './EmojiList';

const SendMess = ({sendMassage}) => {

    let [message, setMessage] = useState('')
    
    function getEmoji(e) {
        document.getElementById('chat_message').focus()
        setMessage(prev => prev + e) 
    }

    return (
     <form onSubmit={(e) => sendMassage(e, message, setMessage)}>
        <input type="text" id='chat_message' className='chat_input main_card' placeholder='Type message...' value={message} onChange={(e) => setMessage(e.target.value)}/>
        
        <EmojiOpen />
        
        <EmojiList func={getEmoji}/>
        
    </form>
    );
};

export default SendMess;