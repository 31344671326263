import React from 'react';
import useInfo from '../../hooks/useInfo';
import NotItems from '../../../../components/NotItems';
import ReferalTierPopup from '../../../../components/referal_tier_popup/ReferalTierPopup';

import coin from './../../../../fonts/main_icon_fonts/coin.svg';

const ThatsMe = ({user}) => {
    const {load, open, client, tier, progres, openTierPopUp, closeTierPopUp} = useInfo(user)

    return (
        <div className="thats_me_block">

                <div className="grid_nav_item_header ">
                    <div className="game_icon">
                        <div className="promo_icon_II"></div>
                    </div>
                    <h4 className='game_title'>That’s me</h4>
                    <div className="decor orange"></div>
                </div>
                {load ? 
                    <>
                        {tier ? 
                            <div className="thats_me_window">

                                <div className="avatar">
                                    <img src={client.user.steamProfile.avatar} alt=""/>
                                </div>
                                <h3 className="name">{client.user.steamProfile.username}</h3>
                                <div className="tier">
                                    <p>Tier {tier.level} <span className="comission">• {tier.commission}% Commission</span></p>
                                </div>

                                <div className="level_block">
                                    <div className="coins">
                                        <img src={coin} alt="" />
                                        <p>0<span>/2000</span></p>
                                    </div>

                                    <div className="level_block_flex">
                                        <div className="level main_card">{tier.level}</div>
                                        <div className="scale main_card">
                                            <div className="scale_fill" style={{'width': `${progres}%`}}></div>
                                        </div>
                                        <div className="level main_card">{tier.level >= 5 ? 'max' : tier.level + 1}</div>
                                    </div>
                                </div>

                                <button className="common_button white full" onClick={openTierPopUp}>View tiers</button>

                                {open && <ReferalTierPopup userTier={tier} userLvl={tier.level} progres={progres} closeTierPopUp={closeTierPopUp}/>}
                            </div>
                        :
                            <NotItems />
                        }
                    </>
                :
                    <div className="loading_info">Loading...</div>
                }
            </div>
    );
};

export default ThatsMe;