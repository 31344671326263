import React, { useEffect } from 'react';
import Api from '../../../../Api';
import coin from './../../../../fonts/main_icon_fonts/coin.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide';

const FooterSplider = ({winSkins, setWinSkins, footer, closePopUp}) => {
    const sellItem = async (id, index) => {
        let res = await Api.asyncGetCred(`api/v1/game-open-case/sell-item?fakeItemId=${id}`)
        if (res === 'unAuthorization') {
            document.querySelector('html').classList.add('hidden')
            document.querySelector('.auth_pop_up_wrapper').classList.add('open')
            return
        }

        if(res === 'error') {
            return 
        }
        
        await Api.updateAccount()

        if(winSkins.length > 1) {  
            setWinSkins(prev => prev.filter(el => el.index !== index))
        } else {
            closePopUp()
        }
        return 'ok'
    }

    useEffect(() => {

        if(winSkins) {
            winSkins.forEach((el, i) => {
                el.index = i
            })
        }

    }, [winSkins])

    const takeAway = (i) => {
        if(winSkins.length > 1) {
            setWinSkins(prev => prev.filter(el => el.index !== i))
        } else {
            closePopUp()
        }
    }

    return (
        <Splide 
            ref={(splider) => (footer.current = splider)}
            options={ {
                rewind       : true,
                gap: '40px',
                type: 'fade',
                drag: false,
                updateOnMove: true,
                autoplay     : false,
                autoWidth    : true,
                autoHeight   : true,
                perPage      : 1,
                perMove      : 1,
                padding      : '10px',
                arrows       : false,
                focus: 'center',
                pagination: false,
                classes: {
                    arrows: 'arrows_header',
                    arrow : 'splide__arrow your-class-arrow',
                    prev  : 'splide__arrow--prev your-class-prev',
                    next  : 'splide__arrow--next your-class-next',
                },
            } } 
            className='skin_footer_splider'
            aria-label="Skin">
            <>
                {winSkins && winSkins.length > 0 && 
                    <>  
                        {winSkins.map((e, i) => (

                            <SplideSlide key={i}>

                                <div className="price">
                                    <img src={coin} alt="" />
                                    {e.price}
                                </div>
                                
                                <>  
                                    <button className="common_button green full" onClick={() => sellItem(e.id, e.index)}>SELL</button>
                                    <button className="common_button full hatch" onClick={() => takeAway(i)}>take away</button>
                                </>
                                
                            </SplideSlide>

                        ))}
                    </>
                }
            </>
        </Splide>
    );
};

export default FooterSplider;