import { useEffect, useState } from "react"
import Api from "../../Api"
import Store from "../../Store"
import Administrator from "../services/Administrator"
import Notice from "../../Notice"


export default function useAdminCategories() {
    let [edit, setEdit] = useState(false)
    const [load, setLoad] = useState(true)
    const [loadBtn, setLoadBtn] = useState(true)
    let [error, setError] = useState({
        name: false,
        image: false,
    })

    let [categoryParams, setCategoryParams] = useState({
        name: '',
        image: '',
    })

    /* ---------Получение существующей категории----------- */
    let [categoryId, setCategoryId] = useState(false)

    useEffect(() => {
        
        if(categoryId) {
            (async () => {
                setEdit(true)
                setLoad(prev => prev = false)
                let categoryInit = await Api.asyncGet(`api/v1/categories/${categoryId}`)
                setLoad(prev => prev = true)
                if(categoryInit === 'error') {
                    Notice.Send({type: 'error', text: 'Ошибка сервера при получении категорий'})
                    return
                }
                setCategoryParams({
                    name: categoryInit.name,
                    image: categoryInit.image
                })
                
            })()
        }

    }, [categoryId])

    Store.useListener('categoryToEdit', setCategoryId)

    /* -----------Ввод данных------------ */

    function changeCatParams (e) {
        setError(prev => ({...prev, [e.target.name]: false}))

        if(!e.target.value) {
            setError(prev => ({...prev, [e.target.name]: true}))
        }

        setCategoryParams(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    /* ------------Создание , редактирование категории------------ */

    async function sendCategory(method, path) {
        try {
            let res = await fetch(`${Api.url}/${path}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                credentials: 'include',
                body: JSON.stringify({
                    name: categoryParams.name,
                    image: categoryParams.image
                })
            })
            if(res.status === 401) {
                let ref = await Api.refresh()
    
                if (ref === 'unAuthorization') {
                    localStorage.removeItem('client')
                    return 'unAuth'
                }
    
                if(ref !== 'ok') {
                    return 'error'
                }
    
                res = await fetch(`${Api.url}/${path}`, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        name: categoryParams.name,
                        image: categoryParams.image
                    })
                })
            }
            if (res.status === 409) {
                return 'exist'
            }
            if (!res.ok) {
                return 'error'
            } 

            res = await res.json()

            return res
        }
        catch(e) {
            return 'error'
        }
    }

    function confirmCategory(e) {
        setError(prev => ({...prev, name: false, image: false}))
        document.getElementById('err_category_exist').classList.remove('error')

        e.preventDefault()
        let err = false
        if(categoryParams.name === '') {
            err = true
            setError(prev => ({...prev, name: true}))
        }
        if(categoryParams.image === '') {
            err = true
            setError(prev => ({...prev, image: true}))
        }
        if(err) {
            return
        }

        (async () => {
            setLoadBtn(prev => prev = false)

            if(edit) {
                // await fetch(`${Api.url}/api/v1/categories/${categoryId}`, {
                //     method: 'PUT',
                //     headers: {
                //         'Content-Type': 'application/json; charset=UTF-8',
                //     },
                //     credentials: 'include',
                //     body: JSON.stringify({
                //         name: categoryParams.name,
                //         image: categoryParams.image
                //     })
                // })
                // .then(res => Administrator.getRes(res))
                // .then(data => {
                //     setLoadBtn(prev => prev = true)
                //     if (data === 'error') {
                //         Notice.Send({type: 'error', text: 'Something went wrong'})
                //         return
                //     }
                //     if (data === 'exist') {
                //         setError(prev => ({...prev, name: true, image: true}))
                //         document.getElementById('err_category_exist').classList.add('error')
                //         return
                //     }
    
                //     Administrator.onSuccess('Category edit')
    
                //     closeAdminPopup()
    
                //     Store.setListener('UPDATE_CATEGORY_LIST_EDIT', data)
                //     Store.setListener('ADMIN_EDIT_CATEGORY', data)
    
                // })
                // .catch(error => console.log(error))

                const data = await sendCategory('PUT', `api/v1/categories/${categoryId}`)
                
                setLoadBtn(prev => prev = true)

                if (data === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
                if (data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true}))
                    document.getElementById('err_category_exist').classList.add('error')
                    return
                }
    
                Administrator.onSuccess('Category edit')
    
                closeAdminPopup()
    
                Store.setListener('UPDATE_CATEGORY_LIST_EDIT', data)
                Store.setListener('ADMIN_EDIT_CATEGORY', data)
                return
            }
            else {
                // await fetch(`${Api.url}/api/v1/categories/create`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json; charset=UTF-8',
                //     },
                //     credentials: 'include',
                //     body: JSON.stringify({
                //         name: categoryParams.name,
                //         image: categoryParams.image
                //     })
                // })
                // .then(res => Administrator.getRes(res))
                // .then(data => {
                    
    
                // })
                // .catch(error => console.log(error))

                const data = await sendCategory('POST', `api/v1/categories/create`)

                setLoadBtn(prev => prev = true)
                if (data === 'error') {
                    Notice.Send({type: 'error', text: 'Something went wrong'})
                    return
                }
                if (data === 'exist') {
                    setError(prev => ({...prev, name: true, image: true}))
                    document.getElementById('err_category_exist').classList.add('error')
                    return
                }
                
                Administrator.onSuccess('You create category!')
                Store.setListener('UPDATE_CATEGORY_LIST_ADD', data)
                Store.setListener('UPDATE_CATEGORY_GLOBAL', data)
            }
        })()
    }

    function closeAdminPopup () {
        document.getElementById('adminEditCategories').classList.remove('open')
        document.getElementById('err_category_exist').classList.remove('error')

        if(!document.getElementById('adminAddCases').classList.contains('open')) {
            document.querySelector('html').classList.remove('hidden')
        }

       resetOptions()
    }

    function resetOptions() {
        setError({
            name: false,
            image: false,
        })

        setCategoryParams({
            name: '',
            image: '',
        })

        setEdit(false)
        setCategoryId(false)
    }

    return {load, loadBtn, error, setError, edit, categoryParams, setCategoryParams, categoryId, changeCatParams, closeAdminPopup, confirmCategory}
}