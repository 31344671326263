import React, { useState, useEffect} from 'react';
import './css/promo.css';
import Title from '../../mainTitle';
import Collect from './components/Collect';
import Dashboard from './components/Dashboard';
import Store from '../../Store';
import ReferalCode from './components/ReferalCode';
import ReferesBlock from './components/dash_board/ReferesBlock';
import Notice from '../../Notice';

const PromoDashboard = () => {

    useEffect(() => {
        Title.setListener('title', 'PROMO')
    }, [])

    const[text, setText] = useState()
    
    const Copy = async () => {
        const text = document.getElementById('link').textContent;
        Notice.Send({type: 'success', text: 'Copied to clipboard!'})
        await navigator.clipboard.writeText(text);
    }

    function setStatus(status) {
        Store.setListener('status', prev => ({...prev, ['status']: status}))
    }


    return (
        <div className='dashboard_block container'>
            
            <div className="dashboard_grid">
                
                <Collect />

                <Dashboard />

            </div>

            <ReferalCode Copy={Copy}/>

            <ReferesBlock />
            
        </div>
    );
};

export default PromoDashboard;