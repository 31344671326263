import React from 'react';

import coin from './../../../../../../fonts/main_icon_fonts/coin.svg'

const HistoryList = ({list}) => {

    return (
        <div className="history_list upgrade">
            {list && list.map((e, i) => (
                // <div className="history_list_item" key={i}>
                //     {/* <div className="createdAt">
                //         <p className="time">{e.createdTime}</p>
                //     </div> */}
                //     {/* <div className="id">{e.betId}</div>

                //     <div className="">Case Battle</div>


                //     <div className="finished">
                //         {e.finishedDate}
                //         <p className="time">{e.finishedTime}</p>
                //     </div>

                //     <div className="initial_bet">
                //         <img src={coin} alt="" />
                //         {e.bet}
                //     </div>

                //     <div className="">{e.rounds}</div>

                //     <div className="payout">
                //         <img src={coin} alt="" />
                //         1.79
                //     </div>

                //     <div className="total">
                //         <img src={coin} alt="" />
                //         {e.totalPayout}
                //     </div> */}

                // </div>
                    <div className="history_list_item" key={i}>
                        <div className="id"><span>{e.id}</span></div>

                        <div className="created">
                            {e.time[0]}
                            <p className="time">{e.time[1]}</p>
                        </div>

                        <div className="initial_bet">
                            <img src={coin} alt="" />
                            <span>{e.betDetails.value}</span>
                        </div>

                        <div className="plurals">
                            87
                        </div>

                        <div className={`payout ${+e.betDetails.totalPayout < 0 ? 'red' : 'green'}`}>
                            <img src={coin} alt="" />
                            <span>{e.betDetails.totalPayout}</span>
                        </div>
                    </div>
            ))}
        </div>
    );
};

export default HistoryList;