import React from 'react';

const EmojiOpen = () => {

    function openEmojiList() {
        document.querySelector('.emoji_list').classList.toggle('open')
        document.getElementById('chat_message').focus()
    }

    return (
        <div className='smiles main_card' onClick={openEmojiList}>
            <div className='icon_smile'></div>
        </div>
    );
};

export default EmojiOpen;